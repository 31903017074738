import React, { useContext, useState } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { currencies } from 'utils';
import { SelectedCompanyContext } from 'context/Context';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import IconButton from 'components/common/IconButton';
import Header from './Header';

const IncomeStatement = ({ reportData, isPdf }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isPrintView, setIsPrintView] = useState(false);

  // Function to get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // YYYY-MM-DD format
  };

  const buttonStyle = isPrintView ? { display: 'none' } : { display: 'block' };

  return (
    <Container id="income-statement">
      <Header />
      <h4 className="text-primary p-0">Income Statement</h4>

      <Row>
        <Col>
          <div id="pdf-content" style={{ paddingTop: '20px' }}>
            <Table
              responsive
              className="styled-table"
              style={{
                color: `${isPdf ? 'black' : 'white'}`,
                marginBottom: '0',
                borderCollapse: 'collapse',
                width: '100%',
                tableLayout: 'fixed'
              }}
            >
              <thead>
                <tr>
                  <th>Income Statement</th>
                  <th>Year 1</th>
                  <th>Year 2</th>
                  <th>Year 3</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, index) => (
                  <React.Fragment key={index}>
                    {index === 14 && (
                      <tr>
                        <td colSpan="4" style={{ height: '20px' }}></td>
                      </tr>
                    )}

                    <tr>
                      {item.bold ? (
                        <td>
                          <strong>{item.label}</strong>
                        </td>
                      ) : (
                        <td>{item.label}</td>
                      )}

                      <td style={{ color: item.year1 < 0 ? 'red' : 'black' }}>
                        {item.year1
                          ? item.percentage
                            ? `${item.year1.toLocaleString()}%`
                            : `${
                                currencies[companyDetails.currency]
                              }${item.year1.toLocaleString()}`
                          : ''}
                      </td>
                      <td style={{ color: item.year2 < 0 ? 'red' : 'black' }}>
                        {item.year2
                          ? item.percentage
                            ? `${item.year2.toLocaleString()}%`
                            : `${
                                currencies[companyDetails.currency]
                              }${item.year2.toLocaleString()}`
                          : ''}
                      </td>
                      <td style={{ color: item.year3 < 0 ? 'red' : 'black' }}>
                        {item.year3
                          ? item.percentage
                            ? `${item.year3.toLocaleString()}%`
                            : `${
                                currencies[companyDetails.currency]
                              }${item.year3.toLocaleString()}`
                          : ''}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default IncomeStatement;
