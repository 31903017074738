import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Table,
  Form,
  FloatingLabel,
  Row,
  Col,
  Tabs,
  Tab,
  Dropdown
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { SelectedCompanyContext } from 'context/Context';
import api from 'utils/api';
import { currencies } from 'utils';
import Item from './Item';
import ExpensePieChart from './ExpensePieChart';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-0 mb-0">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'operating':
      return '';
    case 'summary':
      return '';
    default:
      return '';
  }
};

const aggregateExpenses = (expenses, category) => {
  const aggregated = expenses.reduce((acc, expense) => {
    const categoryValue = parseFloat(
      expense[category]?.replace(/[^0-9.-]+/g, '') || '0'
    );
    if (acc[expense.expenseType]) {
      acc[expense.expenseType] += categoryValue;
    } else {
      acc[expense.expenseType] = categoryValue;
    }
    return acc;
  }, {});

  return Object.entries(aggregated).map(([expenseType, value]) => ({
    expenseType,
    value
  }));
};

function OperatingExpensesList({ close }) {
  const [operatingExpenses, setOperatingExpenses] = useState([]);
  const [yearlyBudget, setYearlyBudget] = useState(0);
  const [monthlyBudget, setMonthlyBudget] = useState(0);
  const [weeklyBudget, setWeeklyBudget] = useState(0);
  const [startupBudget, setStartupBudget] = useState(0);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [filterType, setFilterType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('yearlyBudget');
  const [showCard, setShowCard] = useState('expenses');
  const [year2Increase, setYear2Increase] = useState(10); // Default 10%
  const [year3Increase, setYear3Increase] = useState(10); // Default 10%

  const [activeTab, setActiveTab] = useState('operating'); // Set the default active tab to 'operating'

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/operating-expense/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setOperatingExpenses(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
      return;
    }
    toast.error('No company selected, you must setup a new company to start.');
  }, [companyDetails]);

  useEffect(() => {
    setYearlyBudget(
      operatingExpenses.reduce(
        (acc, operatingExpense) =>
          acc +
          parseFloat(
            operatingExpense.yearlyBudget?.replace(/[^0-9.-]+/g, '') || '0'
          ),
        0
      )
    );
    setMonthlyBudget(
      operatingExpenses.reduce(
        (acc, operatingExpense) =>
          acc +
          parseFloat(
            operatingExpense.expenseAmount?.replace(/[^0-9.-]+/g, '') || '0'
          ),
        0
      )
    );
    setStartupBudget(
      operatingExpenses.reduce(
        (acc, operatingExpense) =>
          acc +
          parseFloat(
            operatingExpense.startupBudget?.replace(/[^0-9.-]+/g, '') || '0'
          ),
        0
      )
    );
    setWeeklyBudget(
      operatingExpenses.reduce(
        (acc, operatingExpense) =>
          acc +
          parseFloat(
            operatingExpense.weeklyBudget?.replace(/[^0-9.-]+/g, '') || '0'
          ),
        0
      )
    );
  }, [operatingExpenses]);

  const handleFilterChange = e => {
    setFilterType(e.target.value);
  };

  const expensesToDisplay = filterType
    ? operatingExpenses.filter(expense => expense.expenseType === filterType)
    : operatingExpenses;

  const generateYearlyData = () => {
    return operatingExpenses.reduce((acc, expense) => {
      const expenseType = expense.expenseType || 'Unknown';
      const yearlyBudget = expense.yearlyBudget
        ? parseFloat(expense.yearlyBudget.replace(/[^0-9.-]+/g, ''))
        : 0;

      if (!acc[expenseType]) {
        acc[expenseType] = { 'Year 1': 0, 'Year 2': 0, 'Year 3': 0 };
      }

      if (expenseType === 'operating') {
        acc[expenseType]['Year 1'] += yearlyBudget;
        const year2Budget =
          yearlyBudget *
          (1 + parseFloat(companyDetails.operatingCostIncrease) / 100);
        acc[expenseType]['Year 2'] += year2Budget;
        acc[expenseType]['Year 3'] +=
          year2Budget *
          (1 + parseFloat(companyDetails.operatingCostIncrease) / 100);
      } else {
        acc[expenseType]['Year 1'] += yearlyBudget;
        const year2Budget =
          yearlyBudget *
          (1 + parseFloat(companyDetails.marketingIncrease) / 100);
        acc[expenseType]['Year 2'] += year2Budget;
        acc[expenseType]['Year 3'] +=
          year2Budget *
          (1 + parseFloat(companyDetails.marketingIncrease) / 100);
      }
      return acc;
    }, {});
  };

  const yearlyData = generateYearlyData();

  const calculateTotals = () => {
    return Object.values(yearlyData).reduce(
      (totals, { 'Year 1': year1, 'Year 2': year2, 'Year 3': year3 }) => {
        totals['Year 1'] += year1;
        totals['Year 2'] += year2;
        totals['Year 3'] += year3;
        return totals;
      },
      { 'Year 1': 0, 'Year 2': 0, 'Year 3': 0 }
    );
  };

  const totals = calculateTotals();
  const aggregatedData = aggregateExpenses(operatingExpenses, selectedCategory);

  const handleSelect = eventKey => {
    setShowCard(eventKey);
  };

  // Tab navigation logic
  const goToNextTab = () => {
    const tabKeys = ['operating', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const tabKeys = ['operating', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <div className="container">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          id="property-form-tabs"
          className="mb-3"
        >
          {/* Tab 1: Operating Expenses */}
          <Tab eventKey="operating" title="Operating Expenses">
            <Row className="pt-3">
              <Form.Group controlId="expenseType">
                <FloatingLabel label="Expense Type">
                  <Form.Select
                    id="expenseTypeFilter"
                    className="form-select"
                    value={filterType}
                    onChange={handleFilterChange}
                  >
                    <option value="">All</option>
                    {Array.from(
                      new Set(
                        operatingExpenses.map(expense => expense.expenseType)
                      )
                    ).map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Table responsive className="styled-table mt-3">
                <thead>
                  <tr>
                    <th>Expense Type</th>
                    <th>Description</th>
                    <th>Months</th>
                    <th>Startup Budget</th>
                    <th>Weekly Budget</th>
                    <th>Monthly Budget</th>
                    <th>Yearly Budget</th>
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {expensesToDisplay.map(operatingExpense => (
                    <Item
                      key={operatingExpense.id}
                      details={operatingExpense}
                      operatingExpenses={operatingExpenses}
                      setOperatingExpenses={setOperatingExpenses}
                      close={close}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className="text-left text-success fw-bold"
                    style={{
                      backgroundColor: 'rgba(0, 123, 255, 0.1)'
                    }}
                  >
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td>
                      {startupBudget.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {weeklyBudget.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {monthlyBudget.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {yearlyBudget.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td className="text-end"></td>
                  </tr>
                </tfoot>
              </Table>
            </Row>
          </Tab>

          {/* Tab 2: Summary */}
          <Tab eventKey="summary" title="Summary">
            <Row className="pt-3">
              <Col xs={12} md={5} className="text-center">
                <Row className="pt-0">
                  <ExpensePieChart
                    data={aggregatedData}
                    category={selectedCategory}
                  />
                </Row>
              </Col>

              <Col xs={12} md={7} className="text-center">
                <Table responsive className="styled-table mt-7">
                  <thead>
                    <tr>
                      <th>Expense Type</th>
                      <th>Year 1</th>
                      <th>Year 2</th>
                      <th>Year 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(yearlyData).map(
                      ([expenseType, budgets]) => (
                        <tr key={expenseType}>
                          <td>
                            {expenseType.charAt(0).toUpperCase() +
                              expenseType.slice(1)}
                          </td>
                          <td>
                            {budgets['Year 1'].toLocaleString('en-US', {
                              style: 'currency',
                              currency: companyDetails.currency
                            })}
                          </td>
                          <td>
                            {budgets['Year 2'].toLocaleString('en-US', {
                              style: 'currency',
                              currency: companyDetails.currency
                            })}
                          </td>
                          <td>
                            {budgets['Year 3'].toLocaleString('en-US', {
                              style: 'currency',
                              currency: companyDetails.currency
                            })}
                          </td>
                        </tr>
                      )
                    )}
                    <tr
                      className="text-left text-success fw-bold"
                      style={{
                        backgroundColor: 'rgba(0, 123, 255, 0.1)'
                      }}
                    >
                      <td className="fw-bold">Total</td>
                      <td>
                        {totals['Year 1'].toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                      </td>
                      <td>
                        {totals['Year 2'].toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                      </td>
                      <td>
                        {totals['Year 3'].toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Tab>
        </Tabs>

        <Row className="mb-3">
          <Col className="text-center mt-3">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={goToPreviousTab}
              disabled={activeTab === 'operating'}
            >
              <FaArrowLeft /> Previous
            </button>
            <button
              className="btn btn-outline-success btn-sm ms-2"
              onClick={goToNextTab}
              disabled={activeTab === 'summary'}
            >
              Next <FaArrowRight />
            </button>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
}

OperatingExpensesList.propTypes = {
  close: PropTypes.func
};

export default OperatingExpensesList;
