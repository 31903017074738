import React from 'react';

const Loader = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      className="d-flex"
    >
      <img
        width={60}
        height={60}
        src={`${process.env.PUBLIC_URL}/custom-assets/tube-spinner.svg`}
      ></img>
    </div>
  );
};

export default Loader;
