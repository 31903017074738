import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const ExpensePieChart = ({ data, category }) => {
  // Prepare data for the pie chart
  const chartData = {
    labels: data.map(item => item.expenseType),
    datasets: [
      {
        label: 'Yearly Budget',
        data: data.map(item => item.value),
        backgroundColor: [
          '#4A90E2',
          '#5DADE2',
          '#85C1E9',
          '#AED6F1',
          '#3498DB',
          '#2E86C1'
        ],
        hoverBackgroundColor: [
          '#4A90E2',
          '#5DADE2',
          '#85C1E9',
          '#AED6F1',
          '#3498DB',
          '#2E86C1'
        ],
        hoverOffset: 4
      }
    ]
  };

  // Options for the pie chart
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: 'Expense Breakdown',
        position: 'top',
        padding: {
          top: 20,
          bottom: 10
        },
        font: {
          size: 24,
          weight: 'bold'
        }
      },
      legend: {
        position: 'left', // Move legend to the top
        align: 'center', // Center the legend
        labels: {
          boxWidth: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `${tooltipItem.label}: $${value.toLocaleString()}`;
          }
        }
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', // Ensure the container takes the full height of its parent
        width: '100%' // Ensure the container takes the full width of its parent
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          maxWidth: '600px', // Optional: maximum width for larger screens
          maxHeight: '600px' // Optional: maximum height for larger screens
        }}
      >
        <Pie data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ExpensePieChart;
