import React, { useContext, useEffect, useState } from 'react';
import { SelectedCompanyContext } from 'context/Context';
import { Table, Tabs, Tab, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';
import RevenuePie from './RevenuePie';
import { parseCurrency } from 'utils';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-0 mb-0">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'purchasePrice':
      return '';
    case 'additionalCosts':
      return '';
    case 'summary':
      return '';
    default:
      return '';
  }
};

const ProductsRevenueList = ({ close }) => {
  const [productsRevenues, setProductsRevenues] = useState([]);
  const [totals, setTotals] = useState({
    totalUnits: 0,
    totalCost: 0,
    totalRevenue: 0,
    totalGrossProfit: 0,
    totalWeeklyProjection: 0,
    totalStartupBudget: 0,
    totalPurchases: 0,
    totalOpeningStock: 0
  });
  const [activeTab, setActiveTab] = useState('purchasePrice');
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/products-revenue/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setProductsRevenues(response.data);
        })

        .catch(error => {
          toast.error(error.message);
        });
    } else {
      toast.error(
        'No company selected, you must set up a new company to start.'
      );
    }
  }, [companyDetails]);

  useEffect(() => {
    const newTotals = productsRevenues.reduce(
      (acc, product) => {
        acc.totalUnits += parseFloat(product.monthlyUnitsSold || 0);
        acc.totalCost += parseCurrency(
          product.totalYearlyCostProjection || '0'
        );
        acc.totalRevenue += parseCurrency(product.totalYearlyRevenue || '0');
        acc.totalGrossProfit += parseCurrency(
          product.yearlyGrossProfitProjection || '0'
        );
        acc.totalWeeklyProjection += parseCurrency(
          product.weeklyProjection || '0'
        );
        acc.totalStartupBudget += parseCurrency(product.startupBudget || '0');
        acc.totalPurchases += parseCurrency(
          product.yearlyProjectionData.purchases[0] || '0'
        );
        acc.totalOpeningStock += parseCurrency(
          product.yearlyProjectionData.openingStock[0] || '0'
        );
        return acc;
      },
      {
        totalUnits: 0,
        totalCost: 0,
        totalRevenue: 0,
        totalGrossProfit: 0,
        totalWeeklyProjection: 0,
        totalStartupBudget: 0,
        totalPurchases: 0,
        totalOpeningStock: 0
      }
    );

    setTotals(newTotals);
  }, [productsRevenues]);

  const growthRate = parseFloat(companyDetails.productSalesIncrease) / 100;
  const costIncrease = parseFloat(companyDetails.productCostIncrease) / 100;

  const yearTableData = [
    {
      year: 'Year 1',
      revenue: totals.totalRevenue,
      cost: totals.totalCost,
      profit: totals.totalRevenue - totals.totalCost, // Adjusted Profit Calculation
      units: totals.totalUnits,
      purchases: totals.totalPurchases,
      openingStock: totals.totalOpeningStock
    },
    {
      year: 'Year 2',
      revenue: totals.totalRevenue * (1 + growthRate),
      cost: totals.totalCost * (1 + costIncrease),
      profit:
        totals.totalRevenue * (1 + growthRate) -
        totals.totalCost * (1 + costIncrease), // Adjusted Profit Calculation
      units: totals.totalUnits * (1 + growthRate),
      purchases: totals.totalPurchases * (1 + growthRate),
      openingStock: totals.totalOpeningStock * (1 + growthRate)
    },
    {
      year: 'Year 3',
      revenue: totals.totalRevenue * Math.pow(1 + growthRate, 2),
      cost: totals.totalCost * Math.pow(1 + costIncrease, 2),
      profit:
        totals.totalRevenue * Math.pow(1 + growthRate, 2) -
        totals.totalCost * Math.pow(1 + costIncrease, 2), // Adjusted Profit Calculation
      units: totals.totalUnits * Math.pow(1 + growthRate, 2),
      purchases: totals.totalPurchases * Math.pow(1 + growthRate, 2),
      openingStock: totals.totalOpeningStock * Math.pow(1 + growthRate, 2)
    }
  ];

  const openingStockYear1 = yearTableData[0].openingStock;
  const closingStockYear1 =
    openingStockYear1 +
    yearTableData[0].purchases -
    yearTableData[0].units * 12;
  const openingStockYear2 = closingStockYear1;
  const closingStockYear2 =
    openingStockYear2 +
    yearTableData[1].purchases -
    yearTableData[1].units * 12;
  const openingStockYear3 = closingStockYear2;

  // Tab navigation logic
  const goToNextTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <div className="container">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={setActiveTab}
          id="products-revenue-tabs"
          className="mb-3"
        >
          <Tab eventKey="purchasePrice" title="Product List ">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th scope="col">Monthly Units</th>
                    <th scope="col">Description</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Retail Price</th>
                    <th scope="col">Inventory Months</th>
                    <th scope="col">Yearly Revenue</th>
                    <th scope="col">Yearly Cost (Proj)</th>
                    <th scope="col">Yearly Gross Profit (Proj)</th>
                    <th scope="col">Startup Budget</th>
                    <th scope="col">Weekly Projection</th>
                    <th className="text-end" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productsRevenues.map((owner, index) => (
                    <Item
                      number={index + 1}
                      key={owner.id}
                      details={owner}
                      productsRevenues={productsRevenues}
                      setProductsRevenues={setProductsRevenues}
                      close={close}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className="text-left text-success fw-bold"
                    style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
                  >
                    <td colSpan="1">Totals:</td>
                    <td colSpan="2" />
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalCost.toLocaleString()}
                    </td>
                    <td />
                    <td />
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalRevenue.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalCost.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalGrossProfit.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalStartupBudget.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalWeeklyProjection.toLocaleString()}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Row>
          </Tab>

          <Tab eventKey="additionalCosts" title="Yearly Summary ">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Year 1</th>
                    <th scope="col">Year 2</th>
                    <th scope="col">Year 3</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Total Revenue</strong>
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[0].revenue.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[1].revenue.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[2].revenue.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Cost</strong>
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[0].cost.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[1].cost.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[2].cost.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Profit</strong>
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[0].profit.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[1].profit.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {yearTableData[2].profit.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Gross Profit Percentage</strong>
                    </td>
                    <td>
                      {yearTableData[0].revenue > 0
                        ? (
                            (yearTableData[0].profit /
                              yearTableData[0].revenue) *
                            100
                          ).toFixed(2) + '%'
                        : ''}
                    </td>
                    <td>
                      {yearTableData[1].revenue > 0
                        ? (
                            (yearTableData[1].profit /
                              yearTableData[1].revenue) *
                            100
                          ).toFixed(2) + '%'
                        : ''}
                    </td>
                    <td>
                      {yearTableData[2].revenue > 0
                        ? (
                            (yearTableData[2].profit /
                              yearTableData[2].revenue) *
                            100
                          ).toFixed(2) + '%'
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 className="text-primary">Stock Control</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Opening Stock</strong>
                    </td>
                    <td>{openingStockYear1.toLocaleString()}</td>
                    <td>{openingStockYear2.toLocaleString()}</td>
                    <td>{openingStockYear3.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Purchases</strong>
                    </td>
                    <td>{yearTableData[0].purchases.toLocaleString()}</td>
                    <td>{yearTableData[1].purchases.toLocaleString()}</td>
                    <td>{yearTableData[2].purchases.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Unit Sales</strong>
                    </td>
                    <td>{(yearTableData[0].units * 12).toLocaleString()}</td>
                    <td>{(yearTableData[1].units * 12).toLocaleString()}</td>
                    <td>{(yearTableData[2].units * 12).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Closing Stock</strong>
                    </td>
                    <td>{closingStockYear1.toLocaleString()}</td>
                    <td>{closingStockYear2.toLocaleString()}</td>
                    <td>
                      {(
                        openingStockYear3 +
                        yearTableData[2].purchases -
                        yearTableData[2].units * 12
                      ).toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Tab>

          <Tab eventKey="summary" title="Chart">
            <Row className="pt-3">
              <div style={{ width: '70%' }}>
                <RevenuePie data={productsRevenues} />
              </div>
            </Row>
          </Tab>
        </Tabs>
        <div className="border-dashed border-bottom my-3" />
        <Row className="mb-3">
          <Col className="text-center mt-3">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={goToPreviousTab}
              disabled={activeTab === 'purchasePrice'}
            >
              <FaArrowLeft /> Previous
            </button>
            <button
              className="btn btn-outline-success btn-sm ms-2"
              onClick={goToNextTab}
              disabled={activeTab === 'summary'}
            >
              Next <FaArrowRight />
            </button>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};

ProductsRevenueList.propTypes = {
  close: PropTypes.func
};

export default ProductsRevenueList;
