import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel,
  Modal
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  OperatingExpenseFormModalContext,
  OperatingExpenseWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import editing from 'assets/img/icons/spot-illustrations/6.svg';

const PayrollForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(OperatingExpenseWizardContext);
  const { setShowFormModal } = useContext(OperatingExpenseFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const [superannuation, setSuperannuation] = useState('');
  const [workCover, setWorkCover] = useState('');
  const [other, setOther] = useState('');
  const [totalPayrollExpense, setTotalPayrollExpense] = useState('');
  const [startupBudget, setStartupBudget] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);
  const [yearlyBudget, setYearlyBudget] = useState('');
  const [weeklyBudget, setWeeklyBudget] = useState('');
  const [weeklyPayroll, setWeeklyPayroll] = useState('');
  const [totalGrossSalary, setTotalGrossSalary] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({
    defaultValues: {
      roleType: 'partTime',
      annualSalary: '$110,000',
      numberOfMonths: '6',
      numberOfPeople: '1'
    },
    mode: 'onChange'
  });

  const calculateOpeningBalance = (amount, months) => {
    const parsedAmount = amount
      ? parseFloat(amount.replace(/[^0-9.]/g, ''))
      : 0;
    const parsedMonths = parseInt(months);
    const budget = parsedAmount * parsedMonths;
    setStartupBudget(
      budget.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );
    const yearly = parsedAmount * 12;
    setYearlyBudget(
      yearly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate total gross salary
    const totalGrossSalary = parsedAmount * watch().numberOfPeople;
    setTotalGrossSalary(
      totalGrossSalary.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate weekly budget
    const weekly = totalGrossSalary / 52;
    setWeeklyBudget(
      weekly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate superannuation
    const superannuationAmount =
      (totalGrossSalary * parseFloat(companyDetails.superannuation)) / 100;
    setSuperannuation(
      superannuationAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate work cover
    const workCoverAmount =
      (totalGrossSalary * parseFloat(companyDetails.workCover)) / 100;
    setWorkCover(
      workCoverAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate other
    const otherAmount =
      (totalGrossSalary * parseFloat(companyDetails.others)) / 100;
    setOther(
      otherAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate total payroll expense
    const totalPayrollExpense =
      totalGrossSalary + superannuationAmount + workCoverAmount + otherAmount;
    setTotalPayrollExpense(
      totalPayrollExpense.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate opening budget based on total payroll expense
    const openingBudget = (totalPayrollExpense / 12) * parsedMonths;
    setStartupBudget(
      openingBudget.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate weekly payroll
    const weeklyPayroll = totalPayrollExpense / 52;
    setWeeklyPayroll(
      weeklyPayroll.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );
  };

  useEffect(() => {
    calculateOpeningBalance(watch().annualSalary, watch().numberOfMonths);
  }, [watch().annualSalary, watch().numberOfMonths, watch().numberOfPeople]);

  useEffect(() => {
    if (edit === 'payroll-expense' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      setValue('roleType', editDetails.roleType);
      setValue('annualSalary', editDetails.annualSalary);
      setValue('numberOfMonths', editDetails.numberOfMonths);
      setValue('numberOfPeople', editDetails.numberOfPeople);

      calculateOpeningBalance(
        editDetails.annualSalary,
        editDetails.numberOfMonths
      );
    }
  }, [edit]);

  console.log(errors);
  const [showNotif, setShowNotif] = useState(false);
  const [totalStaff, setTotalStaff] = useState(0);
  const [totalPayroll, setTotalPayroll] = useState(0);

  useEffect(() => {
    api.get(`services-revenue/total-staff/${companyDetails.id}`).then(res => {
      const total = res.data.totalStaff;

      if (total > 0 && total > res.data.totalPayrolls) {
        setTotalStaff(total);
        setShowNotif(true);
        setTotalPayroll(res.data.totalPayrolls);
      }
    });
  }, [companyDetails, setShowFormModal]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSubmitData = data => {
    data.totalGrossSalary = totalGrossSalary;
    data.superannuation = superannuation;
    data.workCover = workCover;
    data.other = other;
    data.startupBudget = startupBudget;
    data.totalPayrollExpense = totalPayrollExpense;
    data.weeklyPayroll = weeklyPayroll;
    console.log(data);
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : { companyId: companyDetails.id, ...data };

    api
      .post(
        isEditing ? '/payroll-expense/update' : '/payroll-expense/create',
        payload
      )
      .then(res => {
        reset();
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Payroll Expenses"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="roleType"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Modal show={showNotif} onHide={() => setShowNotif(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payroll Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center justify-content-center pt-0">
            <Col xs={12} md={6} className="text-center">
              <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
                <img
                  src={editing}
                  className="img-fluid"
                  alt="Editing"
                  style={{ maxWidth: '60%', height: 'auto' }}
                />
              </div>
            </Col>
            <p className="pt-3">
              There are {totalStaff} employees providing services and{' '}
              {totalPayroll} are registered in payroll. Please make sure to add
              them all to the payroll.
            </p>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowNotif(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Payroll Expenses"
              description="Prepaid costs in business refer to expenses that have been paid in advance but have not yet been consumed or used up. These expenses are typically recorded as assets on the balance sheet until they are utilized and recognized as expenses."
              className="text-primary"
            />

            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="div" style={{ backgroundColor: '' }}>
                    {/* Your popover content */}
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>

            <Form onSubmit={handleSubmit(onSubmitData)}>
              <Row className="g-3 pt-3">
                <Col lg={3}>
                  <Form.Group controlId="numberOfMonths">
                    <FloatingLabel label="Number Of Months?">
                      <Form.Control
                        type="number"
                        name="numberOfMonths"
                        {...register('numberOfMonths', {
                          required: 'Number of months is required.'
                        })}
                        isInvalid={!!errors['numberOfMonths']}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors['numberOfMonths']
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.numberOfMonths?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="numberOfPeople">
                    <FloatingLabel label="Number of People">
                      <Form.Control
                        type="number"
                        name="numberOfPeople"
                        {...register('numberOfPeople', {
                          required: 'Number of people is required.'
                        })}
                        isInvalid={!!errors['numberOfPeople']}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors['numberOfPeople']
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors['numberOfPeople']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="annualSalary">
                    <CurrencyWizardInput
                      symbol={currencySymbol} // Use currency symbol
                      name="annualSalary"
                      label="Annual Salary"
                      onChange={e =>
                        calculateOpeningBalance(
                          e.target.value,
                          watch().numberOfMonths
                        )
                      }
                      errors={errors}
                      formGroupProps={{ className: 'mb-0', as: Col }}
                      formControlProps={{
                        ...register('annualSalary', {
                          required: 'Annual salary is required.'
                        })
                      }}
                      setValue={setValue}
                      watch={watch}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="roleType">
                    <FloatingLabel label="Role Type">
                      <Form.Select
                        aria-label="Select the role type"
                        name="roleType"
                        {...register('roleType', {
                          required: 'Role Type is required.'
                        })}
                        isInvalid={!!errors['roleType']}
                      >
                        <option value="fullTime">Full-Time</option>
                        <option value="partTime">Part-Time</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors['roleType']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="g-3 pt-3">
                <Col lg={3}>
                  <Form.Group controlId="totalGrossSalary">
                    <FloatingLabel label="Total Gross Salary">
                      <Form.Control
                        type="text"
                        placeholder="Total Gross Salary"
                        name="totalGrossSalary"
                        value={totalGrossSalary}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group controlId="superannuation">
                    <FloatingLabel label="Superannuation">
                      <Form.Control
                        type="text"
                        placeholder="Superannuation"
                        name="superannuation"
                        value={superannuation}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="workCover">
                    <FloatingLabel label="Work Cover">
                      <Form.Control
                        type="text"
                        placeholder="Work Cover"
                        name="workCover"
                        value={workCover}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="other">
                    <FloatingLabel label="Other">
                      <Form.Control
                        type="text"
                        placeholder="Other"
                        name="other"
                        value={other}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="g-3 pt-3">
                <Col lg={4}>
                  <Form.Group>
                    <FloatingLabel label="Startup Budget">
                      <Form.Control
                        type="text"
                        placeholder="Startup Budget"
                        name="startupBudget"
                        value={startupBudget}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group controlId="totalPayrollExpense">
                    <FloatingLabel label="Total Payroll Expense">
                      <Form.Control
                        type="text"
                        placeholder="Total Payroll Expense"
                        name="totalPayrollExpense"
                        value={totalPayrollExpense}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group controlId="weeklyPayroll">
                    <FloatingLabel label="Weekly Payroll">
                      <Form.Control
                        type="text"
                        placeholder="Weekly Payroll"
                        name="weeklyPayroll"
                        value={weeklyPayroll}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <div className="border-dashed border-bottom my-3" />

              <Row>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      className="float-end"
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>

      <div className="border-dashed border-bottom my-3" />
    </Row>
  );
};

export default PayrollForm;
