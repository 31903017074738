import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  Container,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';

import RetailStoreForm from './RetailStoreForm';
import GarmentForm from './GarmentForm';
import ProductsForm from './ProductsForm';
import PropertyForm from './PropertyForm';
import OnlineRevenueForm from './OnlineRevenueForm';
import WholesaleRevenueFormForm from './WholesaleRevenueForm';
const RevenueStreamsForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { edit, isFromLoan } = useContext(RevenueWizardContext);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  useEffect(() => {
    if (isFromLoan) {
      setSelectedDiv('div2');
    }
    if (edit === 'products') {
      setSelectedDiv('div1');
    }
    if (edit === 'property') {
      setSelectedDiv('div2');
    }
    if (edit === 'whole-sale') {
      setSelectedDiv('div4');
    }
    if (edit === 'online') {
      setSelectedDiv('div3');
    }
  }, [edit]);

  const [selectedDiv, setSelectedDiv] = useState(null);
  const handleSelectChange = event => {
    setSelectedDiv(event.target.value);
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Container>
        <Row className="align-items-center justify-content-between">
          <div className="mt-3">
            <IconButton
              variant="outline-primary"
              icon="fa-solid fa-info"
              onClick={handleClick}
              size="sm"
            ></IconButton>
          </div>

          <div ref={ref}>
            <Overlay
              show={show}
              target={target}
              placement="right"
              container={ref.current}
              containerPadding={20}
            >
              <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                <Popover.Header as="h3">Help Center</Popover.Header>
                <Popover.Body as="div" style={{ backgroundColor: '' }}>
                  {/* Your popover content */}
                  <Row
                    as={motion.div}
                    className="g-3"
                    initial={{ x: '-20%' }}
                    animate={{ x: '0%' }}
                    exit={{
                      x: window.innerWidth,
                      transition: { duration: 0.5 }
                    }}
                  >
                    <PageHeader
                      className="text-primary"
                      title="Revenue by Products"
                      description=""
                    />
                    <div
                      className="bor"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <Row>
                        <Container>
                          <h6 className="fw-bold pt-2">
                            What is Revenue by Products?
                          </h6>

                          <p>
                            Revenue by products refers to the breakdown of a
                            company's total revenue based on the individual
                            products or services it sells. It provides insights
                            into which products or services are driving the
                            company's sales and revenue growth.
                          </p>

                          <h6>1. Product Segmentation</h6>
                          <p>
                            Companies categorize their offerings into distinct
                            products or services. Each product or service may
                            have its own pricing, target market, and sales
                            strategy.
                          </p>

                          <h6 className="fw-bold pt-2">2. Revenue Analysis</h6>
                          <p>
                            Revenue by products involves analyzing sales data to
                            determine how much revenue each product or service
                            has generated over a specific period, such as a
                            month, quarter, or year.
                          </p>

                          <h6 className="fw-bold pt-2">
                            3. Performance Evaluation
                          </h6>
                          <p>
                            By analyzing revenue by products, companies can
                            evaluate the performance of each product or service.
                            They can identify which products are the most
                            profitable, which ones are experiencing growth, and
                            which ones may be underperforming.
                          </p>

                          <h6 className="fw-bold pt-2">
                            4. Strategic Decision-Making
                          </h6>
                          <p>
                            Understanding revenue by products helps companies
                            make strategic decisions about resource allocation,
                            product development, marketing efforts, and pricing
                            strategies.
                          </p>

                          <p>
                            For example, if a certain product is driving
                            significant revenue growth, the company may choose
                            to invest more resources in its marketing and
                            development.
                          </p>

                          <h6 className="fw-bold pt-2">
                            5. Forecasting and Planning
                          </h6>
                          <p>
                            Revenue by products also aids in forecasting future
                            revenue and planning sales targets. By analyzing
                            historical sales data and trends, companies can make
                            informed predictions about future product
                            performance and adjust their strategies accordingly.
                          </p>

                          <p>
                            Overall, revenue by products provides valuable
                            insights into the financial health of a company and
                            helps guide decision-making to optimize sales and
                            profitability.
                          </p>

                          <h5>Overview</h5>
                          <p>
                            The <strong>Product RevenueForm</strong> component
                            allows users to input and submit financial
                            projections related to a product's cost, pricing,
                            and revenue. It calculates various financial metrics
                            such as total revenue, cost of goods, and profit
                            projections based on user inputs. It also supports
                            editing existing data, generating PDF reports, and
                            uploading product images.
                          </p>

                          <h5>Features</h5>
                          <ul>
                            <li>
                              <strong>Product Information Input:</strong> Enter
                              details about your product including name, type,
                              cost price, retail price, quantity, and number of
                              months of inventory.
                            </li>
                            <li>
                              <strong>Calculated Metrics:</strong> View
                              calculated metrics like total yearly revenue,
                              yearly cost projection, gross profit, startup
                              budget, and weekly projection.
                            </li>
                            <li>
                              <strong>PDF Generation:</strong> Generate a PDF
                              report of the financial projections by clicking
                              the <strong>Generate PDF</strong> button.
                            </li>
                            <li>
                              <strong>Image Upload:</strong> Optionally upload a
                              product image with a maximum size of 400KB.
                            </li>
                          </ul>

                          <h5>How to Use the Form</h5>
                          <ol>
                            <li>
                              <strong>Filling Out the Form:</strong> Enter the
                              product name, select the product type, input the
                              cost and retail prices, specify the quantity and
                              number of months of inventory.
                            </li>
                            <li>
                              <strong>Viewing Calculated Metrics:</strong> The
                              form will automatically update the calculated
                              metrics based on the information entered.
                            </li>
                            <li>
                              <strong>Submitting the Form:</strong> Click the{' '}
                              <strong>Submit</strong> button to save or update
                              the data.
                            </li>
                            <li>
                              <strong>Generating a PDF Report:</strong> Click
                              the <strong>Generate PDF</strong> button to create
                              a PDF report of the financial projections.
                            </li>
                            <li>
                              <strong>Uploading an Image:</strong> Click on the
                              dropzone to upload a product image (300x300
                              pixels, max 400KB).
                            </li>
                          </ol>

                          <h5>Additional Notes</h5>
                          <ul>
                            <li>
                              <strong>Editing Mode:</strong> Pre-fills fields
                              with existing data if in editing mode. You can
                              make changes and submit to update the record.
                            </li>
                            <li>
                              <strong>Error Handling:</strong> Ensure all
                              required fields are filled. Validation errors will
                              be displayed below the respective fields.
                            </li>
                            <li>
                              <strong>Currency Symbol:</strong> The currency
                              symbol is automatically applied based on the
                              selected company’s currency.
                            </li>
                          </ul>

                          <h5>Troubleshooting</h5>
                          <ul>
                            <li>
                              <strong>Errors on Submission:</strong> Verify all
                              required fields are filled and retry if necessary.
                              Network or server errors may require you to
                              contact support.
                            </li>
                            <li>
                              <strong>PDF Generation Issues:</strong> Ensure
                              your browser settings allow pop-ups and file
                              downloads.
                            </li>
                          </ul>

                          <h5>Conclusion</h5>
                          <p>
                            The <strong>Peoduct Form</strong> component provides
                            a comprehensive tool for managing and projecting
                            product financials. Use it to input your product
                            data, view financial projections, and generate
                            detailed reports as needed.
                          </p>
                        </Container>

                        <div className="border-dashed border-bottom my-3" />
                      </Row>
                    </div>
                  </Row>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>

          <Container>
            <div className=" mt-3">
              <Form.Group controlId="revenueType">
                <FloatingLabel label="Revenue Stream">
                  <Form.Select
                    value={selectedDiv}
                    onChange={handleSelectChange}
                    aria-label="Select the role type"
                    name="revenueType"
                  >
                    <option value="">Select Revenue Stream</option>
                    <option value="div3">Online Revenue</option>
                    <option value="div1">Products Revenue</option>
                    <option value="div2">Property Development</option>
                    <option value="div4">Wholesale Revenue </option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </div>

            {/* Render the selected div based on selectedDiv state */}
            <div>
              {selectedDiv === 'div1' && (
                <div>
                  <ProductsForm />
                </div>
              )}

              {selectedDiv === 'div2' && (
                <div>
                  <PropertyForm />
                </div>
              )}

              {selectedDiv === 'div3' && (
                <div>
                  <OnlineRevenueForm />
                </div>
              )}

              {selectedDiv === 'div4' && (
                <div>
                  <WholesaleRevenueFormForm />
                </div>
              )}
            </div>
          </Container>
        </Row>
      </Container>
    </Row>
  );
};

export default RevenueStreamsForm;
