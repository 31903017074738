import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const ServicesPie = ({ data }) => {
  // Extract categories and their Year 1 totals
  const categories = Object.keys(data);
  const totals = categories.map(
    category => data[category].totalYearlyRevenue || 0
  );

  // Prepare the data for the Pie chart
  const chartData = {
    labels: categories.map(
      category => category.charAt(0).toUpperCase() + category.slice(1)
    ), // Capitalize first letter of each category
    datasets: [
      {
        data: totals,
        backgroundColor: [
          '#4A90E2',
          '#5DADE2',
          '#85C1E9',
          '#A3E4D7',
          '#F7DC6F',
          '#F5B041',
          '#EB984E'
        ], // Add more colors if there are more categories
        hoverBackgroundColor: [
          '#4A90E2',
          '#5DADE2',
          '#85C1E9',
          '#A3E4D7',
          '#F7DC6F',
          '#F5B041',
          '#EB984E'
        ]
      }
    ]
  };

  // Chart options
  const chartOptions = {
    plugins: {
      legend: {
        position: 'left', // Move legend to the left
        align: 'center', // Center the legend horizontally
        labels: {
          boxWidth: 20, // Set the width of the legend boxes
          padding: 15 // Space between legend items
        }
      },
      title: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <div style={{ width: '60%', height: '400px' }}>
        <Pie data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default ServicesPie;
