import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Form } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import AnalysisChart from './AnalysisChart';
import { SelectedCompanyContext } from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';

const months = [
  { value: 'all', label: 'All Months' },
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

const Analysis = () => {
  const [chartData, setChartData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('all');
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/analysis/getRevenues?companyId=${companyDetails.id}`)
        .then(response => {
          setChartData(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
    }
  }, [companyDetails]);

  useEffect(() => {
    if (selectedMonth === 'all') {
      setFilteredData(chartData);
    } else {
      const monthData = chartData.filter(item => item.month == selectedMonth);
      setFilteredData(monthData);
    }
  }, [selectedMonth, chartData]);

  return (
    <Card className="mt-3" style={{ height: '500px' }}>
      <Card.Header className="border-bottom">
        <Row className="flex-between-center gy-2">
          <Col xs="auto">
            <h4 className="mb-0 me-x1">Monthly Projection</h4>
          </Col>
          <Col xs="auto">
            <Form.Select
              value={selectedMonth}
              onChange={e => setSelectedMonth(e.target.value)}
            >
              {months.map(month => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Card.Header>
      <SimpleBarReact>
        <Card.Body>
          <AnalysisChart chartData={filteredData} />
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

export default Analysis;
