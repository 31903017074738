import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  Col,
  Row,
  Card,
  Button,
  Dropdown,
  Tabs,
  Tab,
  Form,
  FloatingLabel
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';
import PayrollPie from './PayrollPie';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-0 mb-0">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'operating':
      return '';
    case 'summary':
      return '';
    default:
      return '';
  }
};

function PayrollList({ close }) {
  const [payrolls, setPayrolls] = useState([]);
  const [totalPayroll, setTotalPayroll] = useState(0);
  const [totalStartupBudget, setTotalStartupBudget] = useState(0);
  const [totalOthers, setTotalOthers] = useState(0);
  const [totalWeekly, setTotalWeekly] = useState(0);
  const [totalWorkCover, setTotalWorkCover] = useState(0);
  const [totalAnnuation, setTotalAnnuation] = useState(0);
  const [totalAnnualSalary, setTotalAnnualSalary] = useState(0);
  const [totalGrossSalary, setTotalGrossSalary] = useState(0);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [showCard, setShowCard] = useState('payroll');

  const [yearlyData, setYearlyData] = useState({
    Gross: { year1: 0, year2: 0, year3: 0 },
    superannuation: { year1: 0, year2: 0, year3: 0 },
    workcover: { year1: 0, year2: 0, year3: 0 },
    others: { year1: 0, year2: 0, year3: 0 }
  });

  const [activeTab, setActiveTab] = useState('operating'); // Set the default active tab to 'operating'

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/payroll-expense/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setPayrolls(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
    } else {
      toast.error('No company selected. Please set up a new company.');
    }
  }, [companyDetails]);

  useEffect(() => {
    const calculateTotalPayroll = payrolls.reduce((acc, payroll) => {
      const payrollExpense =
        parseFloat(payroll.totalPayrollExpense.replace(/[^0-9.-]+/g, '')) || 0;
      return acc + payrollExpense;
    }, 0);
    setTotalPayroll(calculateTotalPayroll);

    setTotalAnnualSalary(
      payrolls.reduce((acc, payroll) => {
        return (
          acc +
          (parseFloat(payroll.annualSalary.replace(/[^0-9.-]+/g, '')) || 0)
        );
      }, 0)
    );

    setTotalGrossSalary(
      payrolls.reduce((acc, payroll) => {
        return (
          acc +
          (parseFloat(payroll.totalGrossSalary.replace(/[^0-9.-]+/g, '')) || 0)
        );
      }, 0)
    );
    setTotalAnnuation(
      payrolls.reduce((acc, payroll) => {
        return (
          acc +
          (parseFloat(payroll.superannuation.replace(/[^0-9.-]+/g, '')) || 0)
        );
      }, 0)
    );

    setTotalWorkCover(
      payrolls.reduce((acc, payroll) => {
        return (
          acc + (parseFloat(payroll.workCover.replace(/[^0-9.-]+/g, '')) || 0)
        );
      }, 0)
    );
    setTotalWeekly(
      payrolls.reduce((acc, payroll) => {
        return (
          acc +
          (parseFloat(payroll.weeklyPayroll.replace(/[^0-9.-]+/g, '')) || 0)
        );
      }, 0)
    );
    setTotalOthers(
      payrolls.reduce((acc, payroll) => {
        return acc + (parseFloat(payroll.other.replace(/[^0-9.-]+/g, '')) || 0);
      }, 0)
    );
    setTotalStartupBudget(
      payrolls.reduce((acc, payroll) => {
        return (
          acc +
          (parseFloat(payroll.startupBudget.replace(/[^0-9.-]+/g, '')) || 0)
        );
      }, 0)
    );
    const updatedYearlyData = {
      gross: {
        ...yearlyData.gross,
        year1: totalGrossSalary,
        year2:
          totalGrossSalary *
          (1 + parseFloat(companyDetails.payrollIncrease) / 100),
        year3:
          totalGrossSalary *
          (1 + parseFloat(companyDetails.payrollIncrease) / 100) *
          (1 + parseFloat(companyDetails.payrollIncrease) / 100)
      },
      superannuation: {
        ...yearlyData.superannuation,
        year1: totalAnnuation,
        year2:
          totalAnnuation *
          (1 + parseFloat(companyDetails.superannuation) / 100),
        year3:
          totalAnnuation *
          (1 + parseFloat(companyDetails.superannuation) / 100) *
          (1 + parseFloat(companyDetails.superannuation) / 100)
      },
      workcover: {
        ...yearlyData.workcover,
        year1: totalWorkCover,
        year2:
          totalWorkCover * (1 + parseFloat(companyDetails.workCover) / 100),
        year3:
          totalWorkCover *
          (1 + parseFloat(companyDetails.workCover) / 100) *
          (1 + parseFloat(companyDetails.workCover) / 100)
      },
      others: {
        ...yearlyData.others,
        year1: totalOthers,
        year2: totalOthers * (1 + parseFloat(companyDetails.others) / 100),
        year3:
          totalOthers *
          (1 + parseFloat(companyDetails.others) / 100) *
          (1 + parseFloat(companyDetails.others) / 100)
      }
    };

    setYearlyData(updatedYearlyData);
  }, [
    payrolls,
    totalAnnualSalary,
    totalGrossSalary,
    totalAnnuation,
    totalOthers,
    totalPayroll,
    totalStartupBudget
  ]);

  // Tab navigation logic
  const goToNextTab = () => {
    const tabKeys = ['operating', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const tabKeys = ['operating', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <div className="container">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          id="property-form-tabs"
          className="mb-3"
        >
          {/* Tab 1: Operating Expenses */}
          <Tab eventKey="operating" title="Operating Expenses">
            <Row className="pt-3">
              <Table responsive className="styled-table mt-3">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Months</th>
                    <th>People</th>
                    <th>Gross Salary</th>
                    <th>Annual Salary</th>
                    <th>Annuation</th>
                    <th>Work Cover</th>
                    <th>Weekly Payroll</th>
                    <th>Other</th>
                    <th>Startup Budget</th>
                    <th>Payroll Expense</th>
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {payrolls.map(payroll => (
                    <Item
                      key={payroll.id}
                      details={payroll}
                      payrolls={payrolls}
                      setPayrolls={setPayrolls}
                      close={close}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className="text-left text-success fw-bold"
                    style={{
                      backgroundColor: 'rgba(0, 123, 255, 0.1)'
                    }}
                  >
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td>
                      {totalGrossSalary.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {totalAnnualSalary.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {totalAnnuation.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {totalWorkCover.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {totalWeekly.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {totalOthers.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {totalStartupBudget.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td>
                      {totalPayroll.toLocaleString('en-US', {
                        style: 'currency',
                        currency: companyDetails.currency
                      })}
                    </td>
                    <td className="text-end"></td>
                  </tr>
                </tfoot>
              </Table>
            </Row>
          </Tab>

          {/* Tab 2: Summary */}
          <Tab eventKey="summary" title="Summary">
            <Row className="pt-3">
              <Col xs={12} md={5} className="text-center">
                <Row className="pt-0">
                  <PayrollPie data={payrolls} />
                </Row>
              </Col>

              <Col xs={12} md={7} className="text-center">
                <Table responsive className="styled-table mt-6">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Year 1</th>
                      <th>Year 2</th>
                      <th>Year 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(yearlyData).map(([category, years]) => (
                      <tr key={category}>
                        <td>
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </td>
                        <td>
                          {years.year1.toLocaleString('en-US', {
                            style: 'currency',
                            currency: companyDetails.currency
                          })}
                        </td>
                        <td>
                          {years.year2.toLocaleString('en-US', {
                            style: 'currency',
                            currency: companyDetails.currency
                          })}
                        </td>
                        <td>
                          {years.year3.toLocaleString('en-US', {
                            style: 'currency',
                            currency: companyDetails.currency
                          })}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>
                          {Object.values(yearlyData)
                            .reduce((acc, years) => acc + years.year1, 0)
                            .toLocaleString('en-US', {
                              style: 'currency',
                              currency: companyDetails.currency
                            })}
                        </strong>
                      </td>
                      <td>
                        <strong>
                          {Object.values(yearlyData)
                            .reduce((acc, years) => acc + years.year2, 0)
                            .toLocaleString('en-US', {
                              style: 'currency',
                              currency: companyDetails.currency
                            })}
                        </strong>
                      </td>
                      <td>
                        <strong>
                          {Object.values(yearlyData)
                            .reduce((acc, years) => acc + years.year3, 0)
                            .toLocaleString('en-US', {
                              style: 'currency',
                              currency: companyDetails.currency
                            })}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Tab>
        </Tabs>

        <Row className="mb-3">
          <Col className="text-center mt-3">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={goToPreviousTab}
              disabled={activeTab === 'operating'}
            >
              <FaArrowLeft /> Previous
            </button>
            <button
              className="btn btn-outline-success btn-sm ms-2"
              onClick={goToNextTab}
              disabled={activeTab === 'summary'}
            >
              Next <FaArrowRight />
            </button>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
}

PayrollList.propTypes = {
  close: PropTypes.func
};

export default PayrollList;
