import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Modal,
  Row,
  Col,
  Form,
  FloatingLabel,
  Card,
  Tabs,
  Tab,
  Ratio
} from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/4.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FundingFormModalContext, FundingWizardContext } from 'context/Context';
import FundingWizard from 'components/custom/wizards/fundingWizard/FundingWizard';
import IconButton from 'components/common/IconButton';
import OwnersList from 'components/custom/lists/stage2/OwnerList';
import InvestorsList from 'components/custom/lists/stage2/InvestorList';
import LoansList from 'components/custom/lists/stage2/loanList';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-3 mb-3">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'purchasePrice':
      return 'Enter the purchase price and renovation costs for the property.';
    case 'additionalCosts':
      return 'Fill in additional costs including legal fees, taxes, insurance, and other expenses.';
    case 'summary':
      return 'Review the summary of costs, profit margin, and projected profit percentage.';
    default:
      return '';
  }
};

const OwnerSettings = ({ state }) => {
  const [modalShow, setModalShow] = useState(false);
  const [videoModalShow, setVideoModalShow] = useState(false);
  const { showFormModal, setShowFormModal, setIsFromProperty } = useContext(
    FundingFormModalContext
  );
  const { setStep } = useContext(FundingWizardContext);

  const [activeTab, setActiveTab] = useState('purchasePrice');

  const [settingView, setSettingView] = useState('owners');

  useEffect(() => {
    if (state && state.step) {
      setIsFromProperty(true);
      setShowFormModal(state.showModal);
      setStep(state.step);
    }
  }, [state, setShowFormModal, setIsFromProperty, setStep]);

  const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];

  const goToNextTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { scale: 0.8, opacity: 0 },
        visible: { scale: 1, opacity: 1 }
      }}
    >
      {/* Image Row */}
      <Row className="align-items-center justify-content-center pt-6">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
            <img
              src={editing}
              className="img-fluid"
              alt="Editing"
              style={{ maxWidth: '60%', height: 'auto' }}
            />
          </div>
        </Col>
      </Row>

      {/* Message Row */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            <h2 className="text-primary pt-0">Business Funding</h2>
            <p>
              Business funding involves acquiring capital to sustain or expand a
              business. Potential sources encompass owners, investors, loans,
              personal savings, or grants, contingent on the business's stage
              and objectives. The required capital fluctuates according to the
              business's startup needs. How much capital have you raised?
            </p>
          </div>
        </Col>
      </Row>

      {/* Navigation Row */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/buzPitchSettings" className="text-decoration-none">
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-arrow-left"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>

            <Link
              to="/help-center#businessFunding"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-info"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>

            <IconButton
              onClick={() => setShowFormModal(true)}
              variant="outline-primary"
              size="lg"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />
            <IconButton
              onClick={() => setModalShow(true)}
              variant="outline-warning"
              size="lg"
              icon="fa-solid fa-search"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />

            <Link to="/StartupCostsSettings" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-right"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
          </div>
        </Col>
      </Row>

      {/* Summary Modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Body>
          <Container>
            <PageHeader
              title="Business Funding Contributions"
              description="How have you raised capital to start?"
              className="mb-0"
            />

            <Tabs
              activeKey={activeTab}
              onSelect={setActiveTab}
              id="property-form-tabs"
              className="mb-3"
            >
              <Tab eventKey="purchasePrice" title="Owners">
                <Row className="pt-3">
                  <OwnersList close={() => setModalShow(false)} />
                </Row>
              </Tab>
              <Tab eventKey="additionalCosts" title="Investors">
                <Row className="pt-3">
                  <InvestorsList close={() => setModalShow(false)} />
                </Row>
              </Tab>
              <Tab eventKey="summary" title="Bank Loans">
                <Row className="pt-3">
                  <LoansList close={() => setModalShow(false)} />
                </Row>
              </Tab>
            </Tabs>

            <Row className="mb-3">
              <Col className="text-center mt-3">
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={goToPreviousTab}
                  disabled={activeTab === 'purchasePrice'}
                >
                  <FaArrowLeft /> Previous
                </button>
                <button
                  className="btn btn-outline-success btn-sm ms-2"
                  onClick={goToNextTab}
                  disabled={activeTab === 'summary'}
                >
                  Next <FaArrowRight />
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-danger"
            onClick={() => setModalShow(false)}
            icon="fa-solid fa-times"
          />
        </Modal.Footer>
      </Modal>

      {/* Wizard Modal */}
      <Modal
        show={showFormModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Body>
          <Container>
            <FundingWizard variant="pills" validation={false} />
          </Container>
        </Modal.Body>
      </Modal>

      {/* Video Modal */}
      <Modal
        size="lg"
        show={videoModalShow}
        onHide={() => setVideoModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Company Funding Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio="16x9">
            <iframe
              src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
              allowFullScreen
              title="AuziPlan"
            />
          </Ratio>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OwnerSettings;
