import React, { useContext, useEffect, useState } from 'react';
import { Table, Tabs, Tab, Row, Col, Card, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropertyPie from './PropertyPie';
import { parseCurrency } from 'utils';
import { SelectedCompanyContext } from 'context/Context';
import PropTypes from 'prop-types';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-0 mb-0">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'purchasePrice':
      return '';
    case 'additionalCosts':
      return '';
    case 'summary':
      return '';
    default:
      return '';
  }
};

const PropertyRevenueList = ({ close }) => {
  const [propertyRevenues, setPropertyRevenues] = useState([]);
  const [totals, setTotals] = useState({
    purchasePrice: 0,
    kitchenTiling: 0,
    carpentry: 0,
    plumbing: 0,
    architectDesigning: 0,
    legalFees: 0,
    propertyTaxes: 0,
    insurance: 0,
    marketingSalesCosts: 0,
    financeCosts: 0,
    utilities: 0,
    contingencyFund: 0,
    permitLicensingFees: 0,
    sitePreparationCosts: 0,
    professionalFees: 0,
    sellingPrice: 0,
    totalCost: 0,
    profitMargin: 0
  });
  const [activeCard, setActiveCard] = useState('table');
  const [activeTab, setActiveTab] = useState('purchasePrice');
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/property-revenue/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setPropertyRevenues(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
    } else {
      toast.error(
        'No company selected, you must set up a new company to start.'
      );
    }
  }, [companyDetails]);

  useEffect(() => {
    const calculatedTotals = propertyRevenues.reduce(
      (acc, property) => {
        acc.purchasePrice += parseCurrency(property.purchasePrice || '0');
        acc.kitchenTiling += parseCurrency(property.kitchenTiling || '0');
        acc.carpentry += parseCurrency(property.carpentry || '0');
        acc.plumbing += parseCurrency(property.plumbing || '0');
        acc.architectDesigning += parseCurrency(
          property.architectDesigning || '0'
        );
        acc.legalFees += parseCurrency(property.legalFees || '0');
        acc.propertyTaxes += parseCurrency(property.propertyTaxes || '0');
        acc.insurance += parseCurrency(property.insurance || '0');
        acc.marketingSalesCosts += parseCurrency(
          property.marketingSalesCosts || '0'
        );
        acc.financeCosts += parseCurrency(property.financeCosts || '0');
        acc.utilities += parseCurrency(property.utilities || '0');
        acc.contingencyFund += parseCurrency(property.contingencyFund || '0');
        acc.permitLicensingFees += parseCurrency(
          property.permitLicensingFees || '0'
        );
        acc.sitePreparationCosts += parseCurrency(
          property.sitePreparationCosts || '0'
        );
        acc.professionalFees += parseCurrency(property.professionalFees || '0');
        acc.sellingPrice += parseCurrency(property.sellingPrice || '0');
        acc.totalCost += parseCurrency(property.totalCost || '0');
        acc.profitMargin += parseCurrency(property.profitMargin || '0');
        return acc;
      },
      {
        purchasePrice: 0,
        kitchenTiling: 0,
        carpentry: 0,
        plumbing: 0,
        architectDesigning: 0,
        legalFees: 0,
        propertyTaxes: 0,
        insurance: 0,
        marketingSalesCosts: 0,
        financeCosts: 0,
        utilities: 0,
        contingencyFund: 0,
        permitLicensingFees: 0,
        sitePreparationCosts: 0,
        professionalFees: 0,
        sellingPrice: 0,
        totalCost: 0,
        profitMargin: 0
      }
    );
    setTotals(calculatedTotals);
  }, [propertyRevenues]);

  // Tab navigation logic
  const goToNextTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <div className="container">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={setActiveTab}
          id="property-form-tabs"
          className="mb-3"
        >
          <Tab eventKey="purchasePrice" title="Property List">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Purchase Price</th>
                    <th scope="col">Kitchen Tiling</th>
                    <th scope="col">Carpentry</th>
                    <th scope="col">Plumbing</th>
                    <th scope="col">Architect Designing</th>
                    <th scope="col">Legal Fees</th>
                    <th scope="col">Property Taxes</th>
                    <th scope="col">Insurance</th>
                    <th scope="col">Marketing Sales Costs</th>
                    <th scope="col">Finance Costs</th>
                    <th scope="col">Utilities</th>
                    <th scope="col">Contingency Fund</th>
                    <th scope="col">Permit Licensing Fees</th>
                    <th scope="col">Site Preparation Costs</th>
                    <th scope="col">Professional Fees</th>
                    <th scope="col">Selling Price</th>
                    <th scope="col">Total Cost</th>
                    <th scope="col">Profit Margin</th>
                    <th scope="col">Profit Margin %</th>
                    <th className="text-end" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {propertyRevenues.map((owner, index) => (
                    <Item
                      number={index + 1}
                      key={owner.id}
                      details={owner}
                      propertyRevenues={propertyRevenues}
                      setPropertyRevenues={setPropertyRevenues}
                      close={close}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className="text-left text-success fw-bold"
                    style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
                  >
                    <td>Totals</td>
                    {Object.keys(totals).map(key => (
                      <td key={key}>
                        {currencies[companyDetails.currency]}{' '}
                        {totals[key].toLocaleString()}
                      </td>
                    ))}
                    <td>
                      {totals.sellingPrice > 0
                        ? (
                            (totals.profitMargin / totals.sellingPrice) *
                            100
                          ).toFixed(2)
                        : 0}
                      %
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </Row>
          </Tab>

          <Tab eventKey="additionalCosts" title="Yearly Summary">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Year 1</th>
                    <th>Year 2</th>
                    <th>Year 3</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Property Sales</td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.sellingPrice.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.sellingPrice.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.sellingPrice.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Cost</td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalCost.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalCost.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalCost.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>Gross Profit</td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.profitMargin.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.profitMargin.toLocaleString()}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.profitMargin.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>Gross Profit %</td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.sellingPrice > 0
                        ? (
                            (totals.profitMargin / totals.sellingPrice) *
                            100
                          ).toFixed(2)
                        : 0}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.sellingPrice > 0
                        ? (
                            (totals.profitMargin / totals.sellingPrice) *
                            100
                          ).toFixed(2)
                        : 0}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.sellingPrice > 0
                        ? (
                            (totals.profitMargin / totals.sellingPrice) *
                            100
                          ).toFixed(2)
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Tab>

          <Tab eventKey="summary" title="Charts">
            <Row className="pt-3">
              <div style={{ width: '70%' }}>
                <PropertyPie
                  totalCost={totals.totalCost}
                  totalRevenue={totals.profitMargin}
                  totalSales={totals.sellingPrice}
                />
              </div>
            </Row>
          </Tab>
        </Tabs>
        <div className="border-dashed border-bottom my-3" />
        <Row className="mb-3">
          <Col className="text-center mt-3">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={goToPreviousTab}
              disabled={activeTab === 'purchasePrice'}
            >
              <FaArrowLeft /> Previous
            </button>
            <button
              className="btn btn-outline-success btn-sm ms-2"
              onClick={goToNextTab}
              disabled={activeTab === 'summary'}
            >
              Next <FaArrowRight />
            </button>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};

PropertyRevenueList.propTypes = {
  close: PropTypes.func.isRequired
};

export default PropertyRevenueList;
