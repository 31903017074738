import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthUserContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from '../utilities/useIdleTimer';
import Modal from 'react-bootstrap/Modal';
import IconButton from '../common/IconButton';
import api from 'utils/api';
import { toast } from 'react-toastify';
import editing from 'assets/img/icons/shield.png';
import { Col, Row } from 'react-bootstrap';

const PrivateRoute = ({ ...rest }) => {
  // Access authentication state and user details from context
  const { isAuthenticated, user, isLoading } = useContext(AuthUserContext);
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  // Define timeout for user inactivity (2 minutes)
  const timeout = 120000; // 2 minutes in milliseconds

  // Calculate grace period as 80% of the timeout
  const gracePeriod = 0.8 * timeout; // 80% of the timeout

  // Function to handle user logout due to inactivity
  const handleContinue = () => {
    api
      .get('/users/logout') // Call logout API endpoint
      .then(resp => {
        navigate('/login'); // Redirect to login page
        toast.error('You have been logged out for being idle for too long'); // Notify user
      })
      .catch(err => {
        toast.error('There was an error logging you out.'); // Notify user of error
      });
  };

  // Custom hook to track user inactivity and handle modal display
  const isIdle = useIdleTimer(
    navigate,
    timeout,
    gracePeriod,
    handleContinue,
    idle => {
      setShowModal(idle); // Show modal when idle
    }
  );

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false); // Hide modal
  };

  // Styles for the modal footer and buttons
  const modalFooterStyle = {
    display: 'flex',
    justifyContent: 'center', // Center buttons horizontally
    gap: '10px' // Add space between buttons
  };

  const modalButtonStyle = {
    flex: 1, // Make buttons the same width
    maxWidth: '100px' // Optional: limit the maximum width of buttons
  };

  // Effect hook to navigate based on user authentication and status
  useEffect(() => {
    if (isLoading) {
      return; // Skip navigation if still loading
    }

    // Check authentication and user details after loading
    if (isAuthenticated && user && user.id) {
      if (!user.isAdmin) {
        if (!user.isActive) {
          navigate('/plans'); // Redirect to plans if user is not active
        } else if (window.location.pathname.startsWith('/start')) {
          navigate('/start'); // Redirect to start if on start path
        } else if (user.isAdmin) {
          navigate('/dashboard'); // Redirect to dashboard if user is admin
        }
      }
    }
  }, [isLoading, isAuthenticated, user, navigate]);

  if (isLoading) {
    return null; // Optionally, return a loading spinner or component
  }

  // Render the protected route if authenticated, otherwise redirect to login
  return isAuthenticated && user && user.id ? (
    <>
      <Outlet {...rest} /> {/* Render child routes */}
      {/* Idle Timeout Modal */}
      <Modal
        show={showModal} // Display modal based on state
        onHide={handleCloseModal} // Handle close event
        size="md" // Set the modal size to medium
        aria-labelledby="contained-modal-title-vcenter" // Accessibility label
        centered // Center the modal vertically
        backdrop="static" // Prevent closing modal when clicking outside
        keyboard={false} // Prevent closing modal with keyboard
      >
        <Modal.Header>
          <Modal.Title>Are you still working on you plan?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center justify-content-center pt-2">
            <Col xs={12} md={6} className="text-center">
              <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
                <img
                  src={editing}
                  className="img-fluid"
                  alt="Editing"
                  style={{ maxWidth: '60%', height: 'auto' }}
                />
              </div>
            </Col>
            <h6 className="p-3 text-center">
              You have been inactive for a while. For security reasons please
              continue with your plan or logout for now.
            </h6>
          </Row>
        </Modal.Body>
        <Modal.Footer style={modalFooterStyle}>
          <IconButton
            variant="outline-success"
            size="lg"
            icon="fa-solid fa-arrow-right"
            className="mb-1 mx-1"
            iconAlign="right"
            transform="shrink-3"
            style={modalButtonStyle}
            onClick={handleCloseModal} // Close the modal
          ></IconButton>
          <IconButton
            className="me-0 mb-1"
            variant="outline-primary"
            icon="fa fa-sign-out-alt"
            size="lg"
            transform="shrink-3"
            style={modalButtonStyle}
            onClick={handleContinue} // Handle logout
          ></IconButton>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <Navigate to="/login" /> // Redirect to login if not authenticated
  );
};

export default PrivateRoute;
