import React, { useState, useEffect } from 'react';
import { Col, Row, Container, Modal, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import editing_1 from 'assets/img/icons/spot-illustrations/48.png';
import editing_2 from 'assets/img/icons/spot-illustrations/49.png';
import editing_3 from 'assets/img/icons/spot-illustrations/50.png';

import NavbarStandard from './landing/NavbarStandard';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

const Starter = () => {
  const [showModal, setShowModal] = useState(false);

  // Function to check if the modal has been shown before
  const checkIfModalShown = () => {
    const hasModalBeenShown = localStorage.getItem('modalShown');
    return hasModalBeenShown === 'true';
  };

  // Show the modal when the component mounts
  useEffect(() => {
    if (!checkIfModalShown()) {
      setShowModal(true);
      localStorage.setItem('modalShown', 'true');
    }
  }, []);

  const handleModalClose = () => setShowModal(false);

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const textStyle = {
    color: 'white',
    textAlign: 'center'
  };

  return (
    <>
      <NavbarStandard />

      <Container
        as={motion.div}
        className="g-3 pt-6"
        initial={{ x: '-20%' }}
        animate={{ x: '0%' }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        {/* First Row: Image */}
        <Row className="align-items-center justify-content-center pt-6">
          <Col xs={12} md={6} className="text-center">
            <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
              <img
                src={editing}
                className="img-fluid"
                alt="Editing"
                style={{ maxWidth: '60%', height: 'auto' }}
              />
            </div>
          </Col>
        </Row>

        {/* Second Row: Welcome Message */}
        <Row className="align-items-center justify-content-center pt-4">
          <Col xs={12} md={6} className="text-center">
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <h2 className="text-primary pt-3">Welcome</h2>
              <p>
                You’ve got an incredible business idea, and now it's time to
                transform that vision into a thriving reality! Let's dive deep
                into your concept and explore it's full potential. Together,
                we’ll uncover what makes your idea stand out and map out a path
                to turn it into a successful venture. The adventure begins now!
              </p>
            </div>
          </Col>
        </Row>

        {/* Third Row: Link to Business Pitch Settings */}
        <Row className="align-items-center justify-content-center pt-4">
          <Col xs={12} md={6} className="text-center">
            <Link to="/process" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-right"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              ></IconButton>
            </Link>
          </Col>
        </Row>

        {/* Modal for introduction with carousel */}
        <Modal show={showModal} onHide={handleModalClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Introduction to business planning</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Carousel>
              <Carousel.Item>
                <div style={{ position: 'relative' }}>
                  <img
                    className="d-block w-100"
                    src={editing_1}
                    alt="Slide 1"
                    style={{ maxHeight: '500px', objectFit: 'cover' }}
                  />
                  <div style={overlayStyle}>
                    <div style={textStyle}>
                      <h3>Welcome to Our AuziPlan</h3>
                      <p>Discover the amazing features we offer.</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div style={{ position: 'relative' }}>
                  <img
                    className="d-block w-100"
                    src={editing_2}
                    alt="Slide 2"
                    style={{ maxHeight: '500px', objectFit: 'cover' }}
                  />
                  <div style={overlayStyle}>
                    <div style={textStyle}>
                      <h3>Easy Planning</h3>
                      <p>
                        Simple Techniques for Building a realistic Business
                        Model.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div style={{ position: 'relative' }}>
                  <img
                    className="d-block w-100"
                    src={editing_3}
                    alt="Slide 3"
                    style={{ maxHeight: '500px', objectFit: 'cover' }}
                  />
                  <div style={overlayStyle}>
                    <div style={textStyle}>
                      <h3>Get Started</h3>
                      <p>
                        Start exploring and making the most of our features!
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default Starter;
