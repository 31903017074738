// components/PieChart.js

import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const blueShades = [
  '#0033A0', // Dark Blue
  '#0066CC', // Medium Blue
  '#3399FF', // Light Blue
  '#66B3FF', // Lighter Blue
  '#99CCFF', // Very Light Blue
  '#CCE5FF', // Pale Blue
  '#E6F2FF' // Very Pale Blue
];

const PieChart = ({ investors }) => {
  // Ensure we have enough colors for all investors
  const colors = [...blueShades];
  while (colors.length < investors.length) {
    colors.push(...blueShades); // Repeat the colors if there are more investors than shades
  }

  // Determine the index of the slice with the largest contribution
  const contributions = investors.map(investor =>
    parseFloat(investor.investorContribution.slice(1))
  );
  const maxContributionIndex = contributions.indexOf(
    Math.max(...contributions)
  );

  // Prepare data for the pie chart
  const data = {
    labels: investors.map(
      investor => `${investor.investorFirstName} ${investor.investorLastName}`
    ),
    datasets: [
      {
        label: 'Investor Contributions',
        data: contributions,
        backgroundColor: colors.slice(0, investors.length), // Use specific shades of blue
        borderColor: '#fff',
        borderWidth: 1
        // Use a plugin or custom code for explode effect if needed
      }
    ]
  };

  // Chart options
  const options = {
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || '';
            return `${label}: $${value}`;
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        bottom: 10
      }
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '400px',
        backgroundColor: '#f5f5f5',
        padding: '20px',
        borderRadius: '8px'
      }}
    >
      <Pie data={data} options={options} />
    </div>
  );
};

PieChart.propTypes = {
  investors: PropTypes.arrayOf(
    PropTypes.shape({
      investorFirstName: PropTypes.string.isRequired,
      investorLastName: PropTypes.string.isRequired,
      investorContribution: PropTypes.string.isRequired
    })
  ).isRequired
};

export default PieChart;
