import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { parseCurrency } from 'utils';

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'left', // Move legend to the top
      align: 'center', // Center the legend
      labels: {
        boxWidth: 20
      }
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const value = tooltipItem.raw;
          return `${tooltipItem.label}: ${value.toLocaleString()}`;
        }
      }
    }
  }
};

const PayrollPie = ({ data }) => {
  // Sum the values for superannuation, workcover, and salary
  const totals = data.reduce(
    (acc, row) => {
      acc.others += parseCurrency(row.other || '0');
      acc.superannuation += parseCurrency(row.superannuation || '0');
      acc.workcover += parseCurrency(row.workCover || '0');
      acc.salary += parseCurrency(row.totalGrossSalary || '0');
      return acc;
    },
    { others: 0, superannuation: 0, workcover: 0, salary: 0 }
  );

  // Prepare the data for the Pie chart
  const chartData = {
    labels: ['Superannuation', 'Workcover', 'Gross Salary', 'Others'],
    datasets: [
      {
        data: [
          totals.superannuation,
          totals.workcover,
          totals.salary,
          totals.others
        ],
        backgroundColor: ['#4A90E2', '#5DADE2', '#85C1E9', '#A9CCE3'],
        hoverBackgroundColor: ['#4A90E2', '#5DADE2', '#85C1E9', '#A9CCE3']
      }
    ]
  };

  return <Pie data={chartData} options={options} />;
};

export default PayrollPie;
