import ActionButton from 'components/common/ActionButton'; // Import ActionButton component
import React, { useContext, useState } from 'react'; // Import necessary components from React
import PropTypes from 'prop-types'; // Import PropTypes for type-checking props
import ConfirmationModal from 'components/custom/common/ConfirmationModel'; // Import ConfirmationModal component
import api from 'utils/api'; // Import API utility functions
import { toast } from 'react-toastify'; // Import toast notifications for error handling
import {
  SelectedCompanyContext,
  OperatingExpenseFormModalContext,
  OperatingExpenseWizardContext
} from 'context/Context'; // Import context hooks for selected company details and operating expense forms
import { currencies } from 'utils'; // Import utility function for currencies
import { parseCurrency } from 'utils';

function Item({ details, payrolls, setPayrolls, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false); // State to manage visibility of delete confirmation modal
  const { setEdit, setEditDetails, setStep } = useContext(
    OperatingExpenseWizardContext
  ); // Destructure context for operating expense wizard
  const { setShowFormModal } = useContext(OperatingExpenseFormModalContext); // Destructure context for operating expense form modal
  const { companyDetails } = useContext(SelectedCompanyContext); // Destructure context for selected company details

  // Function to handle deletion of payroll item
  const handleDelete = async () => {
    try {
      await api.delete(`/payroll-expense/delete/${details.id}`); // API call to delete payroll item
      toast.success('Payroll deleted'); // Success toast notification
      const updatedPayroll = payrolls.filter(
        payroll => payroll.id !== details.id
      ); // Update payrolls list after deletion
      setPayrolls(updatedPayroll); // Set updated payrolls
      setShowDelConfirmModal(false); // Close delete confirmation modal
    } catch (error) {
      console.log(error); // Log error to console
      toast.error('Error deleting Payroll'); // Error toast notification
    }
  };

  // Function to handle editing of payroll item
  const handleEdit = () => {
    close(); // Close current modal or component
    setEdit('payroll-expense'); // Set edit mode for payroll expense
    setEditDetails(details); // Set details for editing
    setStep(2); // Set wizard step to 2 (assuming it's part of a wizard)
    setShowFormModal(true); // Show operating expense form modal
  };

  return (
    <>
      {/* Table row for each payroll item */}
      <tr key={details.id}>
        <td>{details.roleType === 'partTime' ? 'Part-Time' : 'Full-Time'}</td>{' '}
        {/* Display role type */}
        <td>{details.numberOfMonths}</td> {/* Display number of months */}
        <td>
          {/* Display number of people */}
          {details.numberOfPeople}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.totalGrossSalary).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.annualSalary).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.superannuation).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.workCover).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.weeklyPayroll).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.other).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.startupBudget).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.totalPayrollExpense).toLocaleString()}
        </td>
        <td className="text-end">
          {/* Action buttons for edit and delete */}
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      {/* Confirmation modal for delete operation */}
      <ConfirmationModal
        title={`Delete ${details.description}`} // Title for delete confirmation modal
        show={showDelConfirmModal} // Show/hide state for delete confirmation modal
        message="Are you sure you want to delete this Motor Vehicle?" // Confirmation message
        handleClose={() => setShowDelConfirmModal(false)} // Function to close delete confirmation modal
        handleConfirm={handleDelete} // Function to confirm deletion
      />
    </>
  );
}

// PropTypes for type-checking props
Item.propTypes = {
  details: PropTypes.object, // Payroll details object
  payrolls: PropTypes.array, // Array of payrolls
  setPayrolls: PropTypes.func, // Function to set payrolls
  close: PropTypes.func // Function to close modal or component
};

export default Item; // Export Item component
