import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import { SelectedCompanyContext } from 'context/Context';
import { useContext } from 'react';
import { currencies } from 'utils';

const months = [
  { value: 'all', label: 'All Months' },
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const tooltipFormatter = (params, currency) => {
  if (!Array.isArray(params) || params.length === 0) {
    return '';
  }

  let tooltip = `<div style="border-bottom: 1px solid ${getColor(
    'gray-300'
  )}; padding-bottom: 5px; margin-bottom: 5px;">`;
  tooltip += `${params[0].name || 'No data'}</div>`;
  params.forEach(param => {
    tooltip += `<div>${param.marker} ${
      param.seriesName
    }: ${currency} ${param.value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</div>`;
  });
  return tooltip;
};

const getOptions = (data, currency) => ({
  color: [
    getColor('primary'),
    getColor('info'),
    getColor('gray-300'),
    '#50E3C2',
    '#7B61FF'
  ],
  legend: {
    data: ['Revenue', 'Cost', 'Gross Profit', 'Payroll', 'Operating Expense'],
    icon: 'circle',
    itemWidth: 10,
    itemHeight: 10,
    padding: [0, 0, 0, 0],
    textStyle: {
      color: getColor('gray-700'),
      fontWeight: '500',
      fontSize: '13px'
    },
    left: 0,
    itemGap: 16
  },
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-900') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: params => tooltipFormatter(params, currency) // Use tooltip formatter
  },
  xAxis: {
    data: data.map(month => months[month.month].label), // Map month names dynamically from data
    splitLine: { show: false },
    splitArea: { show: false },
    axisLabel: {
      color: getColor('gray-600')
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: {
      show: false
    }
  },
  yAxis: {
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLabel: {
      color: getColor('gray-600'),
      formatter: value =>
        `${currency} ${value.toLocaleString(undefined, {
          maximumFractionDigits: 0
        })}`
    }
  },
  series: [
    {
      name: 'Revenue',
      type: 'bar',
      stack: 'one',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: data.map(month => month.totalRevenue),
      label: {
        show: data.length === 1, // Show labels only if a single month is selected
        position: 'top',
        color: '#fff',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}` // Display the value inside the bar with currency
      }
    },
    {
      name: 'Cost',
      type: 'bar',
      stack: 'two',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: data.map(month => month.totalCost),
      label: {
        show: data.length === 1, // Show labels only if a single month is selected
        position: 'top',
        color: '#fff',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}` // Display the value inside the bar with currency
      }
    },
    {
      name: 'Gross Profit',
      type: 'bar',
      stack: 'three',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: data.map(month => month.grossProfit),
      label: {
        show: data.length === 1, // Show labels only if a single month is selected
        position: 'top',
        color: '#fff',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}` // Display the value inside the bar with currency
      }
    },
    {
      name: 'Payroll',
      type: 'bar',
      stack: 'four',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: data.map(month => month.payroll),
      label: {
        show: data.length === 1, // Show labels only if a single month is selected
        position: 'top',
        color: '#fff',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}` // Display the value inside the bar with currency
      }
    },
    {
      name: 'Operating Expense',
      type: 'bar',
      stack: 'five',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: data.map(month => month.operatingExpense),
      label: {
        show: data.length === 1, // Show labels only if a single month is selected
        position: 'top',
        color: '#fff',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}` // Display the value inside the bar with currency
      }
    }
  ],
  itemStyle: {
    borderRadius: [3, 3, 0, 0]
  },
  grid: {
    top: '13%',
    bottom: 0,
    left: 0,
    right: 0,
    containLabel: true
  }
});

const AnalysisChart = ({ chartData }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(chartData, companyDetails.currency)}
      style={{ height: '23.5rem' }}
    />
  );
};

export default AnalysisChart;
