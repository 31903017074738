import React, { useState, useEffect, useContext } from 'react';
import {
  Col,
  Form,
  Row,
  FloatingLabel,
  Button,
  Tabs,
  Tab,
  Card
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from 'utils/api';
import {
  SelectedCompanyContext,
  RevenueFormModalContext
} from 'context/Context';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import { currencies } from 'utils';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-3 mb-3">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const extractNumber = formattedValue => {
  if (typeof formattedValue !== 'string') {
    return formattedValue; // Return 0 or handle the case accordingly
  }
  const cleanedValue = formattedValue.replace(/[^0-9.-]+/g, '');
  return parseFloat(cleanedValue) || 0;
};

const formatCurrency = (value, currency) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value || 0);
};

const formatNumber = value => {
  return new Intl.NumberFormat('en-US').format(value || 0);
};

const OnlineRevenueForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      marketingBudget: 25000,
      costPerClick: 0.4,
      conversionRate: '1 %',
      minimumOrder: 7,
      startupMonths: 1,
      retailPrice: 6.9,
      costPrice: 2.2,
      otherRevenue: 23000,
      paidVisits: 1000,
      dailyVisits: 200,
      organicVisits: 1000000,
      sundry: 15000,
      repeatOrders: 26
    }
  });

  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    api
      .get(`/online-revenue/get?companyId=${companyDetails.id}`)
      .then(res => {
        const data = res.data;
        setIsEditing(true);
        setDocId(data.id);
        reset({
          minimumOrder: data.minimumOrder || 7,
          startupMonths: data.startupMonths || 1,
          conversionRate: data.conversionRate
            ? parseFloat(data.conversionRate)
            : '1 %',
          marketingBudget: data.marketingBudget || 25000,
          costPerClick: data.costPerClick || 0.4,
          retailPrice: data.retailPrice || 5.95,
          costPrice: data.costPrice || 2.2,
          otherRevenue: data.otherRevenue || 23000,
          paidVisits: data.paidVisits || 1000,
          dailyVisits: data.dailyVisits || 200,
          organicVisits: data.organicVisits || 1000000,
          sundry: data.sundry || 15000,
          repeatOrders: data.repeatOrders || 26
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          // Handle case where no data is found
        } else {
          toast.error('Some error occurred');
        }
      });
  }, [companyDetails, reset]);

  const [calculations, setCalculations] = useState({
    numberOfClicks: 0,
    totalVisits: 0,
    sales: 0,
    revenue: 0,
    totalRevenue: 0,
    totalStartupBudget: 0,
    totalCost: 0,
    averageBasketValue: '0',
    averageBasketCostValue: '0',
    dailyVisits: 0,
    paidVisits: 0,
    organicVisits: 0,
    total: 0,
    totalRepeatedOrders: 0
  });

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('inputs1');

  const watchFields = [
    'marketingBudget',
    'costPerClick',
    'conversionRate',
    'minimumOrder',
    'startupMonths',
    'retailPrice',
    'costPrice',
    'otherRevenue',
    'dailyVisits',
    'sundry',
    'repeatOrders'
  ];

  const [
    marketingBudget,
    costPerClick,
    conversionRate,
    minimumOrder,
    startupMonths,
    retailPrice,
    costPrice,
    otherRevenue,
    dailyVisits,
    sundry,
    repeatOrders
  ] = watch(watchFields);

  const calculateValues = () => {
    if (costPerClick <= 0) {
      setCalculations({
        numberOfClicks: 0,
        totalVisits: 0,
        sales: 0,
        revenue: 0,
        totalRevenue: 0,
        totalStartupBudget: 0,
        totalCost: 0,
        averageBasketValue: '0',
        averageBasketCostValue: '0',
        dailyVisits: 0,
        paidVisits: 0,
        organicVisits: 0,
        total: 0,
        totalRepeatedOrders: 0
      });
      return;
    }

    const averageBasketValue = minimumOrder * extractNumber(retailPrice);
    const averageBasketCostValue = minimumOrder * extractNumber(costPrice);

    const numberOfClicks =
      extractNumber(marketingBudget) / extractNumber(costPerClick);
    const organicVisits = extractNumber(dailyVisits) * 365;
    const totalVisits =
      extractNumber(numberOfClicks) + extractNumber(organicVisits);
    const sales = totalVisits * (parseFloat(conversionRate) / 100);
    const revenue = sales * averageBasketValue;
    const paidVisits =
      extractNumber(marketingBudget) / extractNumber(costPerClick);
    const total = sales * extractNumber(minimumOrder);
    const totalRepeatedOrders = total * extractNumber(repeatOrders);

    const totalRevenue = totalRepeatedOrders * extractNumber(retailPrice);

    // Calculate totalStartupBudget based on startupBudget and startupMonths
    const totalStartupBudget =
      extractNumber(totalRepeatedOrders) *
      extractNumber(costPrice / 12) *
      extractNumber(startupMonths); // Adjust this line based on your actual startup budget logic.

    const totalCost = totalRepeatedOrders * extractNumber(costPrice);

    setCalculations({
      numberOfClicks,
      totalVisits,
      sales,
      revenue,
      totalRevenue,
      totalStartupBudget,
      totalCost,
      averageBasketValue: `${
        currencies[companyDetails.currency]
      } ${averageBasketValue.toFixed(2)}`,
      averageBasketCostValue: `${
        currencies[companyDetails.currency]
      } ${averageBasketCostValue.toFixed(2)}`,
      paidVisits,
      organicVisits,
      total,
      totalRepeatedOrders
    });
  };

  useEffect(() => {
    calculateValues();
  }, [
    marketingBudget,
    costPerClick,
    conversionRate,
    minimumOrder,
    startupMonths,
    retailPrice,
    costPrice,
    otherRevenue,
    dailyVisits,
    sundry,
    repeatOrders
  ]);

  const onSubmit = async data => {
    setLoading(true);
    const formData = {
      ...data,
      ...calculations,
      companyId: companyDetails.id
    };

    const formattedData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, String(value)])
    );

    const payload =
      isEditing && docId ? { id: docId, data: formattedData } : formattedData;

    try {
      await api.post(
        `/online-revenue/${isEditing ? 'update' : 'create'}`,
        payload
      );
      toast.success(
        `Form ${isEditing ? 'updated' : 'submitted'} successfully!`
      );
      setShowFormModal(false);
    } catch (error) {
      toast.error('Error submitting form!');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onError = () => {
    clearErrors();
  };

  const getTabDescription = tabKey => {
    switch (tabKey) {
      case 'inputs1':
        return 'Input your anticipated marketing budget and estimated cost per click to calculate yearly clicks.';
      case 'inputs2':
        return 'Input the projected number of visitors to your online store.';
      case 'results':
        return 'Here’s your estimated revenue projection.';
      case 'summary':
        return 'Review the overview of costs, profit margins, and expected profit percentage.';
      default:
        return '';
    }
  };

  const tabKeys = ['inputs1', 'inputs2', 'results', 'summary'];

  const goToNextTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1)
      setActiveTab(tabKeys[currentIndex + 1]);
  };

  const goToPreviousTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) setActiveTab(tabKeys[currentIndex - 1]);
  };

  return (
    <div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Col>
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <PageHeader
              title={
                <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                  {tabKeys.find(key => key === activeTab) === 'inputs1' &&
                    'Marketing Budget'}
                  {tabKeys.find(key => key === activeTab) === 'inputs2' &&
                    'Online Visitors'}
                  {tabKeys.find(key => key === activeTab) === 'results' &&
                    'Results'}
                  {tabKeys.find(key => key === activeTab) === 'summary' &&
                    'Summary'}
                </span>
              }
              description={
                <span style={{ fontSize: '16px', color: '#6c757d' }}>
                  {getTabDescription(activeTab)}
                </span>
              }
            />

            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={setActiveTab}
              className="mb-3"
            >
              <Tab eventKey="inputs1" title="Marketing Budget">
                <Row className="g-3">
                  <Col xs={12} md={3}>
                    <CurrencyWizardInput
                      symbol={currencies[companyDetails.currency]}
                      name="marketingBudget"
                      label="Marketing Budget"
                      errors={errors}
                      formControlProps={{
                        ...register('marketingBudget', {
                          required: 'This field is required'
                        })
                      }}
                      watch={watch}
                      setValue={setValue}
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <CurrencyWizardInput
                      symbol={currencies[companyDetails.currency]}
                      name="costPerClick"
                      label="Average Cost per Click"
                      errors={errors}
                      formControlProps={{
                        ...register('costPerClick', {
                          required: 'This field is required'
                        })
                      }}
                      watch={watch}
                      setValue={setValue}
                    />
                  </Col>

                  <Col xs={12} md={3}>
                    <PercentageWizardInput
                      name="conversionRate"
                      label="Conversion Rate"
                      errors={errors}
                      formControlProps={{
                        ...register('conversionRate', {
                          required: 'This field is required'
                        })
                      }}
                      watch={watch}
                      setValue={setValue}
                    />
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="dailyVisits">
                      <FloatingLabel label="Daily Visits">
                        <Form.Control
                          type="text"
                          value={formatNumber(dailyVisits)}
                          onChange={e =>
                            setValue(
                              'dailyVisits',
                              extractNumber(e.target.value)
                            )
                          }
                          isInvalid={!!errors.dailyVisits}
                          placeholder="250"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dailyVisits?.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="paidVisits">
                      <FloatingLabel label="Paid Visits">
                        <Form.Control
                          type="text"
                          value={formatNumber(calculations.paidVisits)}
                          //value={calculations.paidVisits}
                          onChange={e =>
                            setValue(
                              'paidVisits',
                              extractNumber(e.target.value)
                            )
                          }
                          isInvalid={!!errors.paidVisits}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.paidVisits?.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="organicVisits">
                      <FloatingLabel label="Yearly Organic Visits">
                        <Form.Control
                          type="text"
                          value={formatNumber(calculations.organicVisits)}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.organicVisits?.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="totalVisits">
                      <FloatingLabel label="Total Yearly Visits">
                        <Form.Control
                          type="text"
                          value={formatNumber(calculations.totalVisits)}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="sales">
                      <FloatingLabel label="Customers per Year">
                        <Form.Control
                          type="text"
                          value={formatNumber(calculations.sales)}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="inputs2" title="Online Visitors">
                <Row className="g-3 pt-3">
                  <Col xs={12} md={3}>
                    <Form.Group controlId="startupMonths">
                      <FloatingLabel label="Startup Months">
                        <Form.Control
                          type="number"
                          placeholder="startup Months"
                          {...register('startupMonths', {
                            required: 'Startup Months required.'
                          })}
                          isInvalid={!!errors.startupMonths}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.startupMonths?.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="minimumOrder">
                      <FloatingLabel label="Minimum Order">
                        <Form.Control
                          type="number"
                          placeholder="Minimum Order"
                          {...register('minimumOrder', {
                            required: 'Minimum Order required.'
                          })}
                          isInvalid={!!errors.minimumOrder}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.minimumOrder?.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="repeatOrders">
                      <FloatingLabel label="Repeat Orders">
                        <Form.Control
                          type="text"
                          value={formatNumber(repeatOrders)}
                          onChange={e =>
                            setValue(
                              'repeatOrders',
                              extractNumber(e.target.value)
                            )
                          }
                          isInvalid={!!errors.repeatOrders}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.repeatOrders?.message}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <CurrencyWizardInput
                      symbol={currencies[companyDetails.currency]}
                      name="retailPrice"
                      label="Average Retail Price"
                      errors={errors}
                      formControlProps={{
                        ...register('retailPrice', {
                          required: 'This field is required'
                        })
                      }}
                      watch={watch}
                      setValue={setValue}
                    />
                  </Col>

                  <Col xs={12} md={3}>
                    <CurrencyWizardInput
                      symbol={currencies[companyDetails.currency]}
                      name="costPrice"
                      label="Average Cost Price"
                      errors={errors}
                      formControlProps={{
                        ...register('costPrice', {
                          required: 'This field is required'
                        })
                      }}
                      watch={watch}
                      setValue={setValue}
                    />
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="totalRepeatedOrders">
                      <FloatingLabel label="Total Repeated Orders">
                        <Form.Control
                          type="text"
                          value={formatNumber(calculations.totalRepeatedOrders)}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="averageBasketValue">
                      <FloatingLabel label="Average Basket Value">
                        <Form.Control
                          type="text"
                          value={calculations.averageBasketValue}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="averageBasketCostValue">
                      <FloatingLabel label="Average Basket Cost Value">
                        <Form.Control
                          type="text"
                          value={calculations.averageBasketCostValue}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="totalCost">
                      <FloatingLabel label="Total Cost">
                        <Form.Control
                          type="text"
                          value={formatCurrency(
                            calculations.totalCost,
                            companyDetails.currency
                          )}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="g-3">
                  <Col xs={12} md={3} style={{ display: 'none' }}>
                    <CurrencyWizardInput
                      symbol={currencies[companyDetails.currency]}
                      name="otherRevenue"
                      label="Other Revenue"
                      errors={errors}
                      formControlProps={{
                        ...register('otherRevenue', {
                          required: 'This field is required'
                        })
                      }}
                      watch={watch}
                      setValue={setValue}
                    />
                  </Col>

                  <Col xs={12} md={3} style={{ display: 'none' }}>
                    <CurrencyWizardInput
                      symbol={currencies[companyDetails.currency]}
                      name="sundry"
                      label="Sundry"
                      errors={errors}
                      formControlProps={{
                        ...register('sundry', {
                          required: 'This field is required'
                        })
                      }}
                      watch={watch}
                      setValue={setValue}
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="results" title="Results">
                <Row className="g-3">
                  <Col xs={12} md={3}>
                    <Form.Group controlId="totalStartupBudget">
                      <FloatingLabel label="Total Startup Budget">
                        <Form.Control
                          type="text"
                          value={formatCurrency(
                            calculations.totalStartupBudget,
                            companyDetails.currency
                          )}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="numberOfClicks">
                      <FloatingLabel label="Number of Clicks">
                        <Form.Control
                          type="text"
                          value={formatNumber(calculations.numberOfClicks)}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={3}>
                    <Form.Group controlId="total">
                      <FloatingLabel label="Total Sales">
                        <Form.Control
                          type="text"
                          value={formatNumber(calculations.total)}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>

                  {/* 
                  <Col xs={12} md={6} style={{ display: 'none' }}>
                    <Form.Group controlId="revenue">
                      <FloatingLabel label="Revenue">
                        <Form.Control
                          type="text"
                          value={formatCurrency(
                            calculations.revenue,
                            companyDetails.currency
                          )}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>


*/}

                  <Col xs={12} md={3}>
                    <Form.Group controlId="totalRevenue">
                      <FloatingLabel label="Total Yearly Revenue">
                        <Form.Control
                          type="text"
                          value={formatCurrency(
                            calculations.totalRevenue,
                            companyDetails.currency
                          )}
                          disabled
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="summary" title="Summary">
                <Card body className="text-center">
                  <h4>Summary</h4>
                  <p>
                    <strong>Number of Clicks:</strong>{' '}
                    {formatNumber(calculations.numberOfClicks)}
                  </p>
                  <p>
                    <strong>Total Visits:</strong>{' '}
                    {formatNumber(calculations.totalVisits)}
                  </p>

                  <p>
                    <strong>Sales:</strong> {formatNumber(calculations.sales)}
                  </p>
                  {/* 

                  <p>
                    <strong>Revenue:</strong>{' '}
                    {formatCurrency(
                      calculations.revenue,
                      companyDetails.currency
                    )}
                  </p>

                */}

                  <p>
                    <strong>Total Revenue:</strong>{' '}
                    {formatCurrency(
                      calculations.totalRevenue,
                      companyDetails.currency
                    )}
                  </p>

                  <p>
                    <strong>Total Cost:</strong>{' '}
                    {formatCurrency(
                      calculations.totalCost,
                      companyDetails.currency
                    )}
                  </p>
                </Card>
              </Tab>
            </Tabs>
            <div className="border-dashed border-bottom my-3" />
            <Row className="p-3 text-center">
              <Col xs={12} className="text-center">
                <IconButton
                  variant="outline-primary"
                  className="me-2"
                  size="sm"
                  onClick={goToPreviousTab}
                  disabled={activeTab === 'inputs1'}
                >
                  <FaArrowLeft /> Previous
                </IconButton>
                {activeTab === 'summary' ? (
                  <IconButton
                    className="float-center"
                    variant="outline-success"
                    size="sm"
                    type="submit"
                    disabled={loading}
                  >
                    {loading
                      ? isEditing
                        ? 'Updating...'
                        : 'Submitting...'
                      : isEditing
                      ? 'Update'
                      : 'Submit'}
                  </IconButton>
                ) : (
                  <IconButton
                    variant="outline-success"
                    className="float-center"
                    size="sm"
                    onClick={goToNextTab}
                  >
                    Next <FaArrowRight />
                  </IconButton>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </motion.div>
    </div>
  );
};

export default OnlineRevenueForm;
