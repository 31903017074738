import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import GettingStarted from 'components/documentation/GettingStarted';
import Configuration from 'components/documentation/Configuration';
import DarkMode from 'components/documentation/DarkMode';
import Plugins from 'components/documentation/Plugins';
import Styling from 'components/documentation/Styling';
import DesignFile from 'components/documentation/DesignFile';
import Starter from 'components/pages/Starter';
import Changelog from 'components/documentation/change-log/ChangeLog';
import Settings from 'components/pages/user/settings/Settings';
import ModalAuth from 'components/authentication/modal/ModalAuth';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import HelpCenter from 'components/pages/miscellaneous/help-center/HelpCenter';

import ThePitch from 'components/pages/miscellaneous/the-pitch/ThePitch';

import PricingAlt from 'components/pages/pricing/pricing-alt/PricingAlt';
import Reports from 'components/pages/companyReports/Reports';
import Index from 'components/pages/companyAnalysis/index';

import Customers from 'components/app/e-commerce/customers/Customers';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitRegistration from 'components/authentication/split/Registration';
import SplitForgetPassword from 'components/authentication/split/ForgetPassword';
import SplitPasswordReset from 'components/authentication/split/PasswordReset';
import SplitConfirmMail from 'components/authentication/split/ConfirmMail';

import Dashboard from 'components/dashboards/default';

import Faq from 'components/documentation/Faq';
import PrivateRoute from 'components/HOC/PrivateRoute';
import UpdateUser from 'components/app/custom/updateUser';
import AddUser from 'components/app/custom/addUser';
import Subscriptions from 'components/app/custom/subscriptions/Subscriptions';
import { AnimatePresence } from 'framer-motion';

import BuzPitchSettingsPage from 'components/pages/custom/settings/BuzPitchSettingsPage';
import OwnerSettingsPage from 'components/pages/custom/settings/OwnerSettingsPage';
import RevenueSettingsPage from 'components/pages/custom/settings/RevenueSettingsPage';
import StartupSettingsPage from 'components/pages/custom/settings/StartupSettingsPage';
import OperatingExpenseSettingsPage from 'components/pages/custom/settings/OperatingExpenseSettingsPage';
import StageProvider from 'components/custom/providers/StageProvider';
import ReportsSettingPage from 'components/pages/custom/settings/ReportSettingsPage';

const FalconRoutes = () => {
  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<PrivateRoute />}>
          <Route path="/start" element={<Starter />} />
          {/* Custom */}
          <Route path="/plans" element={<PricingAlt />} />
          <Route element={<StageProvider />}>
            <Route path="/process" element={<Settings />} />

            <Route
              path="/buzPitchSettings"
              element={<BuzPitchSettingsPage />}
            />
            <Route path="/ownerSettings" element={<OwnerSettingsPage />} />
            <Route
              path="/startupCostsSettings"
              element={<StartupSettingsPage />}
            />
            <Route
              path="/operatingExpensesSettings"
              element={<OperatingExpenseSettingsPage />}
            />
            <Route path="/revenueSettings" element={<RevenueSettingsPage />} />
            <Route path="/reportsSettings" element={<ReportsSettingPage />} />
            <Route path="/reports" element={<Reports />} />
          </Route>
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/the-pitch" element={<ThePitch />} />

          <Route path="/index" element={<Index />} />
        </Route>

        <Route path="/login" element={<SplitLogin />} />
        <Route path="/register" element={<SplitRegistration />} />
        <Route path="/forgot-password" element={<SplitForgetPassword />} />
        <Route path="/logout" element={<SplitLogout />} />
        <Route path="/reset/:token" element={<SplitPasswordReset />} />
        <Route path="/confirm-mail" element={<SplitConfirmMail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* END Custom */}
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* //--- MainLayout Starts  */}
        <Route element={<PrivateRoute />}>
          <Route element={<MainLayout />}>
            {/* Custom */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/users" element={<Customers />} />
            <Route path="/dashboard/users/edit" element={<UpdateUser />} />
            <Route path="/dashboard/users/add" element={<AddUser />} />
            <Route
              path="/dashboard/subscriptions"
              element={<Subscriptions />}
            />

            {/*Documentation*/}
            <Route
              path="documentation/getting-started"
              element={<GettingStarted />}
            />
            <Route
              path="documentation/configuration"
              element={<Configuration />}
            />
            <Route path="documentation/styling" element={<Styling />} />
            <Route path="documentation/dark-mode" element={<DarkMode />} />
            <Route path="documentation/plugin" element={<Plugins />} />
            <Route path="documentation/faq" element={<Faq />} />
            <Route path="documentation/design-file" element={<DesignFile />} />
            <Route path="changelog" element={<Changelog />} />
            <Route path="authentication-modal" element={<ModalAuth />} />
          </Route>
        </Route>
        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
    </AnimatePresence>
  );
};

export default FalconRoutes;
