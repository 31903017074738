import React, { useContext, useEffect, useState } from 'react';
import { SelectedCompanyContext } from 'context/Context';
import api from 'utils/api';
import Header from './Header';

import { Container, Card, Row, Col } from 'react-bootstrap';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import IconButton from 'components/common/IconButton';

const Pitch = ({ pitchName }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [pitch, setPitch] = useState({ pitchName: '', pitchDescription: '' });

  useEffect(() => {
    // Making an API request to get single pitch details
    api
      .get(`/pitches/getSinglePitch`, {
        params: {
          companyId: companyDetails.id,
          title: pitchName
        }
      })
      .then(response => {
        // Checking if the response status is 404
        if (response.status === 404) {
          setPitch(null);
        } else {
          const data = response.data;
          setPitch({
            pitchName: data.pitchName,
            pitchDescription: data.pitchDescription
          });
          console.log(data);
        }
      })
      .catch(error => {
        // Handling other potential errors, such as network errors or server errors
        if (error.response && error.response.status === 404) {
          setPitch(null);
        } else {
          console.error('Error fetching pitch:', error);
        }
      });
  }, [pitchName, companyDetails]);

  const insertPageBreaks = description => {
    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.width = '800px';
    document.body.appendChild(tempDiv);

    let content = '';
    let accumulatedHeight = 0;
    const maxHeight = 850; // Example height for a page

    const paragraphs = description.split('\n');
    paragraphs.forEach(paragraph => {
      tempDiv.innerHTML = paragraph; // Add the paragraph to the temp div
      const paragraphHeight = tempDiv.clientHeight; // Measure the height

      accumulatedHeight += paragraphHeight;

      if (accumulatedHeight > maxHeight) {
        console.log('Inserting break');
        content += `<div style="height: 20px; page-break-before: always;"></div>`; // Add a page break
        accumulatedHeight = paragraphHeight;
      }
      content += paragraph;
    });

    document.body.removeChild(tempDiv); // Clean up the temporary div
    return content;
  };

  if (!pitch) return <h3>No Pitch Available</h3>;

  return (
    <Container id="pitch">
      <Header />
      <h4 className="text-primary p-0">Your Pitch</h4>
      <div id="pdf-content" style={{ paddingTop: '0px' }}>
        <Card>
          <Card.Body>
            <div className="cover-page pt-0">
              <div className="mt-0">
                <div
                  style={{ padding: '0px 0px 0 0px' }}
                  dangerouslySetInnerHTML={{
                    __html: insertPageBreaks(pitch.pitchDescription)
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Pitch;
