import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table, Tabs, Tab, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from 'utils/api';
import Item from './item';
import PropTypes from 'prop-types';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-3 mb-3">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'purchasePrice':
      return '';
    case 'additionalCosts':
      return '';
    case 'summary':
      return '';
    default:
      return '';
  }
};

// Tab navigation logic
const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];

function OnlineRevenueList({ close }) {
  const [onlineRevenue, setOnlineRevenue] = useState(null);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [activeTab, setActiveTab] = useState('purchasePrice');

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/online-revenue/get?companyId=${companyDetails.id}`)
        .then(response => {
          setOnlineRevenue(response.data);
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Handle case where no data is found
            toast.info('No data found for the selected company.');
          } else {
            toast.error('Some error occurred');
          }
        });
    } else {
      toast.error(
        'No company selected, you must set up a new company to start.'
      );
    }
  }, [companyDetails]);

  const goToNextTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <div className="container">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={setActiveTab}
          id="online-revenue-tabs"
          className="mb-3"
        >
          <Tab eventKey="purchasePrice" title="Online Revenue Analysis">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th scope="col">Marketing Budget</th>
                    <th scope="col">Cost Per Click</th>
                    <th scope="col">Conversion Rate</th>
                    <th scope="col">Average Basket Value</th>
                    <th scope="col">Paid Visits</th>
                    <th scope="col">Organic Visits</th>
                    <th scope="col">Startup Months</th>
                    <th scope="col">Startup Budget</th>
                    <th scope="col">Total Visits</th>
                    <th scope="col">Sales</th>
                    <th scope="col">Revenue</th>
                    <th scope="col">Total Revenue</th>
                    <th scope="col">Minimum Order</th>
                    <th scope="col">Retail Price</th>
                    <th scope="col">Daily Visits</th>
                    <th scope="col">Total</th>
                    <th scope="col">Total Repeated Orders</th>
                    <th scope="col">Repeat Orders</th>
                    <th className="text-end" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {onlineRevenue && (
                    <Item
                      key={onlineRevenue.id}
                      details={onlineRevenue}
                      setOnlineRevenue={setOnlineRevenue}
                      close={close}
                    />
                  )}
                </tbody>
              </Table>
            </Row>
          </Tab>

          <Tab eventKey="additionalCosts" title="Yearly Summary">
            <Row className="pt-3">{/* Yearly Summary Content Goes Here */}</Row>
          </Tab>

          <Tab eventKey="summary" title="Charts">
            <Row className="pt-3">{/* Charts Content Goes Here */}</Row>
          </Tab>
        </Tabs>
        <div className="border-dashed border-bottom my-3" />
        <Row className="mb-3">
          <Col className="text-center mt-3">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={goToPreviousTab}
              disabled={activeTab === 'purchasePrice'}
            >
              <FaArrowLeft /> Previous
            </button>
            <button
              className="btn btn-outline-success btn-sm ms-2"
              onClick={goToNextTab}
              disabled={activeTab === 'summary'}
            >
              Next <FaArrowRight />
            </button>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
}

OnlineRevenueList.propTypes = {
  close: PropTypes.func
};

export default OnlineRevenueList;
