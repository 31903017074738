import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Table,
  Modal,
  Button
} from 'react-bootstrap';
import { SelectedCompanyContext } from 'context/Context';
import { currencies } from 'utils';
import Header from './Header';

const OpeningCosts = ({ report, reportData, isPdf }) => {
  const [startupCosts, setStartupCosts] = useState([]);
  const [projections, setProjections] = useState([]);

  const [startupFunding, setStartupFunding] = useState([
    { description: 'Accounts payable', amount: 0 },
    { description: 'Other liabilities', amount: 0 },
    { description: 'Debt', amount: 0 },
    { description: 'Investor capital', amount: 0 },
    { description: 'Owner capital', amount: 0 }
  ]);

  const [assets, setAssets] = useState([
    { description: 'Land And Buildings', amount: 0 },
    { description: 'Plant & Equipment', amount: 0 },
    { description: 'Intellectual Property', amount: 0 },
    { description: 'Security Deposits', amount: 0 },
    { description: 'Other', amount: 0 },
    { description: 'Cash', amount: 0 },
    { description: 'Accounts receivable', amount: 0 },
    { description: 'Inventory', amount: 0 }
  ]);

  const [openingBalanceSheet, setOpeningBalanceSheet] = useState([
    { description: 'Cash', amount: 0 },
    { description: 'Accounts receivable', amount: 0 },
    { description: 'Inventory', amount: 0 },
    { description: 'Current Assets', amount: 0 },
    { description: 'Long-Term Assets', amount: 0 }
  ]);

  const [liabilitiesAndEquity, setLiabilitiesAndEquity] = useState([
    { description: 'Accounts payable', amount: 0 },
    { description: 'Other liabilities', amount: 0 },
    { description: 'Current liabilities', amount: 0 },
    { description: 'Debt', amount: 0 },
    { description: 'Capital', amount: 0 },
    { description: 'Retained earnings', amount: 0 }
  ]);

  const [showNotif, setShowNotif] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);

  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    setOpeningBalanceSheet(reportData.balanceSheet);
    setLiabilitiesAndEquity(reportData.liabilitiesAndEquity);
    setStartupCosts(reportData.startupCosts);
    setStartupFunding(reportData.startupFunding);
    setAssets(reportData.assets);
    setProjections(reportData.projections);
  }, [reportData]);

  const calculateTotal = items =>
    items.reduce((acc, item) => acc + item.amount, 0);

  const totalStartupCosts = calculateTotal(startupCosts);
  const totalStartupFunding = calculateTotal(startupFunding);
  const currentAssets =
    openingBalanceSheet.find(asset => asset.description === 'Current Assets')
      ?.amount || 0;
  const longTermAssets =
    openingBalanceSheet.find(asset => asset.description === 'Long-Term Assets')
      ?.amount || 0;

  const totalOpeningBalanceSheet = currentAssets + longTermAssets;
  const totalLiabilitiesAndEquity = calculateTotal(liabilitiesAndEquity);
  const totalAssets = calculateTotal(assets);
  const totalProjections = calculateTotal(projections);

  const totalCosts = totalStartupCosts + totalAssets + totalProjections;
  const fundingMinusCosts = totalStartupFunding - totalCosts;

  useEffect(() => {
    if (totalCosts !== totalStartupFunding && report === 'opening-costs') {
      setShowNotif(true);
      setShowCongrats(false);
    } else if (
      totalCosts === totalStartupFunding &&
      report === 'opening-costs'
    ) {
      setShowNotif(false);
      if (!showCongrats) {
        // Show congratulatory modal only once
        setShowCongrats(true);
      }
    }
  }, [totalCosts, totalStartupFunding, report]);

  const totalRowStyle = {
    backgroundColor: fundingMinusCosts !== 0 ? 'red' : '',
    color: 'white'
  };

  const LiabilitiesFunding = startupFunding.slice(0, 3);
  const CapitalFunding = startupFunding.slice(3, 6);

  const totalFundingLiabilities = LiabilitiesFunding.reduce(
    (sum, item) => sum + item.amount,
    0
  );
  const totalCapitalFunding = CapitalFunding.reduce(
    (sum, item) => sum + item.amount,
    0
  );

  const insertPageBreaks = description => {
    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.width = '800px';
    document.body.appendChild(tempDiv);

    let content = '';
    let accumulatedHeight = 0;
    const maxHeight = 850; // Example height for a page

    const paragraphs = description.split('\n');
    paragraphs.forEach(paragraph => {
      tempDiv.innerHTML = paragraph; // Add the paragraph to the temp div
      const paragraphHeight = tempDiv.clientHeight; // Measure the height

      accumulatedHeight += paragraphHeight;

      if (accumulatedHeight > maxHeight) {
        console.log('Inserting break');
        content += `<div style="height: 20px; page-break-before: always;"></div>`; // Add a page break
        accumulatedHeight = paragraphHeight;
      }
      content += paragraph;
    });

    document.body.removeChild(tempDiv); // Clean up the temporary div
    return content;
  };

  return (
    <Container id="opening-costs">
      <Header />
      <h4 className="text-primary p-0">Opening Cost Assumptions</h4>
      <div id="pdf-content" style={{ paddingTop: '00px' }}>
        <Row className="mt-0">
          <Col md={7} style={{ padding: '2px' }}>
            <Card>
              <Card.Header>
                <h5 className="text-primary">Startup Costs</h5>
              </Card.Header>
              <Card.Body>
                <Table
                  responsive
                  className="styled-table"
                  style={{
                    color: `${isPdf ? 'black' : 'white'}`,
                    marginBottom: '0',
                    borderCollapse: 'collapse',
                    width: '100%',
                    tableLayout: 'fixed'
                  }}
                >
                  <thead>
                    <tr>
                      <th>Cost Type</th>
                      <th>Description</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {startupCosts.map((item, index) => (
                      <tr key={index}>
                        <td>{item.costType}</td>
                        <td>{item.description}</td>
                        <td>
                          {currencies[companyDetails.currency]}
                          {item.amount.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    {projections.map((projection, index) => (
                      <tr key={index}>
                        {console.log(projection)}
                        <td colSpan={2}>{projection.label}</td>
                        <td>
                          {currencies[companyDetails.currency]}
                          {projection.amount.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr style={totalRowStyle}>
                      <td></td> {/* Adjusted for alignment */}
                      <td>
                        <strong>Total Expenses</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {(
                            totalStartupCosts + totalProjections
                          ).toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} style={{ padding: '2px' }}>
            <Card>
              <Card.Header>
                <h5 className="text-primary">Start Up Funding</h5>
              </Card.Header>

              <Card.Body>
                <Table
                  responsive
                  className="styled-table"
                  style={{
                    color: `${isPdf ? 'black' : 'white'}`,
                    marginBottom: '0',
                    borderCollapse: 'collapse',
                    width: '100%',
                    tableLayout: 'fixed'
                  }}
                >
                  <thead>
                    <tr>
                      <th>Accounts</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {LiabilitiesFunding.map((item, index) => (
                      <tr key={index}>
                        <td>{item.description}</td>
                        <td>
                          {currencies[companyDetails.currency]}
                          {item.amount.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <strong>Total Liabilities</strong>
                      </td>
                      <td>
                        {currencies[companyDetails.currency]}
                        {totalFundingLiabilities.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">&nbsp;</td> {/* Empty row */}
                    </tr>

                    {CapitalFunding.map((item, index) => (
                      <tr key={`capital-${index}`}>
                        <td>{item.description}</td>
                        <td>
                          {currencies[companyDetails.currency]}
                          {item.amount.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <strong>Total Capital</strong>
                      </td>
                      <td>
                        {currencies[companyDetails.currency]}
                        {totalCapitalFunding.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">&nbsp;</td> {/* Empty row */}
                    </tr>
                    <tr style={totalRowStyle}>
                      <td>
                        <strong>Total Funding</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {calculateTotal(startupFunding).toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-0">
          <Col md={7} style={{ padding: '2px' }}>
            <Card>
              <Card.Header>
                <h5 className="text-primary">Assets</h5>
              </Card.Header>

              <Card.Body>
                <Table
                  responsive
                  className="styled-table"
                  style={{
                    color: `${isPdf ? 'black' : 'white'}`,
                    marginBottom: '0',
                    borderCollapse: 'collapse',
                    width: '100%',
                    tableLayout: 'fixed'
                  }}
                >
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assets.map((item, index) => (
                      <>
                        {index === 5 && (
                          <>
                            <tr>
                              <td
                                colSpan="2"
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '30px'
                                }}
                              >
                                Long Term Assets
                              </td>
                            </tr>
                          </>
                        )}
                        <tr key={index}>
                          <td>{item.description}</td>
                          <td>
                            {currencies[companyDetails.currency]}
                            {item.amount.toLocaleString()}
                          </td>
                        </tr>
                      </>
                    ))}
                    <tr style={totalRowStyle}>
                      <td>
                        <strong>Total Assets</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {totalAssets.toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                    <tr style={{ height: '20px' }}></tr> {/* Add spacing row */}
                    <tr style={totalRowStyle}>
                      <td>
                        <strong>Total Costs</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {totalCosts.toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col md={5} style={{ padding: '2px' }}>
            <Card>
              <Card.Header>
                <h5 className="text-primary">Opening Balance Sheet</h5>
              </Card.Header>

              <Card.Body>
                <Table
                  responsive
                  className="styled-table"
                  style={{
                    color: `${isPdf ? 'black' : 'white'}`,
                    marginBottom: '0',
                    borderCollapse: 'collapse',
                    width: '100%',
                    tableLayout: 'fixed'
                  }}
                >
                  <thead>
                    <tr>
                      <th>Asset</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openingBalanceSheet.map((item, index) => (
                      <tr key={index}>
                        <td>{item.description}</td>
                        <td>
                          {currencies[companyDetails.currency]}
                          {item.amount.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr style={totalRowStyle}>
                      <td>
                        <strong>Total Opening Balance Sheet</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {totalOpeningBalanceSheet.toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                    <tr style={{ height: '20px' }}></tr>{' '}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>
                <h5 className="text-primary">Liabilities and Equity</h5>
              </Card.Header>

              <Card.Body>
                <Table
                  responsive
                  className="styled-table pt-3"
                  style={{
                    color: `${isPdf ? 'black' : 'white'}`,
                    marginBottom: '0',
                    borderCollapse: 'collapse',
                    width: '100%',
                    tableLayout: 'fixed'
                  }}
                >
                  <thead>
                    <tr>
                      <th>Liabilities</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {liabilitiesAndEquity.slice(0, 3).map((item, index) => (
                      <>
                        {index === 2 && (
                          <>
                            <tr>
                              <td>
                                <strong>Current Assets</strong>
                              </td>
                              <td>
                                {currencies[companyDetails.currency]}
                                {calculateTotal(
                                  liabilitiesAndEquity.slice(0, 2)
                                ).toLocaleString()}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr key={index}>
                          <td>{item.description}</td>
                          <td>
                            {currencies[companyDetails.currency]}
                            {item.amount.toLocaleString()}
                          </td>
                        </tr>
                      </>
                    ))}
                    <tr style={totalRowStyle}>
                      <td>
                        <strong>Total Liabilities</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {calculateTotal(
                            liabilitiesAndEquity.slice(0, 3) // Summing up the first 2 items
                          ).toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                    <div style={{ paddingBottom: '30px' }}></div>
                    <tr>
                      <td colSpan={2}>
                        <strong>Equity</strong>
                      </td>
                    </tr>
                    {liabilitiesAndEquity.slice(3, 5).map((item, index) => (
                      <tr key={index}>
                        <td>{item.description}</td>
                        <td>
                          {currencies[companyDetails.currency]}
                          {item.amount.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                    <tr style={totalRowStyle}>
                      <td>
                        <strong>Total Equity</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {calculateTotal(
                            liabilitiesAndEquity.slice(3, 5) // Summing up the remaining items
                          ).toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                    <tr style={totalRowStyle}>
                      <td>
                        <strong>Total Liabilities and Equity</strong>
                      </td>
                      <td>
                        <strong>
                          {currencies[companyDetails.currency]}
                          {totalLiabilitiesAndEquity.toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={6} style={{ padding: '2px' }}></Col>
        </Row>

        <Modal show={showNotif} onHide={() => setShowNotif(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">
              Oops! We have a problem!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="align-items-center justify-content-center">
              <h3>
                <span className="text-danger pt-4">
                  Difference: {currencies[companyDetails.currency]}
                  {fundingMinusCosts.toLocaleString()}
                </span>
              </h3>
              <p className="pt-3">
                The total startup cost and total startup funding don't match so
                your balance sheet is not balanced, you need to adjust one of
                the following:
                <br />
              </p>

              <ul className="p-5">
                <li> Go to: Owners and increase the contributions</li>
                <li> Go to: Investors and increase the contributions </li>
                <li> Go to: Bank Loan and increase the loan amount </li>
                <li>
                  Go to: Expenses and reduce the startup expense, payroll
                  expense, or operating expense startup budgets
                </li>
              </ul>
              <p className="pt-3">
                It is imperative that your balance sheet balance
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => setShowNotif(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showCongrats}
          onHide={() => setShowCongrats(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-success">Congratulations!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="align-items-center justify-content-center">
              <p className="pt-3">
                Your balance sheet is correct and balanced!
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-success"
              onClick={() => setShowCongrats(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default OpeningCosts;
