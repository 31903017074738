import React, { useEffect, useRef, useContext, useState } from 'react';
import { Badge, Card, Col, Form, Row } from 'react-bootstrap';
import YearlyChart from './YearlyChart';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { SelectedCompanyContext } from 'context/Context';

const Yearly = () => {
  const [chartData, setChartData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('Year 1 - Year 3');
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/analysis/yearlyProjection?companyId=${companyDetails.id}`)
        .then(response => {
          setChartData(response.data);
          setFilteredData(response.data); // Initialize filteredData with the complete dataset
        })
        .catch(error => {
          toast.error(error.message);
        });
    }
  }, [companyDetails]);

  useEffect(() => {
    // Filter data based on selectedYear
    if (selectedYear === 'Year 1 - Year 3') {
      setFilteredData(chartData);
    } else {
      const yearNumber = parseInt(selectedYear.split(' ')[1], 10);
      const filtered = chartData.filter(data => data.year === yearNumber);
      setFilteredData(filtered);
    }
  }, [selectedYear, chartData]);

  const chartRef = useRef(null);

  return (
    <Card className="mt-3" style={{ height: '500px' }}>
      <Card.Header className="border-bottom">
        <Row className="flex-between-center gy-2">
          <Col xs="auto">
            <h4 className="mb-0 me-x1">Yearly Analysis</h4>
          </Col>
          <Col xs="auto">
            <Form.Select
              size="sm"
              value={selectedYear}
              onChange={e => setSelectedYear(e.target.value)}
            >
              <option>Year 1</option>
              <option>Year 2</option>
              <option>Year 3</option>
              <option>Year 1 - Year 3</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <YearlyChart data={filteredData} ref={chartRef} />
      </Card.Body>
    </Card>
  );
};

export default Yearly;
