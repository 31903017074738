import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  FloatingLabel,
  Tab,
  Tabs,
  Form,
  Button,
  Card
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { addMoreNotification } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import { toast } from 'react-toastify';
import api from 'utils/api';
import { SelectedCompanyContext } from 'context/Context';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { currencies } from 'utils';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { RevenueWizardContext } from 'context/Context';
import { RevenueFormModalContext } from 'context/Context';

const PageHeader = ({ title, description }) => (
  <div className="page-header mb-3">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

// Helper functions
const formatNumber = (value, type, currency) => {
  if (type === 'currency') {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currency
    }).format(value || 0);
  }
  return new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
    value || 0
  );
};

const extractNumber = formattedValue => {
  if (typeof formattedValue !== 'string') {
    return formattedValue; // Return 0 or handle the case accordingly
  }
  const cleanedValue = formattedValue.replace(/[^0-9.-]+/g, '');
  return parseFloat(cleanedValue) || 0;
};

const calculateValues = (
  initialOrderValue,
  startupMonthsValue,
  retailPriceValue,
  costPriceValue,
  rangeValue,
  repeatOrderValue
) => {
  const initialOrder = extractNumber(initialOrderValue) || 0;
  const startupMonths = extractNumber(startupMonthsValue) || 0;
  const range = extractNumber(rangeValue) || 0;
  const repeatOrder = extractNumber(repeatOrderValue) || 0;
  const retailPrice = extractNumber(retailPriceValue) || 0;
  const costPrice = extractNumber(costPriceValue) || 0;

  const totalOrder = initialOrder * range;
  const totalYearlyOrder = totalOrder * repeatOrder;
  const totalYearlyCost = totalYearlyOrder * costPrice; // Moved before totalStartupBudget calculation
  const totalYearlyRevenue = totalYearlyOrder * retailPrice;
  const totalStartupBudget = (totalYearlyCost / 12) * startupMonths; // Corrected reference to totalYearlyCost
  const monthlyRevenue = totalYearlyRevenue / 12;
  const weeklyRevenue = totalYearlyRevenue / 52;

  return {
    totalOrder,
    totalYearlyOrder,
    totalYearlyRevenue,
    totalStartupBudget,
    totalYearlyCost,
    monthlyRevenue,
    weeklyRevenue
  };
};

const WholesaleRevenueForm = () => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      retailPrice: '0',
      costPrice: '0',
      initialOrder: '0',
      startupMonths: 1,
      repeatOrder: '0',
      range: '0'
    }
  });

  const [activeTab, setActiveTab] = useState('orderDetails');
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [calculatedValues, setCalculatedValues] = useState({});
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);

  const initialOrder = watch('initialOrder');
  const startupMonths = watch('startupMonths');
  const retailPrice = watch('retailPrice');
  const costPrice = watch('costPrice');
  const range = watch('range');
  const repeatOrder = watch('repeatOrder');

  useEffect(() => {
    if (edit === 'whole-sale' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      setValue('customerName', editDetails.customerName);
      setValue('initialOrder', editDetails.initialOrder);
      setValue('startupMonths', editDetails.startupMonths);
      setValue('retailPrice', editDetails.retailPrice);
      setValue('costPrice', editDetails.costPrice);
      setValue('repeatOrder', editDetails.repeatOrder);
      setValue('range', editDetails.range);

      setCalculatedValues(
        calculateValues(
          editDetails.initialOrder,
          editDetails.startupMonths,
          editDetails.retailPrice,
          editDetails.costPrice,
          editDetails.range,
          editDetails.repeatOrder
        )
      );
    }
  }, [edit, editDetails]);

  useEffect(() => {
    setCalculatedValues(
      calculateValues(
        initialOrder,
        startupMonths,
        retailPrice,
        costPrice,
        range,
        repeatOrder
      )
    );
  }, [initialOrder, startupMonths, retailPrice, costPrice, range, repeatOrder]);

  const getTabDescription = tabKey => {
    switch (tabKey) {
      case 'orderDetails':
        return 'Enter the name of the customer and the projected initial order per item range.';

      case 'orderDetails1':
        return 'Enter the name of the customer and the projected initial order.';

      case 'summary':
        return 'Review the summary of costs, profit margin, and projected profit percentage.';
      default:
        return '';
    }
  };

  const goToNextTab = () => {
    const tabs = ['orderDetails', 'orderDetails1', 'summary'];
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex < tabs.length - 1) setActiveTab(tabs[currentIndex + 1]);
  };

  const goToPreviousTab = () => {
    const tabs = ['orderDetails', 'orderDetails1', 'summary'];
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex > 0) setActiveTab(tabs[currentIndex - 1]);
  };

  const onSubmit = async data => {
    const formattedData = {
      ...data,
      companyId: String(companyDetails.id),
      totalOrder: String(calculatedValues.totalOrder),
      totalYearlyOrder: String(calculatedValues.totalYearlyOrder),
      totalYearlyRevenue: String(calculatedValues.totalYearlyRevenue),
      totalStartupBudget: String(calculatedValues.totalStartupBudget),
      totalYearlyCost: String(calculatedValues.totalYearlyCost),
      monthlyRevenue: String(calculatedValues.monthlyRevenue),
      weeklyRevenue: String(calculatedValues.weeklyRevenue)
    };

    const payload =
      isEditing && docId ? { id: docId, data: formattedData } : formattedData;

    api
      .post(
        isEditing ? '/wholeSale-revenue/update' : 'wholeSale-revenue/create',
        payload
      )
      .then(res => {
        reset();
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Whole Sale Revenue"?'
            onClick={() => {
              setShowFormModal(true);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, { theme: 'colored' });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            if (error.response.data.fundsError) {
              setShowNotif(true);
            } else {
              toast.error(error.response.data.message || error.response.data, {
                theme: 'colored'
              });
            }
            return;
          }
        }
        toast.error(`Something went wrong!`, { theme: 'colored' });
      });
  };

  return (
    <div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Row className="pt-3">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <PageHeader
                title={
                  <span
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold'
                    }}
                  >
                    {
                      {
                        orderDetails: 'Customer order details',
                        orderDetails1: 'Customer order details',
                        summary: 'Summary'
                      }[activeTab]
                    }
                  </span>
                }
                description={
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#6c757d'
                    }}
                  >
                    {getTabDescription(activeTab)}
                  </span>
                }
              />

              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={k => setActiveTab(k)}
                className="mb-3"
              >
                <Tab eventKey="orderDetails" title="Order Details">
                  <Row className="g-3">
                    <Col xs={12} lg={12}>
                      <Form.Group controlId="customerName" className="mb-1">
                        <FloatingLabel label="Customer Name">
                          <Form.Control
                            type="text"
                            placeholder="Customer Name"
                            defaultValue="Selfridges Food Hall"
                            {...register('customerName', {
                              required: 'This field is required'
                            })}
                            isInvalid={!!errors.customerName}
                            name="customerName"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors['customerName']?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={4}>
                      <Form.Group controlId="initialOrder" className="mb-1">
                        <FloatingLabel label="Initial Order">
                          <Form.Control
                            type="text"
                            placeholder="Initial Order"
                            {...register('initialOrder', {
                              required: 'This field is required'
                            })}
                            value={initialOrder}
                            onChange={e => {
                              const formattedValue = parseFloat(
                                extractNumber(e.target.value)
                              ).toLocaleString();
                              setValue('initialOrder', formattedValue);
                            }}
                            isInvalid={!!errors.initialOrder}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.initialOrder?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={4}>
                      <Form.Group controlId="startupMonths" className="mb-1">
                        <FloatingLabel label="Startup Months">
                          <Form.Control
                            type="text"
                            placeholder="Startup Months"
                            {...register('startupMonths', {
                              required: 'This field is required'
                            })}
                            value={startupMonths}
                            onChange={e => {
                              const formattedValue = parseFloat(
                                extractNumber(e.target.value)
                              ).toLocaleString();
                              setValue('startupMonths', formattedValue);
                            }}
                            isInvalid={!!errors.startupMonths}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.startupMonths?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={4}>
                      <Form.Group controlId="range" className="mb-1">
                        <FloatingLabel label="Product Range">
                          <Form.Control
                            type="text"
                            placeholder="Product Range"
                            {...register('range', {
                              required: 'This field is required'
                            })}
                            value={range}
                            onChange={e => {
                              const formattedValue = parseFloat(
                                extractNumber(e.target.value)
                              ).toLocaleString();
                              setValue('range', formattedValue);
                            }}
                            isInvalid={!!errors.range}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.range?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-3">
                    <Col xs={12} lg={4}>
                      <Form.Group controlId="repeatOrder" className="mb-1">
                        <FloatingLabel label="Repeat Order">
                          <Form.Control
                            type="text"
                            placeholder="Repeat Order"
                            {...register('repeatOrder', {
                              required: 'This field is required'
                            })}
                            value={repeatOrder}
                            onChange={e => {
                              const formattedValue = parseFloat(
                                extractNumber(e.target.value)
                              ).toLocaleString();
                              setValue('repeatOrder', formattedValue);
                            }}
                            isInvalid={!!errors.repeatOrder}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.repeatOrder?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={4}>
                      <CurrencyWizardInput
                        symbol={currencies[companyDetails.currency]}
                        name="retailPrice"
                        label="Wholesale Price"
                        errors={errors}
                        formGroupProps={{}}
                        formControlProps={{
                          ...register('retailPrice', {
                            required: 'This field is required'
                          })
                        }}
                        setValue={setValue}
                        watch={watch}
                      />
                    </Col>

                    <Col xs={12} lg={4}>
                      <CurrencyWizardInput
                        symbol={currencies[companyDetails.currency]}
                        name="costPrice"
                        label="Cost Price"
                        errors={errors}
                        formGroupProps={{}}
                        formControlProps={{
                          ...register('costPrice', {
                            required: 'This field is required'
                          })
                        }}
                        setValue={setValue}
                        watch={watch}
                      />
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="orderDetails1" title="Projections">
                  <Row className="g-3">
                    <Col xs={12} lg={4}>
                      <Form.Group controlId="totalOrder" className="mb-1">
                        <FloatingLabel label="Total Order">
                          <Form.Control
                            value={formatNumber(
                              calculatedValues.totalOrder,
                              'number'
                            )}
                            disabled
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={4}>
                      <Form.Group controlId="totalYearlyOrder" className="mb-1">
                        <FloatingLabel label="Total Yearly Order">
                          <Form.Control
                            value={formatNumber(
                              calculatedValues.totalYearlyOrder,
                              'number'
                            )}
                            disabled
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={4}>
                      <Form.Group
                        controlId="totalYearlyRevenue"
                        className="mb-1"
                      >
                        <FloatingLabel label="Total Yearly Revenue">
                          <Form.Control
                            value={formatNumber(
                              calculatedValues.totalYearlyRevenue,
                              'currency',
                              companyDetails.currency
                            )}
                            disabled
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-3">
                    <Col xs={12} lg={3}>
                      <Form.Group controlId="toa" className="mb-1">
                        <FloatingLabel label="Total Startup Budget">
                          <Form.Control
                            value={formatNumber(
                              calculatedValues.totalStartupBudget,
                              'currency',
                              companyDetails.currency
                            )}
                            disabled
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={3}>
                      <Form.Group controlId="weeklyRevenue" className="mb-1">
                        <FloatingLabel label="Total Weekly Revenue">
                          <Form.Control
                            value={formatNumber(
                              calculatedValues.weeklyRevenue,
                              'currency',
                              companyDetails.currency
                            )}
                            disabled
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={3}>
                      <Form.Group controlId="monthlyRevenue" className="mb-1">
                        <FloatingLabel label="Total Monthly Revenue">
                          <Form.Control
                            value={formatNumber(
                              calculatedValues.monthlyRevenue,
                              'currency',
                              companyDetails.currency
                            )}
                            disabled
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col xs={12} lg={3}>
                      <Form.Group controlId="totalYearlyCost" className="mb-1">
                        <FloatingLabel label="Total Yearly Cost">
                          <Form.Control
                            value={formatNumber(
                              calculatedValues.totalYearlyCost,
                              'currency',
                              companyDetails.currency
                            )}
                            disabled
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="summary" title="Summary">
                  <Row className="g-1">
                    <Col>
                      <Card className="shadow-sm">
                        <Card.Header className=" text-center text-white">
                          <Card.Title className="mb-0"></Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Row className="gy-2">
                            <Col xs={12} md={12}>
                              <Card body className="text-center">
                                <p>
                                  <strong>Total Order:</strong>

                                  {formatNumber(
                                    calculatedValues.totalOrder,
                                    'number'
                                  )}
                                </p>

                                <p>
                                  <strong>Total Yearly Order:</strong>

                                  {formatNumber(
                                    calculatedValues.totalYearlyOrder,
                                    'number'
                                  )}
                                </p>

                                <p>
                                  <strong>Total Yearly Revenue:</strong>
                                  {formatNumber(
                                    calculatedValues.totalYearlyRevenue,
                                    'currency',
                                    companyDetails.currency
                                  )}
                                </p>

                                <p>
                                  <strong>Total Yearly Cost:</strong>

                                  {formatNumber(
                                    calculatedValues.totalYearlyCost,
                                    'currency',
                                    companyDetails.currency
                                  )}
                                </p>

                                <p>
                                  <strong>Monthly Revenue:</strong>

                                  {formatNumber(
                                    calculatedValues.monthlyRevenue,
                                    'currency',
                                    companyDetails.currency
                                  )}
                                </p>

                                <p>
                                  <strong>Weekly Revenue:</strong>

                                  {formatNumber(
                                    calculatedValues.weeklyRevenue,
                                    'currency',
                                    companyDetails.currency
                                  )}
                                </p>

                                <p>
                                  <strong>Startup Budget:</strong>

                                  {formatNumber(
                                    calculatedValues.totalStartupBudget,
                                    'currency',
                                    companyDetails.currency
                                  )}
                                </p>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>

              <div className="border-dashed border-bottom my-3" />
              <Row className=" mb-3">
                <Col className="text-center mt-3">
                  <IconButton
                    variant="outline-primary"
                    size="sm"
                    onClick={goToPreviousTab}
                    disabled={activeTab === 'orderDetails'}
                  >
                    <FaArrowLeft /> Previous
                  </IconButton>
                  <IconButton
                    variant="outline-success"
                    size="sm"
                    onClick={goToNextTab}
                    disabled={activeTab === 'summary'}
                    className="ms-2"
                  >
                    Next <FaArrowRight />
                  </IconButton>
                </Col>
              </Row>

              <Row className="mt-3 mb-3">
                <Col className="text-center">
                  <IconButton type="submit" variant="outline-success" size="sm">
                    {isEditing ? 'Update' : 'Submit'}
                  </IconButton>

                  <Button
                    type="button"
                    variant="outline-secondary"
                    size="sm"
                    className="ms-2"
                    onClick={() => reset()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};

export default WholesaleRevenueForm;
