import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import {
  SelectedCompanyContext,
  StartupFormModalContext,
  StartupWizardContext
} from 'context/Context';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from 'utils/api';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

// Form component for managing Land and Building data
const LandAndBuildingForm = () => {
  // State variables and refs initialization
  const [show, setShow] = useState(false); // State for popover visibility
  const [target, setTarget] = useState(null); // State to track popover target
  const ref = useRef(null); // Ref to manage popover positioning

  // Context usage for managing wizard steps and editing details
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(StartupWizardContext);
  const { setShowFormModal } = useContext(StartupFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  // State and form handling
  const [isEditing, setIsEditing] = useState(false); // State for edit mode
  const [docId, setDocId] = useState(null); // State for document ID

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      // Default values for form fields
      propertyDescription: '', // Description of the property being entered
      purchasePrice: '$ 100', // Total cost of purchasing the property
      fixturesFittings: ' $ 50', // Cost of fixtures and fittings included in the purchase (includes GST)
      landValue: '', // Value of the land portion of the property
      usefulLife: '1', // Expected useful life of the property for depreciation purposes
      gstVat: '0', // GST or VAT amount applied (initially set to $0)
      fixturesFittingsGST: '0', // GST amount included in fixtures and fittings (initially set to $0)
      totalDepreciation: '0', // Total depreciation value of fixtures and fittings
      depreciationYearly: '0', // Yearly depreciation value
      depreciationMontly: '0', // Monthly depreciation value
      depreciationPercentage: '0' // Percentage rate of depreciation
    }
  });

  // Effect to populate form fields when editing existing data
  useEffect(() => {
    if (edit === 'LandAndBuilding' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      // Set form field values from editDetails
      setValue('propertyDescription', editDetails.propertyDescription); // Set property description
      setValue('purchasePrice', editDetails.purchasePrice); // Set purchase price
      setValue('fixturesFittings', editDetails.fixturesFittings); // Set fixtures and fittings cost (including GST)
      setValue('landValue', editDetails.landValue); // Set land value
      setValue('usefulLife', editDetails.usefulLife); // Set useful life of the property
      setValue('gstVat', editDetails.gstVat); // Set GST or VAT amount
      setValue('fixturesFittingsGST', editDetails.fixturesFittingsGST); // Set GST amount in fixtures and fittings
      setValue('totalDepreciation', editDetails.totalDepreciation); // Set total depreciation value
      setValue('depreciationYearly', editDetails.depreciationYearly); // Set yearly depreciation value
      setValue('depreciationMontly', editDetails.depreciationMontly); // Set monthly depreciation value
      setValue('depreciationPercentage', editDetails.depreciationPercentage); // Set depreciation percentage
    }
  }, [edit]);
  // Watchers for form fields
  const watchPurchasePrice = watch('purchasePrice'); // Watch changes in the purchase price field
  const watchFixturesFittings = watch('fixturesFittings'); // Watch changes in the fixtures and fittings field
  const watchUsefulLife = watch('usefulLife'); // Watch changes in the useful life field

  // Effect to calculate dependent fields based on user input
  useEffect(() => {
    // Check if all necessary fields have values
    if (watchPurchasePrice && watchFixturesFittings && watchUsefulLife) {
      // Parsing input values
      const purchasePrice = parseFloat(
        watchPurchasePrice.replace(/[^0-9.]/g, '')
      );
      const fixturesFittings = parseFloat(
        watchFixturesFittings.replace(/[^0-9.]/g, '')
      );
      const usefulLife = parseFloat(watchUsefulLife);

      // GST calculation
      const gstPercentage = 10; // 10% GST/VAT
      const inclusiveValue = fixturesFittings;
      const gstAmount =
        (inclusiveValue / (1 + gstPercentage / 100)) * (gstPercentage / 100);

      // Calculating derived values
      const gstVat = gstAmount;
      const fixturesFittingsGST = gstAmount;
      const landValue = purchasePrice - fixturesFittings;
      const totalDepreciation = fixturesFittings - gstAmount;
      let depreciationYearly = 0;
      if (usefulLife > 0) depreciationYearly = totalDepreciation / usefulLife;
      const depreciationMontly = depreciationYearly / 12;
      const depreciationPercentage =
        (depreciationYearly / totalDepreciation) * 100;

      // Updating form fields with calculated values
      setValue(
        'gstVat',
        currencies[companyDetails.currency] +
          ' ' +
          gstVat.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      setValue(
        'fixturesFittingsGST',
        `$ ${fixturesFittingsGST.toLocaleString('en-US', {
          maximumFractionDigits: 2
        })}`
      );
      setValue(
        'landValue',
        currencies[companyDetails.currency] +
          ' ' +
          landValue.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      setValue(
        'totalDepreciation',
        currencies[companyDetails.currency] +
          ' ' +
          totalDepreciation.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      setValue(
        'depreciationYearly',
        currencies[companyDetails.currency] +
          ' ' +
          depreciationYearly.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      setValue(
        'depreciationMontly',
        currencies[companyDetails.currency] +
          ' ' +
          depreciationMontly.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      setValue(
        'depreciationPercentage',
        `${depreciationPercentage.toFixed(2)} %`
      );
    }
  }, [watchPurchasePrice, watchFixturesFittings, watchUsefulLife]);

  // Function to handle popover visibility
  const handleClick = event => {
    setShow(!show); // Toggle visibility of the popover
    setTarget(event.target); // Set the target element for the popover
  };

  // Function to handle form submission
  const onSubmitData = data => {
    // Prepare payload for API request
    data.usefulLife = data.usefulLife.toString();
    const payload =
      isEditing && docId
        ? { id: docId, data } // Update existing record
        : {
            companyId: companyDetails.id, // Create new record
            ...data
          };

    // API call to save or update data
    api
      .post(
        isEditing ? '/land-and-building/update' : '/land-and-building/create',
        payload
      )
      .then(res => {
        // Reset form and state on success
        reset(); // Reset form fields
        setEdit(''); // Clear edit mode
        setEditDetails({}); // Clear edit details
        setShowFormModal(false); // Close modal
        // Display notification to add more items
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Land and Buildings" ?'
            onClick={() => {
              setShowFormModal(true); // Show form modal again
              setTimeout(() => {
                document
                  .querySelector('input[name="propertyDescription"]')
                  ?.focus(); // Focus on property description input
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        // Error handling and toast notifications
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }
          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  // Function to handle form validation errors
  const onError = () => {
    const validation = true; // Placeholder for validation logic

    if (!validation) {
      clearErrors();
      setStep(step + 1); // Increment step in wizard
    }
  };

  // Render JSX for Land and Building form
  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            {/* Page header with title and description */}
            <PageHeader
              title="Land and Buildings"
              description="Land and buildings refer to real estate assets owned by a company. These are long-term assets that appear on a company's balance sheet and are considered part of its property holdings."
              className="text-primary"
            />

            {/* Button to toggle help popover */}
            <div className="text-start">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                size="sm"
                onClick={handleClick}
              ></IconButton>
            </div>

            {/* Help popover */}
            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="div" style={{ backgroundColor: '' }}>
                    {/* Help content */}
                    <Row
                      as={motion.div}
                      className="g-3"
                      initial={{ x: '-20%' }}
                      animate={{ x: '0%' }}
                      exit={{
                        x: window.innerWidth,
                        transition: { duration: 0.5 }
                      }}
                    >
                      <PageHeader
                        className="text-primary"
                        title="Pre-Paid Costs"
                        description=""
                      />
                      <div
                        className="border-none rounded mt-2"
                        style={{ maxHeight: '280px', overflowY: 'auto' }}
                      >
                        <Row>
                          {/* Help content details */}
                          <ul>
                            <h6 className="fw-bold mt-0">
                              What is meant by Land and Building?
                            </h6>
                            <div className="border-dashed border-bottom my-3" />
                            <li>
                              "Land and buildings" refer to real estate assets
                              owned by a company. These are long-term assets
                              that appear on a company's balance sheet and are
                              considered part of its property holdings. Here's
                              what each term means:
                            </li>

                            {/* Other content here... */}

                            <div className="border-dashed border-bottom my-3" />
                            <h6 className="fw-bold mt-4">What to do?</h6>
                            <div className="border-dashed border-bottom my-3" />
                            <li>
                              Enter the detail of the premises of the company
                              that you wish to purchase.
                            </li>
                            <div className="border-dashed border-bottom my-3" />
                          </ul>
                          <div className="border-dashed border-bottom my-3" />
                        </Row>
                      </div>
                    </Row>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>

            {/* Form for data entry */}
            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              {/* First row of form fields */}
              <Row className="g-3 pt-3">
                <Col lg={3}>
                  {/* Property Description input field */}
                  <Form.Group controlId="propertyDescription">
                    <FloatingLabel label="Property Description">
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="propertyDescription"
                        {...register('propertyDescription', {
                          required: 'This field is required.'
                        })}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors.propertyDescription
                        }
                        isInvalid={errors.propertyDescription}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.propertyDescription?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  {/* Purchase Price input field */}
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    label="Purchase Price"
                    name="purchasePrice"
                    errors={errors}
                    formControlProps={{
                      ...register('purchasePrice', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={3}>
                  {/* Fixtures Fittings input field */}
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    label="Fixtures Fittings"
                    name="fixturesFittings"
                    errors={errors}
                    formControlProps={{
                      ...register('fixturesFittings', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={3}>
                  {/* Useful Life input field */}
                  <Form.Group controlId="usefulLife">
                    <FloatingLabel label="Useful Life">
                      <Form.Control
                        type="number"
                        placeholder="Useful Life"
                        name="usefulLife"
                        {...register('usefulLife', {
                          required: 'This field is required.',
                          min: {
                            value: 1,
                            message: 'Minimum value is 1'
                          },
                          valueAsNumber: true
                        })}
                        isValid={
                          Object.keys(errors).length > 0 && !errors.usefulLife
                        }
                        isInvalid={errors.usefulLife}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.usefulLife?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              {/* Second row of form fields */}
              <Row className="g-3 pt-3">
                <Col lg={3}>
                  {/* GST/VAT input field */}
                  <PercentageWizardInput
                    label="GST/VAT"
                    name="gstVat"
                    errors={errors}
                    formControlProps={{
                      ...register('gstVat', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true}
                  />
                </Col>
                <Col lg={3}>
                  {/* Depreciation Monthly input field */}
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    label="Depreciation Monthly"
                    name="depreciationMontly"
                    errors={errors}
                    formControlProps={{
                      ...register('depreciationMontly', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true}
                  />
                </Col>

                <Col lg={3}>
                  {/* Land Value input field */}
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    label="Land Value"
                    name="landValue"
                    errors={errors}
                    formControlProps={{
                      ...register('landValue', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true}
                  />
                </Col>
                <Col lg={3}>
                  {/* Depreciation Yearly input field */}
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    label="Depreciation Yearly"
                    name="depreciationYearly"
                    errors={errors}
                    formControlProps={{
                      ...register('depreciationYearly', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true}
                  />
                </Col>
              </Row>

              {/* Additional rows or content can be added as per form requirements */}
              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={3}></Col>
                <Col lg={6}></Col>
              </Row>
              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}></Col>
                <Col lg={6}></Col>
              </Row>

              {/* Save button section */}
              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default LandAndBuildingForm;
