import React, { forwardRef } from 'react';
import { Card, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';

const CompanySetup = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="companySetup" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Company Setup</h5>
      </Card.Header>
      <Card.Body>
        <div>
          <h5 className="text-primary mt-1 ps-3 mb-3">
            Financial Forecast Assumptions
          </h5>

          <h5 className="text-primary mt-1 ps-3 mb-3">General Assumptions</h5>
          <ul>
            <li>
              The forecasts have been prepared using Australian Accounting
              Standards as a guide.
            </li>
            <li>
              The forecasts are representative only of the forecast financial
              performance of the business.
            </li>
          </ul>

          <h5 className="text-primary mt-1 ps-3 mb-3">Product Sales</h5>
          <ul>
            <li>
              Sales forecasts are based on market research and marketing
              strategies.
            </li>
            <li>
              Unit sales increase over previous year: Year 2: 50%, Year 3: 50%.
            </li>
            <li>
              Unit sales prices are in line with market research and do not
              increase in Year 2 and Year 3.
            </li>
          </ul>

          <h5 className="text-primary mt-1 ps-3 mb-3">
            Profit and Loss Forecast
          </h5>
          <h5 className="text-primary mt-1 ps-3 mb-3">General Assumptions</h5>
          <ul>
            <li>Expenses are exclusive of GST.</li>
            <li>
              Employment Expenses increase by 20% in Year 2 and by 20% in Year
              3.
            </li>
            <li>
              Business Development Expenses increase by 10% in Year 2 and by 10%
              in Year 3.
            </li>
            <li>
              Overhead Expenses increase by 20% in Year 2 and by 20% in Year 3.
            </li>
            <li>Tax expense is based on the company tax rate of 30%.</li>
          </ul>

          <h5 className="text-primary mt-1 ps-3 mb-3">Assumption Notes</h5>
          <ol>
            {' '}
            <li>
              {' '}
              Travel and accommodation expenses that cannot be recovered from
              clients, facilitating both intrastate and interstate growth.{' '}
            </li>{' '}
            <li>
              {' '}
              Advertising and publicity costs in alignment with the marketing
              budget, with the majority of expenses occurring in the first four
              months.{' '}
            </li>{' '}
            <li>
              {' '}
              Entertainment expenses aimed at enhancing relationships with
              existing and potential customers for better retention.{' '}
            </li>{' '}
            <li>
              {' '}
              Marketing and promotion costs consistent with the marketing
              budget, encompassing branding and promotional materials.{' '}
            </li>{' '}
            <li>
              {' '}
              Accounting and tax expenses related to setup, tax structuring, and
              auditing fees.{' '}
            </li>{' '}
            <li>
              {' '}
              Bank fees projected based on historical data, adjusted for
              increased transaction volumes.{' '}
            </li>{' '}
            <li>
              {' '}
              Books and subscriptions for industry publications, modified
              according to activity levels.{' '}
            </li>{' '}
            <li>
              {' '}
              Computer consumables for minor purchases, such as printer
              cartridges.{' '}
            </li>{' '}
            <li>
              {' '}
              Insurance covering various liabilities and office premises.{' '}
            </li>{' '}
            <li>
              {' '}
              Internet and communication expenses for web hosting and data
              services.{' '}
            </li>{' '}
            <li> Telephone and fax costs based on past data. </li>{' '}
            <li> Equipment leases for phones, computers, and furniture. </li>{' '}
            <li>
              {' '}
              Legal fees for investment structuring and intellectual property
              protection.{' '}
            </li>{' '}
            <li>
              {' '}
              Rent and occupancy expenses, including outgoing and maintenance
              costs.{' '}
            </li>{' '}
            <li>
              {' '}
              Postage and courier costs based on historical information.{' '}
            </li>{' '}
            <li>
              {' '}
              Printing and stationery expenses based on historical data.{' '}
            </li>{' '}
            <li>
              {' '}
              Software and licensing fees for new software and updates.{' '}
            </li>{' '}
            <li> Depreciation estimated at 30% annually. </li>{' '}
          </ol>

          <h5 className="text-primary mt-1 ps-3 mb-3">Cash Flow Assumptions</h5>
          <ol>
            <li>GST is calculated on 10% of sales.</li>
            <li>
              Debtors received in line with assumptions: Month 0: COD, 30 Days,
              60 Days, 90, Days, 120 Days:
            </li>
            <li>
              Opening creditors paid in equal installments for the first 2
              months; purchases paid in the following month.
            </li>
            <li>Business Development costs paid in the month incurred.</li>
            <li>
              Overheads and employment costs paid monthly in the month incurred.
            </li>
            <li>Operating expenses in cash flow are net of GST.</li>
            <li>GST paid monthly, quarterly, or at the end of the year.</li>
            <li>
              Company Tax (PAYG) monthly, quarterly, or at the end of the year..
            </li>
            <li>
              Capital Purchases represent assets purchased during the year.
            </li>
            <li>
              Investors contribute capital in a the start of the business based
              on the investment offer.
            </li>
            <li>
              Dividends paid as per shares and valuation schedule in accordance
              with policy.
            </li>
          </ol>

          <h5 className="text-primary mt-1 ps-3 mb-3">Balance Sheet</h5>
          <ol>
            <li>
              Intangible assets have not been amortized as required by
              Australian Accounting Standards.
            </li>
            <li>
              Intangibles increase with share split and revaluation to reflect
              increased value.
            </li>
            <li>Directors and family loans are interest-free and long-term.</li>
            <li>
              Founders capital represents share capital contributed to set up
              the business.
            </li>
            <li>New shares represent new investors' capital.</li>
          </ol>

          <h5 className="text-primary mt-1 ps-3 mb-3">
            What is "Company Setup"
          </h5>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">
            Turning Your Business Concept into Reality
          </h5>
          <p className="mt-1 ps-3 mb-3">
            Absolutely, transforming your fantastic business concept into a
            successful reality requires meticulous planning and strategic
            thinking. Let's delve deeper into each key aspect to ensure your
            business starts on the right foot:
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">
            1. Company Identification:
          </h5>
          <p className="mt-1 ps-3 mb-3">
            Start by defining your company’s identity. This includes crafting a
            clear mission statement that outlines your business's purpose and
            values. Your vision statement should articulate where you see your
            company in the future. Establishing core values will guide your
            company culture and decision-making processes.
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">2. Revenue Models:</h5>
          <p className="mt-1 ps-3 mb-3">
            Choosing the right revenue model is crucial for sustainable growth.
            Consider various options such as direct sales of products or
            services, subscription-based models, freemium offerings, advertising
            revenue, or licensing agreements. Each model has its pros and cons,
            so align your choice with your target market and business goals.
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">
            3. Competitive Analysis:
          </h5>
          <p className="mt-1 ps-3 mb-3">
            Conducting a thorough competitive analysis will help you understand
            your market positioning and identify opportunities for
            differentiation. Analyze direct competitors to understand their
            strengths and weaknesses. Explore indirect competitors and industry
            trends to anticipate market shifts. This analysis will inform your
            unique selling propositions (USPs) and strategic positioning.
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">
            4. Operational Strategies:
          </h5>
          <p className="mt-1 ps-3 mb-3">
            Developing effective operational strategies ensures that your
            business runs smoothly on a day-to-day basis. Consider your supply
            chain management, distribution channels, technological
            infrastructure (such as CRM and ERP systems), and human resources
            needs. Addressing legal and regulatory requirements from the outset
            will help you avoid potential pitfalls.
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">
            5. Financial Assumptions:
          </h5>
          <p className="mt-1 ps-3 mb-3">
            Financial planning is essential for viability and growth. Estimate
            your startup costs, ongoing expenses, and revenue projections based
            on your chosen revenue model. Develop a comprehensive budget and
            financial plan that includes cash flow forecasts, profit and loss
            projections, and break-even analysis. This will provide a roadmap
            for financial management and decision-making.
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">6. Risk Assessment:</h5>
          <p className="mt-1 ps-3 mb-3">
            Every business venture carries inherent risks. Identify potential
            risks such as market fluctuations, financial constraints,
            operational challenges, and regulatory changes. Implement risk
            mitigation strategies and contingency plans to minimize these risks
            and maintain business continuity.
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">
            7. Marketing and Customer Acquisition:
          </h5>
          <p className="mt-1 ps-3 mb-3">
            A solid marketing strategy is essential for attracting and retaining
            customers. Define your target market and develop a compelling value
            proposition. Outline your marketing channels, messaging strategies,
            and customer acquisition tactics. Incorporate feedback loops to
            continually refine your marketing efforts based on customer
            insights.
          </p>
          <h5 className="text-primary mt-1 ps-3 mb-3">
            8. Scaling and Growth Plans:
          </h5>
          <p className="mt-1 ps-3 mb-3">
            Plan for scalability from the outset. Outline milestones for growth
            and expansion, including hiring plans, new market penetration
            strategies, and product/service diversification. Continuously
            monitor performance metrics and adjust your strategies to capitalize
            on emerging opportunities.
          </p>
          <p className="mt-1 ps-3 mb-3">
            By meticulously addressing these aspects, you will lay a strong
            foundation for your business concept and increase your chances of
            long-term success. Remember that entrepreneurship is a journey that
            requires agility, resilience, and a commitment to continuous
            improvement. With careful planning and execution, your fantastic
            business concept can thrive in the competitive marketplace.
          </p>
          <p className="mt-1 ps-3 mb-3">
            Setting up a company in Australia involves making a decision about
            the type of company you want to set up through to registering the
            company with ASIC and maintaining a company register.
          </p>
          <div className="border-dashed border-bottom my-3" />
          <p className="mt-1 ps-3 mb-3">
            Although this is the opening stage of your business plan, the
            business name, tagline, and logo all add together to help the
            investor gain a good first impression and understanding of your
            business and they should lead on and link into the remaining
            sections of the business plan.
          </p>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Your Business Name</h5>
          <div className="border-dashed border-bottom my-3" />
          <p className="mt-1 ps-3 mb-3">
            The business name is the first thing an investor will see, and
            reinforces and communicates the key components of your business, it
            gives the investor an impression of your business, the more it does
            this, the easier it will be for the business plan that follows to
            explain what the business stands for and what it does.
          </p>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Your Tagline Name</h5>
          <div className="border-dashed border-bottom my-3" />
          <p className="mt-1 ps-3 mb-3">
            The tagline is a short, easily remembered phrase so that people will
            remember your business. Think Auziplan, “Planning is the key to your
            success”, BMW “The ultimate driving machine” or McDonald's “I’m
            lovin it”. Again, the tagline encapsulates everything about the
            business, it communicates to the investor what kind of business you
            are, what values the business has, what the product is, how the
            product provides a solution to a customer's problem, and what
            differentiates the business from the competition. This all hints and
            helps to reinforce what's to come in the rest of the business plan.
          </p>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Your Logo</h5>
          <div className="border-dashed border-bottom my-3" />
          <p className="mt-1 ps-3 mb-3">
            Finally, the logo is a visual representation of what the business
            stands for. The logo helps investors develop a first and lasting
            impression of the business, to understand what makes your product
            different from the competition, your competitive advantage, and who
            your target audience is.
          </p>
          <div className="border-dashed border-bottom my-3" />
          <p className="mt-1 ps-3 mb-3">
            First, seek financial or legal advice to determine if setting up a
            company is the best means to conduct your business. Before you set
            up a company, you should know the name you want to give to the
            company, how many and who will be the director(s), shareholders and
            officeholders, how many shares each shareholder will hold, etc.
          </p>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">
            How do I add a Company?
          </h5>
          <div className="border-dashed border-bottom my-3" />
          <ol className="pt-1">
            <li>
              Click the plus button
              <IconButton
                variant="outline-primary"
                size="sm"
                icon="fa-solid fa-plus"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
                en="true"
              ></IconButton>
            </li>
            <li>
              Upload your company logo that will be printed on the header of
              your financial reports.
            </li>
            <li>Enter the date.</li>
            <li>Enter your company name.</li>
            <li>Enter your ABN number if you have one. (Optional).</li>
            <li>Enter your company structure.</li>
            <li>Enter your currency.</li>
            <li>Enter your business location.</li>
            <li>Select your industry (Products by default).</li>
            <li>Enter your address.</li>
            <li>Enter your email.</li>
            <li>Select your telephone.</li>
            <li>Write a tagline.</li>
            <li>
              Click{' '}
              <IconButton className="ml-3" variant="outline-primary" size="sm">
                <i className="fa fa-save fa-1x" aria-hidden="true"></i>
                Next
              </IconButton>{' '}
              to go to the next stage.
            </li>
          </ol>
        </div>

        <div className="border-dashed border-bottom my-3" />
      </Card.Body>

      <Card.Footer>
        <Row className="g-0 container mx-auto p-3 mb-0">
          <div className="float-end">
            <div className="text-end">
              <Link to="/process" className="text-decoration-none">
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1 mx-1"
                ></IconButton>
              </Link>
            </div>
          </div>
        </Row>
      </Card.Footer>
    </Card>
  );
});

export default CompanySetup;
