import React, { useState, useRef, useContext, useEffect } from 'react';

import { Col, Form, Row, FloatingLabel, Popover } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//import logo from 'assets/img/favicons/apple-icon-512x512.png';

import logo from 'assets/img/illustrations/logo.png';

import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { CompanyWizardContext } from 'context/Context';
import FalconDropzone from 'components/common/FalconDropzone';
import Flex from 'components/common/Flex';
import SaleInputGraph from '../components/SaleInputGraph';

const ProductForm = () => {
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState('');
  const [totalYearlyRevenue, setTotalYearlyRevenue] = useState('');
  const [weeklyProjection, setWeeklyProjection] = useState('');
  const [totalCostOfGoods, setTotalCostOfGoods] = useState('');
  const [grossProfit, setGrossProfit] = useState('');
  const [startupBudget, setStartupBudget] = useState('');
  const [totalYearlyCostProjection, setTotalYearlyCostProjection] =
    useState('');
  const [yearlyGrossProfitProjection, setYearlyGrossProfitProjection] =
    useState('');

  const [monthlyProductsData, setMonthlyProductsData] = useState(
    Array.from({ length: 12 }, (_, i) => ({ x: i + 1, y: 0 }))
  );

  const [yearlyData, setYearlyData] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      productDescription: '',
      costPricePerUnit: '',
      retailPricePerUnit: '',
      monthlyUnitsSold: '',
      monthsOfInventory: ''
    }
  });

  const [showGraph, setShowGraph] = useState(false);

  const handleGraphSave = (average, monthlyProductsData, yearlyData) => {
    setValue('monthlyUnitsSold', average);
    setMonthlyProductsData(monthlyProductsData);
    setYearlyData(yearlyData);
    setShowGraph(false);
  };

  useEffect(() => {
    if (edit === 'products' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      console.log(editDetails);
      setValue('productDescription', editDetails.productDescription);
      setValue('costPricePerUnit', editDetails.costPricePerUnit);
      setValue('retailPricePerUnit', editDetails.retailPricePerUnit);
      setValue('monthlyUnitsSold', editDetails.monthlyUnitsSold);
      setValue('monthsOfInventory', editDetails.monthsOfInventory);
      setMonthlyProductsData(
        editDetails.monthlyProductsData ||
          Array.from({ length: 12 }, (_, i) => ({ x: i + 1, y: 0 }))
      );

      setYearlyData(editDetails.yearlyProjectionData);
      setAvatar(editDetails.avatar);

      calculateProjections(
        editDetails.costPricePerUnit,
        editDetails.retailPricePerUnit,
        editDetails.monthlyUnitsSold,
        editDetails.monthsOfInventory
      );
    }
  }, [edit]);

  useEffect(() => {
    calculateProjections(
      watch().costPricePerUnit,
      watch().retailPricePerUnit,
      watch().monthlyUnitsSold,
      watch().monthsOfInventory
    );
  }, [
    watch().costPricePerUnit,
    watch().retailPricePerUnit,
    watch().monthlyUnitsSold,
    watch().monthsOfInventory
  ]);

  const { company } = useContext(CompanyWizardContext);
  const [avatar, setAvatar] = useState(null);

  const calculateProjections = (
    costPricePerUnit,
    retailPricePerUnit,
    monthlyUnitsSold,
    months
  ) => {
    const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol

    const parsedCostPricePerUnit = parseFloat(
      costPricePerUnit?.replace(/[^\d.-]/g, '') || '0'
    );
    const parsedRetailPricePerUnit = parseFloat(
      retailPricePerUnit?.replace(/[^\d.-]/g, '') || '0'
    );
    const parsedMonthlyUnitsSold = parseInt(monthlyUnitsSold) || 0;
    const parsedMonths = parseInt(months) || 0;

    const totalRevenueValue = parsedRetailPricePerUnit * parsedMonthlyUnitsSold;
    setTotalRevenue(
      totalRevenueValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const yearly = totalRevenueValue * 12;
    setTotalYearlyRevenue(
      yearly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const totalYearlyCostProjectionValue =
      parsedCostPricePerUnit * parsedMonthlyUnitsSold * 12;
    setTotalYearlyCostProjection(
      totalYearlyCostProjectionValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const yearlyGrossProfitProjectionValue =
      yearly - totalYearlyCostProjectionValue;
    setYearlyGrossProfitProjection(
      yearlyGrossProfitProjectionValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const weekly = yearly / 52;
    setWeeklyProjection(
      weekly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const costOfGoods = parsedCostPricePerUnit * parsedMonthlyUnitsSold;
    setTotalCostOfGoods(
      costOfGoods.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const grossProfitValue = totalRevenueValue - costOfGoods;
    setGrossProfit(
      grossProfitValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const startupBudgetValue = costOfGoods * parsedMonths;
    setStartupBudget(
      startupBudgetValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );
  };

  const onSubmitData = data => {
    data.totalYearlyRevenue = totalYearlyRevenue;
    data.totalYearlyCostProjection = totalYearlyCostProjection;
    data.yearlyGrossProfitProjection = yearlyGrossProfitProjection;
    data.startupBudget = startupBudget;
    data.weeklyProjection = weeklyProjection;
    data.companyId = companyDetails.id;
    data.monthlyProductsData = monthlyProductsData;
    data.yearlyProjectionData = yearlyData;
    // data.avatar = data.avatar

    const payload =
      isEditing && docId
        ? { id: docId, data }
        : { companyId: companyDetails.id, ...data };
    api
      .post(
        isEditing ? '/products-revenue/update' : 'products-revenue/create',
        payload
      )
      .then(res => {
        reset();
        setEdit('');
        setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Products"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document
                  .querySelector('input[name="productDescription"]')
                  ?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol
  const generatePDF = () => {
    const pageTitle = 'Financial Projections'; // Adjusted page title
    const currentDate = new Date().toLocaleDateString(); // Get current date

    const input = document.getElementById('pdf-table');
    html2canvas(input, {
      backgroundColor: 'rgba(205, 209, 228, 1)' // Light blue color (RGB: 205, 209, 228)
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');

      // Set PDF to landscape
      const pdf = new jsPDF('l', 'px', 'a4'); // 'l' for landscape orientation

      // Add light blue background to the entire page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.setFillColor(205, 209, 228); // Light blue color (RGB: 205, 209, 228)
      pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

      // Add header
      const headerImgWidth = 20;
      const headerImgHeight = 20;
      pdf.addImage(logo, 'PNG', 10, 10, headerImgWidth, headerImgHeight);

      // Calculate title position to center it in the header
      const titleWidth =
        pdf.getStringUnitWidth(pageTitle) * pdf.internal.getFontSize();
      const titleX = (pdfWidth - titleWidth) / 2;
      const titleY = 40; // Adjust this value to position the title as desired

      // Add centered page title in the header with blue color
      pdf.setFontSize(10); // Set font size to 10
      pdf.setTextColor(0, 0, 0); // Blue color
      pdf.text(pageTitle, titleX, titleY);

      // Reset text color to black for other content
      pdf.setTextColor(0, 0, 0);

      // Add blue line under header
      const headerBottomY = titleY + 10;
      pdf.setLineWidth(0.1);
      pdf.setDrawColor(0, 0, 0); // Blue color
      pdf.line(10, headerBottomY, pdfWidth - 10, headerBottomY);

      // Calculate content position and size
      const contentStartY = headerBottomY + 10;
      const contentHeight = pdfHeight - contentStartY - 50; // Adjust as necessary

      // Add table content or overlay
      const imageAspectRatio = canvas.width / canvas.height;
      const imgWidth = pdfWidth - 20;
      const imgHeight = imgWidth / imageAspectRatio;

      pdf.addImage(imgData, 'PNG', 10, contentStartY, imgWidth, imgHeight);

      // Add blue line above footer
      const footerTopY = pdf.internal.pageSize.height - 30; // Adjust position based on footer height
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0); // Blue color
      pdf.line(10, footerTopY, pdfWidth - 10, footerTopY);

      // Add footer with page number and current date
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10); // Set font size to 10
        pdf.text(`Page ${i} of ${pageCount}`, pdfWidth - 50, pdfHeight - 10, {
          align: 'right'
        });
        pdf.text(currentDate, 10, pdfHeight - 10, {
          align: 'left'
        });
      }

      // Save PDF
      pdf.save('table.pdf');
    });
  };
  return (
    <div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Form onSubmit={handleSubmit(onSubmitData, onError)}>
          <div className="pt-4">
            <h2
              style={{
                fontSize: '24px',
                fontWeight: 'bold'
              }}
            >
              Product Evaluation
            </h2>
            <p>How many products wil you sell each year?</p>
          </div>

          <Row className="align-items-start justify-content-between pt-3">
            <Col md={4}>
              <FalconDropzone
                files={avatar}
                onChange={files => {
                  setAvatar(files[0].base64);
                  setValue('avatar', files[0].base64);
                }}
                multiple={false}
                accept="image/*"
                placeholder={
                  <Row>
                    {avatar && (
                      <img
                        src={avatar}
                        style={{
                          margin: '0px auto',
                          width: '400px',
                          paddingBottom: '20px'
                        }}
                      />
                    )}
                    <Flex justifyContent="center">
                      <img
                        alt="logo"
                        width={25}
                        src={cloudUpload}
                        className="me-2"
                      />
                    </Flex>
                    <h className=" mb-0 w-75 mx-auto text-400 text-muted pt-3">
                      Optional
                    </h>
                    {!avatar && (
                      <p className="mb-0 w-75 mx-auto text-400">
                        Upload a 300x300 jpg image with a maximum size of 400KB,
                        or use default no image
                      </p>
                    )}
                  </Row>
                }
              />
            </Col>

            <Col lg={8}>
              <Row className="align-items-start justify-content-between">
                <Col lg={6}>
                  <Form.Group controlId="productDescription">
                    <FloatingLabel label="Describe your product?">
                      <Form.Control
                        type="text"
                        placeholder="Describe your product?"
                        name="productDescription"
                        {...register('productDescription')}
                      />
                      {errors.productDescription && (
                        <Form.Text className="text-danger">
                          Product name is required
                        </Form.Text>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="totalYearlyRevenue">
                    <FloatingLabel label="Total Yearly Revenue">
                      <Form.Control
                        type="text"
                        placeholder="Total Yearly Revenue"
                        name="totalYearlyRevenue"
                        value={totalYearlyRevenue}
                        onChange={e => settotalYearlyRevenue(e.target.value)}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}>
                  <Form.Group controlId="monthlyUnitsSold">
                    <FloatingLabel label="How many products will sell each month?">
                      <Form.Control
                        type="number"
                        placeholder="How many products will sell each period?"
                        name="monthlyUnitsSold"
                        {...register('monthlyUnitsSold', {
                          required: 'Number Of Products is required.'
                        })}
                        isInvalid={errors['monthlyUnitsSold']}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors['monthlyUnitsSold']
                        }
                      />
                      <Form.Control.Feedback type="monthlyUnitsSold">
                        {errors['monthlyUnitsSold']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="totalYearlyCostProjection">
                    <FloatingLabel label="Total Yearly Cost Projection">
                      <Form.Control
                        type="text"
                        placeholder="Total Yearly Cost Projection"
                        name="totalYearlyRevenue"
                        value={totalYearlyCostProjection}
                        onChange={e =>
                          settotalYearlyCostProjection(e.target.value)
                        }
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="retailPricePerUnit"
                    label="What is the average retail price for each product?"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('retailPricePerUnit', {
                        required: 'This field is required'
                      })
                    }}
                    {...register('retailPricePerUnit', { required: true })}
                    onChange={e => {
                      const value = e.target.value.replace(currencySymbol, '');
                      setValue('retailPricePerUnit', currencySymbol + value);
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="yearlyGrossProfitProjection">
                    <FloatingLabel label="Yearly Gross Profit Projection">
                      <Form.Control
                        type="text"
                        placeholder="Yearly Gross Profit Projection"
                        name="yearlyGrossProfitProjection"
                        value={yearlyGrossProfitProjection}
                        onChange={e =>
                          setyearlyGrossProfitProjection(e.target.value)
                        }
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="costPricePerUnit"
                    label="What is the average cost price for each product?"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('costPricePerUnit', {
                        required: 'This field is required'
                      })
                    }}
                    {...register('costPricePerUnit', { required: true })}
                    onChange={e => {
                      const value = e.target.value.replace(currencySymbol, '');
                      setValue('costPricePerUnit', currencySymbol + value);
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="startupBudget">
                    <FloatingLabel label="Start Up Budget">
                      <Form.Control
                        type="text"
                        placeholder="Start Up Budget"
                        name="startupBudget"
                        value={startupBudget}
                        onChange={e => setStartupBudget(e.target.value)}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}>
                  <Form.Group controlId="monthsOfInventory">
                    <FloatingLabel label="How many months of inventory will you start with?">
                      <Form.Control
                        type="number"
                        placeholder="Number Of Months?"
                        name="monthsOfInventory"
                        {...register('monthsOfInventory', {
                          required: 'Number of months is required.'
                        })}
                        isInvalid={errors['monthsOfInventory']}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors['monthsOfInventory']
                        }
                      />
                      <Form.Control.Feedback type="monthsOfInventory">
                        {errors['monthsOfInventory']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="weeklyProjection">
                    <FloatingLabel label="Weekly Projection">
                      <Form.Control
                        type="text"
                        placeholder="Weekly Projection"
                        name="weeklyProjection"
                        value={weeklyProjection}
                        onChange={e => setWeeklyProjection(e.target.value)}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col lg={4}></Col>
          </Row>

          <div className="border-dashed border-bottom my-3" />

          <Row>
            <Col lg={6}>
              <div className="text-start mb-3 p-3"></div>
            </Col>
            <Col lg={6}>
              <div className="text-end p-3">
                {/*
                <IconButton
                  onClick={generatePDF}
                  className="float-end mb-1 mx-1 "
                  variant="outline-success"
                  size="sm"
                >
                  PDF
                </IconButton>
*/}
                <IconButton
                  className="float-end mb-1 mx-1"
                  variant="outline-success"
                  size="sm"
                  type="submit"
                >
                  {isEditing ? 'Update' : 'Submit'}
                </IconButton>
                <IconButton
                  className="float-end mb-1 mx-1"
                  variant="outline-warning"
                  size="sm"
                  onClick={() => setShowGraph(true)}
                >
                  Input Monthly Sales
                </IconButton>
              </div>
            </Col>
          </Row>
        </Form>
        <SaleInputGraph
          unitsData={monthlyProductsData}
          show={showGraph}
          onClose={() => setShowGraph(false)}
          onSave={handleGraphSave}
        />
      </motion.div>
    </div>
  );
};

export default ProductForm;
