import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RevenueWizardContext } from 'context/Context';

const RevenueWizardProvider = ({ children }) => {
  const [revenue, setRevenue] = useState({});
  const [step, setStep] = useState(1);
  const [edit, setEdit] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [isFromLoan, setIsFromLoan] = useState({});

  const value = {
    revenue,
    setRevenue,
    step,
    setStep,
    edit,
    setEdit,
    editDetails,
    setEditDetails,
    isFromLoan,
    setIsFromLoan
  };
  return (
    <RevenueWizardContext.Provider value={value}>
      {children}
    </RevenueWizardContext.Provider>
  );
};

RevenueWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default RevenueWizardProvider;
