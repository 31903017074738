import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  SelectedCompanyContext,
  StartupFormModalContext,
  StartupWizardContext
} from 'context/Context';
import { currencies } from 'utils';
import { parseCurrency } from 'utils';

function Item({ details, landAndBuildings, setLandAndBuildings, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(StartupWizardContext);
  const { setShowFormModal } = useContext(StartupFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleDelete = async () => {
    try {
      await api.delete(`/land-and-building/delete/${details.id}`);
      toast.success('Land and building deleted');
      console.log(landAndBuildings);
      const updatedLandAndBuildings = landAndBuildings.filter(
        landAndBuilding => landAndBuilding.id !== details.id
      );
      setLandAndBuildings(updatedLandAndBuildings);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting Land and Building');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('LandAndBuilding');
    setEditDetails(details);
    setStep(4);
    setShowFormModal(true);
  };

  return (
    <>
      {console.log(details)}
      <tr key={details.id}>
        <td>{details.propertyDescription}</td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.purchasePrice).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.usefulLife).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.gstVat).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.totalDepreciation).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.depreciationYearly).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.depreciationMontly).toLocaleString()}
        </td>
        <td>{details.depreciationPercentage}</td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.landValue).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.fixturesFittings).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.fixturesFittingsGST).toLocaleString()}
        </td>
        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.propertyDescription}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Land and Building?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  landAndBuildings: PropTypes.array,
  setLandAndBuildings: PropTypes.func,
  close: PropTypes.func
};

export default Item;
