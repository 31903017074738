import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  StartupFormModalContext,
  StartupWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { currencies } from 'utils';
import { parseCurrency } from 'utils';

function Item({ details, plantAndEquipments, setPlantAndEquipments, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(StartupWizardContext);
  const { setShowFormModal } = useContext(StartupFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handlePlantAndEquipmentDelete = async () => {
    try {
      await api.delete(`/plant-and-equipment/delete/${details.id}`);
      toast.success('Item deleted');
      const updatedPlantAndEquipments = plantAndEquipments.filter(
        plantAndEquipment => plantAndEquipment.id !== details.id
      );
      setPlantAndEquipments(updatedPlantAndEquipments);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting plant and equipment');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('PlantAndEquipment');
    setEditDetails(details);
    setStep(3);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{details.quantity}</td>
        <td>{details.itemDescription}</td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.purchasePrice).toLocaleString()}
        </td>
        <td>{details.usefulLife}</td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.gstVat).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.totalDepreciation).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.depreciationYearly).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.depreciationMonthly).toLocaleString()}
        </td>
        <td>{details.depreciationPercentage}</td>

        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.itemDescription}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Plant and Equipment?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handlePlantAndEquipmentDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  plantAndEquipments: PropTypes.array,
  setPlantAndEquipments: PropTypes.func,
  close: PropTypes.func
};

export default Item;
