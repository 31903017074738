import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageHeader from 'components/common/PageHeader';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGulp } from '@fortawesome/free-brands-svg-icons'; // module
import {
  faCheckSquare,
  faHome,
  faBars,
  faTachometerAlt,
  faFile
} from '@fortawesome/free-solid-svg-icons'; // module

library.add(
  faGulp,
  faCheckSquare,
  faHome,
  faBars,
  faTachometerAlt,
  faFile,
  faHome
);

import { Link } from 'react-router-dom';
import {
  Card,
  Modal,
  Nav,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  ProgressBar,
  Row,
  Button
} from 'react-bootstrap';

import AppContext, { AuthUserContext } from 'context/Context';
import api from 'utils/api';
import IconButton from 'components/common/IconButton';

//import { faqDoc } from 'data/faqs';
//import Login from 'components/authentication/simple/Login';
//import Registration from 'components/authentication/simple/Registration';

const LandingRightSideNavItem = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  const { user, isAuthenticated, isLoading } = useContext(AuthUserContext);
  const handleBillClick = e => {
    api
      .post('/pid/p1')
      .then(res => {
        window.location.href = res.data.url;
        e.target.disabled = true;
      })
      .catch(err => {
        console.log(err);
        e.target.disabled = false;
      });
  };

  const handleClick = () => {
    setConfig('showSettingPanel', true);
  };

  return (
    <Nav navbar className="ms-auto">
      {/* <Nav.Item as={'li'}>
        <Nav.Link
          className="pe-2 theme-switch-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip
                style={{ position: 'fixed' }}
                id="ThemeColor"
                className="d-none d-lg-block"
              >
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <span>
              <div className="theme-switch-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="me-2"
                />
              </div>
              <p className="d-lg-none mb-0">
                {isDark ? 'Switch to light theme ' : 'Switch to dark theme'}
              </p>
            </span>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <Dropdown>
        <Dropdown.Toggle as={Link} to="#!" className="nav-link fw-semi-bold">
          Login
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
          <Card className="navbar-card-login shadow-none">
            <Card.Body className="fs--1 fw-normal p-4">
              <Login />
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown> */}
      {user && isAuthenticated && !isLoading ? (
        <>
          {/*
          <Nav.Item>
            <Nav.Link as={Link} to="/help-center">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                size="sm"
              ></IconButton>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={handleBillClick}>
              <Button variant="outline-primary">Pay Bill</Button>
            </Nav.Link>
          </Nav.Item>
        */}

          <Nav.Item as={'li'}>
            <Nav.Link>
              <OverlayTrigger
                key="left"
                placement={isRTL ? 'bottom' : 'left'}
                overlay={
                  <Tooltip
                    style={{ position: 'fixed' }}
                    id="ThemeColor"
                    className="d-none d-lg-block"
                  >
                    {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
                  </Tooltip>
                }
              >
                <IconButton
                  className="me-0 mb-1"
                  icon={isDark ? 'sun' : 'moon'}
                  onClick={() => setConfig('isDark', !isDark)}
                  variant="outline-primary"
                  size="lg"
                  transform="shrink-3"
                ></IconButton>
              </OverlayTrigger>
            </Nav.Link>

            <p className="d-lg-none mb-0">
              {isDark ? 'Ligh/Dark' : 'Light/Dark'}
            </p>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/start">
              <IconButton
                className="me-0 mb-1"
                variant="outline-primary"
                icon="fa fa-home"
                size="lg d"
                transform="shrink-3"
              ></IconButton>
            </Nav.Link>
            <p className="d-lg-none mb-0">{isDark ? 'Home' : 'Home'}</p>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to="/Index">
              <IconButton
                className="me-0 mb-1"
                variant="outline-primary"
                icon="fa fa-tachometer-alt"
                size="lg"
                transform="shrink-3"
              ></IconButton>
            </Nav.Link>
            <p className="d-lg-none mb-0">
              {isDark ? 'Dashboard' : 'Dashboard'}
            </p>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to="/plans">
              <IconButton
                className="me-0 mb-1"
                variant="outline-danger"
                icon="fa fa-file"
                size="lg"
                transform="shrink-3"
              ></IconButton>
            </Nav.Link>
            <p className="d-lg-none mb-0">{isDark ? 'Plans' : 'Plans'}</p>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/logout">
              <IconButton
                className="me-0 mb-1"
                variant="outline-primary"
                icon="fa fa-sign-out-alt"
                size="lg"
                transform="shrink-3"
              ></IconButton>
            </Nav.Link>
            <p className="d-lg-none mb-0">{isDark ? 'logout' : 'logout'}</p>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={handleClick}>
              <IconButton
                className="me-0 mb-1"
                variant="outline-success"
                icon="fa fa-bars"
                size="lg"
                transform="shrink-3"
              ></IconButton>
            </Nav.Link>
            <p className="d-lg-none mb-0">{isDark ? 'Menu' : 'Menu'}</p>
          </Nav.Item>
        </>
      ) : (
        <>
          <Nav.Item>
            <Nav.Link as={Link} to="/login">
              <IconButton
                variant="outline-success"
                className="px-4 my-0 me-0"
                icon="fa-solid fa fa-wrench"
                size="sm"
              >
                Login
              </IconButton>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/register"
              // onClick={() => setShowRegistrationModal(!showRegistrationModal)}
            >
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                size="sm"
              >
                Register
              </IconButton>
            </Nav.Link>

            {/* <Modal
            show={showRegistrationModal}
            centered
            onHide={() => setShowRegistrationModal(false)}
          >
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                  <Registration />
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal> */}
          </Nav.Item>
        </>
      )}
    </Nav>
  );
};

export default LandingRightSideNavItem;
