import React, { forwardRef, useContext } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import * as echarts from 'echarts/core';
import { getColor, getPosition } from 'helpers/utils';
import AppContext, { SelectedCompanyContext } from 'context/Context';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const tooltipFormatter = (params, currency) => {
  let tooltip = `<div style="border-bottom: 1px solid ${getColor(
    'gray-300'
  )}; padding-bottom: 5px; margin-bottom: 5px;">`;
  tooltip += `${params[0].axisValueLabel}</div>`;
  params.forEach(param => {
    tooltip += `<div>${param.marker} ${
      param.seriesName
    }: ${currency} ${param.value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</div>`;
  });
  return tooltip;
};

const getOption = ({ data, isDark, currency }) => ({
  color: [
    '#1E90FF', // DodgerBlue
    '#4682B4', // SteelBlue
    '#6495ED', // CornflowerBlue
    '#00BFFF', // DeepSkyBlue
    '#87CEFA' // LightSkyBlue
  ],
  legend: {
    data: [
      'Total Revenue',
      'Total Cost',
      'Gross Profit',
      'Payroll',
      'Operating Expense'
    ],
    icon: 'circle',
    itemWidth: 10,
    itemHeight: 10,
    padding: [0, 0, 0, 0],
    textStyle: {
      color: isDark ? '#FFFFFF' : '#000000',
      fontWeight: '500',
      fontSize: '13px'
    },
    left: 0,
    itemGap: 16
  },
  xAxis: {
    type: 'category',
    data: data.map(item => `Year ${item.year}`),
    axisLine: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: getColor('gray-600')
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      color: getColor('gray-600'),
      formatter: value =>
        `${currency} ${value.toLocaleString(undefined, {
          maximumFractionDigits: 0
        })}`
    }
  },
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    axisPointer: {
      type: 'none'
    },
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    position(pos, params, dom, rect, size) {
      return getPosition(pos, params, dom, rect, size);
    },
    formatter: params => tooltipFormatter(params, currency)
  },
  series: [
    {
      name: 'Total Revenue',
      type: 'bar',
      data: data.map(item => item.totalRevenue),
      emphasis: {
        itemStyle: {
          color: '#1E90FF'
        }
      },
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFF',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
      }
    },
    {
      name: 'Total Cost',
      type: 'bar',
      data: data.map(item => item.totalCost),
      emphasis: {
        itemStyle: {
          color: '#4682B4'
        }
      },
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFF',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
      }
    },
    {
      name: 'Gross Profit',
      type: 'bar',
      data: data.map(item => item.grossProfit),
      emphasis: {
        itemStyle: {
          color: '#6495ED'
        }
      },
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFF',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
      }
    },
    {
      name: 'Payroll',
      type: 'bar',
      data: data.map(item => item.payroll),
      emphasis: {
        itemStyle: {
          color: '#00BFFF'
        }
      },
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFF',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
      }
    },
    {
      name: 'Operating Expense',
      type: 'bar',
      data: data.map(item => item.operatingExpense),
      emphasis: {
        itemStyle: {
          color: '#87CEFA'
        }
      },
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFF',
        formatter: ({ value }) =>
          `${currency} ${value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
      }
    }
  ],
  grid: {
    right: '0px',
    left: '80px',
    bottom: '6%',
    top: '15%'
  }
});

const YearlyChart = forwardRef(({ data }, ref) => {
  const { config } = useContext(AppContext);
  const { isDark } = config;

  const { companyDetails } = useContext(SelectedCompanyContext);
  const currency = companyDetails.currency;

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption({ data, isDark, currency })}
      ref={ref}
      style={{ height: '24.81rem' }}
    />
  );
});

YearlyChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      totalRevenue: PropTypes.number.isRequired,
      totalCost: PropTypes.number.isRequired,
      grossProfit: PropTypes.number.isRequired,
      payroll: PropTypes.number.isRequired,
      operatingExpense: PropTypes.number.isRequired
    })
  ).isRequired
};

export default YearlyChart;
