import Flex from 'components/common/Flex';
import React, { useContext, useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  FloatingLabel,
  ProgressBar,
  FormSelect,
  FormGroup,
  Collapse
} from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/6.svg';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { BuzPitchFormModalContext } from 'context/Context';
import BuzPitchWizard from 'components/custom/wizards/buzPitchWizard/BuzPitchWizard';
import PageHeader from 'components/common/PageHeader';

import { Typewriter } from 'react-simple-typewriter';
import CompanyItem from '../CompanyItem';
import CompanyWizard from 'components/custom/wizards/companyWizard/CompanyWizard';
import Section from 'components/common/Section';
import {
  AuthUserContext,
  CompaniesContext,
  CompanyFormModalContext,
  CompanyWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { toast } from 'react-toastify';

const BuzPitchSettings = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const { showFormModal, setShowFormModal } = useContext(
    BuzPitchFormModalContext
  );

  const [modalShow, setModalShow] = React.useState(false);

  const handleFormModelClose = () => {
    setShowFormModal(false);
  };

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const { companies } = useContext(CompaniesContext);
  const { setStep, setCompany } = useContext(CompanyWizardContext);
  const { user } = useContext(AuthUserContext);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );

  const handleAddNewCompany = () => {
    if (user.status && user.status === 'trialing' && companies.length >= 1) {
      toast.error(
        'Your are only allowed to work on one company with this trial version, upgrade to AuziPro for multi-company support and more features.'
      );
      return;
    }
    setShowFormModal(true);
  };
  const [lgShow, setLgShow] = useState(false);

  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      {/* First Row: Image */}
      <Row className="align-items-center justify-content-center pt-6">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
            <img
              src={editing}
              className="img-fluid"
              alt="Editing"
              style={{ maxWidth: '60%', height: 'auto' }}
            />
          </div>
        </Col>
      </Row>

      {/* Second Row: Pitch Message */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            <h2 className="text-primary pt-3">Pitch</h2>
            <p>
              A business pitch is a concise presentation designed to persuade
              potential investors, partners, or customers about the viability,
              benefits, and potential success of a business idea, product, or
              service. It should be engaging, clear, and compelling, conveying
              both the vision and the practicality of the business concept.
            </p>
          </div>
        </Col>
      </Row>

      {/* Third Row: Navigation Buttons */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/process" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-left"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              ></IconButton>
            </Link>

            <Link to="/help-center#thePitch" className="text-decoration-none">
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-info"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              ></IconButton>
            </Link>

            <IconButton
              onClick={() => setShowFormModal(true)}
              variant="outline-primary"
              size="lg"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>

            {companies.length > 0 && companyDetails && (
              <Link to="/ownerSettings" className="text-decoration-none mx-0">
                <IconButton
                  variant="outline-success"
                  size="lg"
                  icon="fa-solid fa-arrow-right"
                  className="mb-1 mx-1"
                  iconAlign="right"
                  transform="shrink-3"
                />
              </Link>
            )}
          </div>
        </Col>
      </Row>

      {/* Modals */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-label="contained-modal-title-vcenter"
        entered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Header closeButton>
          <Modal.Title>Operating Expense Budget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="startupCostType">
            <Form.Label>Startup Cost Type</Form.Label>
            <Form.Control as="select" aria-label="Select a cost type">
              <option></option>
              <option value="1">Let's start</option>
              <option value="2">The Pitch</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showFormModal}
        aria-label="contained-modal-title-vcenter"
        entered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Body>
          <Container>
            {/* Assuming BuzPitchWizard is a component for the form */}
            <BuzPitchWizard variant="pills" validation={false} />
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default BuzPitchSettings;
