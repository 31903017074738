import React, { forwardRef } from 'react';
import { Card, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';

const PayrollCosts = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="payrollCosts" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Payroll Costs</h5>
      </Card.Header>
      <Card.Body>
        {/* 
        <h6>Indemnity</h6>
        <p className="mb-0 ps-3">
          You shall defend, indemnify and hold us harmless against claims,
          actions, proceedings, losses, damages, expenses and costs (including
          without limitation court costs and reasonable legal fees) arising out
          of or in connection with your use of the Service (or us taking any
          action in relation to the Service at your direction), including any
          claim or action from a recipient of any Content sent by means of the
          Service.
        </p>
        <hr className="my-4" />
        <p className="fs-0 mb-0 fw-semi-bold">
          Our responsibility for loss or damage if you are a business:
        </p>
        <ol type="1">
          <li>arising under or in connection with these Terms;</li>
          <li>in respect of any use made by you of the Service; and</li>
          <li>
            in respect of any representation, statement or tortious act or
            omission (including negligence) arising under or in connection with
            these Terms.
          </li>
        </ol>
        <hr className="my-4" />
        <p className="fs-0 mb-0 fw-semi-bold">
          Except as expressly and specifically provided in these Terms:
        </p>
        <ol type="1">
          <li>
            you assume sole responsibility for results obtained from your use of
            the Service, and for conclusions drawn from such use. We shall have
            no liability for any damage caused by errors or omissions in any
            information, instructions or scripts provided to us by you in
            connection with the Service, or any actions taken by us at your
            direction;
          </li>
          <li>
            all warranties, representations, conditions and all other terms of
            any kind whatsoever implied by statute or common law are, to the
            fullest extent permitted by applicable law, excluded from these
            Terms; and
          </li>
          <li>the Service is provided to you on an “as is” basis.</li>
        </ol>
        <hr className="my-4" />
        <p className="fs-0 mb-0 fw-semi-bold">
          Nothing in these Terms excludes our liability:
        </p>
        <ol type="1">
          <li>for death or personal injury caused by our negligence; or</li>
          <li>for fraud or fraudulent misrepresentation.</li>
        </ol>
        <hr className="my-4" />
        <p className="fs-0 mb-0 fw-semi-bold">
          Subject to section 15.3 above: We shall not be liable whether in tort
          (including for negligence or breach of statutory duty), contract,
          misrepresentation, restitution or otherwise for any:
        </p>
        <ol type="1">
          <li>loss of profits,</li>
          <li>loss of business,</li>
          <li>depletion of goodwill and/or similar losses,</li>
          <li>loss or corruption of data or information,</li>
          <li>pure economic loss, or</li>
          <li>
            special, indirect or consequential loss, costs, damages, charges or
            expenses however arising under these Terms;
          </li>
        </ol>
        <hr className="my-4" />
        <p className="fs-0 mb-0 fw-semi-bold">
          Our responsibility for loss or damage if you are a consumer
        </p>
        <ol type="1">
          <li>
            We are responsible to you for foreseeable loss and damage caused by
            us. If we fail to comply with these terms, we are responsible for
            loss or damage you suffer that is a foreseeable result of our
            breaking this contract or our failing to use reasonable care and
            skill, but we are not responsible for any loss or damage that is not
            foreseeable. Loss or damage is foreseeable if either it is obvious
            that it will happen or if, at the time the contract was made, both
            we and you knew it might happen, for example, if you discussed it
            with us during the sales process.
          </li>
          <li>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so. This includes liability for death or
            personal injury caused by our negligence or the negligence of our
            employees, agents or subcontractors; for fraud or fraudulent
            misrepresentation; for breach of your legal consumer rights in
            relation to the Service.
          </li>
          <li>
            We are not liable for business losses. If you are a consumer we only
            supply our services to you for domestic and private use. If you use
            our service for any commercial, business or resale purpose our
            liability to you will be limited as set out in Clause 15.
          </li>
        </ol>
        <hr className="my-4" />
        <p className="fs-0 mb-0 fw-semi-bold">Force majeur</p>
        <p className="mb-0 ps-3">
          We won’t be held liable for any delays or failure in performance of
          any part of the Service, from any cause beyond our control. This
          includes, but is not limited to, changes to law or regulations,
          embargoes, fires, earthquakes, floods, strikes, power blackouts,
          unusually severe weather conditions, and acts of hackers or
          third-party internet service providers.
        </p>
        <hr className="my-4" />
        <p className="fs-0 mb-0 fw-semi-bold">Data processing term</p>
        <p className="mb-0 ps-3">
          To the extent that you are a business and we process personal data on
          your behalf in providing the Services, the{' '}
          <Link to="#!">Data processing terms</Link> shall apply and are
          incorporated into these Terms.
        </p>

*/}

        <h5 className="text-primary mt-1 ps-3 mb-3">
          What are "Payroll Costs"
        </h5>

        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Payroll costs refer to the total amount of money that a business
          spends on compensating its employees for their work during a specific
          period, typically on a monthly or bi-weekly basis. These costs
          encompass various components of employee compensation, including
          salaries, wages, bonuses, benefits, and payroll taxes. Payroll costs
          are a significant expense for most businesses and are essential for
          attracting and retaining talented employees.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">
          Key Components of Payroll Costs
        </h5>
        <div className="border-dashed border-bottom my-3" />
        <ol>
          <h5 className="text-primary mt-1 ps-3 mb-3">Salaries and Wages</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            This includes the base pay that employees receive for their work,
            whether they are paid an hourly wage or an annual salary...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">
            Bonuses and Incentives
          </h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Additional payments made to employees as rewards for achieving
            specific performance targets, meeting deadlines, or contributing to
            the success of the business...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Benifits</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Employer-sponsored benefits provided to employees as part of their
            compensation package, including health insurance, retirement plans,
            life insurance, and paid time off...
          </li>

          <h5 className="text-primary mt-1 ps-3 mb-3">Payroll Taxes</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Taxes that employers are required to withhold from employees' wages
            and remit to the appropriate tax authorities, including federal
            income tax, state income tax, Social Security tax, Medicare tax, and
            unemployment insurance taxes...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">
            Employer Contributions
          </h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Contributions made by employers toward employee benefits, such as
            matching contributions to retirement plans, employer-paid health
            insurance premiums, and other forms of compensation beyond salary or
            wages...
          </li>
        </ol>
        <p className="mt-1 ps-3 mb-3">
          Managing payroll costs effectively is crucial for businesses to
          maintain financial stability and ensure compliance with legal and
          regulatory requirements. Proper budgeting, forecasting, and monitoring
          of payroll expenses help businesses allocate resources efficiently,
          control costs, and retain talented employees. Additionally, accurate
          payroll processing and timely payment of wages and taxes are essential
          for maintaining employee satisfaction and avoiding potential legal or
          financial penalties for non-compliance.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="mt-1 ps-3 mb-3">How do I add payroll Costs?</h5>

        <div className="border-dashed border-bottom my-3" />
        <ol className="pt-1">
          <li>
            Click the plus button
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              en="true"
            ></IconButton>
          </li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li>
            Click{' '}
            <IconButton className="ml-3" variant="outline-primary" size="sm">
              <i className="fa fa-save fa-1x" aria-hidden="true"></i>
              Next
            </IconButton>{' '}
            to go to then next stage.
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />
      </Card.Body>
      <Card.Footer>
        <Row className="g-0 container mx-auto p-3 mb-0">
          <div className="float-end">
            <div className="text-end">
              <Link
                to="/OperatingExpensesSettings"
                className="text-decoration-none"
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1 mx-1"
                ></IconButton>
              </Link>
            </div>
          </div>
        </Row>
      </Card.Footer>
    </Card>
  );
});

export default PayrollCosts;
