import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Col, Form, Row, FloatingLabel } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

const AccountingForm = () => {
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);

  const [totalWeeklyRevenue, setTotalWeeklyRevenue] = useState('');
  const [totalYearlyRevenue, setTotalYearlyRevenue] = useState('');
  const [totalMonthlyRevenue, setTotalMonthlyRevenue] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      serviceType: 'auditing',
      ratePerHour: '',
      hoursPerWeek: '20',
      noOfStaff: '3',
      weeklyRevenue: '',
      yearlyRevenue: '',
      monthlyRevenue: ''
    }
  });

  useEffect(() => {
    if (edit === 'service' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      setValue('ratePerHour', editDetails.ratePerHour);
      setValue('serviceType', editDetails.serviceType);
      setValue('noOfStaff', editDetails.noOfStaff);
      setValue('hoursPerWeek', editDetails.hoursPerWeek);
    }
  }, [edit]);

  useEffect(() => {
    calculateOpeningBalance(watch().ratePerHour, watch().numberOfMonths);
  }, [
    watch().ratePerHour,
    watch().numberOfMonths,
    watch().hoursPerWeek,
    watch().noOfStaff
  ]);

  const calculateOpeningBalance = (amount, months) => {
    const parsedAmount = amount
      ? parseFloat(amount.replace(/[^0-9.]/g, ''))
      : 0;
    const parsednoOfStaff = parseInt(watch().noOfStaff);

    const totalWeeklyRevenue =
      parsedAmount * watch().hoursPerWeek * parsednoOfStaff;
    setTotalWeeklyRevenue(
      totalWeeklyRevenue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const totalYearlyRevenueAmount =
      parsedAmount * watch().hoursPerWeek * parsednoOfStaff * 52;
    setTotalYearlyRevenue(
      totalYearlyRevenueAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const totalMonthlyRevenueAmount = totalYearlyRevenueAmount / 12;
    setTotalMonthlyRevenue(
      isNaN(totalMonthlyRevenueAmount)
        ? ''
        : totalMonthlyRevenueAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: companyDetails.currency
          })
    );
  };

  const onSubmitData = data => {
    data.weeklyRevenue = totalWeeklyRevenue;
    data.monthlyRevenue = totalMonthlyRevenue;
    data.yearlyRevenue = totalYearlyRevenue;
    data.companyId = companyDetails.id;

    const payload =
      isEditing && docId
        ? { id: docId, data }
        : { companyId: companyDetails.id, ...data };

    api
      .post(
        isEditing ? '/services-revenue/update' : 'services-revenue/create',
        payload
      )
      .then(res => {
        reset();
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Services Revenues"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="revenueType"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Form onSubmit={handleSubmit(onSubmitData, onError)}>
        {/* Service Type and Total Weekly Revenue */}
        <Row className="align-items-start justify-content-between pt-3">
          <Col xs={12} md={6}>
            <Form.Group controlId="serviceType">
              <FloatingLabel label="Service Type">
                <Form.Select
                  aria-label="Select the type of service you will offer"
                  name="serviceType"
                  {...register('serviceType', {
                    required: 'Service Type is required.'
                  })}
                  isInvalid={errors['serviceType']}
                  isValid={
                    Object.keys(errors).length > 0 && !errors['serviceType']
                  }
                >
                  <option value="taxPreparation">Tax Preparation</option>
                  <option value="auditing">Auditing</option>
                  <option value="bookKeeping">Book Keeping</option>
                  <option value="financialConsulting">
                    Financial Consulting
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="serviceType">
                  {errors['serviceType']?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="totalWeeklyRevenue">
              <FloatingLabel label="Total Weekly Revenue">
                <Form.Control
                  type="text"
                  placeholder="Total Weekly Revenue"
                  name="totalWeeklyRevenue"
                  value={totalWeeklyRevenue}
                  onChange={e => setTotalWeeklyRevenue(e.target.value)}
                  disabled
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        {/* Number Of Employees and Total Monthly Revenue */}
        <Row className="align-items-start justify-content-between pt-3">
          <Col xs={12} md={6}>
            <Form.Group controlId="noOfStaff">
              <FloatingLabel label="How many staff will you employ?">
                <Form.Control
                  type="number"
                  placeholder="How many staff will you employ?"
                  name="noOfStaff"
                  {...register('noOfStaff', {
                    required: 'Number Of Employees is required.'
                  })}
                  isInvalid={errors['noOfStaff']}
                  isValid={
                    Object.keys(errors).length > 0 && !errors['noOfStaff']
                  }
                />
                <Form.Control.Feedback type="noOfStaff">
                  {errors['noOfStaff']?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="totalMonthlyRevenue">
              <FloatingLabel label="Total Monthly Revenue">
                <Form.Control
                  type="text"
                  placeholder="Total Monthly Revenue"
                  name="totalMonthlyRevenue"
                  value={totalMonthlyRevenue}
                  onChange={e => setTotalMonthlyRevenue(e.target.value)}
                  disabled
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        {/* Number Of Hours and Total Yearly Revenue */}
        <Row className="align-items-start justify-content-between pt-3">
          <Col xs={12} md={6}>
            <Form.Group controlId="hoursPerWeek">
              <FloatingLabel label="How many hours does your staff work each week?">
                <Form.Control
                  type="number"
                  placeholder="How many hours does your staff work each week?"
                  name="hoursPerWeek"
                  {...register('hoursPerWeek', {
                    required: 'Number Of Hours is required.'
                  })}
                  isInvalid={errors['hoursPerWeek']}
                  isValid={
                    Object.keys(errors).length > 0 && !errors['hoursPerWeek']
                  }
                />
                <Form.Control.Feedback type="hoursPerWeek">
                  {errors['hoursPerWeek']?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="totalYearlyRevenue">
              <FloatingLabel label="Total Yearly Revenue">
                <Form.Control
                  type="text"
                  placeholder="Total Yearly Revenue"
                  name="totalYearlyRevenue"
                  value={totalYearlyRevenue}
                  onChange={e => setTotalYearlyRevenue(e.target.value)}
                  disabled
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        {/* Rate Per Hour */}
        <Row className="align-items-start justify-content-between pt-3">
          <Col xs={12} md={6}>
            <Form.Group controlId="ratePerHour">
              <CurrencyWizardInput
                symbol={currencySymbol} // Use currency symbol
                name="ratePerHour"
                label="How much does each staff member charge per hour for their services?"
                onChange={e =>
                  calculateOpeningBalance(
                    e.target.value,
                    watch().numberOfMonths
                  )
                }
                errors={errors}
                formGroupProps={{ className: 'mb-0', as: Col }}
                formControlProps={{
                  ...register('ratePerHour', {
                    required: 'Product Cost is required.'
                  })
                }}
                setValue={setValue}
                watch={watch}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}></Col>
        </Row>

        {/* Save Button */}
        <div className="border-dashed border-bottom my-3" />
        <Row>
          <Col xs={12} md={6}></Col>
          <Col xs={12} md={6}>
            <div className="text-end">
              <IconButton
                className="float-end"
                variant="outline-success"
                size="sm"
                type="submit"
              >
                Save
              </IconButton>
            </div>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default AccountingForm;
