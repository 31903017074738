import React, { useContext, useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Nav,
  Ratio,
  ProgressBar,
  FloatingLabel
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/icons/spot-illustrations/1.svg';
import { motion } from 'framer-motion';

import IconButton from 'components/common/IconButton';
import StartupWizard from 'components/custom/wizards/startupWizard/StartupWizard';
import { StartupFormModalContext } from 'context/Context';
import PrepaidCostsList from 'components/custom/lists/stage3/PrepaidCostsList';
import MotorVehicleList from 'components/custom/lists/stage3/MotorVehicleList';
import PlantAndEquipmentList from 'components/custom/lists/stage3/PlantAndEquipmentList';
import LandAndBuildingList from 'components/custom/lists/stage3/LandAndBuidlingList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inDepthItems } from 'data/support-desk/reportsData';
import PropTypes from 'prop-types';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-3 mb-3">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const InDepthItem = ({ item, status }) => {
  const progressBarWidth =
    item.progressbarWidth[status] || item.progressbarWidth[3];
  return (
    <Row className="mt-2">
      <Col xs={3} sm={2} md={3} lg={2}>
        <p className="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">
          {item.name}
        </p>
      </Col>
      <Col xs={9} sm={10} md={9} lg={10} className="d-flex align-items-center">
        <ProgressBar
          now={progressBarWidth}
          className="w-100 animated-progress-bar"
          style={{ height: '6px' }}
        />
        <p className="mb-0 fs--1 ps-3 fw-semi-bold text-600">
          {progressBarWidth}
        </p>
      </Col>
    </Row>
  );
};

InDepthItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    progressbarWidth: PropTypes.array.isRequired
  }).isRequired,
  status: PropTypes.string.isRequired
};

const StartupCostsSettings = () => {
  const [modalShow, setModalShow] = useState(false);
  const { showFormModal, setShowFormModal } = useContext(
    StartupFormModalContext
  );
  const [activeTab, setActiveTab] = useState('prePaid');

  const tabKeys = ['prePaid', 'motor', 'plant', 'land'];

  const goToNextTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { scale: 0.8, opacity: 0 },
        visible: { scale: 1, opacity: 1 }
      }}
    >
      <Row className="align-items-center justify-content-center pt-6">
        <Col xs={12} md={6} className="text-center">
          <img
            src={editing}
            className="img-fluid"
            alt="Editing"
            style={{ maxWidth: '60%', height: 'auto' }}
          />
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <h2 className="text-primary pt-0">Startup Costs</h2>
          <p>
            Business startup costs are the expenses incurred when starting a new
            business. These costs typically include everything necessary to get
            the business up and running before it begins generating revenue.
            Common startup costs may encompass expenditures such as equipment
            purchases, legal fees, initial inventory.
          </p>
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/ownerSettings" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-left"
                className="mb-1 mx-1"
              />
            </Link>
            <Link
              to="/help-center#startupCosts"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-info"
                className="mb-1 mx-1"
              />
            </Link>
            <IconButton
              onClick={() => setShowFormModal(true)}
              variant="outline-primary"
              size="lg"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
            />
            <IconButton
              onClick={() => setModalShow(true)}
              variant="outline-warning"
              size="lg"
              icon="fa-solid fa-search"
              className="mb-1 mx-1"
            />
            <Link to="/revenueSettings" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-right"
                className="mb-1 mx-1"
              />
            </Link>
          </div>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Body>
          <Container>
            <PageHeader
              title="Business Startup Expenses"
              description="How much have you spent so far?"
            />

            <Tabs
              activeKey={activeTab}
              onSelect={setActiveTab}
              id="property-form-tabs"
              className="mb-3"
            >
              <Tab eventKey="prePaid" title="Pre-Paid Costs">
                <Row className="pt-3">
                  <PrepaidCostsList close={() => setModalShow(false)} />
                </Row>
              </Tab>
              <Tab eventKey="motor" title="Motor Vehicles">
                <Row className="pt-3">
                  <MotorVehicleList close={() => setModalShow(false)} />
                </Row>
              </Tab>
              <Tab eventKey="plant" title="Plant & Equipment">
                <Row className="pt-3">
                  <PlantAndEquipmentList close={() => setModalShow(false)} />
                </Row>
              </Tab>
              <Tab eventKey="land" title="Land & Buildings">
                <Row className="pt-3">
                  <LandAndBuildingList close={() => setModalShow(false)} />
                </Row>
              </Tab>
            </Tabs>

            <Row className="mb-3">
              <Col className="text-center mt-3">
                <Button
                  onClick={goToPreviousTab}
                  disabled={activeTab === 'purchasePrice'}
                  variant="outline-primary"
                  className="btn-sm me-2"
                >
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left" /> Previous
                </Button>
                <Button
                  onClick={goToNextTab}
                  disabled={activeTab === 'land'}
                  variant="outline-success"
                  className="btn-sm"
                >
                  Next <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-danger"
            onClick={() => setModalShow(false)}
            icon="fa-solid fa-times"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={showFormModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Body>
          <Container>
            <StartupWizard variant="pills" validation={false} />
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default StartupCostsSettings;
