// Import necessary modules and components
import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../components/WizardInput';
import { Col, Card, Overlay, Popover, Row } from 'react-bootstrap';
import { currencies } from 'utils';
import { isIterableArray } from 'helpers/utils';
import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { CompanyWizardContext } from 'context/Context';
import FalconDropzone from 'components/common/FalconDropzone';
import Avatar from 'components/common/Avatar';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PhoneWizardInput from 'components/custom/common/PhoneWizardInput';
import PageHeader from 'components/common/PageHeader';
import Flex from 'components/common/Flex';

// Import additional components and assets
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Background from 'components/common/Background';
import gallery2 from 'assets/img/illustrations/corner-4.png';
import OpeningBalancesForm from './OpeningBalancesForm';

// Define the CompanyForm component
const CompanyForm = ({ register, errors, setValue, getValues }) => {
  // State hooks for managing popover visibility and target
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  // Click handler for showing/hiding popover
  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  // Access company context using useContext hook
  const { company } = useContext(CompanyWizardContext);

  // State hook for managing avatar image
  const [avatar, setAvatar] = useState([]);

  useEffect(() => {
    setAvatar([...(company.avatar ? company.avatar : []), { src: avatarImg }]);
  }, [company]);

  // Render JSX
  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            {/* Page header */}
            <PageHeader
              title="Company Setup"
              description=""
              className="text-primary"
            />

            {/* Info icon button triggering popover */}
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            {/* Popover container */}
            <div className="border-none rounded mt-0">
              <div ref={ref}>
                {/* Overlay popover for help center */}
                <Overlay
                  show={show}
                  target={target}
                  placement="right"
                  container={ref.current}
                  containerPadding={20}
                >
                  <Popover id="popover-contained">
                    <Popover.Header as="h3">Help Center</Popover.Header>
                    <Popover.Body as="h6">
                      {/* Help content */}
                      <PageHeader
                        title="Company Details"
                        description=""
                        className="text-primary"
                      />
                      <div className="border-dashed border-bottom my-3" />
                      <div
                        className="border-none rounded mt-1"
                        style={{ maxHeight: '280px', overflowY: 'auto' }}
                      >
                        <div className="container mt-3">
                          {/* Company Setup Form Help Guide */}
                          <h6 className="text-primary">
                            Company Setup Form Help Guide
                          </h6>
                          <p>
                            Welcome to the Company Setup form! This guide will
                            assist you in filling out the necessary details
                            accurately.
                          </p>

                          {/* Avatar Upload Section */}
                          <h6 className="text-primary">Avatar Upload</h6>
                          <ul>
                            <li>
                              <strong>Avatar:</strong> You can upload a company
                              avatar by dragging and dropping an image file into
                              the designated area. This is optional and can be
                              left as the default image if preferred.
                            </li>
                          </ul>

                          {/* Company Details Section */}
                          <h6 className="text-primary">Company Details</h6>
                          <ul>
                            <li>
                              <strong>Company Name:</strong> Enter your
                              company's legal name. This field is required.
                            </li>
                            <li>
                              <strong>Registration Number:</strong> If
                              applicable, enter your company's registration
                              number.
                            </li>
                            <li>
                              <strong>Address:</strong> Provide the physical
                              address of your company. This field is required.
                            </li>
                            <li>
                              <strong>Structure:</strong> Select the legal
                              structure of your company from the dropdown list
                              (e.g., Limited Company, Partnership). This field
                              is required.
                            </li>
                            <li>
                              <strong>Currency:</strong> Select the currency
                              used by your company from the dropdown list. This
                              field is required.
                            </li>
                            <li>
                              <strong>Industry:</strong> Select the industry in
                              which your company operates from the dropdown list
                              (e.g., Products, Services). This field is
                              required.
                            </li>
                            <li>
                              <strong>Email and Phone:</strong> Enter the
                              primary email address and phone number for your
                              company. Both fields are required and must be
                              valid.
                            </li>
                            <li>
                              <strong>Introduction:</strong> Write a brief
                              tagline or introduction for your company's
                              products/services. This field is required.
                            </li>
                          </ul>

                          {/* Additional Information Section */}
                          <h6 className="text-primary">
                            Additional Information
                          </h6>
                          <ul>
                            <li>
                              If you need help with any specific field, click
                              the <i className="fas fa-info"></i>
                              icon next to the field for more information.
                            </li>
                          </ul>

                          {/* Submission Section */}
                          <h6 className="text-primary">Submission</h6>
                          <p>
                            Once all required fields are filled out correctly,
                            you can proceed with the submission of the form.
                          </p>

                          <p>
                            Thank you for providing these details! If you
                            encounter any issues or have questions, feel free to
                            reach out for further assistance.
                          </p>
                        </div>

                        <div className="border-dashed border-bottom my-3" />
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>

              <div className="border-dashed border-bottom my-3" />

              {/* Avatar and company setup form */}

              <Row className="mb-0 g-3 mt-1 justify-content-center">
                {/* Avatar display and dropzone */}
                <Col lg={4} className="d-flex flex-column align-items-center">
                  <Avatar
                    className="mb-3"
                    size="4xl"
                    src={
                      isIterableArray(avatar)
                        ? avatar[0]?.base64 || avatar[0]?.src
                        : ''
                    }
                  />
                  {/* Avatar dropzone for image upload */}
                  <FalconDropzone
                    files={avatar}
                    onChange={files => {
                      setAvatar(files);
                      setValue('avatar', files);
                    }}
                    multiple={false}
                    accept="image/*"
                    placeholder={
                      <Row>
                        <Flex justifyContent="center">
                          <img
                            src={cloudUpload}
                            alt="logo"
                            width={25}
                            className="me-2"
                          />
                        </Flex>
                        <h className=" mb-0 w-75 mx-auto text-400 text-muted pt-3">
                          Optional
                        </h>
                        <p className="mb-0 mt-5 w-75 mx-auto text-400">
                          Upload your company logo 300x300 jpg image with a
                          maximum size of 400KB, or use default no image
                        </p>
                      </Row>
                    }
                  />
                </Col>
                {/* Company details input fields */}

                <Col lg={8}>
                  {/* Input for company address */}
                  <Row className="g-3 mb-0 mt-1">
                    {/* Input for company name */}
                    <Col lg={12}>
                      <WizardInput
                        name="name"
                        label="Company Name *"
                        placeholder="Company Name *"
                        errors={errors}
                        formGroupProps={{ className: 'mb-0' }}
                        formControlProps={{
                          ...register('name', {
                            required: 'Company name is required'
                          })
                        }}
                      />
                    </Col>
                    {/* Input for company address */}

                    <Col lg={12}>
                      <WizardInput
                        type="address"
                        name="address"
                        label="Address*"
                        placeholder="Address*"
                        errors={errors}
                        formGroupProps={{ className: 'mb-0' }}
                        setValue={setValue}
                        formControlProps={{
                          ...register('address', {
                            required: 'Company address is required'
                          })
                        }}
                        getValues={getValues}
                      />
                    </Col>

                    {/* Input for registration number */}
                    <Col lg={6}>
                      <WizardInput
                        name="registrationNumber"
                        label="Registration Number"
                        placeholder="Registration Number"
                        errors={errors}
                        formGroupProps={{ className: 'mb-0' }}
                        formControlProps={{
                          ...register('registrationNumber', {
                            required: 'Company registration number is required'
                          })
                        }}
                      />
                    </Col>
                    {/* Input for company structure */}
                    <Col lg={6}>
                      <WizardInput
                        type="select"
                        errors={errors}
                        name="structure"
                        label="Structure"
                        placeholder="Structure"
                        formGroupProps={{ className: 'mb-0' }}
                        formControlProps={{
                          ...register('structure', {
                            required: 'Company structure is required'
                          })
                        }}
                        options={[
                          'Limited Company Pty Ltd',
                          'Partnership',
                          'Sole Trader',
                          'Trust'
                        ]}
                      />
                    </Col>

                    {/* Input for company currency */}
                    <Col lg={6}>
                      <WizardInput
                        type="select"
                        errors={errors}
                        name="currency"
                        label="Currency"
                        placeholder="Currency"
                        formGroupProps={{ className: 'mb-0' }}
                        formControlProps={{
                          ...register('currency', {
                            required: 'Currency is required'
                          })
                        }}
                        options={Object.keys(currencies)}
                        symbols={Object.values(currencies)}
                      />
                    </Col>

                    {/* Select input for company industry */}
                    <Col lg={6}>
                      <WizardInput
                        type="select"
                        errors={errors}
                        name="industry"
                        label="Industry"
                        placeholder="Industry"
                        formGroupProps={{ className: 'mb-0' }}
                        formControlProps={{
                          ...register('industry', {
                            required: 'Company industry is required'
                          })
                        }}
                        options={['Products', 'Services']}
                      />
                    </Col>
                    {/* Input for company email */}
                    <Col lg={6}>
                      <WizardInput
                        type="email"
                        errors={errors}
                        name="email"
                        label="Email*"
                        placeholder="Email*"
                        formGroupProps={{ className: 'mb-0' }}
                        formControlProps={{
                          ...register('email', {
                            required: 'Email field is required',
                            pattern: {
                              value:
                                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                              message: 'Email must be valid'
                            }
                          })
                        }}
                      />
                    </Col>

                    {/* Phone input component */}
                    <Col lg={6}>
                      <PhoneWizardInput
                        name="phone"
                        label="Phone*"
                        placeholder="Phone*"
                        errors={errors}
                        formGroupProps={{ className: 'mb-0' }}
                        formControlProps={{
                          ...register('phone', {
                            required: 'Phone is required'
                          })
                        }}
                        setValue={setValue}
                        getValues={getValues}
                      />
                    </Col>

                    <Col lg={12}>
                      <div>
                        <WizardInput
                          name="intro"
                          label="Write a tag line..."
                          placeholder="Write a tag line..."
                          errors={errors}
                          formGroupProps={{ className: 'mb-0' }}
                          formControlProps={{
                            ...register('intro', {
                              required: 'Company introduction is required'
                            })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

// PropTypes for type validation
CompanyForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func
};

export default CompanyForm;
