import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CompaniesContext,
  CompanyWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { parseCompany } from 'utils';
import api from 'utils/api';
import { toast } from 'react-toastify';

const CompaniesProvider = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );

  useEffect(() => {
    if (companyDetails && companyDetails.id) {
      localStorage.setItem('selectedCompanyId', companyDetails.id);
    }
  }, [companyDetails]);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await api.get('/companies/getAll');
        setCompanies(response.data);

        // Retrieve the saved company ID from localStorage
        const savedCompanyId = localStorage.getItem('selectedCompanyId');

        if (response.data.length > 0) {
          const foundCompany = response.data.find(
            company => company.id === savedCompanyId
          );

          if (foundCompany) {
            setCompanyDetails(foundCompany);
          } else {
            setCompanyDetails(response.data[0]);
            localStorage.setItem('selectedCompanyId', response.data[0].id);
          }
        }
      } catch (error) {
        console.error(error);
        if (error.response?.status === 404) return;
        toast.error('Error fetching companies');
      }
    };

    getCompanies();
  }, [setCompanyDetails]);

  const value = { companies, setCompanies };
  return (
    <CompaniesContext.Provider value={value}>
      {children}
    </CompaniesContext.Provider>
  );
};

CompaniesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CompaniesProvider;
