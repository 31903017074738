import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SelectedCompanyContext } from 'context/Context';
import { Card, Row, Col, Table, Form, FloatingLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PieChart from 'components/custom/lists/stage3/PrepaidCostsList/PieChart';

function PrepaidCostsList({ close }) {
  const [prepaidCosts, setPrepaidCosts] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [filterType, setFilterType] = useState('');

  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/prepaid-costs/getAll?companyId=${companyDetails.id}`)
        .then(response => setPrepaidCosts(response.data || []))
        .catch(error => toast.error(error.message));
    } else {
      toast.error(
        'No company selected, you must set up a new company to start.'
      );
    }
  }, [companyDetails]);

  useEffect(() => {
    setTotalCost(
      prepaidCosts.reduce(
        (acc, { costAmount }) =>
          acc + parseFloat(costAmount.replace(/[^0-9.-]+/g, '')),
        0
      )
    );
  }, [prepaidCosts]);

  const handleFilterChange = useCallback(e => {
    setFilterType(e.target.value);
  }, []);

  const costsToDisplay = filterType
    ? prepaidCosts.filter(({ costType }) => costType === filterType)
    : prepaidCosts;

  const renderPieChart = () => {
    if (prepaidCosts.length > 0) {
      console.log('Rendering PieChart with:', prepaidCosts); // Debug log
      return <PieChart prePaidCosts={prepaidCosts} />;
    }
    return <p>No prepaid costs data available.</p>;
  };

  return (
    <div className="container">
      <Form.Group controlId="CostType" className="p-1">
        <FloatingLabel label="Cost Type">
          <Form.Select
            id="costTypeFilter"
            className="form-select"
            value={filterType}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            {Array.from(
              new Set(prepaidCosts.map(({ costType }) => costType))
            ).map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      </Form.Group>

      <div>
        <Card>
          <Card.Body>
            <Row className="align-items-left justify-content-left pt-0">
              {/* Column for PieChart */}
              <Col xs={12} md={5} className="text-center">
                <div style={{ width: '100%', height: 'auto' }}>
                  {renderPieChart()}
                </div>
              </Col>

              {/* Column for owners table */}
              <Col xs={12} md={7} className="text-left">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '400px'
                  }}
                >
                  <div style={{ flex: 1, overflowY: 'auto' }}>
                    <Table responsive className="styled-table">
                      <thead>
                        <tr>
                          <th scope="col">Cost Type</th>
                          <th scope="col">Description</th>
                          <th scope="col">Amount</th>
                          <th className="text-end" scope="col">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {costsToDisplay.map(cost => (
                          <Item
                            key={cost.id}
                            details={cost}
                            prepaidCosts={prepaidCosts}
                            setPrepaidCosts={setPrepaidCosts}
                            close={close}
                          />
                        ))}
                      </tbody>
                      <tfoot
                        style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
                      >
                        <tr>
                          <td colSpan="4" className="text-left text-success">
                            <span className="fw-bold">
                              Total Cost:{' '}
                              {`${
                                currencies[companyDetails.currency]
                              } ${totalCost.toLocaleString('en-US')}`}
                            </span>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

PrepaidCostsList.propTypes = {
  close: PropTypes.func
};

PrepaidCostsList.defaultProps = {
  close: () => {}
};

export default PrepaidCostsList;
