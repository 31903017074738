//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Modal, Button, Row, Ratio, Col } from 'react-bootstrap';
import CompanyItem from '../CompanyItem';
import CompanyWizard from 'components/custom/wizards/companyWizard/CompanyWizard';
import editing from 'assets/img/icons/spot-illustrations/5.png';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AuthUserContext,
  CompaniesContext,
  CompanyFormModalContext,
  CompanyWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
//import api from 'utils/api';
//import Section from 'components/common/Section';
//import bg1 from 'assets/img/illustrations/corner-4.png';
//import gallery2 from 'assets/img/team/1-thumb.png';
//import Background from 'components/common/Background';
//import Section from 'components/common/Section';
//import Background from 'components/common/Background';
//import gallery2 from 'assets/img/avatars/no_image.png';

const CompanySettings = () => {
  const { companies, setCompanies } = useContext(CompaniesContext);
  const { setStep, setCompany } = useContext(CompanyWizardContext);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthUserContext);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );
  const { showFormModal, setShowFormModal } = useContext(
    CompanyFormModalContext
  );
  const handleFormModelClose = () => {
    setShowFormModal(false);
    setCompany({});
    setStep(1);
  };
  const [modalShow, setModalShow] = React.useState(false);
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleAddNewCompany = () => {
    console.log(user);
    if (user.status && user.status === 'trialing' && companies.length >= 1) {
      setShowErrorModal(true);
      return;
    }
    setShowFormModal(true);
  };
  const [lgShow, setLgShow] = useState(false);
  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Upgrade to Pro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center justify-content-center pt-2">
            <Col xs={12} md={6} className="text-center">
              <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
                <img
                  src={editing}
                  className="img-fluid"
                  alt="Editing"
                  style={{ maxWidth: '60%', height: 'auto' }}
                />
              </div>
            </Col>
            <h6 className="p-3 text-center">
              You are only allowed to setup one company in this trial version.
              Upgrade your plan to create multiple companies and unlock all the
              features.
            </h6>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-success"
            size="lg"
            icon="fa-solid fa-times"
            className="mb-1 mx-1"
            iconAlign="right"
            transform="shrink-3"
            onClick={() => setShowErrorModal(false)} // Close the modal
          ></IconButton>
          <IconButton
            className="me-0 mb-1"
            variant="outline-primary"
            icon="fa fa-arrow-right"
            size="lg"
            transform="shrink-3"
            onClick={() => {
              navigate('/plans');
            }} // Handle redirect
          ></IconButton>
        </Modal.Footer>
      </Modal>
      {/* First Row: Image */}
      <Row className="align-items-center justify-content-center pt-6">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
            <img
              src={editing}
              className="img-fluid"
              alt="Editing"
              style={{ maxWidth: '60%', height: 'auto' }}
            />
          </div>
        </Col>
      </Row>
      {/* Second Row: Setup Message */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            <h2 className="text-primary pt-3">Company Setup</h2>

            <p>
              Launching a new company is an exhilarating journey filled with
              boundless opportunities and exciting challenges. Before diving
              into the intricacies of crafting a business plan, it's crucial to
              establish a solid foundation of fundamental business assumptions.
              These assumptions serve as guiding principles that shape your
              direction
            </p>

            {/* Add company wizard modal */}

            {companies.length === 0 && (
              <div className="text-center my-4">
                <h3 className="text-primary text-muted">
                  No companies found. Please add a new company by clicking on
                  the plus button to add your company.
                </h3>
              </div>
            )}
            {Object.keys(companyDetails).length > 0 && (
              <div className="border-none rounded mt-4">
                <CompanyItem
                  details={companyDetails}
                  isLast={companies.length === 0}
                  setShowFormModal={setShowFormModal}
                  isSelected={true}
                  companies={companies}
                  setCompanies={setCompanies}
                  setSelected={setCompanyDetails}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/* Third Row: Navigation Buttons */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/Start" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-left"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>

            <Link
              to="/help-center#companySetup"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-info"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>

            <IconButton
              onClick={handleAddNewCompany}
              variant="outline-primary"
              size="lg"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />

            <IconButton
              onClick={() => setModalShow(true)}
              variant="outline-warning"
              size="lg"
              icon="fa-solid fa-search"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />

            <Link to="/BuzPitchSettings" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-right"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
          </div>
        </Col>
      </Row>
      {/* Modals */}
      {/* Company list modal */}
      <Modal
        show={modalShow}
        aria-label="contained-modal-title-vcenter"
        centered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Header closeButton>
          <Modal.Title>Company List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <PageHeader
              title="Select Company"
              description="Please select a company from the list below."
              className="mb-3"
            />

            {companies.length === 0 ? (
              <div className="text-center">
                <h5 className="text-muted text-primary">
                  No companies found. Please add a new company.
                </h5>
                <Button
                  variant="outline-primary"
                  onClick={handleAddNewCompany}
                  className="mt-3"
                >
                  Add New Company
                </Button>
              </div>
            ) : (
              <div>
                {companies.map(item => (
                  <div key={item.id} className="mb-3">
                    <CompanyItem
                      details={item}
                      setShowFormModal={setShowFormModal}
                      isSelected={item.id === companyDetails.id}
                      setSelected={setCompanyDetails}
                    />
                  </div>
                ))}
              </div>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add company wizard modal */}
      <Modal
        show={showFormModal}
        aria-label="contained-modal-title-vcenter"
        centered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Body>
          <Container>
            {/* Assuming CompanyWizard is a component for the form */}
            <CompanyWizard variant="pills" validation={false} />
          </Container>
        </Modal.Body>
      </Modal>
      {/* Video modal */}
      <Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-label="contained-modal-title-vcenter"
        centered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Header closeButton>
          <Modal.Title>Company Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio="16x9">
            <iframe
              src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
              allowFullScreen={true}
              title="AuziPlan"
            />
          </Ratio>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default CompanySettings;

{
  /* 
  
   <PageHeader
              title="Company List"
              description="Pick a company from the list below to give it a good once-over and spruce it up if needed!"
              className="mb-0"
            />


 <h6 className=" h6 mt-3">You are preparing a plan for:</h6>
          {companies.length === 0 && (
            <div className="text-center my-4">
              <h6 className="text-muted">
                No companies found. Please add a new company by clicking on the
                plus button to add your company.
              </h6>
            </div>
          )}
          {Object.keys(companyDetails).length > 0 && (
            <div className="border-none rounded mt-4">
              <CompanyItem
                details={companyDetails}
                isLast={companies.length === 0}
                setShowFormModal={setShowFormModal}
                isSelected={true}
                setSelected={setCompanyDetails}
              />
            </div>
          )}


<Link to="#!">{company}</Link>
  <div className="border-dashed border-bottom my-3" />        
  <p className="lead pt-1">
  "In Stage 2, you'll include the owner and investor roles, while also
  considering the option of securing a business loan if required."
  </p>

<div className="border-dashed border-bottom my-3" />
 <Link
    to="/help-center"
      className="border-2 rounded-pill mt-0 fs-0 py-2"
    >
      <h6 className="fw-bold mt-4">
        Help Center
        <TooltipBadge
          tooltip="Typically GST and Sales Tax in paid quaterly in line with BAS (Business Activivty Statement)."
          icon="question-circle"
        />
      </h6>
    </Link>
      <ReactVimeoPlayer
        url="https://vimeo.com/90509568"
        controls={true}
        className="react-player"
      />
  */

  {
    /* 
          <Link to="/help-center" className="text-decoration-none">
            <IconButton
              variant="outline-primary"
              icon="fa-solid fa-info"
              className="mb-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
          <Link to="" className="text-decoration-none mx-2">
            <IconButton
              onClick={() => setLgShow(true)}
              variant="outline-danger"
              icon="fa-solid fa-video"
              className="mb-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
          
          <h6 className=" h6 mt-3">You are preparing a plan for:</h6>
          {companies.length === 0 && (
            <div className="text-center my-4">
              <h6 className="text-muted">
                No companies found. Please add a new company by clicking on the
                plus button to add your company.
              </h6>
            </div>
          )}
          {Object.keys(companyDetails).length > 0 && (
            <div className="border-none rounded mt-4">
              <CompanyItem
                details={companyDetails}
                isLast={companies.length === 0}
                setShowFormModal={setShowFormModal}
                isSelected={true}
                setSelected={setCompanyDetails}
              />
            </div>
          )}
        
          */
    {
      /* Company list  modal 
          <Link to="/start" className="text-decoration-none">          
            <IconButton
              variant="outline-success"
              icon="fa-solid fa-arrow-left"
              className="mb-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
          <Link to="/help-center" className="text-decoration-none">
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="fa-solid fa-info"
              className="mb-1 mx-o"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
               <h4 className=" h4 mt-6 text-success">Stage - 1</h4>
          */
    }
  }
}
