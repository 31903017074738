import React, { useContext, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { SelectedCompanyContext } from 'context/Context';
import Header from './Header';

const CoverPage = ({ isPdf }) => {
  const [reportData, setReportData] = useState({
    totalRevenueProducts: 0,
    totalCostProducts: 0,
    totalRevenueServices: 0,
    totalPayroll: 0,
    totalOperatingExpenses: 0,
    revenue: 0,
    grossMargin: 0
  });

  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <Container id="cover-page">
      <Header />
      <h1 className="text-primary p-0">Business Plan Year 1-3</h1>
      <div id="pdf-content" style={{ paddingTop: '0px' }}>
        <Card>
          <Card.Body>
            <p className="p-1">
              We are excited to present our comprehensive business plan, crafted
              with dedication and foresight to drive our vision forward. This
              document outlines our strategic approach, key objectives, and the
              innovative solutions we are poised to deliver. Thank you for
              taking the time to explore our plan. We look forward to partnering
              with you on this journey to success.
            </p>
            <Row className="align-items-start justify-content-between">
              <Col lg={6}>
                <Row className="p-2">
                  <Col className="p-2 bg-00 border border-00 text-end">
                    Prepared by:
                  </Col>
                  <Col className="p-2 bg-000 border border-000">Nuri Bydel</Col>
                </Row>
              </Col>
              <Col lg={6}>
                <Row className="p-2">
                  <Col className="p-2 bg--00 border border--00 text-end">
                    Prepared For:
                  </Col>
                  <Col className="p-2 bg-00 border border-00">Serina Elan</Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>

          <Card.Footer>
            <p>
              Information provided in this business plan is unique to this
              business and confidential; therefore, anyone reading this plan
              agrees not to disclose any of the information in this business
              plan without prior written permission of the company.
            </p>
          </Card.Footer>
        </Card>
      </div>
    </Container>
  );
};

export default CoverPage;
