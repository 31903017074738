import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  FundingFormModalContext,
  FundingWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { currencies } from 'utils';

function Item({ details, loans, setLoans, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(FundingWizardContext);
  const { setShowFormModal } = useContext(FundingFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleLoanDelete = async () => {
    try {
      await api.delete(`/loans/delete/${details.id}`);
      toast.success('Loan deleted');
      const updatedLoans = loans.filter(loan => loan.id !== details.id);
      setLoans(updatedLoans);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting loan');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('loan');
    setEditDetails(details);
    setStep(3);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{details.bankName}</td>
        <td>{details.loanAmount}</td>
        <td>{details.interestRate}</td>
        <td>{details.loanTerm}</td>
        <td>
          {currencies[companyDetails.currency]}
          {details.repayment.toLocaleString('en-US', {
            maximumFractionDigits: 2
          })}
        </td>
        <td>{details.repaymentFrequency}</td>
        <td>
          {currencies[companyDetails.currency]}
          {details.totalLoan.toLocaleString('en-US', {
            maximumFractionDigits: 2
          })}
        </td>
        <td className="text-end">
          <ActionButton
            icon="info"
            title="Info"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.bankName}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this loan?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleLoanDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  loans: PropTypes.array,
  setLoans: PropTypes.func,
  close: PropTypes.func
};

export default Item;
