import React, { useState, useContext, useEffect } from 'react';
import { Table, Col, Row, Tab, Tabs, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';
import ServicesPie from './ServicesPie';
import { parseCurrency } from 'utils';
import { SelectedCompanyContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-0 mb-0">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'purchasePrice':
      return '';
    case 'additionalCosts':
      return '';
    case 'summary':
      return '';
    default:
      return '';
  }
};

const formatted = type => {
  switch (type) {
    case 'financialConsulting':
      return 'Financial Consulting';
    case 'bookKeeping':
      return 'Book Keeping';
    case 'auditing':
      return 'Auditing';
    case 'taxPreparation':
      return 'Tax Preparation';
    default:
      return type;
  }
};

function ServiceList({ close }) {
  const [servicesRevenue, setServicesRevenue] = useState([]);
  const [totalRevenueByCategory, setTotalRevenueByCategory] = useState({});
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [filterType, setFilterType] = useState('');
  const [totals, setTotals] = useState({
    noOfStaff: 0,
    weeklyRevenue: 0,
    monthlyRevenue: 0,
    yearlyRevenue: 0
  });
  const [activeTab, setActiveTab] = useState('purchasePrice');

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/services-revenue/getAll?companyId=${companyDetails.id}`)
        .then(response => setServicesRevenue(response.data))
        .catch(error => toast.error(`Failed to fetch data: ${error.message}`));
    } else {
      toast.error(
        'No company selected, you must set up a new company to start.'
      );
    }
  }, [companyDetails]);

  useEffect(() => {
    const groupedRevenue = servicesRevenue.reduce((acc, serviceRevenue) => {
      const { serviceType, yearlyRevenue } = serviceRevenue;
      const revenueValue = parseFloat(yearlyRevenue.replace(/[^0-9.-]+/g, ''));

      if (!acc[serviceType]) {
        acc[serviceType] = {
          totalYearlyRevenue: 0,
          totalYearlyRevenueYear2: 0,
          totalYearlyRevenueYear3: 0
        };
      }

      acc[serviceType].totalYearlyRevenue += revenueValue;
      acc[serviceType].totalYearlyRevenueYear2 +=
        revenueValue *
        (1 + parseFloat(companyDetails.serviceRevenueIncrease) / 100);
      acc[serviceType].totalYearlyRevenueYear3 +=
        revenueValue *
        Math.pow(
          1 + parseFloat(companyDetails.serviceRevenueIncrease) / 100,
          2
        );

      return acc;
    }, {});

    setTotalRevenueByCategory(groupedRevenue);

    const calculatedTotals = servicesRevenue.reduce(
      (acc, serviceRevenue) => {
        acc.noOfStaff += parseFloat(serviceRevenue.noOfStaff || 0);
        acc.weeklyRevenue += parseCurrency(serviceRevenue.weeklyRevenue || 0);
        acc.monthlyRevenue += parseCurrency(serviceRevenue.monthlyRevenue || 0);
        acc.yearlyRevenue += parseCurrency(serviceRevenue.yearlyRevenue || 0);
        return acc;
      },
      {
        noOfStaff: 0,
        weeklyRevenue: 0,
        monthlyRevenue: 0,
        yearlyRevenue: 0
      }
    );

    setTotals(calculatedTotals);
  }, [servicesRevenue]);

  const filteredServicesRevenue = filterType
    ? servicesRevenue.filter(service => service.serviceType === filterType)
    : servicesRevenue;

  const goToNextTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <div className="container">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={setActiveTab}
          id="property-form-tabs"
          className="mb-3"
        >
          <Tab eventKey="purchasePrice" title="Services Provided">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th scope="col">Service Type</th>
                    <th scope="col">No of Staff</th>
                    <th scope="col">Hours per week</th>
                    <th scope="col">Rate per Hour</th>
                    <th scope="col">Weekly Revenue</th>
                    <th scope="col">Monthly Revenue</th>
                    <th scope="col">Yearly Revenue</th>
                    <th className="text-end" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredServicesRevenue.map(serviceRevenue => (
                    <Item
                      key={serviceRevenue.id}
                      details={serviceRevenue}
                      servicesRevenue={servicesRevenue}
                      setServicesRevenue={setServicesRevenue}
                      close={close}
                      formatted={formatted}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className="text-left text-success fw-bold"
                    style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
                  >
                    <td>Totals</td>
                    <td>{totals.noOfStaff}</td>
                    <td></td>
                    <td></td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.weeklyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.monthlyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.yearlyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </Row>
          </Tab>
          <Tab eventKey="additionalCosts" title="Yearly Summary">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Year 1</th>
                    <th>Year 2</th>
                    <th>Year 3</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(totalRevenueByCategory).map(category => (
                    <tr key={category}>
                      <td>{formatted(category)}</td>
                      <td>
                        {totalRevenueByCategory[
                          category
                        ].totalYearlyRevenue.toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                      </td>
                      <td>
                        {totalRevenueByCategory[
                          category
                        ].totalYearlyRevenueYear2.toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                      </td>
                      <td>
                        {totalRevenueByCategory[
                          category
                        ].totalYearlyRevenueYear3.toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}>
                  <tr>
                    <th>Total Service Revenue</th>
                    <td>
                      {Object.values(totalRevenueByCategory)
                        .reduce(
                          (acc, category) => acc + category.totalYearlyRevenue,
                          0
                        )
                        .toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                    </td>
                    <td>
                      {Object.values(totalRevenueByCategory)
                        .reduce(
                          (acc, category) =>
                            acc + category.totalYearlyRevenueYear2,
                          0
                        )
                        .toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                    </td>
                    <td>
                      {Object.values(totalRevenueByCategory)
                        .reduce(
                          (acc, category) =>
                            acc + category.totalYearlyRevenueYear3,
                          0
                        )
                        .toLocaleString('en-US', {
                          style: 'currency',
                          currency: companyDetails.currency
                        })}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Row>
          </Tab>
          <Tab eventKey="summary" title="Chart">
            <Row className="pt-3">
              <Card.Body
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div style={{ width: '70%' }}>
                  <ServicesPie data={totalRevenueByCategory} />
                </div>
              </Card.Body>
            </Row>
          </Tab>
        </Tabs>
        <div className="border-dashed border-bottom my-3" />
        <Row className="mb-3">
          <Col className="text-center mt-3">
            <IconButton
              variant="outline-primary"
              size="sm"
              onClick={goToPreviousTab}
              disabled={activeTab === 'purchasePrice'}
            >
              <FaArrowLeft /> Previous
            </IconButton>
            <IconButton
              variant="outline-success"
              size="sm"
              onClick={goToNextTab}
              disabled={activeTab === 'summary'}
              className="ms-2"
            >
              Next <FaArrowRight />
            </IconButton>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
}

ServiceList.propTypes = {
  close: PropTypes.func
};

export default ServiceList;
