import { FundingFormModalContext, FundingWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from '../lottie/celebration.json';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';

const Success = () => {
  const { setStep } = useContext(FundingWizardContext);
  const { setShowFormModal } = useContext(FundingFormModalContext);

  const emptyData = () => {
    setShowFormModal(false);
    setStep(1);
  };

  return (
    <>
      <div className="border-none rounded mt-4">
        <Row className="mb-3">
          <Col className="text-center">
            <div className="wizard-lottie-wrapper">
              <div className="wizard-lottie mx-auto">
                <Lottie animationData={celebration} loop={true} />
              </div>
            </div>
            <h4 className="mb-1">You've just raised some funding!</h4>
            <p className="fs-0">Time to add you Startup Costs!</p>
            <IconButton
              color="warning"
              variant="outline-warning"
              size="lg"
              icon="fa-solid fa-arrow-left"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              onClick={emptyData}
            ></IconButton>

            <IconButton
              type="submit"
              color="success"
              variant="outline-success"
              size="lg"
              icon="fa-solid fa-arrow-right"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              as={Link}
              to="/StartupCostsSettings"
            ></IconButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Success;
