import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { SelectedCompanyContext } from 'context/Context';
import { currencies } from 'utils';

const PropertyPie = ({ totalCost, totalSales, totalRevenue }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  const chartData = {
    labels: ['Total Cost', 'Total Sales', 'Total Revenue'],
    datasets: [
      {
        data: [totalCost, totalSales, totalRevenue],
        backgroundColor: ['#4A90E2', '#5DADE2', '#85C1E9'],
        hoverBackgroundColor: ['#4A90E2', '#5DADE2', '#85C1E9'],
        borderWidth: 1
      }
    ]
  };

  // Prepare chart options
  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || '';
            const value = tooltipItem.raw || 0;
            return `${label}: ${
              currencies[companyDetails.currency]
            } ${value.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`;
          }
        }
      },
      legend: {
        display: true,
        position: 'left'
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <div style={{ width: '60%', height: '400px' }}>
        <Pie data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default PropertyPie;
