import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const CourseContext = createContext({ courses: [], primaryCourses: [] });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const CompanyWizardContext = createContext({ company: {} });

export const CompanyFormModalContext = createContext({ showFormModal: false });

export const BuzPitchWizardContext = createContext({});

export const BuzPitchFormModalContext = createContext({ showFormModal: false });

export const ReportsFormModalContext = createContext({ showFormModal: false });

export const FundingWizardContext = createContext({});

export const FundingFormModalContext = createContext({ showFormModal: false });

export const RevenueWizardContext = createContext({});

export const RevenueFormModalContext = createContext({ showFormModal: false });

export const StartupWizardContext = createContext({ startup: {} });

export const StartupFormModalContext = createContext({ showFormModal: false });

export const OperatingExpenseWizardContext = createContext({ startup: {} });

export const OperatingExpenseFormModalContext = createContext({
  showFormModal: false
});

export const CompaniesContext = createContext({ companies: [] });

export const SelectedCompanyContext = createContext({ companyDetails: {} });

export const AuthUserContext = createContext({});

export const ChatContext = createContext();

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});

export default AppContext;
