import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReportsFormModalContext } from 'context/Context';

const ReportsFormModalProvider = ({ children }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const value = { showFormModal, setShowFormModal };

  return (
    <ReportsFormModalContext.Provider value={value}>
      {children}
    </ReportsFormModalContext.Provider>
  );
};

ReportsFormModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReportsFormModalProvider;
