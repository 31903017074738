import React, { useContext } from 'react';
import { Container, Card, Col, Row, Table } from 'react-bootstrap';
import { currencies } from 'utils';
import { SelectedCompanyContext } from 'context/Context';
import Header from './Header';

import Yearly from 'components/pages/companyReports/yearly-analysis/Yearly';

const ProfitabilityAnalysis = ({ reportData, isPdf }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <Container id="profitability-analysis">
      <Header />

      <h4 className="text-primary p-0">Profitability Analysis</h4>
      <Row>
        <Col>
          <div id="pdf-content" style={{ paddingTop: '20px' }}>
            <Card>
              <Card.Header>
                <h5 className="text-primary">Startup Costs</h5>
              </Card.Header>
              <Card.Body>
                <Table
                  responsive
                  className="styled-table"
                  style={{
                    color: `${isPdf ? 'black' : 'white'}`,
                    marginBottom: '0',
                    borderCollapse: 'collapse',
                    width: '100%',
                    tableLayout: 'fixed'
                  }}
                >
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Year 1</th>
                      <th>Year 2</th>
                      <th>Year 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData[0].map((item, index) => (
                      <tr key={index}>
                        <td>{item.label}</td>
                        <td>
                          {currencies[companyDetails.currency]}{' '}
                          {item.year1.toLocaleString()}
                        </td>
                        <td>
                          {currencies[companyDetails.currency]}{' '}
                          {item.year2.toLocaleString()}
                        </td>
                        <td>
                          {currencies[companyDetails.currency]}{' '}
                          {item.year3.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Row lg={12} className=" styled-row mt-4 mb-4 ">
                  <Container className="styled-contaner">
                    <Yearly />
                  </Container>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfitabilityAnalysis;
