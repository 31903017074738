import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../components/WizardInput';
import {
  Col,
  Card,
  Button,
  Overlay,
  Popover,
  ListGroup,
  Form,
  ListGroupItem,
  FloatingLabel,
  Tab,
  Tabs,
  Row
} from 'react-bootstrap';

import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
//import PageHeader from 'components/common/PageHeader';
import { CompanyWizardContext } from 'context/Context';
import { currencies } from 'utils';
import Flex from 'components/common/Flex';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const PageHeader = ({ title, description }) => (
  <div className="page-header mb-3">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const CompanyTaxForm = ({ register, errors, watch, setValue, getValues }) => {
  // Accessing company context to get currency symbol
  const { company, setDisableForward } = useContext(CompanyWizardContext);
  const symbol = company.currency && currencies[company.currency];

  // State hooks for managing popover visibility and target
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  // Click handler for showing/hiding popover
  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const [activeTab, setActiveTab] = useState('orderDetails');
  const getTabDescription = tabKey => {
    switch (tabKey) {
      case 'orderDetails':
        return 'Enter the name of the customer and the projected initial order per item range.';

      case 'orderDetails1':
        return 'Enter the name of the customer and the projected initial order.';

      case 'orderDetails2':
        return 'Enter the name of the customer and the projected initial order.';

      case 'summary':
        return 'Review the summary of costs, profit margin, and projected profit percentage.';
      default:
        return '';
    }
  };

  const goToNextTab = () => {
    const tabs = ['orderDetails', 'orderDetails1', 'orderDetails2', 'summary'];
    const currentIndex = tabs.indexOf(activeTab);
    if (activeTab)
      if (currentIndex < tabs.length - 1) setActiveTab(tabs[currentIndex + 1]);
  };

  const goToPreviousTab = () => {
    const tabs = ['orderDetails', 'orderDetails1', 'orderDetails2', 'summary'];
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex > 0) setActiveTab(tabs[currentIndex - 1]);
  };

  useEffect(() => {
    activeTab == 'summary' ? setDisableForward(false) : setDisableForward(true);
  }, [activeTab]);

  // JSX structure with motion animation
  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            {/* Page header for company business assumptions */}
            <PageHeader
              title="Company Business Assumptions"
              description=""
              className="text-primary"
            />

            {/* Info icon button triggering popover */}
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            {/* Popover container */}
            <div className="border-none rounded mt-0">
              <div ref={ref}>
                {/* Overlay popover for help center */}
                <Overlay
                  show={show}
                  target={target}
                  placement="right"
                  container={ref.current}
                  containerPadding={20}
                >
                  <Popover id="popover-contained">
                    {/* Popover header */}
                    <Popover.Header as="h3">Help Center</Popover.Header>
                    {/* Popover body with company tax and GST/Sales tax information */}
                    <Popover.Body as="h6">
                      {/* Subtitle for company business assumptions */}
                      <PageHeader
                        title="Company Business Assumptions"
                        description=""
                        className="text-primary"
                      />
                      <div className="border-dashed border-bottom my-3" />
                      <div
                        className="border-none rounded mt-1"
                        style={{ maxHeight: '280px', overflowY: 'auto' }}
                      >
                        <div className="pt-3">
                          {/* Company Tax Rate and Payment Schedule */}

                          <h6 className="text-primary">
                            Company Business Assumptions Form Help Guide
                          </h6>
                          <p>
                            Welcome to the Company Setup form! This guide will
                            assist you in filling out the necessary details
                            accurately.
                          </p>

                          <ul>
                            <li>
                              <strong>Company Tax Rate:</strong> Enter the
                              applicable tax rate for your country. For example,
                              in Australia, the corporate tax rate is currently
                              39%.
                            </li>
                            <li>
                              <strong>Tax Payment Period:</strong> Select how
                              often you plan to pay your tax liabilities:
                              30-days, 60-days, 90-days, or at Year-End.
                            </li>
                          </ul>

                          {/* Company GST/Sales Tax Rate and Payment Schedule */}
                          <div className="pt-3">
                            <h6 className="text-primary ">
                              Company GST/Sales Tax Rate and Payment Schedule:
                            </h6>
                            <ul>
                              <li>
                                <strong>GST/VAT Sales Tax Rate:</strong> Specify
                                the GST or Sales Tax rate applicable in your
                                jurisdiction.
                              </li>
                              <li>
                                <strong>
                                  BAS Activity Statement Payment Period:
                                </strong>{' '}
                                Choose your preferred payment frequency for
                                GST/Sales tax payments: 30-days, 60-days,
                                90-days, or at Year-End.
                              </li>
                            </ul>
                          </div>

                          {/* Payroll Assumptions */}

                          <h6 className="text-primary ">
                            Payroll Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Payroll Increase:</strong> Indicate the
                              annual percentage increase in payroll expenses.
                            </li>
                            <li>
                              <strong>Superannuation Liability:</strong> Enter
                              the percentage of payroll allocated to
                              superannuation.
                            </li>
                            <li>
                              <strong>WorkCover Liability:</strong> Enter the
                              percentage of payroll allocated to WorkCover
                              expenses.
                            </li>
                          </ul>

                          {/* Operating Assumptions */}

                          <h6 className="text-primary ">
                            Operating Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Sales Increase:</strong> Specify the
                              annual percentage increase in sales.
                            </li>
                            <li>
                              <strong>Product Cost Increase:</strong> Enter the
                              annual percentage increase in product costs.
                            </li>
                            <li>
                              <strong>Operating Expenses Increase:</strong>{' '}
                              Indicate the annual percentage increase in
                              operating expenses.
                            </li>
                          </ul>

                          {/* General Assumptions */}

                          <h6 className="text-primary ">
                            General Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>
                                Marketing and Advertising Increase:
                              </strong>{' '}
                              Specify the annual percentage increase in
                              marketing and advertising expenses.
                            </li>
                            <li>
                              <strong>PP&E Depreciation Calculation:</strong>{' '}
                              Enter the percentage for PP&E (Property, Plant,
                              and Equipment) depreciation.
                            </li>
                            <li>
                              <strong>Bad Debt Written Off:</strong> Indicate
                              the percentage of bad debts that you expect to
                              write off annually.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Company Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>Company Tax Rate:</strong> Enter the
                              applicable tax rate for your country. For example,
                              in Australia, the corporate tax rate is currently
                              39%.
                            </li>
                            <li>
                              <strong>Tax Payment Period:</strong> Select how
                              often you plan to pay your tax liabilities:
                              30-days, 60-days, 90-days, or at Year-End.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Company GST/Sales Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>GST/VAT Sales Tax Rate:</strong> Specify
                              the GST or Sales Tax rate applicable in your
                              jurisdiction.
                            </li>
                            <li>
                              <strong>
                                BAS Activity Statement Payment Period:
                              </strong>
                              Choose your preferred payment frequency for
                              GST/Sales tax payments: 30-days, 60-days, 90-days,
                              or at Year-End.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Payroll Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Payroll Increase:</strong> Indicate the
                              annual percentage increase in payroll expenses.
                            </li>
                            <li>
                              <strong>Superannuation Liability:</strong> Enter
                              the percentage of payroll allocated to
                              superannuation.
                            </li>
                            <li>
                              <strong>WorkCover Liability:</strong> Enter the
                              percentage of payroll allocated to WorkCover
                              expenses.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Operating Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Sales Increase:</strong> Specify the
                              annual percentage increase in sales.
                            </li>
                            <li>
                              <strong>Product Cost Increase:</strong> Enter the
                              annual percentage increase in product costs.
                            </li>
                            <li>
                              <strong>Operating Expenses Increase:</strong>{' '}
                              Indicate the annual percentage increase in
                              operating expenses.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            General Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>
                                Marketing and Advertising Increase:
                              </strong>{' '}
                              Specify the annual percentage increase in
                              marketing and advertising expenses.
                            </li>
                            <li>
                              <strong>PP&E Depreciation Calculation:</strong>{' '}
                              Enter the percentage for PP&E (Property, Plant,
                              and Equipment) depreciation.
                            </li>
                            <li>
                              <strong>Bad Debt Written Off:</strong> Indicate
                              the percentage of bad debts that you expect to
                              write off annually.
                            </li>
                          </ul>

                          {/* Company Tax Rate and Payment Schedule */}
                          <h6 className="text-primary ">
                            Company Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>Company Tax Rate:</strong> Enter the
                              applicable tax rate for your country. For example,
                              in Australia, the corporate tax rate is currently
                              39%.
                            </li>
                            <li>
                              <strong>Tax Payment Period:</strong> Select how
                              often you plan to pay your tax liabilities:
                              30-days, 60-days, 90-days, or at Year-End.
                            </li>
                          </ul>

                          {/* Company GST/Sales Tax Rate and Payment Schedule */}
                          <h6 className="text-primary ">
                            Company GST/Sales Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>GST/VAT Sales Tax Rate:</strong> Specify
                              the GST or Sales Tax rate applicable in your
                              jurisdiction.
                            </li>
                            <li>
                              <strong>
                                BAS Activity Statement Payment Period:
                              </strong>{' '}
                              Choose your preferred payment frequency for
                              GST/Sales tax payments: 30-days, 60-days, 90-days,
                              or at Year-End.
                            </li>
                          </ul>

                          {/* Payroll Assumptions */}
                          <h6 className="text-primary ">
                            Payroll Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Payroll Increase:</strong> Indicate the
                              annual percentage increase in payroll expenses.
                            </li>
                            <li>
                              <strong>Superannuation Liability:</strong> Enter
                              the percentage of payroll allocated to
                              superannuation.
                            </li>
                            <li>
                              <strong>WorkCover Liability:</strong> Enter the
                              percentage of payroll allocated to WorkCover
                              expenses.
                            </li>
                          </ul>

                          {/* Operating Assumptions */}
                          <h6 className="text-primary ">
                            Operating Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Sales Increase:</strong> Specify the
                              annual percentage increase in sales.
                            </li>
                            <li>
                              <strong>Product Cost Increase:</strong> Enter the
                              annual percentage increase in product costs.
                            </li>
                            <li>
                              <strong>Operating Expenses Increase:</strong>{' '}
                              Indicate the annual percentage increase in
                              operating expenses.
                            </li>
                          </ul>

                          {/* General Assumptions */}
                          <h6 className="text-primary ">
                            General Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>
                                Marketing and Advertising Increase:
                              </strong>{' '}
                              Specify the annual percentage increase in
                              marketing and advertising expenses.
                            </li>
                            <li>
                              <strong>PP&E Depreciation Calculation:</strong>{' '}
                              Enter the percentage for PP&E (Property, Plant,
                              and Equipment) depreciation.
                            </li>
                            <li>
                              <strong>Bad Debt Written Off:</strong> Indicate
                              the percentage of bad debts that you expect to
                              write off annually.
                            </li>
                          </ul>

                          <p>
                            Thank you for providing these details! If you
                            encounter any issues or have questions, feel free to
                            reach out for further assistance.
                          </p>
                        </div>

                        <div className="border-dashed border-bottom my-3" />
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>

              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={k => setActiveTab(k)}
                className="mb-3"
              >
                <Tab eventKey="orderDetails" title="Company">
                  <Card className="styled-card-1">
                    <Card.Header className="text-primary">
                      Company Trading Terms
                    </Card.Header>

                    <Card.Body>
                      {/* Form inputs for tax rate and payment schedule */}

                      <Row className="mt-0 mb-0 g-3">
                        <Col lg={3}>
                          <PercentageWizardInput
                            name="taxRate"
                            label="Company Tax Rate by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('taxRate', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          {/* Form inputs for GST/Sales tax rate and payment schedule */}
                          <PercentageWizardInput
                            name="gstSalesTaxRate"
                            label="GST/VAT Sales Tax Rate by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-1' }}
                            formControlProps={{
                              ...register('gstSalesTaxRate', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          <Form.Group>
                            <FloatingLabel label="Inventory Pay in:">
                              <Form.Control
                                type="text"
                                name="inventoryPayPeriod"
                                className="w-100"
                                placeholder="Enter days"
                                value={watch('inventoryPayPeriod') || ''}
                                isInvalid={!!errors.inventoryPayPeriod}
                                onChange={e => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ''
                                  );
                                  setValue(
                                    'inventoryPayPeriod',
                                    value ? `${value} Days` : ''
                                  );
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.inventoryPayPeriod?.message}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>

                        <Col lg={3}>
                          <Form.Group>
                            <FloatingLabel label="BAS Activity paid in:">
                              <Form.Control
                                type="text"
                                name="gstSalesTaxPeriod"
                                className="w-100"
                                placeholder="Enter days"
                                value={watch('gstSalesTaxPeriod') || ''}
                                isInvalid={!!errors.gstSalesTaxPeriod}
                                onChange={e => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ''
                                  );
                                  setValue(
                                    'gstSalesTaxPeriod',
                                    value ? `${value} Days` : ''
                                  );
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.gstSalesTaxPeriod?.message}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>

                        <Col lg={3}>
                          <Form.Group>
                            <FloatingLabel label="Company Tax paid in:">
                              <Form.Control
                                type="text"
                                name="taxPeriod"
                                className="w-100"
                                placeholder="Enter days"
                                value={watch('taxPeriod') || ''}
                                isInvalid={!!errors.taxPeriod}
                                onChange={e => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ''
                                  );
                                  setValue(
                                    'taxPeriod',
                                    value ? `${value} Days` : ''
                                  );
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.taxPeriod?.message}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>

                        <Col lg={3}>
                          <Form.Group>
                            <FloatingLabel label="Customers paid in:">
                              <Form.Control
                                type="text"
                                name="customerPayPeriod"
                                className="w-100"
                                placeholder="Enter days"
                                value={watch('customerPayPeriod') || ''}
                                isInvalid={!!errors.customerPayPeriod}
                                onChange={e => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ''
                                  );
                                  setValue(
                                    'customerPayPeriod',
                                    value ? `${value} Days` : ''
                                  );
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.customerPayPeriod?.message}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>

                        <Col lg={3}>
                          <Form.Group>
                            <FloatingLabel label="Suppliers paid in:">
                              <Form.Control
                                type="text"
                                name="supplierPayPeriod"
                                className="w-100"
                                placeholder="Enter days"
                                value={watch('supplierPayPeriod') || ''}
                                isInvalid={!!errors.supplierPayPeriod}
                                onChange={e => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ''
                                  );
                                  setValue(
                                    'supplierPayPeriod',
                                    value ? `${value} Days` : ''
                                  );
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.supplierPayPeriod?.message}
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Tab>

                <Tab eventKey="orderDetails1" title="Payroll">
                  <Card>
                    <Card.Header className="text-primary">
                      Payroll Assumptions
                    </Card.Header>

                    <Card.Body>
                      {/* Form inputs for payroll assumptions */}
                      <Row className="mt-0 mb-0 g-3">
                        <Col lg={3}>
                          <PercentageWizardInput
                            name="payrollIncrease"
                            label="Payroll increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('payrollIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          <PercentageWizardInput
                            name="superannuation"
                            label="Superannuation % of payroll by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('superannuation', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          <PercentageWizardInput
                            name="workCover"
                            label="Work Cover % of payroll by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('workCover', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          <PercentageWizardInput
                            name="others"
                            label="Other liability % of payroll by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('others', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Tab>

                <Tab eventKey="orderDetails2" title="Assumptions">
                  {/* Form inputs for Company tax rate and payment schedule */}

                  <Card>
                    <Card.Header className="text-primary">
                      General Business Assumptions
                    </Card.Header>

                    <Card.Body>
                      {/* Form inputs for payroll assumptions */}
                      <Row className="mt-0 mb-0 g-1">
                        <Col lg={4}>
                          <PercentageWizardInput
                            name="productSalesIncrease"
                            label="Product Sales increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('productSalesIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={4}>
                          <PercentageWizardInput
                            name="onlineSalesIncrease"
                            label="Online Sales increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('onlineSalesIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={4}>
                          <PercentageWizardInput
                            name="wholeSaleRevenueIncrease"
                            label="Wholesale Revenue increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('wholeSaleRevenueIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={4}>
                          <PercentageWizardInput
                            name="productCostIncrease"
                            label="Product cost increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('productCostIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={4}>
                          <PercentageWizardInput
                            name="onlineCostIncrease"
                            label="Online Cost increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('onlineCostIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={4}>
                          <PercentageWizardInput
                            name="wholeSaleCostIncrease"
                            label="Wholesale Cost increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('wholeSaleCostIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>
                        <Col lg={4}>
                          <PercentageWizardInput
                            name="otherRevenue"
                            label="Other Revenue by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('otherRevenue', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>
                        <Col lg={4}>
                          <PercentageWizardInput
                            name="otherCost"
                            label="Other Cost by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('otherCost', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        {/* Form inputs for Company tax rate and payment schedule */}

                        <Col lg={4}>
                          <PercentageWizardInput
                            name="serviceRevenueIncrease"
                            label="Service Revenue increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('serviceRevenueIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Tab>

                <Tab eventKey="summary" title="Operating">
                  {/* Form inputs for operating assumptions */}
                  <Card>
                    <Card.Header className="text-primary">
                      Operating Trading Assumptions
                    </Card.Header>

                    <Card.Body>
                      {/* Form inputs for general assumptions */}
                      <Row className="mt-0 mb-0 g-1">
                        <Col lg={3}>
                          <PercentageWizardInput
                            name="operatingCostIncrease"
                            label="Operating Expenses increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('operatingCostIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          <PercentageWizardInput
                            name="marketingIncrease"
                            label="Marketing increase by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('marketingIncrease', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          <PercentageWizardInput
                            name="depreciation"
                            label="PP&E Depreciation calculation by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('depreciation', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>

                        <Col lg={3}>
                          <PercentageWizardInput
                            name="badDebt"
                            label="Bad debt written off by:"
                            errors={errors}
                            formGroupProps={{ className: 'mb-0' }}
                            formControlProps={{
                              ...register('badDebt', {
                                required: 'This field is required'
                              })
                            }}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Tab>
              </Tabs>

              <div className="border-dashed border-bottom my-3" />
              <Row className=" mb-3">
                <Col className="text-center mt-3">
                  <IconButton
                    variant="outline-primary"
                    size="sm"
                    onClick={goToPreviousTab}
                    disabled={activeTab === 'orderDetails'}
                  >
                    <FaArrowLeft /> Previous
                  </IconButton>
                  <IconButton
                    variant="outline-success"
                    size="sm"
                    onClick={goToNextTab}
                    disabled={activeTab === 'summary'}
                    className="ms-2"
                  >
                    Next <FaArrowRight />
                  </IconButton>
                </Col>
              </Row>

              {/* Divider line */}
              <div className="border-dashed border-bottom my-3" />
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

// PropTypes for type validation
CompanyTaxForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func
};

export default CompanyTaxForm;
