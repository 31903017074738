import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { parseCurrency } from 'utils';

const RevenuePie = ({ data }) => {
  const totals = data.reduce(
    (acc, row) => {
      acc.totalYearlyRevenue += parseCurrency(row.totalYearlyRevenue || '0');
      acc.totalYearlyCostProjection += parseCurrency(
        row.totalYearlyCostProjection || '0'
      );
      acc.yearlyGrossProfitProjection += parseCurrency(
        row.yearlyGrossProfitProjection || '0'
      );
      return acc;
    },
    {
      totalYearlyRevenue: 0,
      totalYearlyCostProjection: 0,
      yearlyGrossProfitProjection: 0
    }
  );

  // Prepare the data for the Pie chart
  const chartData = {
    labels: ['Revenue', 'Cost Projection', 'Gross Profit'],
    datasets: [
      {
        data: [
          totals.totalYearlyRevenue,
          totals.totalYearlyCostProjection,
          totals.yearlyGrossProfitProjection
        ],
        backgroundColor: ['#4A90E2', '#5DADE2', '#85C1E9'],
        hoverBackgroundColor: ['#4A90E2', '#5DADE2', '#85C1E9']
      }
    ]
  };

  // Chart options
  const chartOptions = {
    plugins: {
      legend: {
        position: 'left', // Move legend to the top
        align: 'center', // Center the legend horizontally
        labels: {
          boxWidth: 20, // Set the width of the legend boxes
          padding: 15 // Space between legend items
        }
      },
      title: {
        display: false,
        text: 'Revenue Distribution',
        position: 'top',
        padding: {
          top: 20,
          bottom: 20
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <div style={{ width: '60%', height: '400px' }}>
        <Pie data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default RevenuePie;
