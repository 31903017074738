import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';

import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';

import {
  FundingFormModalContext,
  FundingWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { useForm } from 'react-hook-form';
import api from 'utils/api';
import { toast } from 'react-toastify';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import PhoneWizardInput from 'components/custom/common/PhoneWizardInput';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

import Background from 'components/common/Background';
import gallery2 from 'assets/img/illustrations/corner-4.png';

const InvestorForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(FundingWizardContext);
  const { setShowFormModal } = useContext(FundingFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      investorFirstName: '',
      investorLastName: '',
      investorType: 'angel',
      investorYear: '',
      investorContribution: '',
      investorPercentageEquity: '',
      investorEmail: '',
      investorPhone: '',
      investorIntro: ''
    }
  });

  useEffect(() => {
    if (edit === 'investor' && editDetails.id) {
      setValue('investorFirstName', editDetails.investorFirstName);
      setValue('investorLastName', editDetails.investorLastName);
      setValue('investorType', editDetails.investorType);
      setValue('investorYear', editDetails.investorYear);
      setValue(
        'investorContribution',
        `${
          currencies[companyDetails.currency]
        }${editDetails.investorContribution.slice(1)}`
      );
      setValue(
        'investorPercentageEquity',
        editDetails.investorPercentageEquity
      );
      setValue('investorEmail', editDetails.investorEmail);
      setValue('investorPhone', editDetails.investorPhone);
      setValue('investorIntro', editDetails.investorIntro);
      setIsEditing(true);
      setDocId(editDetails.id);
      setEdit('');
      setEditDetails({});
    }
  }, [edit]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSubmitData = data => {
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : {
            companyId: companyDetails.id,
            ...data
          };

    api
      .post(isEditing ? '/investors/update' : '/investors/create', payload)
      .then(res => {
        reset();
        setEdit('');
        setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message="Do you want to add more investors?"
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document
                  .querySelector('input[name="investorFirstName"]')
                  ?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const currentYear = new Date().getFullYear();

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Who are the investors of the company ?"
              description="
              
              
                                Investors are individuals or entities who
                                contribute capital to the company in exchange
                                for equity or other financial instruments. They
                                play a crucial role in funding and supporting
                                the company's growth and success.
                      
              
              
              "
              className="text-primary"
            />

            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <Row className="mb-0 mt-0">
              <Col md="auto">
                <div ref={ref}>
                  <Overlay
                    show={show}
                    target={target}
                    placement="right"
                    container={ref.current}
                    containerPadding={20}
                  >
                    <Popover
                      id="popover-contained"
                      style={{ backgroundColor: '' }}
                    >
                      <Popover.Header as="h3">Help Center</Popover.Header>
                      <Popover.Body
                        style={{
                          backgroundColor: '',
                          maxHeight: '400px',
                          overflowY: 'auto',
                          padding: '15px'
                        }}
                      >
                        <Row
                          as={motion.div}
                          className="g-3"
                          initial={{ x: '-20%' }}
                          animate={{ x: '0%' }}
                          exit={{
                            x: window.innerWidth,
                            transition: { duration: 0.5 }
                          }}
                        >
                          <PageHeader
                            className="text-primary"
                            title="Company Investors"
                            description=""
                          />
                          <div
                            className="border-none rounded mt-2"
                            style={{ maxHeight: '280px', overflowY: 'auto' }}
                          >
                            <Row>
                              <h6 className="text-primary pt-3">
                                Who are they?
                              </h6>
                              <p>
                                Investors are individuals or entities who
                                contribute capital to the company in exchange
                                for equity or other financial instruments. They
                                play a crucial role in funding and supporting
                                the company's growth and success.
                              </p>
                              <p>
                                Provide details about the company investor(s)
                                and their initial financial contributions to
                                support the company.
                              </p>

                              <h6 className="text-primary">
                                Percentage Share Equity
                              </h6>
                              <p>
                                Specify the percentage of ownership held by the
                                investor(s).
                              </p>

                              <h6 className="text-primary">Example:</h6>
                              <p>
                                Shireen and her sister, Chanelle, invested
                                $200,000 each in XYZ Inc. They each hold a 25%
                                stake in the company, contributing equally to
                                its ownership structure.
                              </p>

                              <div className="container pt-0">
                                <h6 className="text-primary pt-3">
                                  How to Fill Out the Investor Form?
                                </h6>

                                <p>
                                  Below are step-by-step instructions to help
                                  you fill out the form accurately:
                                </p>

                                <h6 className="text-primary pt-3">
                                  1. Investor Details
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Investor First Name:</strong> Enter
                                    the first name of the company Investor.
                                  </li>
                                  <li>
                                    <strong>Investor Last Name:</strong> Provide
                                    the last name of the company Investor.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  2. Investor Role
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Investor Role:</strong> Select the
                                    role of the Investor from the dropdown menu.
                                    Options include Family Member, Angel
                                    Investor, Seed Investor, Company or Best
                                    Friend.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  3. Financial Contribution
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Investor Contribution:</strong>{' '}
                                    Specify the financial contribution made by
                                    the investor.
                                  </li>
                                  <li>
                                    <strong>Percentage Equity:</strong> Enter
                                    the percentage of return of investment held
                                    by the investor(s).
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  4. Contact Information
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Investor Email:</strong> Input the
                                    email address of the Investor. Ensure it is
                                    valid as per the specified format.
                                  </li>
                                  <li>
                                    <strong>Investor Phone:</strong> Enter the
                                    phone number of the Investor.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  5. Introduction
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Investor Intro:</strong> Provide a
                                    brief introduction of the Investor.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  Additional Information
                                </h6>
                                <ul>
                                  <li>
                                    If you encounter any errors while
                                    submitting, ensure all required fields are
                                    correctly filled out. Error messages will
                                    guide you on any missing or incorrect
                                    information.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  Saving Your Data
                                </h6>
                                <ul>
                                  <li>
                                    Click the "Save" button at the bottom right
                                    after filling out all required fields to
                                    save your entries.
                                  </li>
                                  <li>
                                    You will receive notifications and prompts
                                    based on your actions during the form
                                    submission process.
                                  </li>
                                </ul>
                              </div>

                              <div className="border-dashed border-bottom my-3" />
                            </Row>
                          </div>
                        </Row>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </Col>
              <Col md></Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              <Row className="mt-0 mb-2 g-3">
                <Form.Group as={Col} lg={6} controlId="investorFirstName">
                  <FloatingLabel label="Investor First Name">
                    <Form.Control
                      type="text"
                      placeholder="Investor First Name"
                      name="investorFirstName"
                      {...register('investorFirstName', {
                        required: 'Investor First Name is required.'
                      })}
                      isInvalid={errors['investorFirstName']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['investorFirstName']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['investorFirstName']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} lg={6} controlId="InvestorLastName">
                  <FloatingLabel label="Investor Last Name">
                    <Form.Control
                      type="text"
                      placeholder="Investor Last Name"
                      name="investorLastName"
                      {...register('investorLastName', {
                        required: 'Investor Last Name is required.'
                      })}
                      isInvalid={errors['investorLastName']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['investorLastName']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['investorLastName']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-2 g-3">
                <Form.Group as={Col} lg={3} controlId="investorType">
                  <FloatingLabel label="Investor Type">
                    <Form.Select
                      aria-label="Default select investor type"
                      {...register('investorType', {
                        required: 'Investor Type is required.'
                      })}
                      isInvalid={errors['investorType']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['investorType']
                      }
                    >
                      <option value="angel">Angel</option>
                      <option value="seed">Seed</option>
                      <option value="family-member">Family Member</option>
                      <option value="best-friend">Best Friend</option>
                      <option value="company">Company</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors['investorType']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} lg={3} controlId="investorYear">
                  <FloatingLabel label="Investment year">
                    <Form.Select
                      aria-label="Default select investor year"
                      {...register('investorYear', {
                        required: 'Investor Year is required.'
                      })}
                      isInvalid={errors['investorYear']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['investorYear']
                      }
                    >
                      <option value="opening">Opening Investor</option>
                      <option value={currentYear}>{currentYear}</option>
                      <option value={currentYear + 1}>{currentYear + 1}</option>
                      <option value={currentYear + 2}>{currentYear + 2}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors['investorYear']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <CurrencyWizardInput
                  symbol={currencies[companyDetails.currency]}
                  name="investorContribution"
                  label="Investor Contribution"
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', lg: 3, as: Col }}
                  formControlProps={{
                    ...register('investorContribution', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />
                <PercentageWizardInput
                  name="investorPercentageEquity"
                  label="Percentage Equity"
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', as: Col, lg: 3 }}
                  formControlProps={{
                    ...register('investorPercentageEquity', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />
              </Row>
              <Row className="mb-2 g-3">
                <Form.Group as={Col} lg={6} controlId="InvestorEmail">
                  <FloatingLabel label="Investor Email">
                    <Form.Control
                      type="email"
                      placeholder="Investor Email"
                      name="investorEmail"
                      {...register('investorEmail', {
                        required: 'Investor Email is required.',
                        pattern: {
                          value:
                            /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                          message: 'Email must be valid'
                        }
                      })}
                      isInvalid={errors['investorEmail']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['investorEmail']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['investorEmail']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <PhoneWizardInput
                  name="investorPhone"
                  label="Investor Phone*"
                  placeholder="Investor Phone*"
                  errors={errors}
                  formGroupProps={{ as: Col, sm: 6 }}
                  formControlProps={{
                    ...register('investorPhone', {
                      required: 'Investor Phone is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />
              </Row>
              <Form.Group className="mb-3" controlId="investorIntro">
                <FloatingLabel label="Investor Intro">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Investor Intro"
                    name="investorIntro"
                    {...register('investorIntro', {
                      required: 'Investor Intro is required.'
                    })}
                    isInvalid={errors['investorIntro']}
                    isValid={
                      Object.keys(errors).length > 0 && !errors['investorIntro']
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors['investorIntro']?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <div className="border-dashed border-bottom my-3" />
              <Row>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default InvestorForm;
