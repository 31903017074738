import Flex from 'components/common/Flex';
import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  FloatingLabel,
  ProgressBar,
  FormSelect,
  FormGroup,
  Collapse
} from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/6.svg';

import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { OperatingExpenseFormModalContext } from 'context/Context';
import OperatingExpenseWizard from 'components/custom/wizards/operatingExpenseWizard/OperatingExpenseWizard';
import PageHeader from 'components/common/PageHeader';
import OperatingExpensesList from 'components/custom/lists/stage4 /OperatingExpensesList';
import PayrollList from 'components/custom/lists/stage4 /PayrollList';

const OperatingExpensesSettings = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [expenseType, setExpenseType] = useState('');
  const { showFormModal, setShowFormModal } = useContext(
    OperatingExpenseFormModalContext
  );
  const [modalShow, setModalShow] = React.useState(false);
  const handleFormModelClose = () => {
    setShowFormModal(false);
  };
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  useEffect(() => {
    setExpenseType('operating-expense');
  }, []);

  return (
    <Container
      as={motion.div} // Using motion.div from framer-motion for animation
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        // Animation variants for initial and visible states
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      {/* Image Row */}
      <Row className="align-items-center justify-content-center pt-6">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
            <img
              src={editing}
              className="img-fluid"
              alt="Editing"
              style={{ maxWidth: '60%', height: 'auto' }}
            />
          </div>
        </Col>
      </Row>

      {/* Message Row */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            <h2 className="text-primary pt-0">Operating Expenses</h2>
            <p>
              Ensuring the smooth operation of your business involves
              proficiently managing recurring expenses such as rent, utilities,
              and employee salaries. These ongoing financial commitments play a
              critical role in sustaining the day-to-day activities and
              long-term viability of your operations.
            </p>
          </div>
        </Col>
      </Row>

      {/* Navigation Row */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            {/* Link to previous page */}
            <Link to="/RevenueSettings" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                icon="fa-solid fa-arrow-left"
                size="lg"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
            {/* Link to help center */}
            <Link
              to="/help-center#operatingCosts"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-info"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
            {/* Button to show form modal */}
            <IconButton
              onClick={() => setShowFormModal(true)}
              variant="outline-primary"
              size="lg"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />
            {/* Button to show search modal */}
            <IconButton
              onClick={() => setModalShow(true)}
              variant="outline-warning"
              size="lg"
              icon="fa-solid fa-search"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />
            {/* Link to next page */}
            <Link to="/reportsSettings" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-right"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
          </div>
        </Col>
      </Row>

      {/* Summary Modal */}
      <Modal
        show={modalShow}
        aria-label="contained-modal-title-vcenter"
        centered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Body>
          <Container>
            {/* Page header inside modal */}

            <PageHeader
              title="Operating Expense Budget"
              description="Create your operating expenses budget"
              className="mb-0"
            />

            <Card className="mt-3">
              <div className="border-none rounded mt-4">
                <div className="p-x1">
                  {/* Form group for selecting expense type */}
                  <Form.Group as={Col} lg={12} controlId="startupCostType">
                    <FloatingLabel label="Expense Type">
                      <Form.Select
                        aria-label="Default select an expense type"
                        onChange={e => setExpenseType(e.target.value)}
                        value={expenseType}
                      >
                        <option value="operating-expense">
                          Operating Expenses
                        </option>
                        <option value="payroll-expense">
                          Payroll Expenses
                        </option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </div>
                <div className="my-3">
                  {expenseType === 'operating-expense' && (
                    <OperatingExpensesList close={() => setModalShow(false)} />
                  )}
                  {expenseType === 'payroll-expense' && (
                    <PayrollList close={() => setModalShow(false)} />
                  )}
                </div>
              </div>
            </Card>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* Button to close modal */}
          <IconButton
            variant="outline-success"
            size="sm"
            onClick={() => setModalShow(false)}
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>

      {/* Wizard Modal */}
      <Modal
        show={showFormModal}
        aria-label="contained-modal-title-vcenter"
        centered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Body>
          <Container>
            {/* Wizard component for operating expenses */}
            <OperatingExpenseWizard variant="pills" validation={false} />
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OperatingExpensesSettings;
