import React from 'react';
import { Line } from 'react-chartjs-2';
import { useContext } from 'react';
import { SelectedCompanyContext } from 'context/Context';
import { currencies } from 'utils';

const LoanLineChart = ({ years, interestRate, loanAmount }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  const currencySymbol = currencies[companyDetails.currency];

  const calculateData = (years, interestRate, loanAmount) => {
    const principalPaid = [];
    const interestPaid = [];
    const labels = [];

    let remainingLoan = loanAmount;
    const monthlyRate = interestRate / 100 / 12;
    const months = years * 12;
    const emi =
      (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, months)) /
      (Math.pow(1 + monthlyRate, months) - 1);

    let cumulativeInterest = 0;
    let cumulativePrincipal = 0;
    let yearlyInterestTotal = 0;
    let yearlyPrincipalTotal = 0;
    let monthCounter = 0;

    for (let month = 1; month <= months; month++) {
      const interest = remainingLoan * monthlyRate;
      const principalPayment = emi - interest;
      remainingLoan -= principalPayment;

      yearlyInterestTotal += interest;
      yearlyPrincipalTotal += principalPayment;

      if (++monthCounter === 12) {
        cumulativeInterest += yearlyInterestTotal;
        cumulativePrincipal += yearlyPrincipalTotal;
        labels.push(Math.ceil(month / 12));
        principalPaid.push(cumulativePrincipal);
        interestPaid.push(cumulativeInterest);
        yearlyInterestTotal = 0;
        yearlyPrincipalTotal = 0;
        monthCounter = 0;
      }
    }

    return { labels, principalPaid, interestPaid };
  };

  const { labels, principalPaid, interestPaid } = calculateData(
    years,
    interestRate,
    loanAmount
  );

  const data = {
    labels,
    datasets: [
      {
        label: 'Principal Paid',
        data: principalPaid,
        borderColor: '#4682B4', // Steel Blue
        fill: false
      },
      {
        label: 'Interest Paid',
        data: interestPaid,
        borderColor: '#1E90FF', // Dodger Blue
        fill: false
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw.toLocaleString(); // format value with commas
            return `${tooltipItem.dataset.label}: ${currencySymbol}${value}`;
          }
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Year'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Amount'
        },
        ticks: {
          callback: function (value) {
            return `${currencySymbol}${value.toLocaleString()}`;
          }
        }
      }
    }
  };

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LoanLineChart;
