import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import {
  SelectedCompanyContext,
  StartupFormModalContext,
  StartupWizardContext
} from 'context/Context';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from 'utils/api';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

const PlantAndEquipmentForm = () => {
  // State variables and refs for managing form data and UI states
  const [show, setShow] = useState(false); // State for controlling popover visibility
  const [target, setTarget] = useState(null); // State for popover target element
  const ref = useRef(null); // Ref for overlay positioning
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(StartupWizardContext); // Context for managing wizard steps and editing state
  const { setShowFormModal } = useContext(StartupFormModalContext); // Context for controlling modal visibility
  const { companyDetails } = useContext(SelectedCompanyContext); // Context for accessing company details
  const [isEditing, setIsEditing] = useState(false); // State to manage edit mode
  const [docId, setDocId] = useState(null); // State for storing document ID

  // Form handling with react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      // Initial form field values
      itemDescription: '', // Initialize item description as empty string
      purchasePrice: '$ 100', // Initialize purchase price as '0'
      usefulLife: '1', // Initialize useful life as '0'
      quantity: '1', // New field for quantity, initialize as '0'
      gstVat: '0', // Initialize GST/VAT as '0'
      totalDepreciation: '0', // Initialize total depreciation as '0'
      depreciationYearly: '0', // Initialize yearly depreciation as '0'
      depreciationMonthly: '0', // Initialize monthly depreciation as '0'
      depreciationPercentage: '0' // Initialize depreciation percentage as '0'
    }
  });

  // Effect to pre-fill form fields when editing existing data
  useEffect(() => {
    if (edit === 'PlantAndEquipment' && editDetails.id) {
      // Set editing mode to true
      setIsEditing(true);

      // Set document ID for editing
      setDocId(editDetails.id);

      // Set initial values from editDetails object for each form field
      setValue('itemDescription', editDetails.itemDescription); // Set item description
      setValue('purchasePrice', editDetails.purchasePrice); // Set purchase price
      setValue('usefulLife', editDetails.usefulLife); // Set useful life
      setValue('quantity', editDetails.quantity); // Set quantity if editing
      setValue('gstVat', editDetails.gstVat); // Set GST/VAT
      setValue('totalDepreciation', editDetails.totalDepreciation); // Set total depreciation
      setValue('depreciationYearly', editDetails.depreciationYearly); // Set yearly depreciation
      setValue('depreciationMonthly', editDetails.depreciationMonthly); // Set monthly depreciation
      setValue('depreciationPercentage', editDetails.depreciationPercentage); // Set depreciation percentage
    }
  }, [edit]);

  // Watch variables for computing derived values
  const watchPurchasePrice = watch('purchasePrice'); // Watch the 'purchasePrice' field for changes
  const watchUsefulLife = watch('usefulLife'); // Watch the 'usefulLife' field for changes
  const watchQuantity = watch('quantity'); // Watch quantity for changes

  // Effect to compute and update derived fields based on input changes
  useEffect(() => {
    // Check if necessary input fields have values
    if (watchPurchasePrice && watchUsefulLife && watchQuantity) {
      // Parse values to numbers
      const purchasePrice = parseFloat(
        watchPurchasePrice.replace(/[^0-9.]/g, '')
      );
      const usefulLife = parseFloat(watchUsefulLife);
      const quantity = parseFloat(watchQuantity);

      // Ensure all parsed values are valid numbers and not zero (to avoid division by zero)
      if (
        !isNaN(purchasePrice) &&
        !isNaN(usefulLife) &&
        usefulLife > 0 &&
        !isNaN(quantity) &&
        quantity > 0
      ) {
        const gstPercentage = 0; // 10% GST/VAT

        // Calculate GST amount
        const gstAmount =
          (purchasePrice / (1 + gstPercentage / 100)) * (gstPercentage / 100);

        // Calculate GST/VAT for the given quantity
        const gstVat = gstAmount * quantity;

        // Calculate total depreciation
        const totalDepreciation = (purchasePrice - gstAmount) * quantity;

        // Calculate yearly depreciation
        const depreciationYearly = totalDepreciation / usefulLife;

        // Calculate monthly depreciation
        const depreciationMonthly = depreciationYearly / 12;

        // Calculate depreciation percentage
        const depreciationPercentage =
          (depreciationYearly / totalDepreciation) * 100;

        // Update form field values with formatted currency and percentage
        setValue(
          'gstVat',
          currencies[companyDetails.currency] +
            ' ' +
            gstVat.toLocaleString('en-US', {
              minimumFractionDigits: 2
            })
        );

        setValue(
          'totalDepreciation',
          currencies[companyDetails.currency] +
            ' ' +
            totalDepreciation.toLocaleString('en-US', {
              minimumFractionDigits: 2
            })
        );

        setValue(
          'depreciationYearly',
          currencies[companyDetails.currency] +
            ' ' +
            depreciationYearly.toLocaleString('en-US', {
              minimumFractionDigits: 2
            })
        );

        setValue(
          'depreciationMonthly',
          currencies[companyDetails.currency] +
            ' ' +
            depreciationMonthly.toLocaleString('en-US', {
              minimumFractionDigits: 2
            })
        );

        setValue(
          'depreciationPercentage',
          `${depreciationPercentage.toFixed(2)} %`
        );
      } else {
        // Handle cases where inputs are invalid
        setValue('gstVat', `${currencies[companyDetails.currency]} 0.00`);
        setValue(
          'totalDepreciation',
          `${currencies[companyDetails.currency]} 0.00`
        );
        setValue(
          'depreciationYearly',
          `${currencies[companyDetails.currency]} 0.00`
        );
        setValue(
          'depreciationMonthly',
          `${currencies[companyDetails.currency]} 0.00`
        );
        setValue('depreciationPercentage', `0.00 %`);
      }
    }
  }, [watchPurchasePrice, watchUsefulLife, watchQuantity]);

  // Handle click event for popover
  const handleClick = event => {
    setShow(!show); // Toggle popover visibility
    setTarget(event.target); // Set target element for popover positioning
  };

  // Form submit handler
  const onSubmitData = data => {
    data.quantity = data.quantity.toString();
    data.usefulLife = data.usefulLife.toString();

    const payload =
      isEditing && docId
        ? { id: docId, data }
        : { companyId: companyDetails.id, ...data };

    // API call to save or update data
    api
      .post(
        isEditing
          ? '/plant-and-equipment/update'
          : '/plant-and-equipment/create',
        payload
      )
      .then(res => {
        reset(); // Reset form fields on successful submission
        setEdit(''); // Clear edit mode
        setEditDetails({}); // Clear edit details
        setShowFormModal(false); // Hide form modal
        // Show notification to add more items
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Plant and Equipment" ?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document
                  .querySelector('input[name="itemDescription"]')
                  ?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        // Handle error scenarios with toast messages
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, { theme: 'colored' });
            return;
          }
          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, { theme: 'colored' });
      });
  };

  // Error handling function (not currently used in this implementation)
  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            {/* Page header with description and popover */}
            <PageHeader
              className="text-primary"
              title="Plant and Equipment"
              description="Plant and Equipment generally refers to the tangible assets that a business uses to produce goods or services. These assets are typically long-term assets that are essential for the operations of the business."
            />
            {/* Info button to show popover */}
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>
            {/* Popover content */}
            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="div" style={{ backgroundColor: '' }}>
                    {/* Detailed information about Plant and Equipment */}
                    <Row
                      as={motion.div}
                      className="g-3"
                      initial={{ x: '-20%' }}
                      animate={{ x: '0%' }}
                      exit={{
                        x: window.innerWidth,
                        transition: { duration: 0.5 }
                      }}
                    >
                      <PageHeader
                        className="text-primary"
                        title="Plant and Equipment"
                        description=""
                      />
                      <div
                        className="border-none rounded mt-2"
                        style={{ maxHeight: '280px', overflowY: 'auto' }}
                      >
                        {/* Explanation and characteristics of Plant and Equipment */}
                        <Row>
                          <h6 className="pt-2">What is Plant and Equipment?</h6>
                          <p>
                            "Plant and Equipment" generally refers to the
                            tangible assets that a business uses to produce
                            goods or services. These assets are typically
                            long-term assets that are essential for the
                            operations of the business. Here's a breakdown of
                            what "Plant and Equipment" typically includes:
                          </p>

                          <h6>1. Plant:</h6>
                          <p>
                            This term refers to machinery, equipment, or tools
                            that are used in the manufacturing process or for
                            providing services. It can include items such as
                            industrial machinery, manufacturing plants, assembly
                            lines, and other equipment necessary for production.
                          </p>

                          <h6>2. Equipment:</h6>
                          <p>
                            Equipment refers to any movable property (excluding
                            buildings) that is used in business operations. This
                            can encompass a wide range of items depending on the
                            industry, such as computers, vehicles, office
                            furniture, and specialized tools.
                          </p>

                          <h6>Key Characteristics:</h6>
                          <ul>
                            <li>
                              <strong>Long-Term Assets:</strong> Plant and
                              equipment are considered long-term assets because
                              they are expected to be used for more than one
                              accounting period.
                            </li>
                            <li>
                              <strong>Significant Cost:</strong> These assets
                              often involve substantial capital expenditure due
                              to their essential role in the business
                              operations.
                            </li>
                            <li>
                              <strong>Depreciation:</strong> Since plant and
                              equipment are subject to wear and tear over time,
                              they are depreciated over their useful life to
                              reflect their declining value in financial
                              statements.
                            </li>
                          </ul>

                          <h6>Importance:</h6>
                          <ul>
                            <li>
                              <strong>Production Efficiency:</strong> Plant and
                              equipment directly contribute to the efficiency
                              and capacity of production processes, thereby
                              impacting overall productivity and output quality.
                            </li>
                            <li>
                              <strong>Capital Investment:</strong> Businesses
                              often make significant investments in plant and
                              equipment to improve operational capabilities,
                              expand production capacity, or enhance service
                              delivery.
                            </li>
                            <li>
                              <strong>Financial Reporting:</strong> Proper
                              management and reporting of plant and equipment
                              are crucial for accurate financial statements and
                              compliance with accounting standards.
                            </li>
                          </ul>

                          <h6>Example:</h6>
                          <p>
                            In a manufacturing company, plant and equipment
                            would include machinery used on the factory floor
                            (like conveyor belts, assembly machines), tools used
                            in production (such as drills, saws), and even
                            vehicles used for transporting goods. In an office
                            setting, plant and equipment could include
                            computers, printers, office furniture, and other
                            tools necessary for daily operations.
                          </p>

                          <p>
                            In summary, "Plant and Equipment" encompasses the
                            tangible assets that businesses rely on for their
                            operational activities, reflecting their essential
                            role in production, service delivery, and overall
                            business efficiency.
                          </p>

                          <div className="border-dashed border-bottom my-3" />
                        </Row>
                      </div>
                    </Row>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
            {/* Form for capturing Plant and Equipment details */}
            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              <Row className="g-3 mb-0 mt-1">
                <Col lg={4}>
                  {/* Input for Item Description */}
                  <Form.Group controlId="itemDescription">
                    <FloatingLabel label="Item Description">
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="itemDescription"
                        {...register('itemDescription', {
                          required: 'This field is required.'
                        })}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors.itemDescription
                        }
                        isInvalid={!!errors.itemDescription}
                        setValue={setValue}
                        watch={watch}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.itemDescription?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {/* Currency input for Purchase Price */}
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    label="Purchase Price"
                    name="purchasePrice"
                    errors={errors}
                    formControlProps={{
                      ...register('purchasePrice', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  {/* Input for Useful Life */}
                  <Form.Group controlId="usefulLife">
                    <FloatingLabel label="Useful Life">
                      <Form.Control
                        type="number"
                        placeholder="Useful Life"
                        name="usefulLife"
                        {...register('usefulLife', {
                          required: 'This field is required.',
                          min: { value: 1, message: 'Minimum value is 1' },
                          valueAsNumber: true
                        })}
                        isValid={
                          Object.keys(errors).length > 0 && !errors.usefulLife
                        }
                        isInvalid={errors.usefulLife}
                        setValue={setValue}
                        watch={watch}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.usefulLife?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="pt-3 g-3">
                <Col lg={4}>
                  {/* Input for Quantity */}
                  <Form.Group controlId="quantity">
                    <FloatingLabel label="Quantity">
                      <Form.Control
                        type="number"
                        placeholder="Quantity"
                        name="quantity"
                        {...register('quantity', {
                          required: 'This field is required.',
                          min: { value: 1, message: 'Minimum value is 1' },
                          valueAsNumber: true
                        })}
                        isValid={
                          Object.keys(errors).length > 0 && !errors.quantity
                        }
                        isInvalid={errors.quantity}
                        setValue={setValue}
                        watch={watch}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.quantity?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {/* Display-only input for GST/VAT */}
                  <FloatingLabel label="GST/VAT">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="gstVat"
                      readOnly
                      {...register('gstVat')}
                      setValue={setValue}
                      watch={watch}
                      disabled={true}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={4}>
                  {/* Display-only input for Total Depreciation */}
                  <FloatingLabel label="Total Depreciation">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="totalDepreciation"
                      readOnly
                      {...register('totalDepreciation')}
                      setValue={setValue}
                      watch={watch}
                      disabled={true}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row className="pt-3 g-3">
                <Col lg={4}>
                  {/* Display-only input for Depreciation Yearly */}
                  <FloatingLabel label="Depreciation Yearly">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="depreciationYearly"
                      readOnly
                      {...register('depreciationYearly')}
                      setValue={setValue}
                      watch={watch}
                      disabled={true}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={4}>
                  {/* Display-only input for Depreciation Monthly */}
                  <FloatingLabel label="Depreciation Monthly">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="depreciationMonthly"
                      readOnly
                      {...register('depreciationMonthly')}
                      setValue={setValue}
                      watch={watch}
                      disabled={true}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={4}>
                  {/* Display-only input for Depreciation Percentage */}
                  <FloatingLabel label="Depreciation Percentage">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="depreciationPercentage"
                      readOnly
                      {...register('depreciationPercentage')}
                      setValue={setValue}
                      watch={watch}
                      disabled={true}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              {/* Separator line */}
              <div className="border-dashed border-bottom my-3" />

              {/* Save button */}
              <Row>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <Button variant="outline-success" size="sm" type="submit">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default PlantAndEquipmentForm;
