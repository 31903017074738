import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import api from 'utils/api';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import {
  CompanyWizardContext,
  CompaniesContext,
  SelectedCompanyContext,
  CompanyFormModalContext
} from 'context/Context';
import celebration from '../lottie/celebration.json';

const Success = ({ reset }) => {
  const { company, setStep, setCompany } = useContext(CompanyWizardContext);
  const { companies, setCompanies } = useContext(CompaniesContext);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );
  const { setShowFormModal } = useContext(CompanyFormModalContext);

  const emptyData = () => {
    setShowFormModal(false);
    setStep(1);
    setCompany({});
    reset();
  };

  useEffect(() => {
    const payload = { ...company };

    if (payload.avatar && payload.avatar.length > 0) {
      payload.avatar = payload.avatar[0];
    }

    if (!payload.avatar) {
      delete payload.avatar;
    }

    const handleSuccess = (res, newCompany) => {
      emptyData();
      const updatedCompanies = companies.filter(doc => doc.id !== company.id);
      setCompanies([...updatedCompanies, newCompany]);

      if (companyDetails.id === company.id) {
        setCompanyDetails(res.data);
      }

      toast.success('Company updated successfully', { theme: 'colored' });
    };

    const handleError = error => {
      console.log(error);
      if (error.code !== 'ERR_NETWORK') {
        if (error.response.status === 500) {
          toast.error('Server error', { theme: 'colored' });
          return;
        }
        if (error.response.status === 401 || error.response.status === 400) {
          toast.error(error.response.data.message || error.response.data, {
            theme: 'colored'
          });
          return;
        }
      }
      toast.error('Something went wrong!', { theme: 'colored' });
    };
    if (company.id && company.userId) {
      const updatePayload = { ...payload };
      delete updatePayload.userId;
      delete updatePayload.id;
      delete updatePayload.createdOn;
      delete updatePayload.isArchived;

      api
        .post('/companies/update', { id: company.id, data: updatePayload })
        .then(res => handleSuccess(res, { ...updatePayload, id: company.id }))
        .catch(handleError);

      return;
    }

    api
      .post('/companies/create', payload)
      .then(res => {
        setCompanies([...companies, res.data]);

        if (!companyDetails.id) {
          setCompanyDetails(res.data);
        }

        emptyData();
        toast.success('Company created successfully', { theme: 'colored' });
      })
      .catch(handleError);

    // Ensure the effect runs only when the company changes
  }, [company]);

  return (
    <div className="border-none rounded mt-4">
      <Row className="mb-3">
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop />
            </div>
          </div>
          <h4 className="mb-1">Your company is all set!</h4>
          <p className="fs-0">Now head over to owner's Funding</p>
          <IconButton
            color="warning"
            variant="outline-warning"
            size="lg"
            icon="fa-solid fa-arrow-left"
            className="mb-1 mx-1"
            iconAlign="right"
            transform="shrink-3"
            onClick={emptyData}
          />
          <IconButton
            type="submit"
            color="success"
            variant="outline-success"
            size="lg"
            icon="fa-solid fa-arrow-right"
            className="mb-1 mx-1"
            iconAlign="right"
            transform="shrink-3"
            as={Link}
            to="/ownerSettings"
          />
        </Col>
      </Row>
    </div>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
