import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  FloatingLabel,
  Modal,
  Tabs,
  Tab
} from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import IconButton from 'components/common/IconButton';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import editing from 'assets/img/icons/spot-illustrations/6.svg';

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-3 mb-3">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const PropertyForm = () => {
  const [totalCost, setTotalCost] = useState('');
  const [profitMargin, setProfitMargin] = useState('');
  const [profitMarginPercentage, setProfitMarginPercentage] = useState('');
  const [activeTab, setActiveTab] = useState('purchasePrice');
  const [docId, setDocId] = useState(null);

  const { companyDetails } = useContext(SelectedCompanyContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { edit, editDetails, setEdit, setEditDetails } =
    useContext(RevenueWizardContext);

  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const defaultValues = {
    purchasePrice: '',
    kitchenTiling: '',
    carpentry: '',
    plumbing: '',
    architectDesigning: '',
    legalFees: '',
    propertyTaxes: '',
    insurance: '',
    marketingSalesCosts: '',
    financeCosts: '',
    utilities: '',
    contingencyFund: '',
    permitLicensingFees: '',
    sitePreparationCosts: '',
    professionalFees: '',
    sellingPrice: ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({
    defaultValues: defaultValues
  });

  useEffect(() => {
    calculateProjections();
  }, [
    watch('purchasePrice'),
    watch('kitchenTiling'),
    watch('carpentry'),
    watch('plumbing'),
    watch('architectDesigning'),
    watch('legalFees'),
    watch('propertyTaxes'),
    watch('insurance'),
    watch('marketingSalesCosts'),
    watch('financeCosts'),
    watch('utilities'),
    watch('contingencyFund'),
    watch('permitLicensingFees'),
    watch('sitePreparationCosts'),
    watch('professionalFees'),
    watch('sellingPrice')
  ]);

  useEffect(() => {
    if (edit === 'property' && editDetails.id) {
      Object.keys(defaultValues).forEach(field =>
        setValue(field, editDetails[field] || '')
      );
      setDocId(editDetails.id);
      setIsEditing(true);
      setTotalCost(editDetails.totalCost);
      setProfitMargin(editDetails.profitMargin);
      setProfitMarginPercentage(editDetails.profitMarginPercentage);
      calculateProjections();
    }
  }, [edit, editDetails, setValue]);

  const calculateProjections = () => {
    const getValue = field =>
      parseFloat((watch(field) || '').replace(/[^0-9.]/g, '')) || 0;

    const parsedValues = {
      purchasePrice: getValue('purchasePrice'),
      kitchenTiling: getValue('kitchenTiling'),
      carpentry: getValue('carpentry'),
      plumbing: getValue('plumbing'),
      architectDesigning: getValue('architectDesigning'),
      legalFees: getValue('legalFees'),
      propertyTaxes: getValue('propertyTaxes'),
      insurance: getValue('insurance'),
      marketingSalesCosts: getValue('marketingSalesCosts'),
      financeCosts: getValue('financeCosts'),
      utilities: getValue('utilities'),
      contingencyFund: getValue('contingencyFund'),
      permitLicensingFees: getValue('permitLicensingFees'),
      sitePreparationCosts: getValue('sitePreparationCosts'),
      professionalFees: getValue('professionalFees'),
      sellingPrice: getValue('sellingPrice')
    };

    const totalRenovationCost =
      parsedValues.kitchenTiling +
      parsedValues.carpentry +
      parsedValues.plumbing +
      parsedValues.architectDesigning;

    const totalCost =
      parsedValues.purchasePrice +
      totalRenovationCost +
      parsedValues.legalFees +
      parsedValues.propertyTaxes +
      parsedValues.insurance +
      parsedValues.marketingSalesCosts +
      parsedValues.financeCosts +
      parsedValues.utilities +
      parsedValues.contingencyFund +
      parsedValues.permitLicensingFees +
      parsedValues.sitePreparationCosts +
      parsedValues.professionalFees;
    setTotalCost(
      totalCost.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const profit = parsedValues.sellingPrice - totalCost;
    setProfitMargin(
      profit.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const profitMarginPercentage =
      totalCost > 0
        ? ((profit / parsedValues.sellingPrice) * 100).toFixed(2) + '%'
        : '0%';
    setProfitMarginPercentage(profitMarginPercentage);
  };

  const openLoanForm = () => {
    navigate('/ownerSettings', { state: { showModal: true, step: 1 } });
  };

  const [showNotif, setShowNotif] = useState(false);

  const onSubmitData = data => {
    data.totalCost = totalCost;
    data.profitMargin = profitMargin;
    data.profitMarginPercentage = profitMarginPercentage;
    data.bankBalance = companyDetails.bankBalance;

    const payload =
      isEditing && docId
        ? { id: docId, data }
        : { companyId: companyDetails.id, ...data };

    api
      .post(
        isEditing ? '/property-revenue/update' : 'property-revenue/create',
        payload
      )
      .then(res => {
        reset();
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Property Expenses"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="purchasePrice"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, { theme: 'colored' });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            if (error.response.data.fundsError) {
              setShowNotif(true);
            } else {
              toast.error(error.response.data.message || error.response.data, {
                theme: 'colored'
              });
            }
            return;
          }
        }
        toast.error(`Something went wrong!`, { theme: 'colored' });
      });
  };

  const generatePDF = () => {
    // PDF generation logic remains unchanged
  };

  const getTabDescription = tabKey => {
    switch (tabKey) {
      case 'purchasePrice':
        return 'Enter the purchase price and renovation costs for the property.';
      case 'additionalCosts':
        return 'Fill in additional costs including legal fees, taxes, insurance, and other expenses.';
      case 'summary':
        return 'Review the summary of costs, profit margin, and projected profit percentage.';
      default:
        return '';
    }
  };

  // Tab navigation logic
  const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];

  const goToNextTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  return (
    <div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Form onSubmit={handleSubmit(onSubmitData)}>
          <PageHeader
            title={
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold'
                }}
              >
                {
                  {
                    purchasePrice: 'Purchase Price and Renovation Costs',
                    additionalCosts: 'Additional Costs',
                    summary: 'Summary'
                  }[activeTab]
                }
              </span>
            }
            description={
              <span
                style={{
                  fontSize: '16px',
                  color: '#6c757d'
                }}
              >
                {getTabDescription(activeTab)}
              </span>
            }
          />

          <Tabs
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            id="property-form-tabs"
            className="mb-3"
          >
            {/* Tab 1: Purchase Price and Renovation Costs */}
            <Tab
              eventKey="purchasePrice"
              title="Purchase Price & Renovation Costs"
            >
              <Row className="pt-3">
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="purchasePrice"
                    label="Purchase Price"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('purchasePrice')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="kitchenTiling"
                    label="Kitchen Tiling"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('kitchenTiling')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="carpentry"
                    label="Carpentry"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('carpentry')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>

              <Row className="pt-3">
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="plumbing"
                    label="Plumbing"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('plumbing')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="architectDesigning"
                    label="Architect Designing"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('architectDesigning')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="legalFees"
                    label="Legal Fees"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('legalFees')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>
            </Tab>

            {/* Tab 2: Additional Costs */}
            <Tab eventKey="additionalCosts" title="Additional Costs">
              <Row className="pt-3">
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="propertyTaxes"
                    label="Property Taxes"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('propertyTaxes')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="insurance"
                    label="Insurance"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('insurance')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="marketingSalesCosts"
                    label="Marketing & Sales Costs"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('marketingSalesCosts')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>

              <Row className="pt-3">
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="financeCosts"
                    label="Finance Costs"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('financeCosts')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="utilities"
                    label="Utilities"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('utilities')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="contingencyFund"
                    label="Contingency Fund"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('contingencyFund')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>
              <Row className="pt-3">
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="permitLicensingFees"
                    label="Permit & Licensing Fees"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('permitLicensingFees')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="sitePreparationCosts"
                    label="Site Preparation Costs"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('sitePreparationCosts')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="professionalFees"
                    label="Professional Fees"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('professionalFees')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>
            </Tab>

            {/* Tab 3: Summary */}
            <Tab eventKey="summary" title="Summary">
              <Row className="pt-3">
                <Col lg={6}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="sellingPrice"
                    label="Selling Price"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('sellingPrice')
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={6}>
                  <Form.Group controlId="profitMargin">
                    <FloatingLabel label="Profit Margin">
                      <Form.Control
                        type="text"
                        placeholder="Profit Margin"
                        name="profitMargin"
                        value={profitMargin}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="pt-3">
                <Col lg={6}>
                  <Form.Group controlId="totalCost">
                    <FloatingLabel label="Total Cost">
                      <Form.Control
                        type="text"
                        placeholder="Total Cost"
                        name="totalCost"
                        value={totalCost}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group controlId="profitMarginPercentage">
                    <FloatingLabel label="Profit Margin Percentage">
                      <Form.Control
                        type="text"
                        placeholder="Profit Margin Percentage"
                        name="profitMarginPercentage"
                        value={profitMarginPercentage}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="pt-3"></Row>
            </Tab>
          </Tabs>
          <div className="border-dashed border-bottom my-3" />
          <Row className=" mb-3">
            <Col className="text-center mt-3">
              <IconButton
                variant="outline-primary"
                size="sm"
                onClick={goToPreviousTab}
                disabled={activeTab === 'purchasePrice'}
              >
                <FaArrowLeft /> Previous
              </IconButton>
              <IconButton
                variant="outline-success"
                size="sm"
                onClick={goToNextTab}
                disabled={activeTab === 'summary'}
                className="ms-2"
              >
                Next <FaArrowRight />
              </IconButton>
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col className="text-center">
              <IconButton type="submit" size="sm" variant="outline-success">
                Submit
              </IconButton>

              <IconButton
                type="button"
                size="sm"
                variant="outline-secondary"
                className="ms-2"
                onClick={() => reset()}
              >
                Reset
              </IconButton>
            </Col>
          </Row>
        </Form>

        <Modal show={showNotif} onHide={() => setShowNotif(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'red' }}>
              Ooops! Not Enough Funds
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="align-items-center justify-content-center pt-6">
              <Col xs={12} md={6} className="text-center">
                <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
                  <img
                    src={editing}
                    className="img-fluid"
                    alt="Editing"
                    style={{ maxWidth: '60%', height: 'auto' }}
                  />
                </div>
              </Col>
              <p className="pt-3">
                You don't have sufficient funds to purchase this property, you
                need to:
                <ul className="mt-2">
                  <li>Ask the owners to contribute more funds</li>
                  <li>Ask the investors to contribute more funds</li>
                  <li>Apply for a bank loan</li>
                </ul>
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              variant="outline-success"
              className="mb-1 mx-1"
              onClick={openLoanForm}
            >
              Back to Funding
            </IconButton>
            <IconButton
              variant="outline-primary"
              onClick={() => setShowNotif(false)}
            >
              Close
            </IconButton>
          </Modal.Footer>
        </Modal>
      </motion.div>
    </div>
  );
};

export default PropertyForm;
