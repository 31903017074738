import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import { toast } from 'react-toastify';

export const currencies = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  JPY: '¥',
  INR: '₹'
};

export function splitImage(imageData, segmentHeight, backgroundColor) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const image = new Image();
    image.src = imageData;

    image.onload = () => {
      const segments = [];
      const imgHeight = image.height;
      const imgWidth = image.width;
      console.log(imgHeight);
      const numSegments = Math.ceil(imgHeight / segmentHeight);
      console.log('segment height: ', segmentHeight);

      // Set canvas size to image width and segment height
      canvas.width = imgWidth;
      canvas.height = segmentHeight;

      for (let i = 0; i < numSegments; i++) {
        const y = i * segmentHeight;
        const currentHeight = segmentHeight;

        // Clear the canvas and fill it with a white background
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Draw the current segment of the image onto the canvas
        context.drawImage(
          image,
          0,
          y,
          imgWidth,
          currentHeight,
          0,
          0,
          imgWidth,
          currentHeight
        );

        // Convert the canvas to a data URL (base64 encoded image)
        const segmentDataUrl = canvas.toDataURL('image/jpeg');
        segments.push(segmentDataUrl);
      }

      // Remove the canvas from the DOM to prevent memory leaks
      canvas.remove();

      resolve(segments);
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
}

export const formatAmount = (amount, currency) => {
  return `${currencies[currency]} ${amount.toLocaleString('en-US', {
    minimumFractionDigits: 2
  })}`;
};

export const parseCompany = company => {
  return {
    id: company.id,
    logo: company.avatar?.base64 || company.avatar,
    degree: company.industry,
    duration: 'Plan Start - 2023',
    location: company.address,
    institution: company.name,
    verified: true
  };
};

export const addMoreNotification = component => {
  toast.info(component, {
    pauseOnHover: true,
    autoClose: 5000,
    theme: 'colored'
  });
};

export const capitalizeWords = str => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const parseCurrency = value => {
  return parseFloat(value.replace(/[^0-9.-]+/g, ''));
};
