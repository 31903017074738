import React, { useContext, useEffect, useState } from 'react';
import { Container, Card, Table, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';
import PieChart from 'components/custom/lists/stage2/InvestorList/PieChart';
import { SelectedCompanyContext } from 'context/Context';

function InvestorsList({ close }) {
  // State to store the list of investors
  const [investors, setInvestors] = useState([]);
  // Context to access the details of the selected company
  const { companyDetails } = useContext(SelectedCompanyContext);
  // State to store the total contributions from all investors
  const [totalContributions, setTotalContributions] = useState(0);

  // Fetch investors data when companyDetails changes
  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/investors/getAll?companyId=${companyDetails.id}`)
        .then(response => setInvestors(response.data)) // Update state with fetched data
        .catch(error => toast.error(error.message)); // Display error if API call fails
      return;
    }
    toast.error('No company selected, you must setup a new company to start.'); // Error message if no company is selected
  }, [companyDetails]);

  // Calculate total contributions from investors
  useEffect(() => {
    setTotalContributions(
      investors.reduce(
        (acc, investor) =>
          acc +
          parseFloat(investor.investorContribution.replace(/[^0-9.-]+/g, '')), // Extract numeric value from contribution string
        0
      )
    );
  }, [investors]);

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Row className="align-items-left justify-content-left pt-0">
            {/* Column for displaying PieChart */}
            <Col xs={12} md={5} className="text-center">
              <div>
                <div style={{ width: '100%', height: '400px' }}>
                  {investors.length > 0 ? (
                    <PieChart investors={investors} />
                  ) : (
                    <p>No investors data available.</p>
                  )}
                </div>
              </div>
            </Col>

            {/* Column for displaying investors table */}
            <Col xs={12} md={7} className="text-left">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Contribution</th>
                    <th scope="col">Percentage Equity</th>
                    <th className="text-end" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Map through investors and render each Item component */}
                  {investors.map(investor => (
                    <Item
                      key={investor.id}
                      details={investor}
                      investors={investors}
                      setInvestors={setInvestors}
                      close={close}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="100%"
                      className="text-left text-success"
                      style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
                    >
                      <span className="fw-bold">
                        Total Contributions:{' '}
                        {`${
                          currencies[companyDetails.currency]
                        } ${totalContributions.toLocaleString('en-US')}`}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

// Define PropTypes to ensure correct props are passed
InvestorsList.propTypes = {
  close: PropTypes.func // Function to close the component, if provided
};

export default InvestorsList;
