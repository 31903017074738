import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Modal, Card, Form, Dropdown, Button, Row, Col } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip
} from 'victory';

import { SelectedCompanyContext } from 'context/Context';

// Constants
const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const YEAR_NAMES = ['Year 1', 'Year 2', 'Year 3'];

// Utility functions
const formatNumber = num => (isNaN(num) ? '' : num.toLocaleString());
const parseNumber = str => parseInt(str.replace(/,/g, ''), 10) || 0;

const SaleInputChart = ({ onClose, onSave, show, unitsData }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);
  // State variables
  const [data, setData] = useState([]);
  const [salesInputs, setSalesInputs] = useState(Array(12).fill(''));
  const [purchaseInputs, setPurchaseInputs] = useState(Array(12).fill(''));
  const [openingStock, setOpeningStock] = useState(Array(12).fill(''));
  const [closingStock, setClosingStock] = useState(Array(12).fill(''));
  const [yearData, setYearData] = useState({
    sales: Array(3).fill(''),
    purchases: Array(3).fill(''),
    openingStock: Array(3).fill(''),
    closingStock: Array(3).fill('')
  });
  const [viewMode, setViewMode] = useState('Monthly');

  // Effect to initialize data
  useEffect(() => {
    if (unitsData) {
      setData(unitsData);
      const initialSalesInputs = unitsData.map(item => formatNumber(item.y));
      const initialPurchaseInputs = unitsData.map(item =>
        formatNumber(item.purchases || 0)
      );
      const initialOpeningStock = unitsData.map(item =>
        formatNumber(item.openingStock || 0)
      );

      setSalesInputs(initialSalesInputs);
      setPurchaseInputs(initialPurchaseInputs);
      calculateStocks(
        initialOpeningStock,
        initialSalesInputs,
        initialPurchaseInputs
      );
    }
  }, [unitsData]);

  // Calculate stocks
  const calculateStocks = useCallback(
    (initialOpeningStock, sales, purchases) => {
      let openingStockArray = [...initialOpeningStock];
      let closingStockArray = [];

      for (let i = 0; i < 12; i++) {
        const salesAmount = parseNumber(sales[i]);
        const purchaseAmount = parseNumber(purchases[i]);
        const opening = parseNumber(openingStockArray[i]);
        const closing = opening + purchaseAmount - salesAmount;

        closingStockArray.push(formatNumber(closing));
        if (i < 11) {
          openingStockArray[i + 1] = formatNumber(closing);
        }
      }

      setOpeningStock(openingStockArray);
      setClosingStock(closingStockArray);
      calculateYearlyData(
        sales,
        purchases,
        openingStockArray,
        closingStockArray
      );
    },
    []
  );

  // Calculate yearly data
  const calculateYearlyData = useCallback(
    (monthlySales, monthlyPurchases, openingStock, closingStock) => {
      const totalSales = monthlySales.reduce(
        (acc, curr) => acc + parseNumber(curr),
        0
      );
      const totalPurchases = monthlyPurchases.reduce(
        (acc, curr) => acc + parseNumber(curr),
        0
      );
      const totalOpeningStock = parseNumber(openingStock[0]);
      const totalClosingStock = parseNumber(closingStock[11]);

      const yearlySales = [];
      let increasedSales = totalSales;
      const yearlyPurchases = [];
      let increasedPurchases = totalPurchases;
      for (let i = 0; i < 3; i++) {
        yearlySales.push(formatNumber(increasedSales));
        increasedSales += Math.round(
          increasedSales * (parseFloat(companyDetails.salesIncrease) / 100)
        );
        yearlyPurchases.push(formatNumber(increasedPurchases));
        increasedPurchases += Math.round(
          increasedPurchases * (parseFloat(companyDetails.salesIncrease) / 100)
        );
      }

      const yearlyOpeningStock = Array(3).fill(formatNumber(totalOpeningStock));
      const yearlyClosingStock = Array(3).fill(formatNumber(totalClosingStock));

      setYearData({
        sales: yearlySales,
        purchases: yearlyPurchases,
        openingStock: yearlyOpeningStock,
        closingStock: yearlyClosingStock
      });
    },
    [companyDetails.salesIncrease]
  );

  // Handle input changes
  const handleInputChange = useCallback(
    (index, value, type) => {
      const parsedValue = parseNumber(value);
      if (!isNaN(parsedValue)) {
        if (type === 'sales') {
          const newSalesInputs = [...salesInputs];
          newSalesInputs[index] = formatNumber(parsedValue);
          setSalesInputs(newSalesInputs);
          setData(
            data.map((item, i) =>
              i === index ? { ...item, y: parsedValue } : item
            )
          );
          calculateStocks(openingStock, newSalesInputs, purchaseInputs);
        } else if (type === 'purchase') {
          const newPurchaseInputs = [...purchaseInputs];
          newPurchaseInputs[index] = formatNumber(parsedValue);
          setPurchaseInputs(newPurchaseInputs);
          setData(
            data.map((item, i) =>
              i === index ? { ...item, purchases: parsedValue } : item
            )
          );
          calculateStocks(openingStock, salesInputs, newPurchaseInputs);
        }
      }
    },
    [salesInputs, purchaseInputs, data, openingStock, calculateStocks]
  );

  // Handle opening stock change
  const handleOpeningStockChange = useCallback(
    value => {
      const parsedValue = formatNumber(parseNumber(value));
      const newOpeningStock = [...openingStock];
      newOpeningStock[0] = parsedValue;
      setOpeningStock(newOpeningStock);
      calculateStocks(newOpeningStock, salesInputs, purchaseInputs);
    },
    [openingStock, salesInputs, purchaseInputs, calculateStocks]
  );

  // Handle save
  const handleSave = useCallback(() => {
    const average = data.reduce((acc, item) => acc + item.y, 0) / data.length;
    const updatedData = data.map((obj, index) => {
      return {
        ...obj,
        openingStock: parseNumber(openingStock[index]),
        closingStock: parseNumber(closingStock[index]),
        purchases: parseNumber(purchaseInputs[index])
      };
    });
    console.log('updated', updatedData);
    onSave(Math.round(average).toString(), updatedData, yearData);
    onClose();
  }, [
    data,
    onSave,
    onClose,
    openingStock,
    closingStock,
    purchaseInputs,
    yearData
  ]);

  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#007bff' }}>
          Monthly Unit Sales
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end mb-2">
          <Dropdown className="float-end">
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              {viewMode}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setViewMode('Monthly')}>
                Monthly
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setViewMode('Yearly')}>
                Yearly
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setViewMode('Graph')}>
                Graph
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <p className="p-3">
          Monthly unit sales may fluctuate due to seasonal variations, such as
          higher sales during Christmas or Mother's Day. Analyze these changes
          to understand how they affect your overall revenue and profitability.
        </p>
        {viewMode === 'Graph' && (
          <Card className="mb-4" style={{ height: '500px' }}>
            <Card.Header>
              <h5 className="mb-0">Sales Graph</h5>
            </Card.Header>
            <Card.Body>
              <VictoryChart
                domainPadding={20}
                width={1200}
                height={400}
                domain={{ y: [0, Math.max(...data.map(d => d.y)) * 1.1] }}
              >
                <VictoryAxis
                  tickValues={data.map(d => d.x)}
                  tickFormat={MONTH_NAMES}
                  style={{
                    axis: { stroke: '#FFFFFF' },
                    ticks: { stroke: '#FFFFFF' },
                    tickLabels: { fill: '#FFFFFF' }
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={tick => `$${tick}`}
                  style={{
                    axis: { stroke: '#FFFFFF' },
                    ticks: { stroke: '#FFFFFF' },
                    tickLabels: { fill: '#FFFFFF' }
                  }}
                />
                <VictoryArea
                  data={data}
                  x="x"
                  y="y"
                  style={{ data: { fill: '#4CAF50', opacity: 0.4 } }}
                />
                <VictoryLine
                  data={data}
                  x="x"
                  y="y"
                  style={{ data: { stroke: '#4CAF50' } }}
                />
                <VictoryScatter
                  data={data}
                  x="x"
                  y="y"
                  size={5}
                  style={{ data: { fill: '#4CAF50' } }}
                  labels={({ datum }) => formatNumber(datum.y)}
                  labelComponent={<VictoryTooltip />}
                />
              </VictoryChart>
            </Card.Body>
          </Card>
        )}
        {viewMode === 'Monthly' && (
          <Row>
            <Col md={12}>
              <Card className="mb-4" style={{ height: '500px' }}>
                <Card.Header>
                  <h5 className="mb-0">Monthly Sales and Purchases</h5>
                </Card.Header>
                <Card.Body>
                  <div style={{ overflowX: 'auto' }}>
                    <table
                      className="table table-bordered"
                      style={{ width: '100%' }}
                    >
                      <thead>
                        <tr>
                          <th>Description</th>
                          {MONTH_NAMES.map((month, index) => (
                            <th key={index} style={{ minWidth: '120px' }}>
                              {month}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Opening Stock</strong>
                          </td>
                          {openingStock.map((input, index) => (
                            <td key={index}>
                              {index === 0 ? (
                                <Form.Control
                                  type="text"
                                  placeholder={`0 ${MONTH_NAMES[index]}`}
                                  value={input}
                                  onChange={e =>
                                    handleOpeningStockChange(e.target.value)
                                  }
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'rgba(0, 123, 255, 0.1)'
                                  }}
                                  aria-label={`Opening stock for ${MONTH_NAMES[index]}`}
                                />
                              ) : (
                                input
                              )}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <strong>Sales</strong>
                          </td>
                          {salesInputs.map((input, index) => (
                            <td key={index}>
                              <Form.Control
                                type="text"
                                placeholder={`0 ${MONTH_NAMES[index]}`}
                                value={input}
                                onChange={e =>
                                  handleInputChange(
                                    index,
                                    e.target.value,
                                    'sales'
                                  )
                                }
                                style={{
                                  width: '100%',
                                  backgroundColor: 'rgba(0, 123, 255, 0.1)'
                                }}
                                aria-label={`Sales for ${MONTH_NAMES[index]}`}
                              />
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <strong>Purchases</strong>
                          </td>
                          {purchaseInputs.map((input, index) => (
                            <td key={index}>
                              <Form.Control
                                type="text"
                                placeholder={`0 ${MONTH_NAMES[index]}`}
                                value={input}
                                onChange={e =>
                                  handleInputChange(
                                    index,
                                    e.target.value,
                                    'purchase'
                                  )
                                }
                                style={{
                                  width: '100%',
                                  backgroundColor: 'rgba(0, 123, 255, 0.1)'
                                }}
                                aria-label={`Purchases for ${MONTH_NAMES[index]}`}
                              />
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <strong>Closing Stock</strong>
                          </td>
                          {closingStock.map((stock, index) => (
                            <td key={index}>{stock}</td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {viewMode === 'Yearly' && (
          <Row>
            <Col md={12}>
              <Card className="mb-4" style={{ height: '500px' }}>
                <Card.Header>
                  <h5 className="mb-0">Yearly Sales and Purchases</h5>
                </Card.Header>
                <Card.Body>
                  <div style={{ overflowX: 'auto' }}>
                    <table
                      className="table table-bordered"
                      style={{ width: '100%' }}
                    >
                      <thead>
                        <tr>
                          <th>Description</th>
                          {YEAR_NAMES.map((year, index) => (
                            <th key={index}>{year}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Sales</strong>
                          </td>
                          {yearData.sales.map((data, index) => (
                            <td key={index}>{data}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <strong>Purchases</strong>
                          </td>
                          {yearData.purchases.map((data, index) => (
                            <td key={index}>{data}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <strong>Opening Stock</strong>
                          </td>
                          {yearData.openingStock.map((data, index) => (
                            <td key={index}>{data}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <strong>Closing Stock</strong>
                          </td>
                          {yearData.closingStock.map((data, index) => (
                            <td key={index}>{data}</td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <IconButton onClick={onClose} variant="outline-primary" size="lg">
          Close
        </IconButton>
        <IconButton variant="outline-success" size="lg" onClick={handleSave}>
          Save
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

export default SaleInputChart;
