import React, { useContext } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { currencies } from 'utils';
import { SelectedCompanyContext } from 'context/Context';
import Header from './Header';

const CashFlow = ({ reportData, isPdf }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <Container id="cash-flow">
      <Header />
      <h4 className="text-primary p-0">Cash Flow</h4>

      <Row>
        <Col>
          <div id="pdf-content" style={{ paddingTop: '20px' }}>
            <Table
              responsive
              className="styled-table"
              style={{
                color: `${isPdf ? 'black' : 'white'}`,
                marginBottom: '0',
                borderCollapse: 'collapse',
                width: '100%',
                tableLayout: 'fixed'
              }}
            >
              <thead>
                <tr>
                  <th colSpan={2}>Cash Flow Statement</th>
                  <th>Year 1</th>
                  <th>Year 2</th>
                  <th>Year 3</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, index) => {
                  const lastThreeStartIndex = reportData.length - 3;

                  return (
                    <React.Fragment key={index}>
                      {index === lastThreeStartIndex && (
                        <>
                          <tr key="spacer">
                            <td colSpan={5} style={{ height: '100px' }}></td>
                          </tr>
                          <tr key="heading">
                            <td colSpan={2}>
                              <strong>BAS (Business Activity Statement)</strong>
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td colSpan={2}>{item.label}</td>

                        <td style={{ color: item.year1 < 0 ? 'red' : 'black' }}>
                          {currencies[companyDetails.currency]}{' '}
                          {item.year1.toLocaleString()}
                        </td>
                        <td style={{ color: item.year2 < 0 ? 'red' : 'black' }}>
                          {currencies[companyDetails.currency]}{' '}
                          {item.year2.toLocaleString()}
                        </td>
                        <td style={{ color: item.year3 < 0 ? 'red' : 'black' }}>
                          {currencies[companyDetails.currency]}{' '}
                          {item.year3.toLocaleString()}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CashFlow;
