import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const blueShades = [
  '#0033A0',
  '#0066CC',
  '#3399FF',
  '#66B3FF',
  '#99CCFF',
  '#CCE5FF',
  '#E6F2FF'
];

const explodePlugin = {
  id: 'explodePlugin',
  beforeDraw(chart) {
    const { ctx, data, config } = chart;
    const { datasetIndex, index } =
      chart.getElementsAtEventForMode(
        {},
        'nearest',
        { intersect: true },
        false
      )[0] || {};

    if (datasetIndex !== undefined && index !== undefined) {
      const dataset = data.datasets[datasetIndex];
      const model = chart.getDatasetMeta(datasetIndex).data[index]._model;

      // Apply the offset to the selected slice
      chart.getDatasetMeta(datasetIndex).data.forEach((arc, i) => {
        if (i === index) {
          arc._model.innerRadius = arc._model.innerRadius - 10;
          arc._model.outerRadius = arc._model.outerRadius + 10;
        } else {
          arc._model.innerRadius = arc._model.innerRadius;
          arc._model.outerRadius = arc._model.outerRadius;
        }
      });

      chart.update();
    }
  }
};

const PieChart = ({ owners }) => {
  const colors = [...blueShades];
  while (colors.length < owners.length) {
    colors.push(...blueShades); // Repeat colors if necessary
  }

  // Safeguard against invalid data
  const contributions = owners.map(owner => {
    const value = parseFloat(owner.ownerContribution.slice(1));
    return isNaN(value) ? 0 : value;
  });

  const data = {
    labels: owners.map(
      owner => `${owner.ownerFirstName} ${owner.ownerLastName}`
    ),
    datasets: [
      {
        label: 'Owner Contributions',
        data: contributions,
        backgroundColor: colors.slice(0, owners.length),
        borderColor: '#fff',
        borderWidth: 1
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#333'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || '';
            return `${label}: $${value.toFixed(2)}`; // Format value
          }
        }
      },
      explodePlugin // Add the custom plugin
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        bottom: 10
      }
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '400px',
        backgroundColor: '#f5f5f5',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Pie
        data={data}
        options={options}
        aria-label="Owner Contributions Pie Chart"
      />
    </div>
  );
};

PieChart.propTypes = {
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      ownerFirstName: PropTypes.string.isRequired,
      ownerLastName: PropTypes.string.isRequired,
      ownerContribution: PropTypes.string.isRequired
    })
  ).isRequired
};

export default PieChart;
