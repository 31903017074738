import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Offcanvas, Button, Row, ButtonGroup, Form } from 'react-bootstrap';
import defaultModeImg from 'assets/img/generic/falcon-mode-default.jpg';
import darkModeImg from 'assets/img/generic/falcon-mode-dark.jpg';
import invertedImg from 'assets/img/generic/inverted.png';
import cardImg from 'assets/img/generic/card.png';
import vibrantImg from 'assets/img/generic/vibrant.png';
import transparentImg from 'assets/img/generic/default.png';
import settings from 'assets/img/icons/spot-illustrations/settings.png';
import dashboard from 'assets/img/icons/spot-illustrations/21.png';
import pitch from 'assets/img/icons/spot-illustrations/6.svg';
import stage1 from 'assets/img/icons/spot-illustrations/5.png';
import stage2 from 'assets/img/icons/spot-illustrations/4.png';
import stage3 from 'assets/img/icons/spot-illustrations/1.svg';
import stage4 from 'assets/img/icons/spot-illustrations/6.svg';
import stage5 from 'assets/img/icons/spot-illustrations/4.svg';
import stage6 from 'assets/img/icons/spot-illustrations/4.svg';
import stage7 from 'assets/img/icons/spot-illustrations/21.png';

import AppContext from 'context/Context';
import RadioItem from './RadioItem';
import IconButton from 'components/common/IconButton';
import { Link, BrowserRouter, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

//import Flex from 'components/common/Flex';
//import Background from 'components/common/Background';
//import Dashboard from 'components/dashboards/default';
//import leftArrowFromLeft from 'assets/img/icons/left-arrow-from-left.svg';
//import SoftBadge from 'components/common/SoftBadge';
//import arrowsH from 'assets/img/icons/arrows-h.svg';
//import paragraph from 'assets/img/icons/paragraph.svg';

const SettingsPanel = () => {
  const {
    config: {
      isFluid,
      isRTL,
      isDark,
      navbarPosition,
      navbarStyle,
      showSettingPanel,
      disabledNavbarPosition
    },
    setConfig,
    configDispatch
  } = useContext(AppContext);

  const [navbars] = useState([
    {
      name: 'transparent',
      image: transparentImg
    },
    {
      name: 'inverted',
      image: invertedImg
    },
    {
      name: 'card',
      image: cardImg
    },
    {
      name: 'vibrant',
      image: vibrantImg
    }
  ]);

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Offcanvas
        show={showSettingPanel}
        onHide={() => setConfig('showSettingPanel', false)}
        placement="end"
        style={{ maxWidth: '22rem' }}
        className="border-0"
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          className="bg-shape settings-panel-header mb-3"
        >
          <Offcanvas.Title as="div" className="py-1 z-index-1 light">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h5 className="text-white">
                <FontAwesomeIcon icon="wrench" className="me-2 fs-0" />
                Main Menu
              </h5>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>

        {/* 
        
        
         <p className="mb-0 fs--1 text-white opacity-75">
              Select a stage to work on
            </p>
        
        
        <ScrollBarCustom>
          <div className="text-center mt-5">
            <img src={settings} alt="settings" width={60} className="mb-4" />
            <h5>Need Help?</h5>
            <Link
              to="/help-center"
              className="text-decoration-none"
              onClick={() => setConfig('showSettingPanel', false)}
            >
              <IconButton
                variant="outline-primary"
                size="sm"
                icon="fa-solid fa-arrow-left"
                className="mb-1"
                iconAlign="right"
                transform="shrink-3"
              ></IconButton>     
            </Link>
          </div>
          <div className="border-dashed border-bottom my-3" />
         */}
        <Offcanvas.Body className="scrollbar">
          <Row>
            <div className="text-center mt-3">
              <img
                src={dashboard}
                alt="settings"
                width={100}
                className="mb-4"
              />
              <h5>Dashboard</h5>
              <Link
                to="/reports"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign=""
                  transform="shrink-3"
                >
                  {' '}
                  Dashboard
                </IconButton>
              </Link>
            </div>
            <div className="border-dashed border-bottom my-3" />

            <div className="text-center mt-3">
              <img src={stage1} alt="settings" width={100} className="mb-2" />
              <h5 className="mb-2">Company Setup</h5>
              <Link
                to="/process"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>

            <div className="text-center mt-3">
              <img src={pitch} alt="settings" width={100} className="mb-2" />
              <h5 className="mb-2">The Pitch</h5>
              <Link
                to="/buzPitchSettings"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>

            <div className="border-dashed border-bottom my-3" />
            <div className="text-center mt-3">
              <img src={stage2} alt="settings" width={100} className="mb-2" />
              <h5 className="mb-2">Business Funding</h5>
              <Link
                to="/ownerSettings"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>

            <div className="border-dashed border-bottom my-3" />
            <div className="text-center mt-3">
              <img src={stage3} alt="settings" width={100} className="mb-2" />
              <h5 className="mb-2">Startup Costs</h5>
              <Link
                to="/StartupCostsSettings"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>

            <div className="border-dashed border-bottom my-3" />
            <div className="text-center mt-3">
              <img src={stage5} alt="settings" width={100} className="mb-2" />
              <h5 className="mb-2">Revenue Streams</h5>
              <Link
                to="/revenueSettings"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>

            <div className="border-dashed border-bottom my-3" />
            <div className="text-center mt-3">
              <img src={stage4} alt="settings" width={100} className="mb-2" />
              <h5 className="mb-2">Operating Expenses</h5>
              <Link
                to="/operatingExpensesSettings"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>

            <div className="border-dashed border-bottom my-3" />
            <div className="text-center mt-3">
              <img src={stage7} alt="settings" width={100} className="mb-2" />
              <h5 className="mb-2">Reports</h5>
              <Link
                to="/reportsSettings"
                className="text-decoration-none"
                onClick={() => setConfig('showSettingPanel', false)}
              >
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>
          </Row>
          <div className="border-dashed border-bottom my-3" />
          {/* </ScrollBarCustom> 
            <div className="border-dashed border-bottom my-3" />
            <Flex justifyContent="between">
              <img src={arrowsH} alt="" width={20} className="me-2 h-100" />
              <div className="flex-1">
                <h5 className="fs-0">Fluid Layout</h5>
                <p className="fs--1 mb-0">Toggle container layout system</p>
              </div>
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                className="mb-1"
                iconAlign="right"
                transform="shrink-3"
              ></IconButton>
            </Flex>

            <div className="border-dashed border-bottom my-3" />

            <Flex justifyContent="between">
              <img
                src={leftArrowFromLeft}
                alt=""
                width={20}
                className="me-2 h-100"
              />
              <div className="flex-1">
                <h5 className="fs-0">RTL Mode</h5>
                <p className="fs--1 mb-0">Switch your language direction </p>
              </div>
              <Form.Check
                type="switch"
                id="rtl-switch"
                checked={isRTL}
                onChange={({ target }) => setConfig('isRTL', target.checked)}
              />
            </Flex>

            <hr />
            <Flex justifyContent="between">
              <img src={arrowsH} alt="" width={20} className="me-2 h-100" />
              <div className="flex-1">
                <h5 className="fs-0">Fluid Layout</h5>
                <p className="fs--1 mb-0">Toggle container layout system</p>
              </div>
              <Form.Check
                type="switch"
                id="fluid-mode-switch"
                checked={isFluid}
                onChange={({ target }) => setConfig('isFluid', target.checked)}
              />
            </Flex>

            <hr />
            <Flex>
              <img src={paragraph} alt="" width={20} className="me-2 h-100" />
              <div>
                <Flex alignItems="center" tag="h5" className="fs-0">
                  Navigation Position
                  <SoftBadge bg="success" pill className="fs--2 ms-2">
                    New
                  </SoftBadge>
                </Flex>
                <p className="fs--1 mb-2">
                  Select a suitable navigation system for your web application
                </p>
                <Form.Select
                  className="mb-3"
                  size="sm"
                  defaultValue={navbarPosition}
                  onChange={({ target }) =>
                    setConfig('navbarPosition', target.value)
                  }
                >
                  {['vertical', 'top', 'combo', 'double-top'].map(option => (
                    <option
                      key={option}
                      disabled={disabledNavbarPosition.includes(option)}
                      value={option}
                    >
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Flex>
            <hr />

            <h5 className="fs-0 d-flex align-items-center">
              Vertical Navbar Style{' '}
            </h5>
            <p className="fs--1">Switch between styles for your vertical navbar</p>
            <ButtonGroup className="btn-group-navbar-style">
              {navbars.slice(0, 2).map(item => (
                <RadioItem
                  key={item.name}
                  name="navbar-style"
                  label={item.name}
                  active={navbarStyle === item.name}
                  onChange={() => setConfig('navbarStyle', item.name)}
                  image={item.image}
                />
              ))}
            </ButtonGroup>
            <ButtonGroup className="btn-group-navbar-style">
              {navbars.slice(2, 4).map(item => (
                <RadioItem
                  key={item.name}
                  name="navbar-style"
                  label={item.name}
                  active={navbarStyle === item.name}
                  onChange={() => setConfig('navbarStyle', item.name)}
                  image={item.image}
                />
              ))}
            </ButtonGroup>

            closeButton
            closeVariant="white"
            className="bg-shape settings-panel-header"
      

          <Offcanvas.Header>
            <Offcanvas.Title as="div" className="py-1 z-index-1 light">
              <div className="d-flex justify-content-between align-items-center mb-1 mt-5">
                <h5 className="text-white">
                  <FontAwesomeIcon icon="wrench" className="me-2 fs-0" />
                  Settings
                </h5>
                <IconButton
                  variant="primary"
                  size="sm"
                  className="rounded-pill mt-0 mb-0"
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    configDispatch({ type: 'RESET' });
                  }}
                >
                  <FontAwesomeIcon
                    icon="redo-alt"
                    style={{ fontSize: '10px' }}
                    className="me-1"
                    size="sm"
                  />
                  Reset
                </IconButton>
              </div>
              <p className="mb-0 fs--1 text-white opacity-75">
                Set your own customized style
              </p>
            </Offcanvas.Title>
          </Offcanvas.Header>

  */}

          <h5 className="fs-0">Color Scheme</h5>
          <p className="fs--1">Choose the perfect color mode for your app.</p>
          <ButtonGroup className="btn-group-navbar-style">
            <RadioItem
              name="theme-mode"
              label="light"
              active={!isDark}
              onChange={({ target }) => {
                setConfig('isDark', !target.checked);
                setConfig('showSettingPanel', false);
              }}
              image={defaultModeImg}
            />
            <RadioItem
              name="theme-mode"
              label="dark"
              active={isDark}
              onChange={({ target }) => {
                setConfig('isDark', target.checked);
                setConfig('showSettingPanel', false);
              }}
              image={darkModeImg}
            />
          </ButtonGroup>
          <div className="border-dashed border-bottom my-3" />
          <div className="text-center mt-5">
            <img src={settings} alt="settings" width={120} className="mb-4" />
            <h5>Need Help?</h5>
            <Link
              to="/help-center"
              className="text-decoration-none"
              onClick={() => setConfig('showSettingPanel', false)}
            >
              <IconButton
                variant="outline-primary"
                size="sm"
                icon="fa-solid fa-arrow-left"
                className="mb-1"
                iconAlign="right"
                transform="shrink-3"
              ></IconButton>
              <div className="flex-1">
                <p className="fs--1 mb-0">Switch to help center</p>
              </div>
            </Link>
          </div>
          <div className="border-dashed border-bottom my-3" />
        </Offcanvas.Body>
        {/* </ScrollBarCustom>          
          <p className="fs--1">Go to our help center fot imformation.</p>
          <h5 className="fs-0">Help Center</h5>     
        */}
      </Offcanvas>
    </Row>
  );
};

export default SettingsPanel;

{
  /* 
    
    </ScrollBarCustom> 
 
    <Button
      color="primary"
      href="https://themes.getbootstrap.com/product/falcon-admin-dashboard-webapp-template-react/"
    >
      Purchase
    </Button>


  */
}
