import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import { CompaniesContext, CompanyWizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseCompany } from 'utils';
import api from 'utils/api';

export const CompanyItem = ({
  details,
  isLast,
  setShowFormModal,
  isSelected,
  setSelected,
  companies,
  setCompanies
}) => {
  const { logo, institution, degree, duration, location, verified } =
    parseCompany(details);
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setCompany, setStep, setEdit } = useContext(CompanyWizardContext);
  const handleCompanyDelete = async () => {
    try {
      await api.delete(`/companies/delete/${details.id}`);
      toast.success('Company deleted');
      const updatedCompanies = companies.filter(
        company => company.id !== details.id
      );
      setCompanies(updatedCompanies);
      if (isSelected) {
        if (updatedCompanies.length > 0) {
          setSelected(updatedCompanies[0]);
        } else {
          setSelected({});
        }
      }
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting company');
    }
  };

  const handleEdit = async () => {
    try {
      const company = { ...details };
      if (company.avatar && typeof company.avatar === 'string') {
        company.avatar = [
          {
            base64: company['avatar'],
            path: `avatar.${company['avatar'].split(';')[0].split('/')[1]}`,
            size: 1,
            type: company['avatar'].split(';')[0].split(':')[1]
          }
        ];
      }
      setCompany(company);
      setStep(1);
      setEdit(true);
      setShowFormModal(true);
      console.log(company);
    } catch (error) {
      console.error(error);
      toast.error('Error fetching company details');
    }
  };

  return (
    <>
      <Flex className="w-100" alignItems={'center'}>
        <Link to="#!" className="align-self-start">
          <Avatar
            size="3xl"
            src={
              logo
                ? logo
                : `${process.env.PUBLIC_URL}/custom-assets/img/avatars/avatar_1.png`
            }
            width={56}
          />
        </Link>
        <div className="flex-1 position-relative ps-3">
          <h6 className="fs-0 mb-0">
            <Link to="#!">{institution}</Link>
            {verified && <VerifiedBadge style={{ color: 'green' }} />}
          </h6>
          {/* 
          <p className="mb-1">{degree}</p>   
          <p className="text-1000 mb-0">{duration}</p>
          <p className="text-1000 mb-0">{location}</p>
         {!isLast && <div className="border-dashed border-bottom my-3"></div>} */}
        </div>
        <div className="justify-self-end align-items-center">
          {isSelected && <span className="me-2 text-info"></span>}
          {!isSelected && (
            <IconButton
              variant="outline-warning"
              size="lg"
              //icon="fa fa-info"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              onClick={() => setSelected(details)}
            >
              Select
            </IconButton>
          )}
          <IconButton
            variant="outline-primary"
            size="lg"
            icon="fa fa-info"
            className="mb-1 mx-1"
            iconAlign="right"
            transform="shrink-3"
            onClick={handleEdit}
          ></IconButton>
          <IconButton
            variant="outline-success"
            size="lg"
            icon="fa-trash"
            className="mb-1 mx-1"
            iconAlign="right"
            transform="shrink-3"
            onClick={() => setShowDelConfirmModal(true)}
          ></IconButton>
        </div>
      </Flex>
      <ConfirmationModal
        title={`Delete ${details.institution}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this company?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleCompanyDelete}
      />
    </>
  );
};

CompanyItem.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.string,
    logo: PropTypes.string,
    institution: PropTypes.string,
    degree: PropTypes.string,
    duration: PropTypes.string,
    location: PropTypes.string,
    verified: PropTypes.bool
  }),
  isLast: PropTypes.bool,
  setShowFormModal: PropTypes.func,
  isSelected: PropTypes.bool,
  setSelected: PropTypes.func
};

export default CompanyItem;
