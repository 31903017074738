import React, { useContext, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { SelectedCompanyContext } from 'context/Context';
import Header from './Header';

const TableOfContents = ({ isPdf }) => {
  const [reportData, setReportData] = useState({});
  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <Container id="table-of-contents">
      <Header />
      <h4 className="text-primary p-0">Table Of Contents</h4>

      <div id="pdf-content" style={{ paddingTop: '0px' }}>
        <Card>
          <Card.Body>
            <ol className="list-disc list-inside">
              <li>
                <strong className="text-primary">Cover Page:</strong> An
                overview of the company structure.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />

              <li>
                <strong className="text-primary">Your Pitch:</strong> Provides
                an overview of your company's mission, vision, and core values.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />

              <li>
                <strong className="text-primary">Startup Funding:</strong>{' '}
                Outlines the sources of capital and funding strategies necessary
                for launching the business.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />

              <li>
                <strong className="text-primary">Opening Costs:</strong> Details
                the initial costs required to start the business, including
                equipment, licenses, and initial inventory.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />

              <li>
                <strong className="text-primary">Income Statement:</strong>{' '}
                Projects the company’s revenues, costs, and expenses to show
                potential profitability.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />
              <li>
                <strong className="text-primary">Balance Sheet:</strong> Shows
                the company's financial position at a specific point in time,
                including assets, liabilities, and equity.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />
              <li>
                <strong className="text-primary">Cash Flow:</strong> Analyzes
                the movement of cash into and out of the business, highlighting
                liquidity and financial stability.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />
              <li>
                <strong className="text-primary">Key Ratios:</strong> Includes
                critical financial ratios used to evaluate the company's
                performance and financial health.
              </li>

              <div className="border-primary border-dashed border-bottom my-3" />

              <li>
                <strong className="text-primary">
                  Profitability Analysis:
                </strong>{' '}
                Assesses the business’s ability to generate profit, examining
                margins and overall financial viability.
              </li>
              <div className="border-primary border-dashed border-bottom my-3" />
            </ol>

            <p className="italic mt-4">
              This index provides a structured outline of the business plan,
              guiding you through the key sections. It is designed to help you
              easily locate and understand the key components of your strategy
              and financial outlook.
            </p>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default TableOfContents;
