import { useState, useRef, useEffect } from 'react';

export const useIdleTimer = (
  navigate,
  timeout = 5000,
  gracePeriod = 3000,
  handleContinue,
  onIdle
) => {
  const [isIdle, setIsIdle] = useState(false);
  const timerRef = useRef(null);
  const graceTimerRef = useRef(null);

  const handleActivity = () => {
    setIsIdle(false);
    clearTimeout(timerRef.current);
    clearTimeout(graceTimerRef.current);

    // Reset the idle timer
    timerRef.current = setTimeout(() => {
      setIsIdle(true);
      onIdle(true); // Notify idle state
      // Start grace period timer
      graceTimerRef.current = setTimeout(() => {
        idleLogout();
      }, gracePeriod);
    }, timeout);
  };

  useEffect(() => {
    // Attach event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Start the initial idle timer
    timerRef.current = setTimeout(() => {
      setIsIdle(true);
      onIdle(true); // Notify idle state
      // Start grace period timer
      graceTimerRef.current = setTimeout(() => {
        handleContinue();
      }, gracePeriod);
    }, timeout);

    // Cleanup function
    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(graceTimerRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [timeout, gracePeriod, onIdle, navigate]);

  return isIdle;
};
