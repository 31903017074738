import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel,
  OverlayTrigger
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  OperatingExpenseFormModalContext,
  OperatingExpenseWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

const OperatingExpenseForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(OperatingExpenseWizardContext);
  const { setShowFormModal } = useContext(OperatingExpenseFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);
  const [startupBudget, setStartupBudget] = useState('');
  const [yearlyBudget, setYearlyBudget] = useState('');
  const [weeklyBudget, setWeeklyBudget] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      expenseType: 'Operating',
      expenseDescription: 'Rent',
      expenseAmount: '',
      numberOfMonths: '6',
      yearlyBudget: '120,000',
      weeklyBudget: '120,000',
      startupBudget: '12,000'
    }
  });

  useEffect(() => {
    if (edit === 'operating-expense' && editDetails.id) {
      console.log(editDetails.expenseDescription);
      setIsEditing(true);
      setDocId(editDetails.id);
      setValue('expenseType', editDetails.expenseType);
      setValue('expenseAmount', editDetails.expenseAmount);
      setValue('numberOfMonths', editDetails.numberOfMonths);
      setValue('yearlyBudget', editDetails.yearlyBudget);
      setValue('weeklyBudget', editDetails.yearlyBudget);
      setValue('startupBudget', editDetails.startupBudget);

      setTimeout(() => {
        setValue('expenseDescription', editDetails.expenseDescription);
      }, 100);

      calculateStartupBalance(
        editDetails.expenseAmount,
        editDetails.numberOfMonths
      );
    } else {
      setValue('expenseAmount', `${currencies[companyDetails.currency]} 2,000`);
    }
  }, [edit]);

  const calculateStartupBalance = (amount, months) => {
    const parsedAmount = parseFloat(amount.replace(/[^0-9.]/g, ''));
    const parsedMonths = parseInt(months);
    const budget = parsedAmount * parsedMonths;
    setStartupBudget(
      budget.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );
    const yearly = parsedAmount * 12;
    setYearlyBudget(
      yearly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const weekly = yearly / 52;
    setWeeklyBudget(
      weekly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );
  };

  useEffect(() => {
    calculateStartupBalance(watch().expenseAmount, watch().numberOfMonths);
  }, [watch().expenseAmount, watch().numberOfMonths]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSubmitData = data => {
    data.startupBudget = startupBudget;
    data.yearlyBudget = yearlyBudget;
    data.weeklyBudget = weeklyBudget;
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : { companyId: companyDetails.id, ...data };

    api
      .post(
        isEditing ? '/operating-expense/update' : '/operating-expense/create',
        payload
      )
      .then(res => {
        reset();
        setEdit('');
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Operating Expenses"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="expenseType"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error?.response?.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (
            error?.response?.status === 401 ||
            error?.response?.status === 400
          ) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const expenseType = watch('expenseType');

  function capitalize(str) {
    if (typeof str !== 'string') {
      return str; // Return the string as is if it's not a string
    }
    return str
      .split(' ')
      .map(word => {
        if (word.length > 0) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return word;
      })
      .join(' ');
  }

  // Define options for descriptions based on expense type
  const expenseDescriptionOptions = {
    advertising: ['billboards', 'online ads'],
    entertainment: ['events', 'tickets'],
    marketing: ['ads', 'promotions', 'sponsorships'],

    operating: [
      'rent',
      'Design & Branding',
      'merchant & Fulfillment Fees',
      'Audit &  Accounting Fee',
      'entertainment - 100% Business',
      'postage, freight & couriers',
      'general expenses',
      'insurance',
      'legal costs',
      'maintenance',
      'motor vehicle expenses',
      'it, software & consumables',
      'research & development',
      'travel',
      'repairs and maintenance'
    ],

    travel: ['flights', 'hotels']
  };

  // Determine the description options based on the selected expense type
  const descriptionOptions = expenseType
    ? expenseDescriptionOptions[expenseType] || []
    : [];

  const onSubmit = data => {
    console.log(data);
  };

  const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Operating Expenses"
              description="Prepaid costs in business refer to expenses that have been paid in advance but have not yet been consumed or used up. These expenses are typically recorded as assets on the balance sheet until they are utilized and recognized as expenses."
              className="text-primary"
            />

            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="div" style={{ backgroundColor: '' }}>
                    {/* Your popover content */}
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>

            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              <Row className="g-3 pt-3">
                <Col lg={3}>
                  <Form.Group controlId="numberOfMonths">
                    <FloatingLabel label="Number Of Months?">
                      <Form.Control
                        type="number"
                        placeholder="Number Of Months?"
                        name="numberOfMonths"
                        {...register('numberOfMonths', {
                          required: 'Number of months is required.'
                        })}
                        isInvalid={errors['numberOfMonths']}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors['numberOfMonths']
                        }
                        onChange={e =>
                          calculateStartupBalance(
                            watch().expenseAmount,
                            e.target.value
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors['numberOfMonths']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="expenseType">
                    <FloatingLabel label="Expense Type">
                      <Form.Select
                        aria-label="Select the type of cost"
                        name="expenseType"
                        {...register('expenseType', {
                          required: 'Expense Type is required.'
                        })}
                        isInvalid={!!errors.expenseType}
                        onChange={e => {
                          setValue('expenseType', e.target.value);
                          setValue('expenseDescription', ''); // Reset description on type change
                        }}
                      >
                        <option value="">Select an expense type</option>

                        {/*
                        <option value="advertising">Advertising</option>
                        <option value="entertainment">Entertainment</option>
                        <option value="travel">Travel</option>

                        */}
                        <option value="marketing">Marketing</option>
                        <option value="operating">Operating</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.expenseType?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="expenseDescription">
                    <FloatingLabel label="Expense Description">
                      <Form.Select
                        name="expenseDescription"
                        {...register('expenseDescription', {
                          required: 'Expense description is required.'
                        })}
                        isInvalid={!!errors.expenseDescription}
                      >
                        <option value="">Select an expense description</option>
                        {descriptionOptions.map(option => (
                          <option key={option} value={option.toLowerCase()}>
                            {capitalize(option)}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.expenseDescription?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group controlId="expenseAmount">
                    <CurrencyWizardInput
                      symbol={currencySymbol} // Use currency symbol
                      name="expenseAmount"
                      label="Expense Amount"
                      onChange={e =>
                        calculateStartupBalance(
                          e.target.value,
                          watch().numberOfMonths
                        )
                      }
                      errors={errors}
                      formGroupProps={{ className: 'mb-0', as: Col }}
                      formControlProps={{
                        ...register('expenseAmount', {
                          required: 'Expense Amount is required.'
                        })
                      }}
                      setValue={setValue}
                      watch={watch}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="g-3 pt-3">
                <Col lg={4}>
                  <Form.Group controlId="startupBudget">
                    <FloatingLabel label="Startup Budget">
                      <Form.Control
                        type="text"
                        placeholder="Startup Budget"
                        name="startupBudget"
                        value={startupBudget}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group controlId="yearlyBudget">
                    <FloatingLabel label="Yearly Budget">
                      <Form.Control
                        type="text"
                        placeholder="Yearly Budget"
                        name="yearlyBudget"
                        value={yearlyBudget}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group controlId="weeklyBudget">
                    <FloatingLabel label="Weekly Budget">
                      <Form.Control
                        type="text"
                        placeholder="Weekly Budget"
                        name="weeklyBudget"
                        value={weeklyBudget}
                        disabled
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <div className="border-dashed border-bottom my-3" />

              <Row>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      className="float-end"
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>

      <div className="border-dashed border-bottom my-3" />
    </Row>
  );
};

export default OperatingExpenseForm;
