import React from 'react';
import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SelectedCompanyContext } from 'context/Context';

const Header = () => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <div>
      <Row
        style={{
          borderBottom: '1px solid #ddd',
          marginBottom: '20px',
          height: 'auto'
        }}
      >
        {/* Left Column for Company Logo and Name */}
        <Col>
          <div
            style={{
              padding: '10px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={companyDetails.avatar}
              className="img-fluid"
              alt="Company Logo"
              style={{ maxWidth: '25%', height: 'auto' }}
            />
            <div style={{ display: 'inline-block', marginLeft: '1px' }}>
              <h2>{companyDetails.name}</h2>
            </div>
          </div>
        </Col>

        {/* Right Column for Address, Email, Mobile Number, and Registration Number */}
        <Col>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
              lineHeight: '0.7',
              paddingRight: '10px'
            }}
          >
            <p style={{ fontSize: '10px' }}>Address:{companyDetails.address}</p>
            <p style={{ fontSize: '10px' }}>Email:{companyDetails.email}</p>
            <p style={{ fontSize: '10px' }}>Tel:{companyDetails.phone}</p>
            <p style={{ fontSize: '10px' }}>
              Abn No:
              {companyDetails.registrationNumber}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
