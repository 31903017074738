import React, { useContext, useState, useEffect } from 'react';
import {
  Card,
  Modal,
  Container,
  Row,
  Col,
  Form,
  FloatingLabel
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { RevenueFormModalContext, RevenueWizardContext } from 'context/Context';
import RevenueWizard from 'components/custom/wizards/revenueWizard/RevenueWizard';
import PageHeader from 'components/common/PageHeader';
import ServicesRevenueList from 'components/custom/lists/stage5 /ServicesRevenueList';
import ProductsRevenueList from 'components/custom/lists/stage5 /ProductsRevenueList';
import editing from 'assets/img/icons/spot-illustrations/4.svg';
import PropertyRevenueList from 'components/custom/lists/stage5 /PropertyRevenueList';
import OnlineRevenueForm from 'components/custom/wizards/revenueWizard/forms/OnlineRevenueForm';
import WholeSaleList from 'components/custom/lists/stage5 /WholeSaleRevenue';
import OnlineRevenueList from 'components/custom/lists/stage5 /OnlineRevenueList';

const RevenueSettings = ({ state }) => {
  const [revenueType, setRevenueType] = useState('products'); // Default to 'products'
  const { showFormModal, setShowFormModal } = useContext(
    RevenueFormModalContext
  );

  const { setStep, setIsFromLoan } = useContext(RevenueWizardContext);

  const [modalShow, setModalShow] = useState(false);

  const handleRevenueTypeChange = e => {
    setRevenueType(e.target.value);
  };

  const [fullscreen, setFullscreen] = useState(true);

  useEffect(() => {
    if (state && state.step) {
      setIsFromLoan(true);
      setShowFormModal(state.showModal);
      setStep(state.step);
    }
  }, [state]);

  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Row className="align-items-center justify-content-center pt-6">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
            <img
              src={editing}
              className="img-fluid"
              alt="Editing"
              style={{ maxWidth: '60%', height: 'auto' }}
            />
          </div>
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            <h2 className="text-primary pt-0">Revenue Streams</h2>
            <p>
              Diversifying revenue streams across services, products, and online
              offerings is crucial for sustained growth and resilience. By
              expanding income sources, you mitigate dependency risks and adapt
              better to economic changes. Let's explore strategies to optimize
              these varied revenue streams while maintaining operational
              efficiency.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/StartupCostsSettings" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                icon="fa-solid fa-arrow-left"
                size="lg"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
            <Link
              to="/help-center#revenueStreams"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-info"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
            <IconButton
              onClick={() => setShowFormModal(true)}
              variant="outline-primary"
              size="lg"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />
            <IconButton
              onClick={() => setModalShow(true)}
              variant="outline-warning"
              size="lg"
              icon="fa-solid fa-search"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />
            <Link
              to="/operatingExpensesSettings"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-right"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
          </div>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-label="contained-modal-title-vcenter"
        centered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Body>
          <Container>
            <PageHeader
              title="Revenue Streams"
              description="How is your company generating revenue?"
              className="mb-0"
            />
            <Card className="mt-3">
              <div className="border-none rounded mt-4">
                <div className="p-x1">
                  <Form.Group as={Col} lg={12} controlId="revenueType">
                    <FloatingLabel label="Revenue Streams">
                      <Form.Select
                        aria-label="Default select a revenue type"
                        value={revenueType}
                        onChange={handleRevenueTypeChange}
                      >
                        <option value="online">Online Store</option>
                        <option value="products">Products</option>
                        <option value="property">Property Development</option>
                        <option value="services">Service</option>
                        <option value="whole-sale">Wholesale</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </div>
                <div className="my-3">
                  {revenueType === 'products' && (
                    <ProductsRevenueList close={() => setModalShow(false)} />
                  )}
                  {revenueType === 'services' && (
                    <ServicesRevenueList close={() => setModalShow(false)} />
                  )}
                  {revenueType === 'property' && (
                    <PropertyRevenueList close={() => setModalShow(false)} />
                  )}
                  {revenueType === 'whole-sale' && (
                    <WholeSaleList close={() => setModalShow(false)} />
                  )}
                  {revenueType === 'online' && (
                    <OnlineRevenueList close={() => setModalShow(false)} />
                  )}
                </div>
              </div>
            </Card>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-success"
            size="sm"
            onClick={() => setModalShow(false)}
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>

      {/* Wizard Modal */}

      <Modal
        show={showFormModal}
        aria-label="contained-modal-title-vcenter"
        centered
        size="xl"
        //fullscreen={fullscreen}
      >
        <Modal.Body>
          <Container>
            <RevenueWizard variant="pills" validation={false} />
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default RevenueSettings;
