import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Card,
  FloatingLabel,
  Form,
  Col,
  Row,
  Table
} from 'react-bootstrap';
import { SelectedCompanyContext } from 'context/Context';
import { useForm } from 'react-hook-form';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';

import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { addMoreNotification, currencies } from 'utils';
import PropTypes from 'prop-types';
import PageHeader from 'components/common/PageHeader';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import Header from './Header';

const Valuation = ({ reportData, isPdf }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  // Initialize state
  const [valuationBefore, setValuationBefore] = useState(300000);
  const [valuationAfter, setValuationAfter] = useState(400000);

  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      investmentWanted: 100000,
      investmentPercentage: 25,
      earningsYear: 5,
      exitYear: new Date().getFullYear() + 5, // Default exit year to 5 years from now
      earnings: 3551248.9,
      peHigh: 9.0,
      peMedium: 5.0,
      peLow: 3.0
    }
  });

  // Extract form values
  const investmentWanted = watch('investmentWanted');
  const investmentPercentage = watch('investmentPercentage');
  const earningsYear = watch('earningsYear');
  const exitYear = watch('exitYear');
  const earnings = watch('earnings');
  const peHigh = watch('peHigh');
  const peMedium = watch('peMedium');
  const peLow = watch('peLow');

  // Update investmentWanted based on percentage
  useEffect(() => {
    if (investmentPercentage != null && valuationBefore != null) {
      setValue(
        'investmentWanted',
        (valuationBefore * investmentPercentage) / 100
      );
    }
  }, [investmentPercentage, valuationBefore, setValue]);

  // Current year
  const currentYear = new Date().getFullYear();

  // Recalculate values when earnings, exitYear, or other fields change
  const valuationsAtExit = {
    high: earnings * peHigh,
    medium: earnings * peMedium,
    low: earnings * peLow
  };

  const exitValues = {
    high: (investmentWanted / valuationBefore) * valuationsAtExit.high,
    medium: (investmentWanted / valuationBefore) * valuationsAtExit.medium,
    low: (investmentWanted / valuationBefore) * valuationsAtExit.low
  };

  const gainsOnInvestment = {
    high: exitValues.high - investmentWanted,
    medium: exitValues.medium - investmentWanted,
    low: exitValues.low - investmentWanted
  };

  const roi = {
    high: ((gainsOnInvestment.high / investmentWanted) * 100).toFixed(1),
    medium: ((gainsOnInvestment.medium / investmentWanted) * 100).toFixed(1),
    low: ((gainsOnInvestment.low / investmentWanted) * 100).toFixed(1)
  };

  // Update CAGR calculation
  const yearsToGrow = exitYear - currentYear;

  const cagr = {
    high: (
      ((valuationsAtExit.high / valuationBefore) ** (1 / yearsToGrow) - 1) *
      100
    ).toFixed(2),
    medium: (
      ((valuationsAtExit.medium / valuationBefore) ** (1 / yearsToGrow) - 1) *
      100
    ).toFixed(2),
    low: (
      ((valuationsAtExit.low / valuationBefore) ** (1 / yearsToGrow) - 1) *
      100
    ).toFixed(2)
  };

  const onSubmitData = data => {
    const payload = {
      companyId: companyDetails.id,
      ...data
    };

    api
      .post('/owners/create', payload)
      .then(res => {
        // Handle success
        toast.success('Data saved successfully.');
      })
      .catch(error => {
        // Handle error
        toast.error('Failed to save data.');
      });
  };

  return (
    <Container id="valuation">
      <Header />
      <h4 className="text-primary p-0">Company Valuation</h4>
      <Row>
        <Col>
          <div id="pdf-content" style={{ paddingTop: '20px' }}>
            <h6 className="text-primary mb-0">
              How much money will you need from you investor and what % will you
              offer
            </h6>

            <Form onSubmit={handleSubmit(onSubmitData)}>
              <div
                style={{
                  marginBottom: '20px',
                  padding: '20px',
                  borderRadius: '8px',
                  backgroundColor: '#f9f9f9',
                  border: '1px solid #ddd'
                }}
              >
                <Row className="mb-3">
                  <Form.Group as={Col} lg={3} controlId="investmentWanted">
                    <FloatingLabel label="Investment Wanted">
                      <Form.Control
                        type="number"
                        placeholder="Investment Wanted"
                        {...register('investmentWanted', {
                          required: 'Investment Wanted is required.',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'Investment Wanted must be greater than or equal to 0.'
                          }
                        })}
                        isInvalid={!!errors.investmentWanted}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.investmentWanted?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} lg={3} controlId="investmentPercentage">
                    <FloatingLabel label="Investment Percentage">
                      <Form.Control
                        type="number"
                        placeholder="Investment Percentage"
                        {...register('investmentPercentage', {
                          required: 'Investment Percentage is required.',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'Investment Percentage must be greater than or equal to 0.'
                          },
                          max: {
                            value: 100,
                            message:
                              'Investment Percentage must be less than or equal to 100.'
                          }
                        })}
                        isInvalid={!!errors.investmentPercentage}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.investmentPercentage?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} lg={3} controlId="earnings">
                    <FloatingLabel label="Earnings">
                      <Form.Control
                        type="number"
                        placeholder="Earnings"
                        {...register('earnings', {
                          required: 'Earnings is required.',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'Earnings must be greater than or equal to 0.'
                          }
                        })}
                        isInvalid={!!errors.earnings}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.earnings?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} lg={3} controlId="earningsYear">
                    <FloatingLabel label="Earnings Year">
                      <Form.Control
                        type="number"
                        placeholder="Earnings Year"
                        {...register('earningsYear', {
                          required: 'Earnings Year is required.',
                          valueAsNumber: true,
                          min: {
                            value: 1,
                            message:
                              'Earnings Year must be greater than or equal to 1.'
                          }
                        })}
                        isInvalid={!!errors.earningsYear}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.earningsYear?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} lg={3} controlId="exitYear">
                    <FloatingLabel label="Exit Year">
                      <Form.Control
                        type="number"
                        placeholder="Exit Year"
                        {...register('exitYear', {
                          required: 'Exit Year is required.',
                          valueAsNumber: true,
                          min: {
                            value: new Date().getFullYear(),
                            message: `Exit Year must be greater than or equal to ${new Date().getFullYear()}.`
                          }
                        })}
                        isInvalid={!!errors.exitYear}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.exitYear?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} lg={3} controlId="peHigh">
                    <FloatingLabel label="PE High">
                      <Form.Control
                        type="number"
                        step="0.1"
                        placeholder="PE High"
                        {...register('peHigh', {
                          required: 'PE High is required.',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'PE High must be greater than or equal to 0.'
                          }
                        })}
                        isInvalid={!!errors.peHigh}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.peHigh?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} lg={3} controlId="peMedium">
                    <FloatingLabel label="PE Medium">
                      <Form.Control
                        type="number"
                        step="0.1"
                        placeholder="PE Medium"
                        {...register('peMedium', {
                          required: 'PE Medium is required.',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'PE Medium must be greater than or equal to 0.'
                          }
                        })}
                        isInvalid={!!errors.peMedium}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.peMedium?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} lg={3} controlId="peLow">
                    <FloatingLabel label="PE Low">
                      <Form.Control
                        type="number"
                        step="0.1"
                        placeholder="PE Low"
                        {...register('peLow', {
                          required: 'PE Low is required.',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message:
                              'PE Low must be greater than or equal to 0.'
                          }
                        })}
                        isInvalid={!!errors.peLow}
                        className="rounded-3"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.peLow?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Row>
              </div>

              {/* Table */}
              <Table
                responsive
                className="styled-table"
                style={{
                  color: `${isPdf ? 'black' : 'white'}`,
                  marginBottom: '0',
                  borderCollapse: 'collapse',
                  width: '100%',
                  tableLayout: 'fixed'
                }}
              >
                <thead>
                  <tr>
                    <th>Metric</th>
                    <th>Low</th>
                    <th>Medium</th>
                    <th>High</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Valuation at Exit</td>
                    <td>{valuationsAtExit.low.toFixed(2)}</td>
                    <td>{valuationsAtExit.medium.toFixed(2)}</td>
                    <td>{valuationsAtExit.high.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Exit Value</td>
                    <td>{exitValues.low.toFixed(2)}</td>
                    <td>{exitValues.medium.toFixed(2)}</td>
                    <td>{exitValues.high.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Gain on Investment</td>
                    <td>{gainsOnInvestment.low.toFixed(2)}</td>
                    <td>{gainsOnInvestment.medium.toFixed(2)}</td>
                    <td>{gainsOnInvestment.high.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>ROI (%)</td>
                    <td>{roi.low}</td>
                    <td>{roi.medium}</td>
                    <td>{roi.high}</td>
                  </tr>
                  <tr>
                    <td>CAGR (%)</td>
                    <td>{cagr.low}</td>
                    <td>{cagr.medium}</td>
                    <td>{cagr.high}</td>
                  </tr>
                </tbody>
              </Table>

              <Row className="mt-4">
                <Col lg={12} className="text-end">
                  <IconButton
                    type="submit"
                    variant="outline-primary"
                    className="save-button"
                  >
                    Save
                  </IconButton>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Valuation.propTypes = {
  reportData: PropTypes.object,
  isPdf: PropTypes.bool
};

export default Valuation;
