import React from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/2.jpg';
import { Col, Row } from 'react-bootstrap';
//import ProfileSettings from './ProfileSettings';
//import ExperiencesSettings from './ExperiencesSettings';
import CompanySettings from './CompanySettings';
import OwnerSettings from './OwnerSettings';
import InvestorSettings from './InvestorSettings';
import LoanSettings from './LoanSettings';
import OpeningBalances from './OpeningBalances';
//import EducationSettings from './EducationSettings';/
import TradingTermsForm from './TradingTermsForm';
import CompanyTaxForm from './CompanyTaxForm';
//import AccountSettings from './AccountSettings';
//import BillingSettings from './BillingSettings';
//import ChangePassword from './ChangePassword';
//import DangerZone from './DangerZone';
//import FooterStandard from 'components/pages/landing/FooterStandard';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import Section from 'components/common/Section';
import CompaniesProvider from 'components/custom/providers/CompaniesProvider';
import CompanyWizardProvider from 'components/custom/wizards/companyWizard/CompanyWizardProvider';
import gallery2 from 'assets/img/icons/spot-illustrations/corner-1.png';
import { motion } from 'framer-motion';
import { CompanyFormModalContext } from 'context/Context';
import CompanyFormModalProvider from 'components/custom/providers/CompanyFormModalProvider';

const Settings = () => {
  return (
    <>
      <NavbarStandard />

      <Section>
        <Row
          as={motion.div}
          className="g-3"
          initial={{ x: '-20%' }}
          animate={{ x: '0%' }}
          exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
          <Col lg={12}>
            <CompanyWizardProvider>
              <CompanyFormModalProvider>
                <CompanySettings />
              </CompanyFormModalProvider>
            </CompanyWizardProvider>

            {/*        
              <OwnerSettings />
              <InvestorSettings />
              <LoanSettings />
              <OpeningBalances />
              <TradingTermsForm />
              <CompanyTaxForm />
            */}
          </Col>
        </Row>
      </Section>

      {/* <FooterStandard /> */}
    </>
  );
};

export default Settings;
