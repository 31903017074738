import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';

function LandAndBuildinList({ close }) {
  const [landAndBuildings, setLandAndBuildings] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/land-and-building/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setLandAndBuildings(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
      return;
    }
    toast.error('No company selected, you must setup a new company to start.');
  }, [companyDetails]);

  useEffect(() => {
    setTotalCost(
      landAndBuildings.reduce(
        (acc, landAndBuilding) =>
          acc +
          parseFloat(
            landAndBuilding.totalDepreciation.replace(/[^0-9.-]+/g, '')
          ),
        0
      )
    );
  }, [landAndBuildings]);

  return (
    <>
      {/* Scrollable table container */}
      <div
        className="scrollable-table"
        style={{ maxHeight: '500px', overflowY: 'auto' }}
      >
        <Table responsive class="table table-success table-striped">
          <thead>
            <tr>
              <th scope="col">Property Description</th>
              <th scope="col">Purchase Price</th>
              <th scope="col">Useful Life</th>
              <th scope="col">GST/VAT</th>
              <th scope="col">Total Depreciation</th>
              <th scope="col">Depreciation Yearly</th>
              <th scope="col">Depreciation Monthly</th>
              <th scope="col">Depreciation Percentage</th>
              <th scope="col">Land Value</th>
              <th scope="col">Fixtures & Fittings</th>
              <th scope="col">Fixtures & Fittings GST</th>
              <th className="text-end" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {landAndBuildings.map(landAndBuilding => (
              <Item
                key={landAndBuilding.id}
                details={landAndBuilding}
                landAndBuildings={landAndBuildings}
                setLandAndBuildings={setLandAndBuildings}
                close={close}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="12"
                className="text-left text-success"
                style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
              >
                <span className="fw-bold">
                  Total Cost:{' '}
                  {`${
                    currencies[companyDetails.currency]
                  } ${totalCost.toLocaleString('en-US')}`}
                </span>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
}

LandAndBuildinList.propTypes = {
  close: PropTypes.func
};

export default LandAndBuildinList;
