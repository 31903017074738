import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { currencies } from 'utils';
import { parseCurrency } from 'utils';

function Item({ details, close, setOnlineRevenue }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleDelete = async () => {
    try {
      await api.delete(`/online-revenue/delete/${details.id}`);
      toast.success('Online Revenue deleted');
      setOnlineRevenue(null);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting Online revenue');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('online');
    setEditDetails(details);
    setStep(1);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseCurrency(details.marketingBudget).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseCurrency(details.costPerClick).toLocaleString()}
        </td>
        <td>{details.conversionRate}</td>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseCurrency(details.averageBasketValue).toLocaleString()}
        </td>

        <td>{parseFloat(details.paidVisits).toLocaleString()}</td>
        <td>{parseFloat(details.organicVisits).toLocaleString()}</td>
        <td>{parseFloat(details.startupMonths).toLocaleString()}</td>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseFloat(details.totalStartupBudget).toLocaleString()}
        </td>
        <td>{parseFloat(details.totalVisits).toLocaleString()}</td>
        <td>{parseFloat(details.sales).toLocaleString()}</td>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseFloat(details.revenue).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseFloat(details.totalRevenue).toLocaleString()}
        </td>
        <td>{parseFloat(details.minimumOrder).toLocaleString()}</td>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseCurrency(details.retailPrice).toLocaleString()}
        </td>
        <td>{parseFloat(details.dailyVisits).toLocaleString()}</td>
        <td>
          {currencies[companyDetails.currency]}{' '}
          {parseFloat(details.total).toLocaleString()}
        </td>
        <td>{parseFloat(details.totalRepeatedOrders).toLocaleString()}</td>
        <td>{parseFloat(details.repeatOrders).toLocaleString()}</td>

        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.customerName}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Whole Sale Revenue?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  setOnlineRevenue: PropTypes.func,
  close: PropTypes.func
};

export default Item;
