import ActionButton from 'components/common/ActionButton'; // Import ActionButton component
import React, { useContext, useState } from 'react'; // Import necessary components from React
import PropTypes from 'prop-types'; // Import PropTypes for type-checking props
import ConfirmationModal from 'components/custom/common/ConfirmationModel'; // Import ConfirmationModal component
import api from 'utils/api'; // Import API utility functions
import { toast } from 'react-toastify'; // Import toast notifications for error handling
import {
  SelectedCompanyContext,
  OperatingExpenseFormModalContext,
  OperatingExpenseWizardContext
} from 'context/Context'; // Import context hooks for selected company details and operating expense forms
import { currencies } from 'utils'; // Import utility function for currencies
import { parseCurrency } from 'utils';

function Item({ details, operatingExpenses, setOperatingExpenses, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false); // State to manage visibility of delete confirmation modal
  const { setEdit, setEditDetails, setStep } = useContext(
    OperatingExpenseWizardContext
  ); // Destructure context for operating expense wizard
  const { setShowFormModal } = useContext(OperatingExpenseFormModalContext); // Destructure context for operating expense form modal
  const { companyDetails } = useContext(SelectedCompanyContext); // Destructure context for selected company details

  // Function to handle deletion of operating expense
  const handleOperatingExpenseDelete = async () => {
    try {
      await api.delete(`/operating-expense/delete/${details.id}`); // API call to delete operating expense
      toast.success('Operating expense deleted'); // Success toast notification
      const updatedOperatingExpenses = operatingExpenses.filter(
        expense => expense.id !== details.id
      ); // Update operating expenses list after deletion
      setOperatingExpenses(updatedOperatingExpenses); // Set updated operating expenses
      setShowDelConfirmModal(false); // Close delete confirmation modal
    } catch (error) {
      console.log(error); // Log error to console
      toast.error('Error deleting operating expense'); // Error toast notification
    }
  };

  // Function to handle edit of operating expense
  const handleEdit = () => {
    close(); // Close current modal or component
    setEdit('operating-expense'); // Set edit mode for operating expense
    setEditDetails(details); // Set details for editing
    setStep(1); // Set wizard step to 1 (assuming it's part of a wizard)
    setShowFormModal(true); // Show operating expense form modal
  };

  // Function to capitalize the first letter of each word in a string
  function capitalize(str) {
    if (typeof str !== 'string') {
      return str; // Return the string as is if it's not a string
    }
    return str
      .split(' ') // Split string into words
      .map(word => {
        if (word.length > 0) {
          // Check if word is not empty
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize first letter, lowercase the rest
        }
        return word; // Return empty string or spaces as is
      })
      .join(' '); // Join capitalized words back into a string
  }

  return (
    <>
      {/* Table row for each operating expense item */}
      <tr key={details.id}>
        <td>{capitalize(details.expenseType)}</td>{' '}
        {/* Capitalized expense type */}
        <td>{capitalize(details.expenseDescription)}</td>{' '}
        {/* Capitalized expense description */}
        <td>{details.numberOfMonths}</td> {/* Number of months */}
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.startupBudget).toLocaleString()}
        </td>{' '}
        {/* Startup budget */}
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.weeklyBudget).toLocaleString()}
        </td>{' '}
        {/* Weekly budget */}
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.expenseAmount).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.yearlyBudget).toLocaleString()}
        </td>{' '}
        {/* Yearly budget */}
        <td className="text-end">
          {/* Action buttons for edit and delete */}
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      {/* Confirmation modal for delete operation */}
      <ConfirmationModal
        title={`Delete ${details.expenseType}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Operating Expense?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleOperatingExpenseDelete}
      />
    </>
  );
}

// PropTypes for type-checking props
Item.propTypes = {
  details: PropTypes.object, // Operating expense details object
  operatingExpenses: PropTypes.array, // Array of operating expenses
  setOperatingExpenses: PropTypes.func, // Function to set operating expenses
  close: PropTypes.func // Function to close modal or component
};

export default Item; // Export Item component
