import React, { forwardRef } from 'react';
import { Card, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';

const ThePitch = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="thePitch" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">The Pitch</h5>
      </Card.Header>
      <Card.Body>
        <h5 className="text-primary mt-1 ps-3 mb-3">
          {' '}
          What is a "Business Pitch"
        </h5>
        <div className="border-dashed border-bottom my-3" />

        <p className="mb-0 ps-3 ">
          The Pitch tells the story of your business ideas and concepts,it
          should be expressed in a captivating way to entice your investors or
          financial organizations to jump on board and support you in your new
          business venture.Writing a business narrative is fundamental step in
          formulating a realistic professional Business Plan and to make
          allowances for setbacks that may arise from a bad business decision
          allowing them to make the necessary changes.
        </p>
        <p className="mb-0 ps-3 pt-2">
          When you are just starting out,it is no easy task to persuade
          investors to fund your new business venture, so your Business Plan
          should be clear, concise, and above all realistic,no point in
          projections that are far fetched. Describe your business in detail and
          express what it does enthusiastically, make sure your company name and
          branding tie into one another so they create one image. and to make
          allowances for setbacks that may arise from a bad business decision
          allowing them to make the necessary changes.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">
          Practical Considerations
        </h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          The purpose behind creating a business plan is to enable owners to
          project if their business ideas will be profitable, and how much
          capital will be required to kick start the businesses.It's important
          to have a clear understanding of your business costs and to make
          allowances for setbacks that may arise from a bad business decision
          allowing them to make the necessary changes.
        </p>
        <p className="mb-0 ps-3 pt-3">
          A typical Business Plan should contain about 34-40 pages,however this
          may vary from different types of businesses. Companies may have other
          important documents such as patents pending or franchise contracts,
          these can be referenced in the index and included as appendices. As
          you grow your business,you should review and update your business
          plan,regularly, an annual review of your plan will show your
          achievement's and change can be made to the plan going forward.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3"> Brainstorming</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          Brainstorming is about sitting down with your family,friends or
          partners to discuss your business ideas and concepts,what’s your
          purpose? What’s your cause? what are your beliefs?, how do you intend
          to grow your business as well as establishing ideas for financing,The
          goal of your Business Plan is to register a unique business name, and
          have a clear picture of what your company does,and what steps you will
          undertake to reach your goals and objectives.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Executive Summary </h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          An executive summary is a brief section at the beginning of you
          Business Plan that summarizes the entire Business Plan document.People
          who read only the executive summary should get the essence of your
          Business without having to read the whole document so you should write
          this last.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary  mt-1 ps-3 mb-3">Mission Statement</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          A mission statement is a few lines of text that introduces your
          company to the world and briefly states your intentions.
          Example:"PYB-Project Your Business" purpose is to provide an easy to
          use online business planning platform, that will lay the foundation to
          grow a successful business.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Vision Statement</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          A vision statement is a short paragraph articulating your companies
          and hopes and dreams going forward, by setting a defined direction for
          the company's growth, like a road map indicating what the company
          wants to achieve.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Business Description</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          Naming your business can be challenging as your name should be
          unique,don't waist time to register the name as they are very quickly
          snapped up. Your name should reflect your branding,and the products or
          services you will be selling, it should present a clear picture to
          your potential bankers, investors,and to your customers.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Company Ownership</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          <ol className="mt-3">
            <li>Who are you?</li>
            <li>Who owns the company?</li>
            <li>what broughtyou down this path?</li>
            <li>
              why should your target market, or investorsbelieve what you’re
              telling them?
            </li>
            <li>
              what experience or qualifications do you or you partners have in
              this business idea?
            </li>
          </ol>
          Contrary to what people may think,your personal story is important and
          relevant to your Business Plan. Don't make the mistake of presenting
          your business as just another boring organization. Instead,let
          everyone see the real you and let your personality stand out from the
          crowd.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">
          Long-Term Goals and Objectives
        </h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          <strong>Goals</strong> establish what direction you intend to pursue
          and when do you plan to get there. They help improve the general
          effectiveness as a company.The more carefully you define your goals,
          the chances are you will make the right decisions and accomplish what
          you set out to achieve from the get go.
          <strong>Objectives</strong> simply put,specifies what you must do in
          your business to reach your goals.
          <strong>Example:</strong>Your goal is to double the number of people
          placing orders from you online store over the nest 12 months. Your
          objectives may be as follows:
          <ol className="mt-3">
            <li>
              <b>Customers:</b>Attract first-time customers by offering an
              online discount coupon.
            </li>
            <li>
              <b>Mailing:</b>Implementing a permission-based weekly e-mail to
              5,00 targeted contacts.
            </li>
            <li>
              <b>Social Media</b>Placing new adds on social media platforms.
            </li>
          </ol>
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">
          Property Plant and Equipment?
        </h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          Property, plant, and equipment commonly abbreviated as (PP&E) are
          long-term assets vital to business operations, these items range from
          generale office furniture,computers,motor vehicles and Property,
          Land,and Buildings that overtime can be depreciated and turned back
          into cash.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Products and Services?</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          If you are planning on selling products,then include detailed
          information about your products from the manufacturing process to
          delivery,along with competition comparisons and future
          development.Describe in depth your products and services. (Technical
          specifications, drawings, photos, sales brochures, and other bulky
          items belong in the Appendices.)
          <ol className="mt-3">
            <li>
              What factors give you competitive advantages or disadvantages? For
              example, the level of quality, or unique or proprietary features.
            </li>
            <li>
              What is the pricing, fee, or leasing structure of your products
              and services?
            </li>
            <li>
              If you are providing a service show why your service would be
              preferred over your competitors, is it unique? do you offer value
              for money?.
            </li>
          </ol>
          Consumers will typically buy something they want,or need so focus on
          what you are able to offer to your customers that they can’t buy from
          your competitors.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Market Target</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          After you have identified your business,focus on your target
          market.Developing products, or providing a unique service may not be
          enough to grow a lucrative business,you have to reach out to your
          customers through all social media platforms and understand who will
          be benifit from your business.You need to focus on one thing and one
          target audience to attract clients quickly and gain momentum.One of
          the best ways to achieve this is to imagine how you would satisfy your
          customers needs or requirement's with your products or
          service.visualize your preferred customer,what age will he or she be?,
          how can you reach out to them.Think about how you would like to be
          satisfied.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Potential Customers</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          Understanding who your preferred customers would be, or what type of
          customers is most likely to benefit from buying your products or
          services is a key factor in generating sales and growing your
          business. Identify your customers, their characteristics, and their
          geographic locations; that is, demographics. Your products or services
          will attract potential customers that live or work in specific
          geographic areas,this could be a particular country,city or
          suburb.Researching and pinpointing your customers is a vital part of
          establishing your market target, and potential customers.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">S.W.O.T</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          <b>S.W.O.T. helps to fine tune your business strategy – </b>strengths,
          weaknesses, opportunities and threats – that’s what it standsfor. The
          example below will help you to identify each of these for your
          business, so that you can conduct regular S.W.O.T Analysis to keep
          your business on the right track.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <p className="text-primary mb-0 ps-3">Plan Points</p>
        <div className="border-dashed border-bottom my-3" />
        <ol>
          <h5 className="text-primary mt-1 ps-3 mb-3">S.W.O.T</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Usually strengths and weaknesses are internal aspects of your
            business (what are you good at, what are you not so good at), while
            opportunities and threats are external forces (what is likely to
            impact on your business in a negative or positive way).
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Strengths</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            top strengths or benefits of your business, and how you can protect
            and enhance them.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Weaknesses</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            your top weaknesses or issues, and how you can minimize them or do
            better.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Opportunities</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            top opportunities for your business and how you can access and take
            advantage of them.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary mt-1 ps-3 mb-3">Threats</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            your top threats, and how you can minimize them and do better.
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">
          Location,Location,Location
        </h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          Location is the place where a firm decides to site its operations.
          Location decisions can have a big impact on costs and revenues. A
          business needs to decide on the best location taking into account
          factors such as: Customers - is the location convenient for customers
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Day to Day Operattion</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mb-0 ps-3">
          The Day-to-day business operations are the activities that a business
          and its employees engage in on a daily basis for the purposes of
          generating a profit and increasing the inherent value of the business
          as a going concern. Describe how you will be running the company, and
          who will be managing the daily operations
        </p>
        <div className="border-dashed border-bottom my-3" />
      </Card.Body>

      <Card.Footer>
        <Row className="g-0 container mx-auto p-3 mb-0">
          <div className="float-end">
            <div className="text-end">
              <Link to="/BuzPitchSettings" className="text-decoration-none">
                <IconButton
                  variant="outline-success"
                  size="sm"
                  icon="fa-solid fa-arrow-left"
                  className="mb-1 mx-1"
                ></IconButton>
              </Link>
            </div>
          </div>
        </Row>
      </Card.Footer>
    </Card>
  );
});

export default ThePitch;

{
  /*
        <h5 className="mt-3">Main Points of a Business Plan</h5>
        <ol>
          <li>
            <h6>Executive Summary</h6>
            <p>
              A concise overview of the entire business plan, highlighting key
              points such as the business concept, market opportunity, unique
              selling proposition, financial projections, and funding
              requirements.
            </p>
          </li>
          <li>
            <h6>Company Description</h6>
            <p>
              An overview of the business, including its mission statement,
              legal structure, location, history (if applicable), and current
              status.
            </p>
          </li>
          <li>
            <h6>Market Analysis</h6>
            <p>
              Research on the industry, target market, and competitors. This
              section should include information on market size, growth trends,
              customer demographics, market needs, and competitive landscape.
            </p>
          </li>
          <li>
            <h6>Organization and Management</h6>
            <p>
              Details about the organizational structure of the company,
              including ownership, management team, and key personnel. This
              section may also include resumes of key team members.
            </p>
          </li>
          <li>
            <h6>Product or Service Line</h6>
            <p>
              Description of the products or services offered by the business,
              including their features, benefits, and unique selling points.
              This section may also cover any research and development
              activities.
            </p>
          </li>
          <li>
            <h6>Marketing and Sales Strategy</h6>
            <p>
              A plan for how the business will attract and retain customers.
              This includes information on pricing, distribution channels,
              advertising and promotion, and sales tactics.
            </p>
          </li>
          <li>
            <h6>Funding Request</h6>
            <p>
              If seeking funding, this section outlines the amount of funding
              required, how it will be used, and the potential return on
              investment for investors.
            </p>
          </li>
          <li>
            <h6>Financial Projections</h6>
            <p>
              Forecasted financial statements, including income statement, cash
              flow statement, and balance sheet. This section typically includes
              projections for the next three to five years and assumptions used
              in the projections.
            </p>
          </li>
          <li>
            <h6>Appendix</h6>
            <p>
              Additional documents or information that support the main sections
              of the business plan. This may include resumes of key personnel,
              detailed market research, legal documents, and any other relevant
              materials.
            </p>
          </li>
        </ol>
      */
}
