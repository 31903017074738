import React, { useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { SelectedCompanyContext } from 'context/Context';
import { currencies } from 'utils';

const LoanDonutChart = ({ interest, principal }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  // Assuming companyDetails.currency is the currency code (e.g., 'USD', 'EUR')
  const currencySymbol = currencies[companyDetails.currency];

  const data = {
    labels: ['Interest', 'Principal'],
    datasets: [
      {
        data: [interest, principal],
        backgroundColor: ['#87CEEB', '#4682B4'], // light blue and steel blue
        hoverBackgroundColor: ['#00BFFF', '#4169E1'], // deep sky blue and royal blue
        cutout: '50%', // makes it a donut chart
        hoverOffset: 10 // makes the section move on hover
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw.toLocaleString(); // format value with commas
            const label = data.labels[tooltipItem.dataIndex];
            return `${label}: ${currencySymbol}${value}`;
          }
        }
      }
    },
    animation: {
      animateScale: true, // enables scaling animation
      animateRotate: true // enables rotation animation
    }
  };

  return <Doughnut data={data} options={options} />;
};

export default LoanDonutChart;
