import illustration1 from 'assets/img/icons/spot-illustrations/50.png';
import illustration2 from 'assets/img/icons/spot-illustrations/49.png';
import illustration3 from 'assets/img/icons/spot-illustrations/48.png';

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'PLAN',
    color: 'danger',
    title: 'Plan today, succeed tomorrow!',
    description:
      'With AuziPlan as your guide, now you have a fine-tuned state of the art tool to make your business planing a reality.',
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'DEVELOP ',
    color: 'info',
    title: 'Powerful Toolkit',
    description:
      "Easily build your business plan using AuziPlan's powerful toolkit, designed to guide you through crafting your successful venture.",
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'PRINT',
    color: 'success',
    title: 'Financial Reports',
    description:
      'Produce impeccably detailed financial reports, meticulously tailored for impactful investor presentations, or utilize them to secure crucial bank financing with confidence and professionalism.',
    image: illustration3
  }
];
