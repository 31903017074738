import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';

function PlantAndEquipmentList({ close }) {
  const [plantAndEquipments, setPlantAndEquipments] = useState([]);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [totalDepreciation, setTotalDepreciation] = useState(0);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/plant-And-Equipment/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setPlantAndEquipments(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
      return;
    }
    toast.error('No company selected, you must setup a new company to start.');
  }, [companyDetails]);

  useEffect(() => {
    setTotalDepreciation(
      plantAndEquipments.reduce(
        (acc, plantAndEquipment) =>
          acc +
          parseFloat(
            plantAndEquipment.totalDepreciation.replace(/[^0-9.-]+/g, '')
          ),
        0
      )
    );
  }, [plantAndEquipments]);

  return (
    <>
      {/* Scrollable table container */}
      <div
        className="scrollable-table"
        style={{ maxHeight: '500px', overflowY: 'auto' }}
      >
        <Table responsive class="table table-success table-striped">
          <thead>
            <tr>
              <th scope="col">Quantity</th>
              <th scope="col">Item Description</th>
              <th scope="col">Purchase Price</th>
              <th scope="col">Useful Life</th>
              <th scope="col">GST/VAT</th>
              <th scope="col">Total Depreciation</th>
              <th scope="col">Depreciation Yearly</th>
              <th scope="col">Depreciation Monthly</th>
              <th scope="col">Depreciation Percentage</th>
              <th className="text-end" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {plantAndEquipments.map(plantAndEquipment => (
              <Item
                key={plantAndEquipment.id}
                details={plantAndEquipment}
                plantAndEquipments={plantAndEquipments}
                setPlantAndEquipments={setPlantAndEquipments}
                close={close}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="12"
                className="text-left text-success"
                style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
              >
                <span className="fw-bold">
                  Total Cost:{' '}
                  {`${
                    currencies[companyDetails.currency]
                  } ${totalDepreciation.toLocaleString('en-US')}`}
                </span>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
}

PlantAndEquipmentList.propTypes = {
  close: PropTypes.func
};

export default PlantAndEquipmentList;
