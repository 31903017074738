import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Overlay,
  Popover,
  Card,
  Col,
  Row,
  Modal,
  Button
} from 'react-bootstrap';
import { CompanyWizardContext } from 'context/Context';
import { currencies } from 'utils';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import PageHeader from 'components/common/PageHeader';
import Background from 'components/common/Background';
import gallery2 from 'assets/img/illustrations/corner-4.png';
import editing from 'assets/img/icons/spot-illustrations/6.svg';

const OpeningBalancesForm = ({
  register,
  errors,
  watch,
  setValue,
  getValues
}) => {
  const { company } = useContext(CompanyWizardContext);
  const symbol = company.currency && currencies[company.currency];
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const ref = useRef(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  {
    /* Modal for bank balance notification */
  }

  // Show the modal when the component mounts
  useEffect(() => {
    setShowModal(true);
  }, []); // Empty dependency array means this runs once when the component mounts

  const handleModalClose = () => setShowModal(false);
  const handleModalSave = () => {
    // Logic to handle the startup capital amount and proceed with form submission
    handleModalClose();
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Company Opening Balances"
              description=" "
              className="text-primary"
            />

            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <div className="border-none rounded mt-0">
              <Row className="mb-3">
                <div ref={ref}>
                  <Overlay
                    show={show}
                    target={target}
                    placement="right"
                    container={ref.current}
                    containerPadding={20}
                  >
                    <Popover id="popover-contained">
                      <Popover.Header as="h3">Help Center</Popover.Header>
                      <Popover.Body as="h6">
                        <PageHeader
                          title="Opening Balances"
                          description=""
                          className="text-primary"
                        />
                        <div className="border-dashed border-bottom my-3" />
                        <div
                          className="border-none rounded mt-1"
                          style={{ maxHeight: '280px', overflowY: 'auto' }}
                        >
                          <h6 className="fw-bold mt-1">Bank Balance</h6>
                          <div className="border-dashed border-bottom my-3" />
                          <p>
                            Enter the exact amount of money currently available
                            in your account.
                          </p>
                          <div className="border-dashed border-bottom my-3" />
                          <h6>Creditors Balance</h6>
                          <div className="border-dashed border-bottom my-3" />
                          <p>
                            Specify any outstanding amounts you owe to
                            suppliers.
                          </p>
                          <div className="border-dashed border-bottom my-3" />
                          <h6>Debtors Balance</h6>
                          <div className="border-dashed border-bottom my-3" />
                          <p>
                            Indicate the total amount owed to you by your
                            customers.
                          </p>
                          <div className="border-dashed border-bottom my-3" />
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </Row>
            </div>
          </Row>

          <Row className="mt-0 mb-0 g-3">
            <Col lg={3}>
              <CurrencyWizardInput
                symbol={symbol}
                name="bankBalance"
                label="Bank Balance"
                errors={errors}
                formGroupProps={{ className: 'mb-1' }}
                formControlProps={{
                  ...register('bankBalance', {
                    required: 'This field is required'
                  })
                }}
                setValue={setValue}
                watch={watch}
              />
            </Col>

            <Col lg={3}>
              <CurrencyWizardInput
                symbol={symbol}
                label="Debtors Balance"
                name="debtorsBalance"
                errors={errors}
                formGroupProps={{ className: 'mb-1' }}
                formControlProps={{
                  ...register('debtorsBalance', {
                    required: 'This field is required'
                  })
                }}
                setValue={setValue}
                watch={watch}
              />
            </Col>

            <Col lg={3}>
              <CurrencyWizardInput
                symbol={symbol}
                label="Creditors Balance"
                name="creditorsBalance"
                errors={errors}
                formGroupProps={{ className: 'mb-1' }}
                formControlProps={{
                  ...register('creditorsBalance', {
                    required: 'This field is required'
                  })
                }}
                setValue={setValue}
                watch={watch}
              />
            </Col>
            <Col lg={3}>
              <CurrencyWizardInput
                symbol={symbol}
                label="Other Liabilities"
                name="otherLiabilities"
                errors={errors}
                formGroupProps={{ className: 'mb-1' }}
                formControlProps={{
                  ...register('otherLiabilities', {
                    required: 'This field is required'
                  })
                }}
                setValue={setValue}
                watch={watch}
              />
            </Col>
          </Row>

          <div className="border-dashed border-bottom my-3" />
        </Card.Body>
      </Card>

      {/* Modal for bank balance notification 
      <Modal show={showModal} onHide={handleModalClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Bank Balance Alert</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="align-items-center justify-content-center pt-1">
            <Col xs={12} md={6} className="text-center">
              <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
                <img
                  src={editing}
                  className="img-fluid"
                  alt="Editing"
                  style={{ maxWidth: '80%', height: 'auto' }}
                />
              </div>
            </Col>
            <p className="text-primary pt-3">
              "Your bank balance should match the startup funding capital amount
              to ensure your balance sheet is accurate."
            </p>
          </Row>
        </Modal.Body>
      </Modal>

*/}
    </Row>
  );
};

OpeningBalancesForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func
};

export default OpeningBalancesForm;
