import React, { useContext } from 'react';
import { Container, Col, Row, Table } from 'react-bootstrap';
import { currencies } from 'utils';
import { SelectedCompanyContext } from 'context/Context';
import Header from './Header';

const Ratios = ({ reportData, isPdf }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <Container id="ratios">
      <Header />
      <h4 className="text-primary p-0">Key Ratios</h4>
      <Row>
        <Col>
          <div id="pdf-content" style={{ paddingTop: '0px' }}>
            <Table
              responsive
              className="styled-table"
              style={{
                color: `${isPdf ? 'black' : 'white'}`,
                marginBottom: '0',
                borderCollapse: 'collapse',
                width: '100%',
                tableLayout: 'fixed'
              }}
            >
              <thead>
                <tr className="">
                  <th colSpan={2}>Key Ratios</th>
                  <th>Year 1</th>
                  <th>Year 2</th>
                  <th>Year 3</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, index) => (
                  <tr key={index}>
                    <td colSpan={2}>{item.label}</td>
                    <td>
                      {item.isCurrency
                        ? `${currencies[companyDetails.currency]} ${item.year1}`
                        : `${item.year1}`}
                    </td>
                    <td>
                      {item.isCurrency
                        ? `${currencies[companyDetails.currency]} ${item.year2}`
                        : `${item.year2}`}
                    </td>
                    <td>
                      {item.isCurrency
                        ? `${currencies[companyDetails.currency]} ${item.year3}`
                        : `${item.year3}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Ratios;
