import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CompanyWizardContext } from 'context/Context';

const CompanyWizardProvider = ({ children }) => {
  const [company, setCompany] = useState({});
  const [step, setStep] = useState(1);
  const [edit, setEdit] = useState(false);
  const [disableForward, setDisableForward] = useState(false);

  const value = {
    company,
    setCompany,
    step,
    setStep,
    edit,
    setEdit,
    disableForward,
    setDisableForward
  };
  return (
    <CompanyWizardContext.Provider value={value}>
      {children}
    </CompanyWizardContext.Provider>
  );
};

CompanyWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CompanyWizardProvider;
