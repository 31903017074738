import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Container, Card, Table, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';
import PieChart from 'components/custom/lists/stage2/OwnerList/PieChart';
import { SelectedCompanyContext } from 'context/Context';

function OwnersList({ close }) {
  // State to hold the list of owners
  const [owners, setOwners] = useState([]);
  // Context to get the details of the selected company
  const { companyDetails } = useContext(SelectedCompanyContext);

  // Fetch owners data when companyDetails changes
  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/owners/getAll?companyId=${companyDetails.id}`)
        .then(response => setOwners(response.data))
        .catch(error => toast.error(error.message)); // Display error if API call fails
      return;
    }
    toast.error('No company selected, you must setup a new company to start.'); // Error message if no company is selected
  }, [companyDetails]);

  // Calculate total contributions when owners list changes
  const totalContributions = useMemo(() => {
    return owners.reduce(
      (acc, owner) =>
        acc + parseFloat(owner.ownerContribution.replace(/[^0-9.-]+/g, '')), // Extract numeric value from contribution string
      0
    );
  }, [owners]);

  // Render pie chart or a message if no owners data is available
  const renderPieChart = () => {
    if (owners.length > 0) {
      return <PieChart owners={owners} />;
    }
    return <p>No owners data available.</p>;
  };

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 5
  });

  return (
    <Card style={{ width: '100%' }}>
      <Card.Body>
        <Row className="align-items-left justify-content-left pt-0">
          {/* Column for PieChart */}
          <Col xs={12} md={5} className="text-center">
            <div style={{ width: '100%', height: '400px' }}>
              {renderPieChart()}
            </div>
          </Col>

          {/* Column for owners table */}
          <Col xs={12} md={7} className="text-left">
            <Table responsive className="styled-table">
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Role</th>
                  <th scope="col">Contribution</th>
                  <th scope="col">Percentage Equity</th>
                  <th className="text-end" scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Map through owners and render each Item component */}
                {owners.map(owner => (
                  <Item
                    key={owner.id}
                    details={owner}
                    owners={owners}
                    setOwners={setOwners}
                    close={close}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan="100%"
                    className="text-left text-success"
                    style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
                  >
                    <span className="fw-bold">
                      Total Contributions:{' '}
                      {`${
                        currencies[companyDetails.currency]
                      } ${totalContributions.toLocaleString('en-US')}`}
                    </span>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

// Define PropTypes to ensure correct props are passed
OwnersList.propTypes = {
  close: PropTypes.func // Function to close the component, if provided
};

export default OwnersList;
