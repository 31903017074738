import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { currencies } from 'utils';
import { parseCurrency } from 'utils';

function Item({
  number,
  details,
  productsRevenues,
  setProductsRevenues,
  close
}) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleDelete = async () => {
    try {
      await api.delete(`/products-revenue/delete/${details.id}`);
      toast.success('Product Revenue deleted');
      const updatedProductsRevenues = productsRevenues.filter(
        productsRevenues => productsRevenues.id !== details.id
      );
      setProductsRevenues(updatedProductsRevenues);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting product revenue');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('products');
    setEditDetails(details);
    setStep(1);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{number}</td>
        <td>{details.monthlyUnitsSold}</td>
        <td>{details.productDescription}</td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.costPricePerUnit).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.retailPricePerUnit).toLocaleString()}
        </td>
        <td>{details.monthsOfInventory}</td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.totalYearlyRevenue).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.totalYearlyCostProjection).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.yearlyGrossProfitProjection).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.startupBudget).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.weeklyProjection).toLocaleString()}
        </td>
        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.productDescription}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Revenue?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  productsRevenues: PropTypes.array,
  setProductsRevenues: PropTypes.func,
  close: PropTypes.func
};

export default Item;
