import React, { useContext } from 'react';
import { Container, Col, Row, Table } from 'react-bootstrap';
import { SelectedCompanyContext } from 'context/Context';
import { currencies } from 'utils';
import Header from './Header';

const BalanceSheet = ({ reportData, isPdf }) => {
  const { companyDetails } = useContext(SelectedCompanyContext);

  return (
    <Container id="balance-sheet">
      <Header />
      <h4 className="text-primary p-0">Balance Sheet</h4>

      <Row>
        <Col>
          <div id="pdf-content" style={{ paddingTop: '20px' }}>
            <Table
              responsive
              className="styled-table"
              style={{
                color: `${isPdf ? 'black' : 'white'}`,
                marginBottom: '0',
                borderCollapse: 'collapse',
                width: '100%',
                tableLayout: 'fixed'
              }}
            >
              <thead>
                <tr>
                  <th>Balance Sheet</th>
                  <th>Opening Balance</th>
                  <th>Year 1</th>
                  <th>Year 2</th>
                  <th>Year 3</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, index) => (
                  <React.Fragment key={index}>
                    {index === 0 && (
                      <tr>
                        <td
                          colSpan="1"
                          style={{ textAlign: 'left', fontWeight: 'bold' }}
                        >
                          Current Assets
                        </td>
                      </tr>
                    )}

                    {index === 8 && (
                      <tr>
                        <td
                          colSpan="1"
                          style={{ textAlign: 'left', fontWeight: 'bold' }}
                        >
                          Current Liabilities
                        </td>
                      </tr>
                    )}

                    {index === 13 && (
                      <tr>
                        <td
                          colSpan="1"
                          style={{ textAlign: 'left', fontWeight: 'bold' }}
                        >
                          Equity
                        </td>
                      </tr>
                    )}

                    <tr>
                      {item.bold ? (
                        <td>
                          <strong>{item.label}</strong>
                        </td>
                      ) : (
                        <td>{item.label}</td>
                      )}

                      <td
                        style={{
                          color: item.openingBalance < 0 ? 'red' : 'black'
                        }}
                      >
                        {item.openingBalance
                          ? `${
                              currencies[companyDetails.currency]
                            }${item.openingBalance.toLocaleString()}`
                          : ''}
                      </td>

                      <td style={{ color: item.year1 < 0 ? 'red' : 'black' }}>
                        {currencies[companyDetails.currency]}
                        {item.year1.toLocaleString()}
                      </td>

                      <td style={{ color: item.year2 < 0 ? 'red' : 'black' }}>
                        {currencies[companyDetails.currency]}
                        {item.year2.toLocaleString()}
                      </td>

                      <td style={{ color: item.year3 < 0 ? 'red' : 'black' }}>
                        {currencies[companyDetails.currency]}
                        {item.year3.toLocaleString()}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BalanceSheet;
