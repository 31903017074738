import React, { useState, useRef, useContext, useEffect } from 'react';
import { Card, Col, Row, Modal, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import FalconComponentCard from 'components/common/FalconComponentCard';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import IncomeStatement from '../components/IncomeStatement';
import BalanceSheet from '../components/BalanceSheet';
import Ratios from '../components/Ratios';
import CashFlow from '../components/CashFlow';
import ProfitabilityAnalysis from '../components/ProfitabilityAnalysis';
import OpeningCosts from '../components/OpeningCosts';
import CoverPage from '../components/CoverPage';
import TableOfContents from '../components/TableOfContents';
import Valuation from '../components/Valuation';

import { SelectedCompanyContext } from 'context/Context';

import editing from 'assets/img/illustrations/authentication-corner.png';

import Pitch from '../components/Pitch';
import PitchModal from '../components/PitchModal';
import { SketchPicker } from 'react-color'; // Import the color picker
import Loader from '../components/Loader';
import { splitImage } from 'utils';
import api from 'utils/api';
import { toast } from 'react-toastify';

const ReportsForm = () => {
  const [showPitchModal, setShowPitchModal] = useState(false);
  const [pitch, setPitch] = useState('accounting');
  const [report, setReport] = useState('cover-page');
  const [modalShow, setModalShow] = useState(false);
  const [backgroundModalShow, setBackgroundModalShow] = useState(false);
  const [printModalShow, setPrintModalShow] = useState(false); // New state for print modal
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedBackground, setSelectedBackground] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const res = await api.get(
          `/analysis/reports?companyId=${companyDetails.id}`
        );
        setReportData(res.data);
      } catch (error) {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          const status = error.response?.status;
          const message =
            error.response?.data?.message ||
            error.response?.data ||
            'Something went wrong!';
          if (status === 500) {
            toast.error('Server error', { theme: 'colored' });
          } else if (status === 401 || status === 400) {
            toast.error(message, { theme: 'colored' });
          } else {
            toast.error(message, { theme: 'colored' });
          }
        } else {
          toast.error('Network error', { theme: 'colored' });
        }
      }
    };

    fetchReportData();
  }, [companyDetails]);

  const tableRef = useRef(null);
  const generatePdf = async reports => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const marginSize = 10; // Margin size in mm

    const footerText = 'Private and Confidential';
    const date = new Date().toLocaleDateString(); // Format date as needed

    let pageNumber = 1;

    for (let i = 0; i < reports.length; i++) {
      const reportId = reports[i];
      const reportContent = document.querySelector(`#${reportId}`);

      if (reportContent) {
        const hiddenContainer = document.createElement('div');
        hiddenContainer.style.position = 'absolute';
        hiddenContainer.style.top = '-9999px';
        hiddenContainer.style.left = '-9999px';
        hiddenContainer.style.width = '210mm'; // A4 width
        hiddenContainer.style.padding = '10mm'; // Add padding for better layout
        hiddenContainer.style.overflow = 'visible';
        hiddenContainer.style.background = backgroundColor;

        document.body.appendChild(hiddenContainer);

        const clonedContent = reportContent.cloneNode(true);
        hiddenContainer.appendChild(clonedContent);

        // Simplify styles to reduce rendering time
        clonedContent.style.background = 'transparent';
        clonedContent.style.backgroundImage = `url(${selectedBackground})`;
        clonedContent.style.backgroundSize = 'cover'; // Make the background image cover the entire area
        clonedContent.style.backgroundPosition = 'top left'; // Position the background image
        clonedContent.style.backgroundRepeat = 'no-repeat'; // Avoid repeating the background image

        clonedContent.querySelectorAll('*').forEach(element => {
          element.style.background = 'transparent';
          element.style.color = 'black';
          element.style.border = 'none';
          element.style.boxShadow = 'none';
        });

        // Generate background color canvas
        const bgCanvas = document.createElement('canvas');
        bgCanvas.width = pdfWidth * 4; // Increase resolution
        bgCanvas.height = pdfHeight * 4;
        const bgContext = bgCanvas.getContext('2d');

        // Fill the entire canvas with the background color
        bgContext.fillStyle = backgroundColor;
        bgContext.fillRect(0, 0, bgCanvas.width, bgCanvas.height);

        const bgImgData = bgCanvas.toDataURL('image/jpeg');

        // Add background color to PDF
        pdf.addImage(bgImgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);

        // Generate content canvas
        const canvas = await html2canvas(hiddenContainer, {
          scale: 2, // Lower scale for performance
          useCORS: true
        });

        const imgData = canvas.toDataURL('image/jpeg', 0.7);
        const imgHeight =
          (canvas.height * (pdfWidth - 2 * marginSize)) / canvas.width;

        if (imgHeight > pdfHeight) {
          const splitImages = await splitImage(
            imgData,
            reportId === 'pitch' ? pdfHeight * 7.7 : pdfHeight * 7.5,
            backgroundColor
          );

          for (let i = 0; i < splitImages.length; i++) {
            const image = splitImages[i];
            if (i > 0) {
              pdf.addPage(); // Add a new page for each split image
            }

            pdf.setFillColor(backgroundColor); // Use the desired background color
            pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

            // Create a new Image object from the split image data
            const img = new Image();
            img.src = image;

            await new Promise(resolve => {
              img.onload = () => {
                // Calculate the appropriate height for the image based on its aspect ratio
                const imageAspectRatio = img.width / img.height;
                const imageHeight =
                  (pdfWidth - 2 * marginSize) / imageAspectRatio;

                pdf.addImage(
                  image,
                  'JPEG',
                  0,
                  marginSize,
                  pdfWidth,
                  imageHeight - 2 * marginSize,
                  undefined,
                  'NONE' // This option prevents the background from being filled
                );

                // Apply the header line only to the first page
                if (i === 0) {
                  const headerHeight = 25;
                  const headerLineY = marginSize + headerHeight + 7;
                  pdf.setDrawColor(173, 216, 230); // Light blue color
                  pdf.setLineWidth(0.5);
                  pdf.line(
                    marginSize,
                    headerLineY,
                    pdfWidth - marginSize,
                    headerLineY
                  );
                }

                resolve();
              };

              img.onerror = () => {
                console.error('Failed to load image segment');
                resolve(); // Continue processing even if this image fails
              };
            });

            // Add page number to each page
            const pageHeightFooter = pdfHeight - marginSize + 10;
            pdf.setFontSize(10);
            pdf.text(`Date: ${date}`, marginSize + 5, pageHeightFooter - 15);
            pdf.text(footerText, pdfWidth / 2, pageHeightFooter - 15, {
              align: 'center'
            });
            pdf.text(
              `Page ${pageNumber}`,
              pdfWidth - marginSize - 5,
              pageHeightFooter - 15,
              { align: 'right' }
            );

            pageNumber++;
          }
        } else {
          pdf.addImage(
            imgData,
            'JPEG',
            0,
            marginSize,
            pdfWidth,
            imgHeight - 2 * marginSize // Use imgHeight directly if it fits within a single page
          );

          // Apply the header line for single-page reports
          const headerHeight = 25;
          const headerLineY = marginSize + headerHeight + 7;
          pdf.setDrawColor(173, 216, 230); // Light blue color
          pdf.setLineWidth(0.5);
          if (pageNumber == 1) {
            pdf.line(
              marginSize,
              headerLineY - 3,
              pdfWidth - marginSize,
              headerLineY - 3
            );
          } else {
            pdf.line(
              marginSize,
              headerLineY,
              pdfWidth - marginSize,
              headerLineY
            );
          }

          // Add page number
          const pageHeightFooter = pdfHeight - marginSize + 10;
          pdf.setFontSize(10);
          pdf.text(`Date: ${date}`, marginSize + 5, pageHeightFooter - 15);
          pdf.text(footerText, pdfWidth / 2, pageHeightFooter - 15, {
            align: 'center'
          });
          pdf.text(
            `Page ${pageNumber}`,
            pdfWidth - marginSize - 5,
            pageHeightFooter - 15,
            { align: 'right' }
          );

          pageNumber++;
        }

        // Add a new page for the next report
        if (i < reports.length - 1) {
          pdf.addPage();
        }

        document.body.removeChild(hiddenContainer);
      } else {
        console.error(`Element with ID ${reportId} not found`);
      }
    }

    // Save the PDF
    const pdfBlob = pdf.output('blob');
    return pdfBlob;
  };

  const handlePrintReport = async reports => {
    setLoading(true);
    const pdfBlob = await generatePdf(reports);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setLoading(false);
    window.open(pdfUrl);
  };

  const reportOrder = [
    'cover-page',
    'table-of-contents',
    'pitch',
    'opening-costs',
    'income-statement',
    'balance-sheet',
    'cash-flow',
    'profitability-analysis',
    'ratios',
    'valuation'
  ];

  const handleCheckboxChange = link => {
    setSelectedReports(prevSelectedReports => {
      let updatedReports;

      if (prevSelectedReports.includes(link)) {
        // Remove the report if it is already selected
        updatedReports = prevSelectedReports.filter(report => report !== link);
      } else {
        // Add the report and maintain the order
        updatedReports = [...prevSelectedReports, link];
      }

      // Reorder the array according to the predefined order
      return updatedReports.sort(
        (a, b) => reportOrder.indexOf(a) - reportOrder.indexOf(b)
      );
    });
  };

  const PrintSelectionModal = React.memo(
    ({
      cardData,
      selectedReports,
      handleCheckboxChange,
      printModalShow,
      setPrintModalShow,
      handlePrintReport,
      loading
    }) => (
      <>
        {printModalShow && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay
              zIndex: 1000
            }}
            onClick={() => setPrintModalShow(false)} // Close modal on overlay click
          />
        )}

        <Modal
          show={printModalShow}
          onHide={() => setPrintModalShow(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PageHeader
              title="Select Company"
              description="Please select a company report from the list below."
              className="mb-3"
            />

            <Row>
              <Col md={12}>
                {/* Left side: Report selection */}
                <div>
                  {!loading &&
                    cardData.map((card, index) => (
                      <div key={index} style={{ marginBottom: '12px' }}>
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedReports.includes(card.link)}
                            onChange={() => handleCheckboxChange(card.link)}
                            style={{ marginRight: '50px' }} // Space between checkbox and text
                          />
                          {card.title}
                        </label>
                      </div>
                    ))}
                  {loading && <Loader />}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              variant="outline-secondary"
              onClick={() => setPrintModalShow(false)}
            >
              Cancel
            </IconButton>
            <IconButton
              variant="outline-primary"
              onClick={() => handlePrintReport(selectedReports)}
            >
              Print Selected
            </IconButton>
            <IconButton
              variant="outline-success"
              onClick={() =>
                handlePrintReport([
                  'cover-page',
                  'table-of-contents',
                  'pitch',
                  'opening-costs',
                  'income-statement',
                  'balance-sheet',
                  'cash-flow',
                  'profitability-analysis',
                  'ratios',
                  'valuation'
                ])
              }
            >
              Print All
            </IconButton>
          </Modal.Footer>
        </Modal>
      </>
    )
  );

  const cardData = [
    { title: 'Cover Page', img: '21.png', link: 'cover-page' },
    { title: 'Table of Contents', img: '21.png', link: 'table-of-contents' },
    { title: 'Pitch', img: '21.png', link: 'pitch' },
    { title: 'Opening Costs', img: '21.png', link: 'opening-costs' },
    { title: 'Income Statement', img: '21.png', link: 'income-statement' },
    { title: 'Balance Sheet', img: '21.png', link: 'balance-sheet' },
    { title: 'Cashflow', img: '21.png', link: 'cash-flow' },
    {
      title: 'Profitability',
      img: '21.png',
      link: 'profitability-analysis'
    },
    { title: 'Key Ratios', img: '21.png', link: 'ratios' },
    { title: 'Valuation', img: '21.png', link: 'valuation' }
  ];

  const handleBackgroundSelect = imageUrl => {
    setSelectedBackground(imageUrl);
    setBackgroundModalShow(false);
  };

  return (
    <>
      <Row
        as={motion.div}
        className="g-3"
        initial={{ x: '-20%' }}
        animate={{ x: '0%' }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <Card className="pt-0">
          <Card.Body className="overflow-hidden p-lg-2 pt-0">
            <Row className="align-items-start">
              {/* Left Column: Card Selection */}
              <Col
                xl={3}
                className="d-flex flex-column align-items-center text-center"
                style={{ height: '800px', overflowY: 'auto' }}
              >
                <Row className="justify-content-center w-100">
                  <Col md={12} className="mb-4">
                    <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
                      <img
                        src={editing}
                        className="img-fluid"
                        alt="Editing"
                        style={{ maxWidth: '60%', height: 'auto' }}
                      />
                    </div>

                    <h5 className="p-2 text-primary">Report Menu</h5>

                    {cardData.map((card, index) => (
                      <Card
                        key={index}
                        className=" text-white mb-4 mx-auto"
                        style={{
                          width: '8rem',
                          height: '8rem',
                          maxWidth: '18rem',
                          cursor: 'pointer',
                          border:
                            report === card.link ? '2px solid #007bff' : 'none',
                          boxShadow:
                            report === card.link ? '0 0 15px #007bff' : 'none'
                        }}
                        onClick={() => setReport(card.link)}
                      >
                        <Card.Img
                          src={`${process.env.PUBLIC_URL}/template-assets/img/pitch/${card.img}`}
                          style={{ opacity: '0.9' }}
                          alt="Card image cap"
                        />
                        <Card.ImgOverlay className="d-flex flex-column justify-content-end">
                          <Card.Title
                            style={{
                              color: 'white',
                              fontSize: '0.60rem',
                              paddingTop: '10px'
                            }} // Adjust the padding value as needed
                          >
                            {card.title}
                          </Card.Title>
                        </Card.ImgOverlay>
                      </Card>
                    ))}
                  </Col>
                </Row>
              </Col>

              {/* Right Column: Report Content */}
              <Col
                xl={9}
                className="d-flex flex-column"
                style={{ height: '800px', overflowY: 'auto' }}
              >
                {reportData ? (
                  <div ref={tableRef} className="flex-grow-1">
                    <div
                      className={`${report !== 'cover-page' ? 'd-none' : ''}`}
                    >
                      <CoverPage />
                    </div>

                    <div
                      className={`${
                        report !== 'table-of-contents' ? 'd-none' : ''
                      }`}
                    >
                      <TableOfContents />
                    </div>

                    <div className={`${report !== 'pitch' ? 'd-none' : ''}`}>
                      <Pitch pitchName={pitch} />
                    </div>

                    <div
                      className={`${
                        report !== 'opening-costs' ? 'd-none' : ''
                      }`}
                    >
                      <OpeningCosts
                        report={report}
                        reportData={reportData.openingCostsObject}
                      />
                    </div>

                    <div
                      className={`${
                        report !== 'income-statement' ? 'd-none' : ''
                      }`}
                    >
                      <IncomeStatement
                        reportData={reportData.IncomeStatementObject}
                      />
                    </div>

                    <div
                      className={`${
                        report !== 'balance-sheet' ? 'd-none' : ''
                      }`}
                    >
                      <BalanceSheet
                        reportData={reportData.balanceSheetObject}
                      />
                    </div>

                    <div
                      className={`${report !== 'cash-flow' ? 'd-none' : ''}`}
                    >
                      <CashFlow
                        isPdf={loading}
                        reportData={reportData.cashFlowObject}
                      />
                    </div>

                    <div
                      className={`${
                        report !== 'profitability-analysis' ? 'd-none' : ''
                      }`}
                    >
                      <ProfitabilityAnalysis
                        reportData={reportData.profitObject}
                      />
                    </div>

                    <div className={`${report !== 'ratios' ? 'd-none' : ''}`}>
                      <Ratios reportData={reportData.ratioObject} />
                    </div>

                    <div
                      className={`${report !== 'valuation' ? 'd-none' : ''}`}
                    >
                      <Valuation reportData={reportData.ratioObject} />
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col className="d-flex justify-content-end align-items-end pt-3">
                <IconButton
                  variant="outline-info"
                  size="sm"
                  icon="fa fa-info-circle"
                  className="d-flex align-items-center ms-2"
                  onClick={() => setModalShow(true)}
                >
                  Help
                </IconButton>
                <IconButton
                  variant="outline-info"
                  size="sm"
                  icon="fa fa-info-circle"
                  className="d-flex align-items-center ms-2"
                  onClick={() => setShowPitchModal(true)}
                >
                  Select Pitch
                </IconButton>
                <IconButton
                  variant="outline-primary"
                  size="sm"
                  icon="fa fa-paint-brush"
                  className="d-flex align-items-center ms-2"
                  onClick={() => setBackgroundModalShow(true)}
                >
                  Theme
                </IconButton>
                <IconButton
                  variant="outline-danger"
                  size="sm"
                  icon="fa fa-print"
                  className="d-flex align-items-center ms-2"
                  onClick={() => setPrintModalShow(true)}
                >
                  Print Options
                </IconButton>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>

      {/* Print Selection Modal */}
      <PrintSelectionModal
        cardData={cardData}
        selectedReports={selectedReports}
        handleCheckboxChange={handleCheckboxChange}
        printModalShow={printModalShow}
        setPrintModalShow={setPrintModalShow}
        handlePrintReport={handlePrintReport}
        loading={loading}
      />

      {/* Help Modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Help Center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PageHeader
            className="text-primary"
            title="Company Pitch"
            description=""
          />
          <div
            className="border-none rounded mt-2"
            style={{ maxHeight: '500px', overflowY: 'auto' }}
          >
            <Row>
              <div
                className="border-none rounded mt-0"
                style={{ maxHeight: '500px', overflowY: 'auto' }}
              >
                <h6 className="fw-bold mt-1">Introduction</h6>
                <div className="border-dashed border-bottom my-3" />
                <p>
                  A business pitch is a concise presentation designed to
                  persuade potential investors, partners, or customers about the
                  viability, benefits, and potential success of a business idea,
                  product, or service. It should be engaging, clear, and
                  compelling, conveying both the vision and the practicality of
                  the business concept.
                </p>
                <p>
                  It serves as a roadmap for entrepreneurs, investors, and
                  stakeholders, providing a clear understanding of the business
                  concept, market analysis, financial projections, and
                  implementation strategy.
                </p>
                <p>
                  A well-crafted business plan not only articulates the business
                  idea but also demonstrates its feasibility, potential for
                  growth, and profitability.
                </p>
                <p>
                  The ultimate goal of a business pitch is to secure support,
                  whether it be in the form of funding, partnerships, or market
                  traction, to bring the business idea to fruition.
                </p>
                <h6 className="fw-bold mt-4">Report Types</h6>
                <div className="border-dashed border-bottom my-3" />
                <p>
                  The reports in this section include financial statements and
                  analyses that provide insights into various aspects of your
                  business's financial health. Each report type serves a
                  specific purpose:
                </p>
                <ul>
                  <li>
                    <strong>Cover Page:</strong> The introductory page of your
                    report, providing a summary and an overview of the report's
                    content.
                  </li>
                  <li>
                    <strong>Income Statement:</strong> An overview of revenues,
                    expenses, and profits over a specific period, highlighting
                    the business's profitability.
                  </li>
                  <li>
                    <strong>Balance Sheet:</strong> A snapshot of the business's
                    financial position, including assets, liabilities, and
                    equity.
                  </li>
                  <li>
                    <strong>Cash Flow:</strong> A report on the inflow and
                    outflow of cash, showing how cash is generated and used.
                  </li>
                  <li>
                    <strong>Ratios:</strong> Key financial ratios that provide
                    insights into the business's performance and financial
                    health.
                  </li>
                </ul>
                <p>
                  Use these reports to analyze your business's financial
                  performance, make informed decisions, and demonstrate the
                  viability of your business to potential investors or partners.
                </p>
              </div>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-success"
            onClick={() => setModalShow(false)}
            size="sm"
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>

      {/* Background Selector Modal */}
      {backgroundModalShow && ( // Only show overlay when the modal is open
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay
            zIndex: 1000 // Ensure it covers everything else
          }}
          onClick={() => setBackgroundModalShow(false)} // Close modal on overlay click
        />
      )}

      <Modal
        show={backgroundModalShow}
        onHide={() => setBackgroundModalShow(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Background Image & Color</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex" style={{ justifyContent: 'space-between' }}>
            {/* Images Section */}
            <div
              className="d-flex flex-wrap"
              style={{ width: '60%', padding: '10px' }}
            >
              {/* Use the current image for the first item */}
              <div className="p-2">
                <img
                  src={editing} // Current image for the first item
                  alt="Background 1"
                  className="img-thumbnail cursor-pointer"
                  onClick={() => handleBackgroundSelect(editing)}
                  style={{ maxWidth: '200px' }}
                />
              </div>

              {/* Other separate images */}
              <div className="p-2">
                <img
                  src={editing} // Current image for the second item
                  alt="Background 2"
                  className="img-thumbnail cursor-pointer"
                  onClick={() =>
                    handleBackgroundSelect(
                      'assets/img/illustrations/image2.png'
                    )
                  }
                  style={{ maxWidth: '200px' }}
                />
              </div>
              <div className="p-2">
                <img
                  src={editing} // Current image for the third item
                  alt="Background 3"
                  className="img-thumbnail cursor-pointer"
                  onClick={() =>
                    handleBackgroundSelect(
                      'assets/img/illustrations/image3.png'
                    )
                  }
                  style={{ maxWidth: '200px' }}
                />
              </div>
              <div className="p-2">
                <img
                  src={editing} // Current image for the fourth item
                  alt="Background 4"
                  className="img-thumbnail cursor-pointer"
                  onClick={() =>
                    handleBackgroundSelect(
                      'assets/img/illustrations/image4.png'
                    )
                  }
                  style={{ maxWidth: '200px' }}
                />
              </div>
            </div>

            {/* Sketch Picker Section */}
            <div
              className="color-picker"
              style={{ width: '35%', padding: '10px' }}
            >
              <SketchPicker
                color={backgroundColor}
                onChangeComplete={color => setBackgroundColor(color.hex)} // Handle color change
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-success"
            onClick={() => setBackgroundModalShow(false)}
            size="sm"
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>

      <PitchModal
        showPitchModal={showPitchModal}
        setShowPitchModal={setShowPitchModal}
        setPitch={setPitch}
        pitch={pitch}
      />
    </>
  );
};

export default ReportsForm;
