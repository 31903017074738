import Flex from 'components/common/Flex';
import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  FloatingLabel,
  ProgressBar,
  FormSelect,
  FormGroup,
  Collapse
} from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { ReportsFormModalContext } from 'context/Context';
import ReportsWizard from 'components/custom/wizards/reportWizard/ReportsWizard';

const ReportsSetting = () => {
  const { showFormModal, setShowFormModal } = useContext(
    ReportsFormModalContext
  );

  const [modalShow, setModalShow] = React.useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  return (
    <Container
      as={motion.div} // Using motion.div from framer-motion for animation
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        // Animation variants for initial and visible states
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      {/* Image Row */}
      <Row className="align-items-center justify-content-center pt-6">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '550px', overflow: 'hidden' }}>
            <img
              src={editing}
              className="img-fluid"
              alt="Editing"
              style={{ maxWidth: '60%', height: 'auto' }}
            />
          </div>
        </Col>
      </Row>

      {/* Message Row */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            <h2 className="text-primary pt-0">Reports</h2>
            <p>
              A company financial report is a formal document summarizing a
              company’s financial performance, key metrics, and strategic
              direction. It includes financial statements, revenue and expense
              analysis, and market trends, helping stakeholders and investors
              make informed decisions and assess the company's progress.
            </p>
          </div>
        </Col>
      </Row>

      {/* Navigation Row */}
      <Row className="align-items-center justify-content-center pt-4">
        <Col xs={12} md={6} className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            {/* Link to previous page */}
            <Link
              to="/operatingExpensesSettings"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-success"
                icon="fa-solid fa-arrow-left"
                size="lg"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
            {/* Link to help center */}
            <Link
              to="/help-center#financialReports"
              className="text-decoration-none"
            >
              <IconButton
                variant="outline-primary"
                size="lg"
                icon="fa-solid fa-info"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
            {/* Button to show form modal */}
            <IconButton
              onClick={() => setShowFormModal(true)}
              variant="outline-primary"
              size="lg"
              icon="fa-solid fa-search"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
            />

            {/* Link to next page */}
            <Link to="/reports" className="text-decoration-none">
              <IconButton
                variant="outline-success"
                size="lg"
                icon="fa-solid fa-arrow-right"
                className="mb-1 mx-1"
                iconAlign="right"
                transform="shrink-3"
              />
            </Link>
          </div>
        </Col>
      </Row>

      <Modal
        show={showFormModal}
        aria-label="contained-modal-title-vcenter"
        entered
        //size="xl"
        fullscreen={fullscreen}
      >
        <Modal.Body>
          <Container>
            <ReportsWizard variant="pills" validation={false} />
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ReportsSetting;
