import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table, Tabs, Tab, Row, Form, Container, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';
import { parseCurrency } from 'utils';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const PageHeader = ({ title, description }) => (
  <div className="page-header pt-0 mb-0">
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const getTabDescription = tabKey => {
  switch (tabKey) {
    case 'purchasePrice':
      return '';
    case 'additionalCosts':
      return '';
    case 'summary':
      return '';
    default:
      return '';
  }
};

function WholeSaleList({ close }) {
  const [wholeSaleRevenues, setWholeSaleRevenues] = useState([]);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const [totals, setTotals] = useState({
    totalStartupBudget: 0,
    totalYearlyRevenue: 0,
    totalYearlyCost: 0,
    monthlyRevenue: 0,
    weeklyRevenue: 0
  });
  const [activeTab, setActiveTab] = useState('purchasePrice');

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/wholeSale-revenue/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setWholeSaleRevenues(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
    } else {
      toast.error(
        'No company selected, you must set up a new company to start.'
      );
    }
  }, [companyDetails]);

  useEffect(() => {
    const calculatedTotals = wholeSaleRevenues.reduce(
      (acc, wholeSaleRevenue) => {
        acc.totalStartupBudget += parseFloat(
          wholeSaleRevenue.totalStartupBudget || 0
        );
        acc.totalYearlyRevenue += parseCurrency(
          wholeSaleRevenue.totalYearlyRevenue || 0
        );
        acc.totalYearlyCost += parseCurrency(
          wholeSaleRevenue.totalYearlyCost || 0
        );
        acc.monthlyRevenue += parseCurrency(
          wholeSaleRevenue.monthlyRevenue || 0
        );
        acc.weeklyRevenue += parseCurrency(wholeSaleRevenue.weeklyRevenue || 0);

        return acc;
      },
      {
        totalStartupBudget: 0,
        totalYearlyRevenue: 0,
        totalYearlyCost: 0,
        monthlyRevenue: 0,
        weeklyRevenue: 0
      }
    );
    setTotals(calculatedTotals);
  }, [wholeSaleRevenues]);

  // Tab navigation logic
  const goToNextTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex < tabKeys.length - 1) {
      setActiveTab(tabKeys[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const tabKeys = ['purchasePrice', 'additionalCosts', 'summary'];
    const currentIndex = tabKeys.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabKeys[currentIndex - 1]);
    }
  };

  const [selectedYear, setSelectedYear] = useState('Year 1');

  const handleYearChange = event => {
    setSelectedYear(event.target.value);
  };

  // Data for each year
  const yearData = {
    'Year 1': {
      revenue: totals.totalYearlyRevenue,
      cost: totals.totalYearlyCost,
      profit: totals.totalYearlyRevenue - totals.totalYearlyCost
    },
    'Year 2': {
      revenue: totals.totalYearlyRevenue * 1.1,
      cost: totals.totalYearlyCost * 1.1,
      profit: totals.totalYearlyRevenue * 1.1 - totals.totalYearlyCost * 1.1
    },
    'Year 3': {
      revenue: totals.totalYearlyRevenue * 1.1 * 1.1,
      cost: totals.totalYearlyCost * 1.1 * 1.1,
      profit:
        totals.totalYearlyRevenue * 1.1 * 1.1 -
        totals.totalYearlyCost * 1.1 * 1.1
    }
  };

  const currentData = yearData[selectedYear];

  return (
    <div className="container">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={setActiveTab}
          id="wholesale-revenue-tabs"
          className="mb-3"
        >
          <Tab eventKey="purchasePrice" title="Wholesale Revenue Analysis">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Initial Order</th>
                    <th scope="col">Repeat Order</th>
                    <th scope="col">Range</th>
                    <th scope="col">Retail Price</th>
                    <th scope="col">Startup Months</th>
                    <th scope="col">Startup Budget</th>
                    <th scope="col">Total Order</th>
                    <th scope="col">Total Yearly Order</th>
                    <th scope="col">Cost Price</th>
                    <th scope="col">Yearly Cost</th>
                    <th scope="col">Yearly Revenue</th>
                    <th scope="col">Monthly Revenue</th>
                    <th scope="col">Weekly Revenue</th>
                    <th className="text-end" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {wholeSaleRevenues.map(wholeSaleRevenue => (
                    <Item
                      key={wholeSaleRevenue.id}
                      details={wholeSaleRevenue}
                      wholeSaleRevenues={wholeSaleRevenues}
                      setWholeSaleRevenues={setWholeSaleRevenues}
                      close={close}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className="text-left text-success fw-bold"
                    style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
                  >
                    <td>Totals</td>
                    <td colSpan={5}></td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalStartupBudget.toLocaleString()}
                    </td>
                    <td colSpan={3}></td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalYearlyCost.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalYearlyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.monthlyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.weeklyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </Row>
          </Tab>

          <Tab eventKey="additionalCosts" title="Yearly Summary">
            <Row className="pt-3">
              <Table responsive className="styled-table">
                <thead>
                  <tr>
                    <th>Metrics</th>
                    <th>Year 1</th>
                    <th>Year 2</th>
                    <th>Year 3</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Yearly Revenue</td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalYearlyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {(totals.totalYearlyRevenue * 1.1).toLocaleString(
                        'en-US',
                        {
                          maximumFractionDigits: 2
                        }
                      )}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {(totals.totalYearlyRevenue * 1.1 * 1.1).toLocaleString(
                        'en-US',
                        {
                          maximumFractionDigits: 2
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Yearly Cost</td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {totals.totalYearlyCost.toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {(totals.totalYearlyCost * 1.1).toLocaleString('en-US', {
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {(totals.totalYearlyCost * 1.1 * 1.1).toLocaleString(
                        'en-US',
                        {
                          maximumFractionDigits: 2
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Yearly Gross Profit</td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {(
                        totals.totalYearlyRevenue - totals.totalYearlyCost
                      ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {(
                        totals.totalYearlyRevenue * 1.1 -
                        totals.totalYearlyCost * 1.1
                      ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </td>
                    <td>
                      {currencies[companyDetails.currency]}{' '}
                      {(
                        totals.totalYearlyRevenue * 1.1 * 1.1 -
                        totals.totalYearlyCost * 1.1 * 1.1
                      ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Tab>
          <Tab eventKey="summary" title="Charts">
            <Container>
              <Row className="pt-3">
                <Col xs={12} lg={6}>
                  <Pie
                    data={{
                      labels: ['Revenue', 'Cost', 'Gross Profit'],
                      datasets: [
                        {
                          data: [
                            currentData.revenue,
                            currentData.cost,
                            currentData.profit
                          ],
                          backgroundColor: [
                            'rgba(54, 162, 235, 0.6)', // Light blue
                            'rgba(75, 192, 192, 0.6)', // Teal
                            'rgba(153, 102, 255, 0.6)' // Light purple
                          ]
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top'
                        },
                        title: {
                          display: true,
                          text: `${selectedYear} - Revenue, Cost, and Gross Profit Breakdown`
                        }
                      }
                    }}
                  />
                </Col>

                <Col xs={12} lg={6}>
                  <Row className="pt-3">
                    <Form.Select
                      onChange={handleYearChange}
                      value={selectedYear}
                    >
                      <option>Year 1</option>
                      <option>Year 2</option>
                      <option>Year 3</option>
                    </Form.Select>
                  </Row>
                  <Row className="pt-3">
                    <Bar
                      data={{
                        labels: [selectedYear],
                        datasets: [
                          {
                            label: 'Yearly Revenue',
                            data: [currentData.revenue],
                            backgroundColor: 'rgba(54, 162, 235, 0.6)' // Light blue
                          },
                          {
                            label: 'Yearly Cost',
                            data: [currentData.cost],
                            backgroundColor: 'rgba(75, 192, 192, 0.6)' // Teal
                          },
                          {
                            label: 'Yearly Gross Profit',
                            data: [currentData.profit],
                            backgroundColor: 'rgba(153, 102, 255, 0.6)' // Light purple
                          }
                        ]
                      }}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: 'top'
                          },
                          title: {
                            display: true,
                            text: `${selectedYear} - Revenue, Cost, and Gross Profit`
                          }
                        }
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          </Tab>
        </Tabs>

        <div className="border-dashed border-bottom my-3" />
        <Row className=" mb-3">
          <Col className="text-center mt-3">
            <IconButton
              variant="outline-primary"
              size="sm"
              onClick={goToPreviousTab}
              disabled={activeTab === 'orderDetails'}
            >
              <FaArrowLeft /> Previous
            </IconButton>
            <IconButton
              variant="outline-success"
              size="sm"
              onClick={goToNextTab}
              disabled={activeTab === 'summary'}
              className="ms-2"
            >
              Next <FaArrowRight />
            </IconButton>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
}

WholeSaleList.propTypes = {
  close: PropTypes.func.isRequired
};

export default WholeSaleList;
