import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Container,
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { RevenueWizardContext } from 'context/Context';
import AccountingForm from './AccountingForm';

const ServiceForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { edit } = useContext(RevenueWizardContext);

  useEffect(() => {
    if (edit === 'service') {
      setSelectedDiv('div1');
    }
  }, [edit]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const [selectedDiv, setSelectedDiv] = useState(null);
  const handleSelectChange = event => {
    setSelectedDiv(event.target.value);
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Container>
        <Row className="align-items-center justify-content-between">
          <div className="mt-3">
            <IconButton
              variant="outline-primary"
              icon="fa-solid fa-info"
              onClick={handleClick}
              size="sm"
            ></IconButton>
          </div>

          <div ref={ref}>
            <Overlay
              show={show}
              target={target}
              placement="right"
              container={ref.current}
              containerPadding={20}
            >
              <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                <Popover.Header as="h3">Help Center</Popover.Header>
                <Popover.Body as="div" style={{ backgroundColor: '' }}>
                  {/* Your popover content */}
                  <Row
                    as={motion.div}
                    className="g-3"
                    initial={{ x: '-20%' }}
                    animate={{ x: '0%' }}
                    exit={{
                      x: window.innerWidth,
                      transition: { duration: 0.5 }
                    }}
                  >
                    <PageHeader
                      className="text-primary"
                      title="Revenue by Services"
                      description=""
                    />
                    <div
                      className="bor"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <Row>
                        <Container>
                          <h6>Description of Service Revenue</h6>
                          <p>
                            Service revenue typically refers to the income
                            generated by a company through the provision of
                            services rather than the sale of physical products.
                            It encompasses the fees charged for services
                            rendered, such as consulting, maintenance, repairs,
                            training, or any other service offered by the
                            business.
                          </p>
                          <p>
                            For example, a consulting firm earns service revenue
                            by providing advice or expertise to clients for a
                            fee, while a software company may generate service
                            revenue by offering technical support or training
                            services to its customers.
                          </p>
                          <p>
                            Service revenue is usually recognized on the income
                            statement when the service is performed or
                            delivered, rather than when payment is received.
                            This is in accordance with the accrual accounting
                            principle, which aims to match revenues with the
                            expenses incurred to generate them, providing a more
                            accurate picture of a company's financial
                            performance over time.
                          </p>
                          <p>
                            In financial statements, service revenue is
                            typically reported separately from other sources of
                            revenue, such as product sales, to provide
                            transparency into the different revenue streams of
                            the business. It's an essential metric for
                            evaluating the performance and growth of
                            service-based businesses.
                          </p>

                          <h5>Overview</h5>
                          <p>
                            The <strong>AccountingForm</strong> component is
                            designed to help users input and manage
                            revenue-related data for various services. It
                            calculates total weekly, monthly, and yearly revenue
                            based on user inputs like service type, rate per
                            hour, hours worked per week, and number of staff. It
                            also supports editing existing data and submitting
                            new or updated records.
                          </p>

                          <h5>Features</h5>
                          <ul>
                            <li>
                              <strong>Service Type Selection:</strong> Choose
                              from various service types such as Tax
                              Preparation, Auditing, Book Keeping, and Financial
                              Consulting.
                            </li>
                            <li>
                              <strong>Revenue Calculations:</strong>{' '}
                              Automatically calculates total weekly, monthly,
                              and yearly revenue based on the rate per hour,
                              hours worked per week, and number of staff.
                            </li>
                            <li>
                              <strong>Editing Mode:</strong> Allows editing of
                              existing data. Fields will be pre-filled with
                              current data when editing.
                            </li>
                            <li>
                              <strong>Form Submission:</strong> Save or update
                              the data with the option to add more records after
                              submission.
                            </li>
                          </ul>

                          <h5>How to Use the Form</h5>
                          <ol>
                            <li>
                              <strong>Service Type:</strong> Select the type of
                              service you are offering from the dropdown menu.
                            </li>
                            <li>
                              <strong>Rate Per Hour:</strong> Enter the rate
                              each staff member charges per hour.
                            </li>
                            <li>
                              <strong>Number of Staff:</strong> Specify how many
                              staff members you will employ.
                            </li>
                            <li>
                              <strong>Hours Per Week:</strong> Enter the number
                              of hours each staff member works per week.
                            </li>
                            <li>
                              <strong>Revenue Display:</strong> View the
                              calculated total weekly, monthly, and yearly
                              revenue based on the inputs provided.
                            </li>
                            <li>
                              <strong>Submit Data:</strong> Click the{' '}
                              <strong>Save</strong> button to submit the form.
                              If in editing mode, it will update the existing
                              record.
                            </li>
                          </ol>

                          <h5>Additional Notes</h5>
                          <ul>
                            <li>
                              <strong>Validation:</strong> Ensure all required
                              fields are filled. Validation errors will be
                              displayed below the respective fields.
                            </li>
                            <li>
                              <strong>Currency Symbol:</strong> The currency
                              symbol is automatically applied based on the
                              selected company’s currency.
                            </li>
                          </ul>

                          <h5>Troubleshooting</h5>
                          <ul>
                            <li>
                              <strong>Errors on Submission:</strong> Check if
                              all required fields are filled correctly. Network
                              or server errors may require contacting support.
                            </li>
                            <li>
                              <strong>Calculation Issues:</strong> Verify the
                              values entered for rate per hour, hours per week,
                              and number of staff. Ensure they are in the
                              correct format.
                            </li>
                          </ul>

                          <h5>Conclusion</h5>
                          <p>
                            The <strong>AccountingForm</strong> component
                            provides a streamlined way to manage and project
                            revenue data for various services. Use it to input
                            service details, view financial projections, and
                            update or create new records.
                          </p>
                        </Container>

                        <div className="border-dashed border-bottom my-3" />
                      </Row>
                    </div>
                  </Row>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>

          <div className="mt-3">
            <Form.Group controlId="industryType">
              <FloatingLabel label="Industry Type">
                <Form.Select
                  value={selectedDiv}
                  onChange={handleSelectChange}
                  aria-label="Select the Industry type"
                  name="industryType"
                >
                  <option value="">Select Industry</option>
                  <option value="div1">Service</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </div>

          {/* Centered Card */}
          <Row className="justify-content-center py-2">
            <Col md={8} lg={12}>
              {selectedDiv === 'div1' && (
                <Card>
                  <Card.Body>
                    <AccountingForm />
                  </Card.Body>
                </Card>
              )}
              {selectedDiv === 'div2' && <div></div>}
              {selectedDiv === 'div3' && <div></div>}
            </Col>
          </Row>
        </Row>
      </Container>
    </Row>
  );
};

export default ServiceForm;
