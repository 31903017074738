import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item'; // Assuming Item component is located in the same directory
import PropTypes from 'prop-types';

function MotorVehicleList({ close }) {
  // State variables
  const [motorVehicles, setMotorVehicles] = useState([]); // State to hold list of motor vehicles
  const [totalMonthlyRepayment, setTotalMonthlyRepayment] = useState(0); // State to calculate and hold total monthly repayment
  const { companyDetails } = useContext(SelectedCompanyContext); // Context to get selected company details

  // Effect to fetch motor vehicle data when companyDetails changes
  useEffect(() => {
    if (companyDetails.id) {
      // Fetch data from API for the selected company
      api
        .get(`/motor-vehicles/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setMotorVehicles(response.data); // Update state with fetched data
        })
        .catch(error => {
          toast.error(error.message); // Notify user of any fetch errors
        });
      return;
    }
    // Notify user if no company is selected
    toast.error('No company selected, you must setup a new company to start.');
  }, [companyDetails]); // Dependency array ensures effect runs when companyDetails changes

  // Effect to calculate total monthly repayment when motorVehicles state changes
  useEffect(() => {
    setTotalMonthlyRepayment(
      motorVehicles.reduce(
        (acc, motorVehicle) =>
          acc +
          parseFloat(motorVehicle.monthlyPayment.replace(/[^0-9.-]+/g, '')),
        0
      )
    );
  }, [motorVehicles]); // Dependency array ensures effect runs when motorVehicles changes

  // Render component
  return (
    <>
      {/* Scrollable table container */}
      <div
        className="scrollable-table"
        style={{ maxHeight: '500px', overflowY: 'auto' }}
      >
        <Table responsive class="table table-success table-striped">
          <thead>
            <tr>
              <th scope="col">Qty</th>
              <th scope="col">Vehicle Type</th>
              <th scope="col">Amount (Inc. GST)</th>
              <th scope="col">Interest Rate</th>
              <th scope="col">Monthly Repayment</th>
              <th className="text-end" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Render each motor vehicle item using the Item component */}
            {motorVehicles.map(motorVehicle => (
              <Item
                key={motorVehicle.id}
                details={motorVehicle}
                motorVehicles={motorVehicles}
                setMotorVehicles={setMotorVehicles}
                close={close}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              {/* Display total monthly repayment using company's currency */}
              <td
                colSpan="12"
                className="text-left text-success"
                style={{ backgroundColor: 'rgba(0, 123, 255, 0.1)' }}
              >
                <span className="fw-bold">
                  Total Monthly Repayment:{' '}
                  {`${
                    currencies[companyDetails.currency]
                  } ${totalMonthlyRepayment.toLocaleString('en-US')}`}
                </span>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
}

// PropTypes validation for MotorVehicleList component
MotorVehicleList.propTypes = {
  close: PropTypes.func // PropType for close function prop
};

export default MotorVehicleList;
