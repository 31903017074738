import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { currencies } from 'utils';
import { parseCurrency } from 'utils';

function Item({
  number,
  details,
  propertyRevenues,
  setPropertyRevenues,
  close
}) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleDelete = async () => {
    try {
      await api.delete(`/property-revenue/delete/${details.id}`);
      toast.success('Property Revenue deleted');
      const updatedpropertyRevenues = propertyRevenues.filter(
        propertyRevenues => propertyRevenues.id !== details.id
      );
      setPropertyRevenues(updatedpropertyRevenues);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting property revenue cost');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('property');
    setEditDetails(details);
    setStep(1);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{number}</td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.purchasePrice).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.kitchenTiling).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.carpentry).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.plumbing).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.architectDesigning).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.legalFees).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.propertyTaxes).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.insurance).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.marketingSalesCosts).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.financeCosts).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.utilities).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.contingencyFund).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.permitLicensingFees).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.sitePreparationCosts).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.professionalFees).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.sellingPrice).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.totalCost).toLocaleString()}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {parseCurrency(details.profitMargin).toLocaleString()}
        </td>
        <td>{details.profitMarginPercentage}</td>

        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.productDescription}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Revenue?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  propertyRevenues: PropTypes.array,
  setPropertyRevenues: PropTypes.func,
  close: PropTypes.func
};

export default Item;
