import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback
} from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import {
  BuzPitchFormModalContext,
  BuzPitchWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { addMoreNotification } from 'utils';
import PageHeader from 'components/common/PageHeader';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import TinymceEditor from 'components/common/TinymceEditor';

import FalconComponentCard from 'components/common/FalconComponentCard';
import SearchBox from 'components/navbar/top/SearchBox';
import autoCompleteInitialItem from 'data/autocomplete/autocomplete';

const CompanyPitchForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(BuzPitchWizardContext);
  const { setShowFormModal } = useContext(BuzPitchFormModalContext);
  const [selectedCard, setSelectedCard] = useState(null); // New state for selected card
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);
  const [pitchDescription, setPitchDescription] = useState('');
  const [selections, setSelections] = useState([]);
  const [loadingSelections, setLoadingSelections] = useState(false);
  const [pitches, setPitches] = useState([]);
  const [hoveredCard, setHoveredCard] = useState(null); // State to track hovered card

  const cardData = [
    {
      title: 'accounting',
      text: '',
      img: '48.png',
      width: '400px',
      height: '400px'
    },
    {
      title: 'fashion',
      text: '',
      img: '50.png',
      width: '400px',
      height: '400px'
    },
    {
      title: 'skinCare',
      text: '',
      img: '6.png',
      width: '400px',
      height: '400px'
    },
    {
      title: 'solicitor',
      text: '',
      img: '5.png',
      width: '400px',
      height: '400px'
    },
    {
      title: 'carpentry',
      text: '',
      img: 'creating.png',
      width: '400px',
      height: '400px'
    },
    {
      title: 'bakery',
      text: '',
      img: '49.png',
      width: '400px',
      height: '400px'
    }
  ];

  const cardStyle = {
    width: '8rem', // Set the width to 400px
    height: '8rem', // Set the height to 400px
    maxWidth: '20rem', // Optional: Limits width to 30rem if needed
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden' // Optional: Ensures content does not overflow the card
  };

  const cardSelectedStyle = {
    border: '2px solid #007bff',
    boxShadow: '0 0 15px #007bff'
  };

  const cardHoverStyle = {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)'
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      pitchName: '',
      companyPitchSelection: ''
    }
  });

  useEffect(() => {
    setLoadingSelections(true);
    api.get(`/pitches/selections/all`).then(res => {
      setSelections(res.data);
      setLoadingSelections(false);
    });
  }, []);

  useEffect(() => {
    setLoadingSelections(true);
    if (!companyDetails.id) return;

    api.get(`/pitches/getAll?companyId=${companyDetails.id}`).then(res => {
      setPitches(res.data);
      setValue('pitchName', res.data[0]?.pitchName || '');
      setValue(
        'companyPitchSelection',
        res.data[0]?.companyPitchSelection || ''
      );
      setLoadingSelections(false);
    });
  }, [companyDetails.id]);

  useEffect(() => {
    if (edit === 'pitch' && editDetails.id) {
      setValue('pitchName', editDetails.pitchName);
      setValue('companyPitchSelection', editDetails.companyPitchSelection);
      setIsEditing(true);
      setDocId(editDetails.id);
      setEdit('');
      setEditDetails({});
    }
  }, [edit]);

  useEffect(() => {
    setPitchDescription(
      pitches.find(
        selection =>
          selection?.companyPitchSelection === watch('companyPitchSelection')
      )?.pitchDescription || ''
    );
    // set pitch name
    setValue(
      'pitchName',
      pitches.find(
        selection =>
          selection?.companyPitchSelection === watch('companyPitchSelection')
      )?.pitchName || ''
    );
  }, [watch('companyPitchSelection')]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onCardClick = cardTitle => {
    setSelectedCard(cardTitle); // Set the selected card index locally
    handleCardClick(cardTitle); // Call the callback function passed as a prop
  };

  const handleCardClick = useCallback(
    title => {
      setValue('companyPitchSelection', title);
    },
    [setValue]
  );

  const onSubmitData = data => {
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : {
            companyId: companyDetails.id,
            pitchDescription,
            ...data
          };
    api
      .post(isEditing ? '/pitches/update' : '/pitches/create', payload)
      .then(res => {
        reset();
        setEdit('');
        setPitchDescription('');
        //setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message="Do you want to add more pitches?"
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="pitchName"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }
          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };
  const onError = () => {
    const validation = true;
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>
            <Row className="mb-0 mt-0">
              <Col md="auto">
                <div ref={ref}>
                  <Overlay
                    show={show}
                    target={target}
                    placement="right"
                    container={ref.current}
                    containerPadding={20}
                  >
                    <Popover
                      id="popover-contained"
                      style={{ backgroundColor: '' }}
                    >
                      <Popover.Header as="h3">Help Center</Popover.Header>
                      <Popover.Body as="div" style={{ backgroundColor: '' }}>
                        <Row
                          as={motion.div}
                          className="g-3"
                          initial={{ x: '-20%' }}
                          animate={{ x: '0%' }}
                          exit={{
                            x: window.innerWidth,
                            transition: { duration: 0.5 }
                          }}
                        >
                          <PageHeader
                            className="text-primary"
                            title="Company Pitch"
                            description=""
                          />
                          <div
                            className="border-none rounded mt-2"
                            style={{ maxHeight: '280px', overflowY: 'auto' }}
                          >
                            <Row>
                              <div
                                className="border-none rounded mt-0"
                                style={{
                                  maxHeight: '320px',
                                  overflowY: 'auto'
                                }}
                              >
                                <h6 className="fw-bold mt-1">Introduction</h6>
                                <div className="border-dashed border-bottom my-3" />
                                <p>
                                  A business pitch is a concise presentation
                                  designed to persuade potential investors,
                                  partners, or customers about the viability,
                                  benefits, and potential success of a business
                                  idea, product, or service. It should be
                                  engaging, clear, and compelling, conveying
                                  both the vision and the practicality of the
                                  business concept.
                                </p>
                                <p>
                                  It serves as a roadmap for entrepreneurs,
                                  investors, and stakeholders, providing a clear
                                  understanding of the business concept, market
                                  analysis, financial projections, and
                                  implementation strategy.
                                </p>
                                <p>
                                  A well-crafted business plan not only
                                  articulates the business idea but also
                                  demonstrates it's feasibility, potential for
                                  growth, and profitability.
                                </p>
                                <p>
                                  It serves as a tool for decision-making,
                                  securing funding, and guiding the day-to-day
                                  operations of the business.
                                </p>
                                <p>
                                  From startup seeking investment to established
                                  companies planning expansion, a business plan
                                  is an essential tool for navigating the
                                  complexities of the business landscape and
                                  achieving long-term success.
                                </p>
                                <div className="border-dashed border-bottom my-3" />
                                <h5>Key Business Plan Details</h5>
                                <div className="border-dashed border-bottom my-3" />
                                <h6>1. Executive Summary:</h6>
                                <p>
                                  A brief overview of the business plan,
                                  highlighting the key points and objectives.
                                </p>
                                <div className="border-dashed border-bottom my-3" />
                                <h6>2. Mission Statement:</h6>
                                <p>
                                  A mission statement is a few lines of text
                                  that introduces your company to the world and
                                  briefly states your intentions.
                                  Example:"SquarePlans" purpose is to provide an
                                  easy to use online business planning platform,
                                  that will lay the foundation to grow a
                                  successful business.
                                </p>
                                <div className="border-dashed border-bottom my-3" />
                                <h6>3. Vission Statement:</h6>
                                <p>
                                  A vision statement serves as a guiding beacon
                                  for a company, articulating its long-term
                                  aspirations, goals, and desired future state.
                                  It provides a clear direction for strategic
                                  decision-making, shaping the organization's
                                  path to growth and success.
                                </p>
                                <div className="border-dashed border-bottom my-3" />
                                <h6>4. Business Description:</h6>
                                <p>
                                  A detailed description of the business
                                  concept, including its products or services,
                                  target market, unique selling proposition, and
                                  competitive advantage.
                                </p>
                                <div className="border-dashed border-bottom my-3" />
                                <h6>5. Management and Organization:</h6>
                                <p>
                                  Information about the management team and
                                  organizational structure of the business,
                                  including key personnel, roles and
                                  responsibilities, and any advisory board or
                                  external partners.
                                </p>
                                <div className="border-dashed border-bottom my-3" />
                                <h6>3. Market Analysis:</h6>
                                <p>
                                  An analysis of the industry and market in
                                  which the business operates, including market
                                  size, growth trends, customer needs and
                                  preferences, and competitor analysis.
                                </p>
                                <h6>4. Marketing and Sales Strategy:</h6>
                                <p>
                                  A description of how the business plans to
                                  attract and retain customers, including
                                  marketing strategies, sales channels, pricing
                                  strategy, and promotional activities.
                                </p>
                                <h6>5. Product and Services Sales Strategy:</h6>
                                <p>
                                  A description of how the business plans to
                                  attract and retain customers, including
                                  marketing strategies, sales channels, pricing
                                  strategy, and promotional activities.
                                </p>
                                <h6>6. Operational Plan:</h6>
                                <p>
                                  Details about the day-to-day operations of the
                                  business, including the location, facilities,
                                  equipment, suppliers, and production process.
                                </p>
                                <h6>6. Management and Organization:</h6>
                                <p>
                                  Information about the management team and
                                  organizational structure of the business,
                                  including key personnel, roles and
                                  responsibilities, and any advisory board or
                                  external partners.
                                </p>
                                <h6>7. Financial Plan:</h6>
                                <p>
                                  Financial projections for the business,
                                  including sales forecasts, expense budgets,
                                  cash flow projections, and a break-even
                                  analysis. This section may also include
                                  information about funding requirements and
                                  sources of funding.
                                </p>
                                <h6>8. Risk Management Plan:</h6>
                                <p>
                                  Identification and assessment of potential
                                  risks and challenges that could impact the
                                  success of the business, along with strategies
                                  for mitigating those risks.
                                </p>
                                <h6>9. Appendices:</h6>
                                <p>
                                  Additional information that supports the main
                                  sections of the business plan, such as resumes
                                  of key personnel, market research data, legal
                                  documents, and other relevant materials.
                                </p>
                              </div>
                              <div className="border-dashed border-bottom my-3" />
                            </Row>
                          </div>
                        </Row>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </Col>
              <Col md></Col>
            </Row>

            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              <Row className="mt-0 mb-2 g-0">
                <FalconComponentCard>
                  <FalconComponentCard.Header title="Plans" noPreview>
                    <p className="mt-2 mb-0">
                      “Choose and customize one of our pre-designed plans to fit
                      your business vision.”
                    </p>
                  </FalconComponentCard.Header>
                  <FalconComponentCard.Body>
                    <SearchBox autoCompleteItem={autoCompleteInitialItem} />
                  </FalconComponentCard.Body>
                </FalconComponentCard>
              </Row>
              <Row>
                <Col lg={4} className="d-flex justify-content-center">
                  <div className="text-center">
                    <Row className="justify-content-center">
                      <Col md={6} className="mb-4">
                        {cardData.slice(0, 3).map((card, index) => (
                          <Card
                            key={index}
                            className="bg-dark text-white mb-4"
                            style={{
                              ...cardStyle,
                              ...(hoveredCard === index && cardHoverStyle),
                              ...(selectedCard === card.title &&
                                cardSelectedStyle)
                            }}
                            onMouseEnter={() => setHoveredCard(index)}
                            onMouseLeave={() => setHoveredCard(null)}
                            onClick={() => onCardClick(card.title)}
                          >
                            <Card.Img
                              src={`${process.env.PUBLIC_URL}/template-assets/img/pitch/${card.img}`}
                              style={{ opacity: '0.5' }}
                              alt="Card image cap"
                            />
                            <Card.ImgOverlay className="d-flex flex-column justify-content-end">
                              <Card.Title
                                style={{ color: 'black', fontSize: '1.00rem' }}
                              >
                                {card.title}
                              </Card.Title>
                            </Card.ImgOverlay>
                          </Card>
                        ))}
                      </Col>
                      <Col md={6} className="mb-4">
                        {cardData.slice(3, 6).map((card, index) => (
                          <Card
                            key={index + 3}
                            className="bg-dark text-white mb-4"
                            style={{
                              ...cardStyle,
                              ...(hoveredCard === index + 3 && cardHoverStyle),
                              ...(selectedCard === card.title &&
                                cardSelectedStyle)
                            }}
                            onMouseEnter={() => setHoveredCard(index + 3)}
                            onMouseLeave={() => setHoveredCard(null)}
                            onClick={() => onCardClick(card.title)}
                          >
                            <Card.Img
                              src={`${process.env.PUBLIC_URL}/template-assets/img/pitch/${card.img}`}
                              style={{ opacity: '0.5' }}
                              alt="Card image cap"
                            />
                            <Card.ImgOverlay className="d-flex flex-column justify-content-end">
                              <Card.Title
                                style={{ color: 'black', fontSize: '1.00rem' }}
                              >
                                {card.title}
                              </Card.Title>
                            </Card.ImgOverlay>
                          </Card>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={8}>
                  <Form.Group
                    as={Col}
                    lg={12}
                    controlId="pitchName"
                    className="mb-2"
                  >
                    <FloatingLabel label="Pitch Name">
                      <Form.Control
                        type="text"
                        placeholder="Pitch Name"
                        name="pitchName"
                        {...register('pitchName', {
                          required: 'Pitch Name is required.'
                        })}
                        isInvalid={errors['pitchName']}
                        isValid={
                          Object.keys(errors).length > 0 && !errors['pitchName']
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors['pitchName']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <TinymceEditor
                    handleChange={e => setPitchDescription(e)}
                    value={pitchDescription}
                  ></TinymceEditor>

                  <Row className="align-items-end">
                    <Col className="d-flex justify-content-end align-items-end pt-3">
                      <IconButton
                        variant="outline-success"
                        size="sm"
                        icon="fa fa-floppy-o"
                        type="submit"
                        className="d-flex align-items-center"
                      >
                        Save
                      </IconButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CompanyPitchForm;
