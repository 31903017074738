import React, { useContext, useState, useEffect } from 'react';
import { Card, Col, Row, Modal } from 'react-bootstrap';
import { motion } from 'framer-motion';
import api from 'utils/api';
import { SelectedCompanyContext } from 'context/Context';
import IconButton from 'components/common/IconButton';

const cardData = [
  { title: 'accounting', img: '48.png' },
  { title: 'fashion', img: '50.png' },
  { title: 'skinCare', img: '6.png' },
  { title: 'solicitor', img: '5.png' },
  { title: 'carpentry', img: 'creating.png' },
  { title: 'bakery', img: '49.png' }
];

const cardStyle = {
  width: '8rem',
  height: '8rem',
  maxWidth: '20rem',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden'
};

const selectedStyle = {
  opacity: '1',
  border: '1px solid #2c7be5',
  boxShadow: '0 1px 12px #2c7be5'
};

const PitchModal = ({ pitch, setPitch, showPitchModal, setShowPitchModal }) => {
  const [selectedPitch, setSelectedPitch] = useState(pitch);
  const [availablePitches, setAvailablePitches] = useState([]);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleCardClick = title => {
    setSelectedPitch(title);
  };

  const handleSave = () => {
    setPitch(selectedPitch);
    setShowPitchModal(false);
  };

  useEffect(() => {
    api
      .get(`/pitches/getPitchesName?companyId=${companyDetails.id}`)
      .then(res => res.data)
      .then(data => setAvailablePitches(data));
  }, []);

  return (
    <>
      {showPitchModal && ( // Only show overlay when the modal is open
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay
            zIndex: 1000 // Ensure it covers everything else
          }}
          onClick={() => setShowPitchModal(false)} // Close modal on overlay click
        />
      )}

      <Modal
        show={showPitchModal}
        onHide={() => setShowPitchModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a Company Pitch</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Row className="g-4 justify-content-center">
            {cardData
              .filter(card => availablePitches.includes(card.title))
              .map((card, index) => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  className="d-flex justify-content-center"
                >
                  <Card
                    style={
                      selectedPitch === card.title
                        ? { ...cardStyle, ...selectedStyle }
                        : cardStyle
                    }
                    className="text-center"
                    as={motion.div}
                    onClick={() => handleCardClick(card.title)}
                  >
                    <Card.Img
                      src={`${process.env.PUBLIC_URL}/template-assets/img/pitch/${card.img}`}
                      style={{
                        opacity: selectedPitch === card.title ? '1' : '0.5'
                      }}
                      alt={`${card.title} image`}
                    />
                    <Card.ImgOverlay className="d-flex flex-column justify-content-end">
                      <Card.Title
                        style={{
                          color: 'white',
                          fontSize: '0.60rem',
                          paddingTop: '10px'
                        }}
                      >
                        {card.title}
                      </Card.Title>
                    </Card.ImgOverlay>
                  </Card>
                </Col>
              ))}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <IconButton
            variant="outline-warning"
            onClick={() => setShowPitchModal(false)}
            size="sm"
          >
            Close
          </IconButton>
          <IconButton
            variant="outline-success"
            onClick={handleSave}
            disabled={!selectedPitch}
            size="sm"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PitchModal;
